import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-phonenumber-blocksize',
  templateUrl: './select-phonenumber-blocksize.component.html',
})
export class SelectPhonenumberBlocksizeComponent implements OnInit {

  @Output() selected: EventEmitter<number> = new EventEmitter<number>();
  blockSize: {name: number}[] = [];
  selectedBlockSize = 1;

  constructor() {
  }

  ngOnInit() {
    this.blockSize.push({name: (1)});
    for (let i = 1; i <= 10; i++) {
      this.blockSize.push({name: (i * 10)});
    }
  }

  onBlockSizeSelected() {
    this.selected.emit(this.selectedBlockSize);
  }

  get selectablePhonenumbers() {
    return this.selectedBlockSize * 3;
  }

}
