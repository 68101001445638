import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-auth-sms',
  templateUrl: './auth-sms.component.html'
})
export class AuthSmsComponent implements OnInit {

  public authCode: string;
  @Output() public confirmed: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }
  ngOnInit(): void {
  }

  verifyCode() {
    this.confirmed.emit(this.authCode);
  }

  resendCode() {
    console.log('Code resending...');
  }
}
