import {Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-page-info-message-box',
  templateUrl: './page-info-message-box.component.html'
})
export class PageInfoMessageBox {
  @Output() next = new EventEmitter();


  @Input() pageHeader: string;
  @Input() cardHeader?: string;
  @Input() text: string;
  @Input() buttonText?: string;
  @Input() icon?: string;
}
