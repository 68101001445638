import {action, computed, observable, runInAction} from "mobx";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {Injectable, OnInit} from "@angular/core";
import {Model} from "./app.model";
import {AutorunComponent} from "@myshared/autorun.component";

@Injectable()
export class NavigationService extends AutorunComponent {
  @observable
  private _showMenu: boolean;
  @observable
  private inDoWorkflow: boolean;

  constructor(private router: Router, private m: Model) {
    super();

    this.router.events.subscribe((r: RouterEvent) => {
      if (r instanceof NavigationEnd) {
        // If RouterEvent is NavigationEnd instance, and we are NOT on a /do/ route: Show the Menu
        runInAction(() => {
          if (!r.url.includes('/do/')) {
              this.inDoWorkflow = false;
          } else {
            runInAction(() => {
              this.inDoWorkflow = true;
            })
          }
        });
      }
    });

    this.autorun(() => {
      if (!this.m.workflow.inWorkflow
        && !this.inDoWorkflow
        && !this.m.agreement.agreementsToAck
        && !this.m.partner.inTest
        && this.m.account.isAuthorized
        && this.m.account.isInCompany) {

        this.onShowMenu();
      } else {
        this.onHideMenu();
      }
    });


  }

  @action private onShowMenu() {
    this._showMenu = true;
  }

  @action private onHideMenu() {
    this._showMenu = false;
  }

  @computed public get isMenu() {
    return this._showMenu;
  }
}
