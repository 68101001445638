import {Component, Input, OnInit} from '@angular/core';
import {PartnerTest} from '../partner.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-test-card',
  templateUrl: './test-card.component.html',
})
export class TestCardComponent implements OnInit {

  @Input() public test: PartnerTest;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  public startTest() {
    this.router.navigate(['/partner', 'test', this.test.id]);
  }
}
