import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {Router} from '@angular/router';
import {AutorunComponent} from '@myshared/autorun.component';
import {AnalyticsService} from "../../analytics.service";

@Component({
  selector: 'app-company-choose-new-existing',
  templateUrl: './company-choose-new-existing.component.html',
})
export class CompanyChooseNewExistingComponent extends AutorunComponent implements OnInit {
  public inCompany: boolean;
  public step: CompanyChooseSteps;

  constructor(private m: Model, private router: Router, private analyticsService: AnalyticsService) {
    super();
  }

  ngOnInit() {
    this.step = 'start';
    this.autorun(() => {
      this.inCompany = this.m.account.isInCompany;
      if (this.inCompany) {
        this.router.navigate(['/subscriptions', 'my']);
        return;
      }
    });
  }

  public createCompany() {
    this.step = 'create';
  }

  public selectCompany() {
    this.step = 'select';
  }

  onCreated() {
    this.analyticsService.addTagManager('new_company_signup');
    window.location.href = '/subscriptions/my'; // normal router navigate does not work at this point
  }

  onBack() {
    this.step = 'start';
  }
}

type CompanyChooseSteps = 'start' | 'select' | 'create';
