<app-content-header backLabel="app_cancel" [hideBackButton]="true" [headline]="'phonenumbers_type_new' | i18next"></app-content-header>


<div class="row align-items-stretch">
  <div class="col">
    <app-icon-card>
      <div class="h3 red">{{ 'number_at_contact_sales_headline' | i18next }}</div>
      <div [innerHtml]="'number_at_new_contact_sales_text' | i18next"></div>

      <div class="d-flex justify-content-center pt-4">
        <button class="btn btn-primary" (click)="onBackOverview()">{{ 'thank_you_buy_phonenumber_back_button' | i18next}}</button>
      </div>
    </app-icon-card>
  </div>
</div>



