
<app-content-header backLabel="app_cancel" [stepsBack]="1" [headline]="'phonenumber_port_prepare_headline' | i18next"></app-content-header>


<div class="row align-items-stretch">
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="prepare_phonenumbers" icon="fas fa-phone">
      <div [innerHtml]="'prepare_phonenumbers_text' | i18next"></div>
    </app-icon-card>
  </div>
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="prepare_latest_phonebill" icon="far fa-file-alt">
      <div [innerHtml]="'prepare_latest_phonebill_text' | i18next"></div>
    </app-icon-card>
    </div>
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="prepare_sign_pdf" icon="fas fa-edit">
      <div [innerHtml]="'prepare_sign_pdf_text' | i18next"></div>
    </app-icon-card>
  </div>
</div>


<div class="d-flex justify-content-end pt-3 pb-3">
  <button class="btn btn-outline-primary" (click)="onNext()">{{ 'app_next' | i18next}}</button>
</div>
