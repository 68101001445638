import parsePhoneNumber, {
  CountryCode as CC,
  isValidPhoneNumber
} from "libphonenumber-js";

export type CountryCode = CC;

export class PhoneNumber {

  /**
   * Formats the phone number for the specified country.
   * DE and AT will be the same.
   *
   * Hint: At the moment it is not necessary to use country code, because the formatting is the same on DACH.
   * This becomes more important for international phone numbers like US or GB.
   *
   * @param phoneNumber
   * @param country
   */
  static format(phoneNumber: string, country?: string) {
    return parsePhoneNumber(phoneNumber, (country as CountryCode) ?? 'DE').formatInternational();
  }

  static parse(phoneNumber: string, country?: string) {
    return parsePhoneNumber(phoneNumber, (country as CountryCode) ?? 'DE');
  }

  /**
   * Reads a text inside phone numbers (or not) and formats anything found inside the text.
   *
   * @param text
   * @param country
   */
  static formatNumbersInText(text: string, country?: string) {
    // RegEx will search for phone numbers starting with 00 because we will get the phone numbers e.g. 0049123555123.
    const match = text.match(new RegExp('00\\d+', 'gm'));
    for (const matchedNumber of match) {
      const formattedPhoneNumber = PhoneNumber.format(matchedNumber, country);
      text = text.replace(matchedNumber, formattedPhoneNumber);
    }
    return text;
  }

  /**
   * Checks if input can be parsed as a "valid" phone number. A phone number is "valid" when it has valid length,
   * and the actual phone number digits match the regular expressions for its country.
   * @param phoneNumber
   * @param country
   */
  static isValid(phoneNumber: string, country?: string): boolean {
    return isValidPhoneNumber(phoneNumber, (country as CountryCode) ?? 'DE')
  }

}
