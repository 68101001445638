<div class="mb-3">
  <button name="back" class="btn btn-outline-primary" [routerLink]="['/support']" >{{ 'app_back' | i18next }}</button>
</div>
<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ "support_select_subscription" | i18next }}</div>
  <div class="ml-auto bd-highlight">
    &nbsp;
  </div>
</div>

<app-filter (searchFilterChanged)="filterLicenses($event)"
            [placeholder]="'appliance_search_placeholder' | i18next"></app-filter>

<i class="fa fa-info-circle mb-3 red"></i><span class="ml-2">{{ "support_select_subscription_hint" | i18next }}</span>
<div class="row align-items-stretch mt-3">
  <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let s of subscriptions" [showSubscriptionDetailLink]="true" [subscription]="s">
    <button *ngIf="s.hasSupport" name="select" class="btn btn-outline-primary" (click)="selectSubscription(s)">{{ 'app_select' | i18next }}</button>
    <a *ngIf="!s.hasSupport" [routerLink]="['/subscriptions', s.appliance_id]" name="upgrade" class="btn btn-outline-secondary">{{ 'support_upgrade_to_premium' | i18next }}</a>
  </app-subscription-card>
</div>
