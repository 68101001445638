import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../app.model';
import {AuthService} from '../auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {WorkflowService} from '../workflow/workflow.service';
import {CurrentUser} from '../auth/account.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invite',
  templateUrl: 'invite.component.html',
})
export class InviteComponent extends AutorunComponent implements OnInit {

  public isActionRequired: boolean;
  public currentUser: CurrentUser;
  public initialized = false;

  constructor(private m: Model,
              private auth: AuthService,
              private route: ActivatedRoute,
              private workflowService: WorkflowService,
              private location: Location) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.currentUser = this.m.account.currentUser;
    });
    this.route.queryParamMap.subscribe(params => {
      if (params.has('t')) {
        const urlToken = params.get('t');
        this.workflowService.parseRequest(urlToken);
        if (this.m.workflow.isInvite) {
          this.m.account.setInvite();
          const token = this.m.workflow.request.userData.t;
          this.auth.activateInvite(token).subscribe(r => {
            if (r.required_action === 'UPDATE_PASSWORD') {
              this.isActionRequired = true;
              // To avoid error box after reload, location.go removes the query parameter without reloading the page
              this.location.go('/invite');
            } else {
              window.location.replace(window.location.origin);
            }
            this.initialized = true;
            this.m.workflow.resetWorkflow();
          });
        } else {
          window.location.replace('/');
          return;
        }
      } else {
        window.location.replace('/');
        return;
      }
    });
  }

  refresh() {
    window.location.replace(window.location.origin);
  }

  get contactName() {
    return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
  }
}
