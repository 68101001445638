<div class="d-flex flex-column">
  <div class="d-flex">
    <div class="align-self-start"><i class="fa fa-th-large red mr-1"></i></div>
    <div [innerHTML]="'phonenumber_block_type' | i18next: { length: length }"></div>
  </div>
  <div class="row m-0 p-0">
    <div class="col col-sm-4 m-0 p-0">{{ 'start' | i18next }}:</div>
    <div class="col col-sm-8 m-0 p-0 text-nowrap">{{from | phonenumber }}</div>
  </div>
  <div class="row m-0 p-0">
    <div class="col col-sm-4 m-0 p-0">{{ 'end' | i18next }}:</div>
    <div class="col col-sm-8 m-0 p-0 text-nowrap">{{ to | phonenumber }}</div>
  </div>
  <div class="row m-0 p-0" *ngIf="deliveryDate">
    <div class="col-12 m-0 p-0">{{ 'phonenumber_delivery_date' | i18next }}:</div>
    <div class="col-12 m-0 p-0 text-nowrap">{{ deliveryDate | localized_date }}</div>
  </div>
</div>
