import {runInAction} from 'mobx';

export class Benefits {
  certified: Array<string>;
  premium: Array<string>;
  excellence: Array<string>;
}

export class BenefitsModel {

  public benefits: Benefits;

  constructor() {
    runInAction(() => this.benefits);
  }
}
