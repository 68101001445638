import { Component, OnInit } from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../app.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Base64} from 'js-base64';

@Component({
  selector: 'app-welcome-activated',
  templateUrl: './welcome-activated.component.html',
})
export class WelcomeActivatedComponent extends AutorunComponent implements OnInit {

  public name: string;

  constructor(
    private m: Model,
    private router: Router,
    private route: ActivatedRoute
  ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.name = this.m.account.currentUser.first_name + ' ' +  this.m.account.currentUser.last_name;
    });
  }

  goToWorkflow() {
    this.m.workflow.resetWorkflow();
    let target = ['/'];
    if (this.route.snapshot.paramMap.has('subroute')) {
      const token = Base64.decode(this.route.snapshot.paramMap.get('subroute'));
      const newTarget = (token || '').split('/').filter(e => !!e);
      if (newTarget) {
        target = newTarget;
      }
    }
    this.router.navigate(target);
  }
}
