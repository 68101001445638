import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {contactForm, cspContactForm, getContactFromForm} from '../contact.form';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {I18NextService} from 'angular-i18next';
import {CompanyService} from '../company.service';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Model} from "../../app.model";
import {Contact} from "../company.model";

@Component({
  selector: 'app-contact-invite',
  templateUrl: './contact-invite.component.html',
  styleUrls: []
})
export class ContactInviteComponent implements OnInit, AfterViewInit {
  public customerId: string;
  private builder: PCFormBuilder;
  public showPermissionText = false;

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;
  is20CSP: boolean;


  constructor(private pcFormService: PCFormsService,
              private i18next: I18NextService,
              private companyService: CompanyService,
              private messageService: MessageService,
              private router: Router,
              private m: Model,
              private route: ActivatedRoute) { }
  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId');
    this.is20CSP = this.m.account.currentUser.companyIsPC20Csp;
    this.createForm();
  }

  ngAfterViewInit() {
    if (this.builder) {
      this.builder.autoFocus();
    }
  }

  createForm() {
    let mypascomAccounValues = [];
    if (!this.isPartnerInvite) {
      mypascomAccounValues = ['enabled', 'admin'];
      // only company owner can set permissions, partner will invite contact with admin permissions
      this.showPermissionText = true;
    }

    if (!this.showPermissionText && this.is20CSP) {
      this.builder = cspContactForm(this.pcFormService);
    } else {
      this.builder = contactForm(this.pcFormService, this.i18next, mypascomAccounValues);
    }

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.formGroup.removeControl('first_name');
  }

  onInvite() {
    if (this.formGroup.valid) {
      const c = getContactFromForm(this.formGroup, '');
      if (this.isPartnerInvite) {
        c.company_id = this.customerId;
      }

      if (this.is20CSP && !!this.customerId) {
        this.inviteCspContact(c);
      } else {
        this.inviteContact(c);
      }
    }
  }

  inviteContact(c: Contact) {
    this.companyService.inviteContact(c).subscribe(() => {
      this.formGroup.reset();
      this.notifyAfterContactInvitedOrAdded(c, 'contact_invited');
    });
  }

  inviteCspContact(c: Contact) {
    this.companyService.addCSPContact(c).subscribe(() => {
      this.formGroup.reset();
      this.notifyAfterContactInvitedOrAdded(c, 'contact_created');
    });
  }

  notifyAfterContactInvitedOrAdded(c: Contact, translationDetailKey: 'contact_created' | 'contact_invited') {
    this.messageService.add({severity: 'success', summary: this.i18next.t('success') as string,
      detail: this.i18next.t(translationDetailKey) as string});
    if (!!c.company_id) {
      this.router.navigate(['/customers', c.company_id, 'contacts']);
    } else {
      this.router.navigate(['/company/contacts/']);
    }
  }

  get isPartnerInvite() {
    return !!this.customerId;
  }


}
