<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'affiliate_partnership_headline' | i18next }}</div>
</div>

<app-partnership-navigation></app-partnership-navigation>
<div class="row mb-3">
  <div class="col-12">
    <div class="card h-100 card-nb card-nm achievement-container normal">
      <div class="card-body">
        <span class="partner-level-headline" [innerHtml]="'partnership_partner_level' | i18next: {partnerLevel: partnerLevelAsString.charAt(0).toUpperCase() + partnerLevelAsString.substr(1).toLowerCase() }"></span>
        <div class="float-right card-h3">
          <div class="column">
            <p>
            {{ 'partnership_partner_year' | i18next }}: {{ affiliate.partner_year_starts | localized_date }} - {{ affiliate.partner_year_ends | localized_date }}
            </p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ width: partnerYearPercentageElapsed }" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="row mb-3">
  <div class="col-12">
    <div class="card h-100 card-nb card-nm achievement-container normal">
      <div class="card-body">
        <div class="pie-wrapper float-left">
          <div class="arc-1" [attr.data-value]="revenueProgress"></div>
          <div class="arc-2" [attr.data-value]="subscriptionsProgress"></div>
        </div>
        <div class="legend float-right">
          <p><span class="arc-legend-1"></span>Revenue</p>
          <p><span class="arc-legend-2"></span>Subscriptions</p>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'affiliate_models_headline' | i18next }}</div>
</div>

<div class="row mb-3" *ngIf="isNextLevelLesserAsActual && remainingPartnerDays <= 60">
  <div class="col-12">
    <div class="card h-100 card-nb card-nm">
      <div class="card-body">
        <span class="red">{{ 'partnership_partner_endofperiod_text' | i18next: {nextPartnerLevel: nextPartnerLevelAsString.charAt(0).toUpperCase() + nextPartnerLevelAsString.substr(1).toLowerCase(), downgradeDate: localizedEndOfPeriodDate, stillDays: remainingPartnerDays, interpolation: { escapeValue: false} } }}</span>
      </div>
    </div>
  </div>
</div>

<div class="row align-items-xl-stretch justify-content-between mb-3">
  <app-partnership-achievement-container
    [affiliateLevel]="affiliate.level === 'certified'"
    class="col-12 col-xl-4 mb-2 mb-md-4 mb-xl-0"
    i18next-level="partnership_partner_level_certified"
    [achievements]="achievements.certified"
    [benefits]="benefits.certified"
  ></app-partnership-achievement-container>
  <app-partnership-achievement-container
    [affiliateLevel]="affiliate.level === 'premium'"
    class="col-12 col-xl-4 mb-2 mb-md-4 mb-xl-0"
    i18next-level="partnership_partner_level_premium"
    [achievements]="achievements.premium"
    [benefits]="benefits.premium"
  ></app-partnership-achievement-container>
  <app-partnership-achievement-container
    [affiliateLevel]="affiliate.level === 'excellence'"
    class="col-12 col-xl-4"
    i18next-level="partnership_partner_level_excellence"
    [achievements]="achievements.excellence"
    [benefits]="benefits.excellence"
  ></app-partnership-achievement-container>
</div>

<div class="row mb-3">
  <div class="col-12">
    <div class="achievement-container card card-nb card-nm"
         [ngClass]="{ 'normal': affiliate.level !== 'registered', 'highlight': affiliate.level === 'registered' }">
      <div class="card-body">
        <div class="row">
          <div class="achievement-container-header col-12">
            <div class="level-type h3">{{ 'partnership_partner_registered_partner' | i18next }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {{ 'partnership_partner_registered_partner_description' | i18next }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasCompletedSpecialAchievements" class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'partnership_partner_specials' | i18next }}</div>
</div>

<div class="row align-items-stretch mb-3">
  <ng-container *ngFor="let achievement of achievements.specials">
    <div class="w-100 col-xs-12 col-md-4 col-lg-4 mb-sm-3 mb-md-0" *ngIf="achievement.id != 'ac_csp' || achievement.completed">
      <div class="achievement-container normal card card-nb card-nm h-100">
        <div class="card-body">
          <app-partnership-achievement-progress-card [achievement]="achievement"
                                                     *ngIf="!achievement.completed"></app-partnership-achievement-progress-card>
          <app-partnership-achievement-complete-card [achievement]="achievement"
                                                   *ngIf="achievement.completed"></app-partnership-achievement-complete-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>
