<div class="d-flex align-items-center mb-3">
  <div class="h3">{{'company_details_header' | i18next}}</div>
</div>
<app-company-navigation></app-company-navigation>
<div class="card card-nb">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <h3>{{'payment_details_form_header' | i18next}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dynamic-primeng-form [group]="sepaGroup" [layout]="sepaLayout" [model]="sepaModel"></dynamic-primeng-form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-outline-primary" [disabled]="sepaGroup.status !== 'VALID'" (click)="onUpdate()">{{'app_update' | i18next}}</button>
    </div>
  </div>
</div>
