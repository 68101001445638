<app-content-header [backLabel]="isWorkflow ? 'app_cancel' : 'app_back'" [historyBack]="!isWorkflow" (backEvent)="onBack()"
                    [headline]="'nfr_one_prepare_headline' | i18next"></app-content-header>

<div class="row align-items-stretch">
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="nfr_one_create_trial_headline" icon="fa fa-plus">
      <div [innerHtml]="'nfr_one_create_trial_content' | i18next"></div>
    </app-icon-card>
  </div>
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="nfr_one_upgrade_headline" icon="fa-solid fa-arrow-trend-up">
      <div [innerHtml]="'nfr_one_upgrade_content' | i18next"></div>
    </app-icon-card>
  </div>
  <div class="col-sm-12 col-lg-4 mb-3 mb-lg-0">
    <app-icon-card header="nfr_one_credit_headline" icon="fa fa-coins">
      <div [innerHtml]="'nfr_one_credit_content' | i18next: { cu: getNfrOnePartnerCu, partnerLevel: partnerLevelTranslation }"></div>
    </app-icon-card>
  </div>
</div>


<div class="d-flex justify-content-end align-items-center pt-3 pb-3 mt-3" *ngIf="isWorkflow">
 <app-pascom-checkbox class="pt-1" [text]="'nfr_one_accept_prepare_content' | i18next" (acceptChanged)="accept($event)"></app-pascom-checkbox>
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="!accepted">{{ 'create_trial_btn' | i18next}}</button>
</div>
