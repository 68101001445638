import {
  workflowPartnerOneUpgradeCuRouting
} from "./workflow-partner-one-upgrade-cu/workflow-partner-one-upgrade-cu.routing";
import {
  workflowPartnerOneUpgradeSlaRouting
} from "./workflow-partner-one-upgrade-sla/workflow-partner-one-upgrade-sla.routing";
import {
  workflowPartnerOneDowngradeCuRouting
} from "./workflow-partner-one-downgrade-cu/workflow-partner-one-downgrade-cu.routing";
import {
  workflowPartnerOneDowngradeSlaRouting
} from "./workflow-partner-one-downgrade-sla/workflow-partner-one-downgrade-sla.routing";
import {
  workflowPartnerOneTrialCreateRouting
} from "./workflow-partner-one-trial-create/workflow-partner-one-trial-create.routing";

export const workflowPartnerRouting = [
  ...workflowPartnerOneUpgradeCuRouting,
  ...workflowPartnerOneUpgradeSlaRouting,
  ...workflowPartnerOneDowngradeCuRouting,
  ...workflowPartnerOneDowngradeSlaRouting,
  ...workflowPartnerOneTrialCreateRouting,
]