import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {pcDate} from "../date.service";

@Pipe({
  name: 'localized_datetime'
})
@Injectable()
export class LocalizedDateTimePipe implements PipeTransform {

  constructor() {}

  transform(value: any): any {
    return pcDate(value).formatDateTime();
  }
}
