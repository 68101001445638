import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {PhonenumberService} from '../phonenumber.service';
import {SubscriptionService} from '../../subscription/subscription.service';
import {observable} from '@myshared/mobx-connector';
import {action, runInAction} from 'mobx';
import {filterText} from '@myshared/utils';
import {Block} from '../phonenumber.model';

@Component({
  selector: 'app-phonenumber-list',
  templateUrl: './phonenumber-list.component.html'
})
export class PhonenumberListComponent extends AutorunComponent implements OnInit {
  public isSearching = false;
  public phoneNumberBlocks: Block[] = [];
  public isAdmin = false;

  @observable private filter$: string;

  constructor(private m: Model, private phonenumberService: PhonenumberService, private subscriptionService: SubscriptionService) {
    super();
  }

  ngOnInit(): void {
    runInAction(() => {
      this.filter$ = '';
    });
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      // We need to map the subscriptions into the block numbers,
      // because we need more details about the subscription, like valid status
      if (this.m.subscription.subscriptions.length > 0 && this.m.phonenumber.blocks.length > 0) {
        this.phoneNumberBlocks = this.m.phonenumber.blocks.map(t => {
          t.appliance = this.m.subscription.subscriptions.find(s => s.appliance_id === t.appliance?.appliance_id);
          return t;
        });
        // After we map all the information together, filter the phone number blocks
        this.phoneNumberBlocks = this.phoneNumberBlocks.filter(t =>
          !!t.appliance?.appliance_id && filterText(t.numbers, this.filter$).length > 0);
      }
    });
    this.phonenumberService.refresh(true);
    this.subscriptionService.refresh(true);
  }

  @action
  doFilter(filter: string) {
    this.filter$ = filter;
  }
}
