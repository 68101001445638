<app-phonenumber-port-provider *ngIf="step === portSteps.select_provider"
                               (onBack)="back()"
                               countryCode="AT"
                               (onNext)="onProviderSelected($event)"></app-phonenumber-port-provider>


<app-phonenumber-port-one-number *ngIf="step === portSteps.select_phone_numbers"
                                 [countryCode]="countryCode"
                                 [trunkPrefix]="trunkPrefix"
                                 (onBack)="back()"
                                 (selected)="onSelectNumbersToPortComplete($event)">
</app-phonenumber-port-one-number>