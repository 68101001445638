import {Component, Input, OnInit} from '@angular/core';
import {Achievement} from '../../achievements.model';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-partnership-achievement-complete-card',
  templateUrl: './partnership-achievement-complete-card.component.html'
})
export class PartnershipAchievementCompleteCardComponent implements OnInit {

  @Input()
  achievement: Achievement;
  @Input()
  cardClass;

  constructor(private i18next: I18NextService) { }

  ngOnInit() {
  }

  get helpContent() {
    if (this.achievement.i18next_help) {
      return this.i18next.t([this.achievement.i18next_help, '']) as string;
    }

    return '';
  }

  get descriptionContent() {
    if (this.achievement.i18next_description) {
      return this.i18next.t([this.achievement.i18next_description, '']) as string;
    }

    return '';
  }

}
