import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "../subscription.model";
import {SubscriptionService} from "../subscription.service";
import {ConfirmationService} from "primeng/api";
import {I18NextService} from "angular-i18next";

@Component({
  selector: 'app-fallback-number-card',
  templateUrl: './fallback-number-card.component.html'
})
export class FallbackNumberCardComponent {

  @Input()
  public subscription: Subscription;

  constructor(private router: Router,
              private subscriptionService: SubscriptionService,
              private confirmService: ConfirmationService,
              private i18next: I18NextService) { }

  public openChangeFallbackNumberWorkflow() {
    this.router.navigate(['/do', 'change-fallback-number', this.subscription.appliance_id]);
  }

  removeFallbackNumber() {
    this.confirmService.confirm({
      message: this.i18next.t('fallback_number_confirm') as string,
      key: 'fallback-number',
      accept: () => {
        this.subscriptionService.setFallbackNumber(this.subscription.appliance_id, '')
          .subscribe(() => {
            window.location.reload();
        });
      }
    });


  }
}
