<app-content-header backLabel="app_back"
                    (backEvent)="back()"
                    [historyBack]="false"
                    [headline]="'phonenumber_to_port_headline' | i18next"></app-content-header>
<form>
  <div class="row mt-2">
    <div class="col-12 col-md-6">
      <app-icon-card [stretch]="false">
        <div class="h3 red">{{ 'port_number_add_block_number' | i18next}}</div>
        <dynamic-primeng-form [group]="formGroup"
                                   [layout]="formLayout"
                                   [model]="formModel">
        </dynamic-primeng-form>
      </app-icon-card>
    </div>
    <div class="col-12 col-md-6">
      <app-icon-card [stretch]="false" header="port_number_example_a_headline"
                     headlineText="port_number_example_a_text"></app-icon-card>
      <app-icon-card [stretch]="false" header="port_number_example_b_headline"
                     headlineText="port_number_example_b_text"></app-icon-card>
      <app-icon-card [stretch]="false" header="port_number_example_c_headline"
                     headlineText="port_number_example_c_text"></app-icon-card>
    </div>
  </div>
  <div class="row d-flex justify-content-end pb-3 pt-3 form-container-footer">
    <button class="btn btn-primary" (click)="onNext()"
            type="submit"
            [disabled]="formDisabled">{{ 'app_next' | i18next}}</button>
  </div>
</form>
