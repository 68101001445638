<app-content-header [hint]="'csphost_select_name_hint' | i18next" [headline]="'csphost_select_name_header' | i18next" [stepsBack]="2" (backEvent)="back()"></app-content-header>

<p><i class="fa fa-exclamation-triangle red"></i><span class="ml-2">{{ 'instance_name_changename_hint_csphost' | i18next }}</span></p>

<div class="form-container">
  <div class="d-flex justify-content-center align-items-center">
    <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
  </div>
  <div class="d-flex justify-content-end mt-2 form-container-footer">
    <button class="btn btn-outline-primary" [disabled]="formGroup.status !== 'VALID'" (click)="onContinue()">{{ 'csphost_create' | i18next }}</button>
  </div>
</div>
