import {Component} from '@angular/core';

@Component({
  selector: 'app-headline-content',
  templateUrl: './headline-content.component.html',
  styles: [
  ]
})
export class HeadlineContentComponent {
}
