import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '../../../autorun.component';
import {Model} from '../../../../app.model';
import {MaintenanceService} from '../../maintenance.service';

@Component({
  selector: 'app-maintenance-api',
  templateUrl: './maintenance-api.component.html'
})
export class MaintenanceApiComponent extends AutorunComponent implements OnInit {
  public isRefreshButtonShown = false;

  constructor(private m: Model, private maintenanceService: MaintenanceService) {
    super();
  }
  ngOnInit() {
    this.autorun(() => {
      this.isRefreshButtonShown = this.m.maintenance.isRefreshButtonShown;
    });
  }

  doRefresh() {
    this.maintenanceService.refreshApplication();
  }
}
