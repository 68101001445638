import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {autorun} from 'mobx';
import {Model} from '../app.model';

@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(private router: Router, private m: Model) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let resolve: (boolean) => void;
    const activatePromise = new Promise<boolean>(function(ok) {
      resolve = ok;
    });
    let autosub = null;
    autosub = autorun(() => {
      if (!this.m.account.isInitialized) {
        return;
      }
      if (this.m.account.isInCompany) {
        resolve(true);
      } else {
        this.router.navigate(['/choose-company']);
        resolve(false);
      }
      if (autosub) {
        autosub();
      }
    });
    return activatePromise;
  }

}
