<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ 'profile_details_header' | i18next }}</div>
  <div class="ml-auto bd-highlight" *ngIf="mode === 'multiprofile'">
    <button class="btn btn-outline-primary" (click)="addProfile()">{{ 'profile_add_new' | i18next }}</button>
  </div>
</div>
<app-partnership-navigation></app-partnership-navigation>
<i class="fa fa-info-circle mb-3 red"></i><span class="ml-2">{{ 'profile_hint' | i18next}}</span>
<div *ngIf="mode === 'enabled'">
    <app-profile-details [profile]="firstProfile"></app-profile-details>
</div>
<div class="row" *ngIf="mode === 'multiprofile'">
    <app-profile-card class="col-xs-12 col-md-6" *ngFor="let p of profiles" [profile]="p"></app-profile-card>
</div>
