<div class="d-flex flex-column h-100" *ngIf="flatrates && flatrates.length > 0">
    <app-content-header [backLabel]="'app_cancel'" [historyBack]="false" (backEvent)="onBack()" [headline]="'tariff_choose_headline' | i18next"></app-content-header>
    <div class="row d-flex justify-content-center mt-2">
        <app-tariff-info-card class="col-sm-12 col-md-6 col-lg-3 mb-3 mb-lg-0"
                              (selected)="onTariffSelected($event)"
                              [currentlyUsed]="currentTariff === flatrates['PC-ONE-TARIFF-FLAT-S'].name"
                              [tariff]="flatrates['PC-ONE-TARIFF-FLAT-S']"></app-tariff-info-card>
        <app-tariff-info-card class="col-sm-12 col-md-6 col-lg-3 mb-3 mb-lg-0"
                              [badgeTitle]="isAbovePopularSelected ? '' : 'tariff_popular'"
                              (selected)="onTariffSelected($event)"
                              [currentlyUsed]="currentTariff === flatrates['PC-ONE-TARIFF-FLAT-M'].name"
                              [tariff]="flatrates['PC-ONE-TARIFF-FLAT-M']"></app-tariff-info-card>
        <app-tariff-info-card class="col-sm-12 col-md-6 col-lg-3 mb-3 mb-lg-0"
                              (selected)="onTariffSelected($event)"
                              [currentlyUsed]="currentTariff === flatrates['PC-ONE-TARIFF-FLAT-L'].name"
                              [tariff]="flatrates['PC-ONE-TARIFF-FLAT-L']"></app-tariff-info-card>
        <app-tariff-info-card class="col-sm-12 col-md-6 col-lg-3 mb-3 mb-lg-0"
                              (selected)="onTariffSelected($event)"
                              [currentlyUsed]="currentTariff === flatrates['PC-ONE-TARIFF-FLAT-XL'].name"
                              [tariff]="flatrates['PC-ONE-TARIFF-FLAT-XL']"></app-tariff-info-card>
    </div>
    <div class="row mt-5">
        <div class="mx-auto">
            <div innerHTML="{{ 'download_complete_pricelist' | i18next:{tenant: countryTranslationCode} }}"></div>
        </div>
    </div>
    <div class="ml-auto mt-auto p-3 small">{{ 'vat_not_included' | i18next }}</div>
</div>
