<app-content-header (backEvent)="back()"
                    [stepsBack]="stepsBack"
                    [historyBack]="historyBack"
                    [headline]="'instancename_headline' | i18next"></app-content-header>

<div class="mt-2">
  <app-icon-card>
    <h3 class="h3 red mb-3">{{ 'instance_name_input_placeholder' | i18next }}</h3>
    <div class="mb-3 d-flex">
      <i class="fa fa-info-circle pt-1 mb-3 red"></i><span class="ml-2 subinformations">{{ 'instancename_pattern_hint' | i18next }}</span>
    </div>
    <div class="mb-3 d-flex">
      <i class="fa fa-exclamation-triangle pt-1 red"></i><span class="ml-2 subinformations">{{ 'instance_name_changename_hint' | i18next }}</span>
    </div>
    <div class="mb-5 d-flex">
      <i class="fa fa-exclamation-triangle pt-1 red"></i><span class="ml-2 subinformations">{{ 'instance_name_pattern_hint' | i18next }}</span>
    </div>

    <div class="mb-5">
      <div class="red d-block d-sm-none">{{ selectedDomain }}</div>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon red d-none d-sm-block">{{ selectedDomain }}</span>
        <input type="text" pInputText
               [ngClass]="{ 'ng-invalid': validationError }"
               [placeholder]="'instance_name_input_placeholder' | i18next"
               (ngModelChange)="onInstanceNameChanged($event)"
               [(ngModel)]="instanceName">
      </div>
      <div class="position-relative">
        <small *ngIf="validationError" id="instancename-help" class="p-error block position-absolute">{{ validationError | i18next }}</small>
      </div>
    </div>
  </app-icon-card>

  <div class="d-flex justify-content-end mt-5 form-container-footer">
    <button name="continue" class="btn btn-outline-primary" [disabled]="!instanceName || validationError || buttonDisabled" (click)="onContinue()">
        {{ (isTrial ? 'start_trial_now' : 'app_continue') | i18next }}
    </button>
  </div>
</div>
