import { Component, OnInit, AfterViewInit } from '@angular/core';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {Model} from '../../app.model';
import {CountryService} from '../../country/country.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {CompanyService} from '../company.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {MessageService} from 'primeng/api';
import {getSepaDetailsFromForm, sepaForm, setSepaDetailsToForm} from '../sepa.form';
import {BankAccount} from '../company.model';
import {I18NextService} from 'angular-i18next';
import {Router} from "@angular/router";

@Component({
  selector: 'app-company-payment-details',
  templateUrl: './company-payment-details.component.html',
  styleUrls: []
})
export class CompanyPaymentDetailsComponent extends AutorunComponent implements OnInit, AfterViewInit {

  sepaModel: DynamicFormControlModel[];
  sepaGroup: FormGroup;
  sepaLayout: DynamicFormLayout;

  bankAccount: BankAccount;

  private sepa: PCFormBuilder;

  constructor(private m: Model,
              private router: Router,
              private pcFormService: PCFormsService,
              private countryService: CountryService,
              private companyService: CompanyService,
              private messageService: MessageService,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    this.sepa = sepaForm(this.pcFormService);
    this.sepaModel = this.sepa.model;
    this.sepaLayout = this.sepa.layout;
    this.sepaGroup = this.sepa.createFormGroup();

    this.autorun(() => {
      if (this.m.company.company.bankAccounts.length > 0) {
        this.bankAccount = this.m.company.company.bankAccounts[0];
      } else {
        this.bankAccount = BankAccount.fromJson({});
      }
      setSepaDetailsToForm(this.sepaGroup, this.bankAccount);
    });
    this.countryService.refresh();
    this.companyService.refresh();
  }

  ngAfterViewInit() {
    this.sepa.autoFocus();
  }

  onUpdate() {
    if (!this.sepaGroup.pristine) {
      const b = getSepaDetailsFromForm(this.sepaGroup, this.bankAccount.id);
      this.companyService.updateBankAccount(b).subscribe(r => {
        if (r) {
          this.messageService.add({severity: 'success',
            summary: this.i18next.t('company_bankaccount_update_success_summary') as string,
            detail: this.i18next.t('company_bankaccount_update_success_detail') as string});
        } else {
          this.messageService.add({severity: 'error',
            summary: this.i18next.t('company_bankaccount_update_error_summary') as string,
            detail: this.i18next.t('company_bankaccount_update_error_detail') as string});
        }
      });
    }
  }
}
