import {Routes} from "@angular/router";
import {WorkflowCspOneDowngradeCuComponent} from "./workflow-csp-one-downgrade-cu.component";

export const workflowCspOneDowngradeCuRouting: Routes = [
    { path: 'csp/downgrade-cu/:aid', component: WorkflowCspOneDowngradeCuComponent, children: [
            { path: 'info', component: WorkflowCspOneDowngradeCuComponent, data: { step: 'info' } },
            { path: 'address', component: WorkflowCspOneDowngradeCuComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowCspOneDowngradeCuComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowCspOneDowngradeCuComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowCspOneDowngradeCuComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
