<div class="achievement-container card card-nb controlled-box"
     [ngClass]="{ 'normal': !affiliateLevel, 'highlight': affiliateLevel }">

  <div class="achievement-container-header mt-3">
    <div class="d-flex align-items-center flex-column">
      <div class="level-type h3">{{ i18nextLevel | i18next }}</div>
    </div>
  </div>
  <div class="achievement-container-body h-100">
    <div class="d-flex flex-column h-100 mb-3 p-3">
      <div>
        <div *ngFor="let achievement of achievements">
          <app-partnership-achievement-progress-card [achievement]="achievement"
                                                     *ngIf="!achievement.completed"></app-partnership-achievement-progress-card>
          <app-partnership-achievement-complete-card [achievement]="achievement"
                                                     *ngIf="achievement.completed"></app-partnership-achievement-complete-card>
        </div>
      </div>
      <div class="mt-auto">
        <div class="row no-gutters align-items-xl-end benefits">
          <div class="col-12">
            <div class="h3 mt-3 mt-xl-0">{{ "benefits" | i18next }}</div>
          </div>
        </div>
        <div class="row no-gutters align-items-end benefits" *ngFor="let benefit of benefits">
          <div class="benefits-item col-12">
            <div class="d-flex">
              <i class="fas fa-plus-circle benefits-icon"></i>
              <span>{{ benefit | i18next }}</span>
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>



</div>
