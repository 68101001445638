import {Component, OnInit} from '@angular/core';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';

@Component({
  selector: 'app-workflow-management-password-reset',
  templateUrl: './workflow-management-password-reset.component.html',
  styleUrls: []
})
export class WorkflowmanagementPasswordresetComponent extends AutorunComponent implements OnInit {

  public subscription: Subscription;
  public applianceId: string;
  public requestToken: string;
  public callback: string;
  public error: string;

  constructor(
    private m: Model,
    private subscriptionService: SubscriptionService,
  ) {
    super();
  }

  ngOnInit() {
    this.error = null;
    this.applianceId = this.m.workflow.userData.aid;
    this.requestToken = this.m.workflow.userData.rt;
    this.callback = this.m.workflow.userData.c;
    this.subscriptionService.refresh(true);
    this.autorun(() => {
      this.subscription = this.m.subscription.subscriptions.find((v) => v.appliance_id == this.applianceId);
      if (!this.subscription) {
        this.error = 'subscription_not_found';
      } else {
        this.error = null;
      }
    });
  }

  public resetPassword() {
    this.subscriptionService.generatePasswordResetToken(this.subscription, this.requestToken).subscribe({
      next: (r) => {
        window.location.replace(this.callback + '?t=' + r.token);
      },
      error: (e) => {
        this.error = e.message;
      }
    });
  }
  public back() {
    window.location.replace(this.m.workflow.request.redirectUrl);
  }
}
