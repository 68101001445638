import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Agreement} from './agreement.model';
import {I18NextService} from 'angular-i18next';
import {map} from 'rxjs/operators';
import {Model} from '../app.model';
import {runInAction} from 'mobx';
import {Router} from '@angular/router';
import {AffiliateService} from '../partner/affiliate/affiliate.service';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class AgreementService {
  private needsRefresh = true;

  constructor(
    private httpClient: HttpClient,
    private i18n: I18NextService,
    private router: Router,
    private m: Model,
    private affiliateService: AffiliateService,
    private authService: AuthService
    ) { }

  public refresh(force: boolean = false): void {
    if (this.needsRefresh || force) {
      this.httpClient.get<any[]>('/services/agreements', {
        params: {
          lang: this.i18n.t('language_code') as string,
        }
      }).pipe(map(lst => lst.map(v => Agreement.fromJson(v))))
        .subscribe(lst => runInAction(() => {
          this.m.agreement.agreements = lst;
          this.needsRefresh = false;
        }));
    }
  }

  public accept(agreementId: number) {
    this.httpClient.post<any[]>('/services/agreement/' + agreementId, {}).subscribe({
      next: () => {
        this.refresh(true);
        this.affiliateService.refresh(true);
        this.authService.checkAuthorization();
      }
    });
  }

  public keepInstance(agreementId: number, keep: boolean) {
    this.httpClient.post<any[]>('/services/agreement/' + agreementId, {
      keep_instance: keep ? 'yes' : 'no',
    })
    .subscribe({
      next: () => {
        this.refresh(true)
        this.affiliateService.refresh(true)
        this.authService.checkAuthorization()
      }
    });

  }

}
