<app-content-header
  (backEvent)="back()"
  [headline]="'cancel_subscription_headline' | i18next:{ applianceId: subscription.appliance_id, applianceName: subscription.name }"></app-content-header>

<div class="col text-align-center mb-5">
  <div class="h3 red">{{ 'cancel_subscription_warning_text' | i18next }}</div>
</div>

<div class="row align-items-stretch">
  <div class="col-md-6 offset-md-3" *ngIf="isNextInvoice">
    <div class="card h-100 card-nb">
      <div class="card-body pb-0">
        <div class="">
          <h2 style="min-height:70px;">{{ 'cancel_subscription_cancel_endofsubscription' | i18next }}</h2>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <p style="min-height:130px;">{{ 'cancel_subscription_cancel_endofsubscription_description' | i18next:{ nextInvoice: nextInvoiceDate, applianceName: subscription.name, interpolation: { escapeValue: false } } }}</p>
        <div class="row justify-content-end">
          <button class="btn btn-outline-primary" name="cancel-subscription-endofsubscription" (click)="cancelOnDate()">{{ "cancel_subscription_cancel_endofsubscription_button"  | i18next:{ nextInvoice: nextInvoiceDate , interpolation: { escapeValue: false } } }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col text-align-center mb-5">
  <button class="btn btn-link" name="cancel-subscription-abort" (click)="back()">{{ "cancel_subscription_abort"  | i18next }}</button>
</div>
