import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CloudDomain} from "../buy.service";

@Component({
  selector: 'app-select-domain',
  templateUrl: './select-domain.component.html'
})
export class SelectDomainComponent implements OnInit {
  @Input() public domains: CloudDomain[];
  @Output() public readonly selected = new EventEmitter<CloudDomain>();
  @Output() public readonly back = new EventEmitter();

  public multiselectableDomains: { label: string; value: CloudDomain }[] = [];
  public selectedDomain: CloudDomain;

  constructor() { }

  ngOnInit(): void {
    this.selectedDomain = this.domains[0];

    if (this.domains.length === 1) {
      this.next();
      return;
    }

    this.setMultiSelectable();
  }

  public selectDomain() {
    if (!this.selectedDomain) return;
    this.next();
  }

  private next() {
    this.selected.emit(this.selectedDomain);
  }


  private setMultiSelectable() {
    this.multiselectableDomains = this.domains.map(c => {
      return {
        label: c.domain,
        value: c
      }
    });
  }
}
