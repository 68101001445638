import {Component, EventEmitter, Output, Input} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';
import {filterText} from '@myshared/utils';
import {runInAction} from 'mobx';
import {action, observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-select-csphost',
  templateUrl: './select-csphost.component.html',
})
export class SelectCsphostComponent extends AutorunComponent {

  public subscriptions: Subscription[];
  @Input() currentSubscription: Subscription;
  @Output() public readonly selected = new EventEmitter<{aid: string, aname: string}>();
  @observable private searchText$;

  constructor(
    private m: Model,
    private subscriptionService: SubscriptionService
  ) {
    super();
  }

  ngOnInit() {
    runInAction(() => this.filterLicenses(''));
    this.autorun(() => {
      this.subscriptions = filterText(this.m.subscription.subscriptions, this.searchText$)
        .filter(s => s.appliance_type === 'csp' && (this.currentSubscription ? s.appliance_id != this.currentSubscription.parent_id : true));
    });
    this.subscriptionService.refresh();
  }

  @action filterLicenses(value: string) {
    this.searchText$ = value;
  }

  back() {
    // FIXME: Adjust for multiple entry points
    this.m.workflow.resetWorkflow();
  }

  selectHost(subscription: Subscription) {
    this.selected.emit({aid: subscription.appliance_id, aname: subscription.name});
  }
}
