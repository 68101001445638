<div class="row" *ngIf="!token">
  <div class="col">
    <h1>{{'account_verify_headline' | i18next}}</h1>
    <p>
      {{'account_verify_content' | i18next}} <a routerLink="" (click)="resendMail()">{{'account_resend_email' | i18next}}</a>
    </p>
    <p *ngIf="message">
      {{message}}
    </p>
  </div>
</div>
<div class="row" *ngIf="token">
  <div class="col">
    {{'account_verify_activating' | i18next}}
  </div>
</div>
