import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-title',
  templateUrl: './subscription-title.component.html',
})
export class SubscriptionTitleComponent implements OnInit {

  @Input() subscription: Subscription;

  constructor() { }

  ngOnInit() {
  }

}
