<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'pascom_one_trial_product_name' | i18next }}</h2>

        <div class="mt-2" [innerHTML]="'subscription_one_trial_details' | i18next: {daysLeft: subscription.trialDaysLeft}"></div>

        <div class="d-flex flex-column">
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations" [innerHTML]="'subscription_one_trial_note' | i18next: {daysLeft: subscription.trialDaysLeft}"></span>
                </div>
            </div>
            <div class="d-flex mt-3 w-100 justify-content-end" *ngIf="isAdmin">
                <button class="btn btn-outline-primary" (click)="doUpgradeSubscription()">
                    {{ 'subscription_upgrade_plan' | i18next }}
                </button>
            </div>
        </div>
    </div>
</div>
