import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Model} from '../app.model';
import {map} from 'rxjs/operators';
import {runInAction} from 'mobx';
import {Order} from './order.model';
import {SaleOrder} from "../buy/buy.model";
import {Subscription} from "../subscription/subscription.model";

@Injectable()
export class OrderService {

    constructor(private httpClient: HttpClient, private m: Model) {
    }

    public refresh(force: boolean = false, subroute: string, state: string, offset, expired = false) {
        let params = new HttpParams();
        if (state !== null) {
            params = params.append('state', state);
        }
        params = params.append('offset', '' + offset);
        if (state === 'sent') {
          params = params.append('show_expired', expired);
        }

        if (force) {
            this.httpClient.get<any[]>('/services/'.concat(subroute), {params: params})
                .pipe(map((r) => r.map((item) => Order.fromJson(item))))
                .subscribe((r) => runInAction(() => {
                    this.m.order.loadMore = r.length >= 20;
                    if (offset > 0) {
                        this.m.order.orders = this.m.order.orders.concat(r);
                    } else {
                        this.m.order.orders = r;
                    }
                }));
        }
    }

    private buildDownloadUrl(order: Order): string {
        let basePath = '/services/';
        const attachmentPath = order.id + '/attachments/' + order.attachment;
        if (order.attachment.startsWith('Quotation') || order.attachment.startsWith('Order')) {
            basePath = basePath.concat('sale_orders/');
        } else {
            basePath = basePath.concat('invoices/');
        }
        return basePath.concat(attachmentPath);
    }

    public downloadFromUrl(order: Order) {
        const request = this.buildDownloadUrl(order);
        this.httpClient.get<any>(request).subscribe(res => {
            window.open(res['url']);
        });
    }

    public resendQuotation(orderName: string) {
      return this.httpClient.post<{ name }>(`services/subscriptions/offer/shared/${orderName}/send_quotation`, {})
    }

    public cancelQuotation(orderName: string) {
      return this.httpClient.post<{ name }>(`services/subscriptions/offer/shared/${orderName}/cancel`, {})
    }
    public cancelOrder(subscription: Subscription, orderName: string) {
        if (!subscription.isOne) {
            return;
        }

        const order = subscription.orders.find(s => s.name === orderName);
        if (!order) {
            return;
        }

        return this.httpClient.post<{ name }>(`services/subscriptions/order/${orderName}/cancel`, {})
    }

    public prepareSaleOrder(response: any) {
        const saleOrder = SaleOrder.fromJson(response);
        runInAction(() => this.m.buy.currentOrder = saleOrder);
        return saleOrder;
    }
}
