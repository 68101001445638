import {Directive} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WorkflowComponent} from "../workflow.component";
import {firstValueFrom} from "rxjs";
import {Subscription} from "../../subscription/subscription.model";
import {normalizeOneSLAProduct} from "@myshared/utils";
import {SlaProduct} from "../../buy/select-product/product.model";
import {observable, runInAction} from "mobx";
import {SubscriptionService} from "../../subscription/subscription.service";
import {BuyService} from "../../buy/buy.service";
import {Model} from "../../app.model";
import {HttpClient} from "@angular/common/http";
import {WorkflowPartnerService} from "./workflow-partner.service";
import {CustomerService} from "../../customer/customer.service";
import {CompanyService} from "../../company/company.service";
import {Customer} from "../../customer/customer.model";
import {Company, Contact} from "../../company/company.model";
import {SaleOrder} from "../../buy/buy.model";

interface WorkflowModel {
    subscription: Subscription,
    saleOrder?: SaleOrder,
    slaName?: string,
    sla?: SlaProduct,
    customer?: Customer,
    contact?: Contact,
    company?: Company,
    selectedSla?: { product: string, short: string, index: number },
    subscriptionSla?: { product: string, short: string, index: number }
}

@Directive()
export abstract class WorkflowPartnerChangeSlaComponent extends WorkflowComponent {

    @observable
    public model: WorkflowModel;

    private workflowName: 'upgrade-sla' | 'downgrade-sla';

    protected constructor(protected route: ActivatedRoute,
                          protected router: Router,
                          protected subscriptionService: SubscriptionService,
                          protected buyService: BuyService,
                          protected m: Model,
                          protected http: HttpClient,
                          protected workflowPartnerService: WorkflowPartnerService,
                          protected customerService: CustomerService,
                          protected companyService: CompanyService) {
        super(route);
    }

    protected async initWorkflow(workflowName: 'upgrade-sla' | 'downgrade-sla') {
        this.workflowName = workflowName;
        const so = this.route.snapshot.queryParamMap.get('so');
        const slaName = this.route.snapshot.queryParamMap.get('sla');
        const contactId = this.route.snapshot.queryParamMap.get('c_id');
        const subscriptionJson = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
        const subscription = Subscription.fromJson(subscriptionJson);

        if (!subscription) {
            this.resetWorkflow();
            return;
        }

        if (subscription.isCancelled) {
            this.resetWorkflow();
            return;
        }

        if (subscription.is_my || !this.m.account.currentUser.companyIsPartner) {
            this.resetWorkflow();
            return;
        }

        if (!!subscription.scheduledSLAOrder) {
            this.resetWorkflow();
            return;
        }

        const model: WorkflowModel = {
            subscription
        }

        try {
            model.selectedSla = normalizeOneSLAProduct(slaName);
            model.subscriptionSla = normalizeOneSLAProduct(subscription.sla_product);
        } catch (e) {
            this.resetWorkflow();
            return;
        }

        const cloudProducts = await firstValueFrom(
          this.http.get<{ sla }>('/services/products/cloud')
        );
        const sla = cloudProducts.sla
          .map(r => SlaProduct.fromJson(r))
          .find(r => r.name === model.selectedSla.product);

        if (!sla) {
            this.resetWorkflow();
            return;
        }

        model.sla = sla;
        model.company = await firstValueFrom(this.companyService.getCompany(model.subscription.customer_id));

        const customers = await firstValueFrom(this.customerService.getCustomers());
        model.customer = customers.find(r => r.id === model.subscription?.customer_id);
        model.contact = model.customer.contacts.find(r => +r.id === +contactId);


        // If we already have a sale order on our purchase model, we load it from the API.
        if (!this.m.buy.currentOrder?.name && so) {
            model.saleOrder = await firstValueFrom(this.buyService.getSaleOrder(so));
        }

        runInAction(() => {
            this.model = model
        });

        if (this.checkSelectedProduct(true)) {
            this.resetWorkflow();
            return;
        }
    }

    /**
     * Override this checkSelectedProduct function to handle the workflow.
     * This can be overridden by the class and can include the statement
     *
     * If TRUE, workflow will reset
     * IF FALSE, workflow will continue
     *
     * @param ifStatement
     * @protected
     */
    protected abstract checkSelectedProduct(ifStatement: boolean);

    protected async createOffer() {
        if (this.model.saleOrder?.name) {
            return;
        }

        const saleOrder = await firstValueFrom(this.workflowPartnerService.oneApplianceSlaChange(this.applianceId, this.model.selectedSla.product, this.model.contact.id));
        runInAction(() => {
            this.model.saleOrder = saleOrder;
        });
    }

    protected navigate(url: any[]) {
        this.router.navigate(['/do', 'partner', this.workflowName, this.applianceId, ...url], {
            queryParams: {
                so: this.model.saleOrder?.name ?? undefined,
                sla: this.model.selectedSla?.short ?? undefined,
                c_id: this.model.contact?.id ?? undefined
            },
        });
    }
}
