<div *ngIf="subscription" class="card card-nb subscription h-100" [ngClass]="{ 'hover-effect': canShowDetails }">
  <div class="card-body pb-0" (click)="showDetails()">
    <div class="row mb-2">
      <div class="col-auto col-sm-auto col-lg-auto p-0 m-0 mr-2 mr-sm-2">
        <i [ngClass]="subscription.typeIcon" class="fa red icon-size-bigger" *ngIf="!subscription.is_one_nfr"></i>
        <span class="badge badge-warning" *ngIf="subscription.is_one_nfr">{{ 'nfr_one_label' | i18next }}</span>
      </div>
      <div class="col-9 col-sm-9 col-lg-9 p-0 m-0 title">
        <a *ngIf="showSubscriptionDetailLink" class="btn-link" [routerLink]="['/subscriptions', subscription.appliance_id]">
          <app-subscription-title [subscription]="subscription"></app-subscription-title>
        </a>
        <app-subscription-title  *ngIf="!showSubscriptionDetailLink" [subscription]="subscription"></app-subscription-title>
      </div>
    </div>
    <div class="row">
      <div class="col-12 highlighted p-0 m-0">
        <i class="far fa-user red"></i><span class="ml-1">{{ subscription.customer_name }}</span>
      </div>
    </div>
    <div class="row subinformations" style="min-height: 100px;">
      <div class="col-12 col-sm-10 p-0 m-0" >
        <div *ngIf="subscription.appliance_type !== 'csp' && !subscription.isOneTrial">{{ subscription.users }}&nbsp;{{ (subscription.is_v3 && !subscription.isOnsitePerpetual ? 'subscription_concurrent_users' : 'subscription_users') | i18next }}</div>
        <div *ngIf="subscription.appliance_type === 'csp'">{{ 'csphost_child_count' | i18next:{count: childCount} }}</div>
        <div>{{ 'type' | i18next }}:&nbsp;
          <app-subscription-type-badge [subscription]="subscription"></app-subscription-type-badge>
        </div>
        <div class="mt-2 red" *ngIf="subscription.isNonPascomOneLicenseType && subscription.isCancelled">
          {{'subscription_cancelled' | i18next}}&nbsp;{{subscription.end_date | localized_date}}
        </div>
        <div class="mt-2 red" *ngIf="isOneAndCancelled">
          {{ (subscription.isOneExpired ? 'pascom_one_expired_on' : 'pascom_one_expires_on') | i18next:
          {date: subscription.end_date | localized_date, interpolation: { escapeValue: false } } }}
        </div>
        <div class="mt-2 text-wrap" *ngIf="!subscription.isCancelled && subscription.next_invoice && !subscription.isOneTrial">
          <div class="text-nowrap">{{'subscription_next_invoice' | i18next}}</div><div>{{subscription.next_invoice | localized_date}}</div>
        </div>
        <div class="mt-2 text-wrap" *ngIf="subscription.isOneTrial && subscription.isValid">
          <div class="text-nowrap red">{{'pascom_one_trial_days_left' | i18next:{daysLeft: subscription.trialDaysLeft} }}</div>
        </div>
        <div class="mt-2 text-wrap" *ngIf="subscription.isOneTrial && !subscription.isValid">
          <div class="text-nowrap red">{{'pascom_one_expired_on' | i18next:{date: subscription.end_date | localized_date, interpolation: { escapeValue: false } } }}</div>
        </div>
      </div>
      <div class="col-12 col-sm-2 p-0 m-0">
        <div class="d-flex align-items-end flex-column h-100 mt-2">
          <div class="mb-auto mr-2" style="min-height: 22px;">
            <div *ngIf="subscription.pairing_state === 'pairing'"><span class="red">{{ 'pending' | i18next }}</span></div>
            <div *ngIf="subscription.pairing_state === 'paired'"><span class="red">{{ 'paired' | i18next }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="subinformations mt-2 text-wrap font-weight-bold d-flex align-items-center" style="width:85%;" *ngIf="subscription.migratableSubscription && subscription.isValid">
        <div [ngClass]="cssClasses" [pTooltip]="subscription.migrationEndDate | localized_date" tooltipPosition="top">
          {{'pascom_one_migration_days_left_text' | i18next:{count: subscription.migrationDaysLeft } }}
        </div>
        <i *ngIf="subscription.migrationDaysLeft <= 60" class="fa fa-exclamation-triangle text-warning ml-2"></i>
      </div>
      <div class="d-flex ml-auto align-items-start">
        <ng-content></ng-content>
        <ng-content *ngIf="canUpgradeAfterExpired" select="[upgrade]"></ng-content>
      </div>
    </div>
  </div>
  <div *ngIf="status" class="row subinformations pb-1 mr-2">
    <div class="d-flex ml-auto align-items-end flex-column mt-auto">
      <div *ngIf="status.status !== 'api_loading'" class="mt-auto" style="min-height: 22px;">
        <span class="mr-2 status-text" [ngClass]="status.color">
          {{ status.status | i18next }}
        </span>
        <span>
          <i class="fas fa-info-circle red" [pTooltip]="status.status + '_tooltip' | i18next"></i>
        </span>
      </div>
      <div *ngIf="status.status === 'api_loading'" class="mt-auto" style="min-height: 22px;">
        <img src="assets/images/loading.gif" height="16px" alt="Loading content">
      </div>
    </div>
  </div>
  <div *ngIf="status && status.status !== 'api_loading'" class="m-0 pt-1 status-bg" [ngClass]="status.color"></div>
</div>
