<app-content-header [headline]="is20CSP ? ('contact_add_header' | i18next) : ('contact_invite_header' | i18next)"></app-content-header>
<div class="card card-nb" *ngIf="formGroup">
  <div class="card-body">
    <div class="row">
      <div [ngClass]="{ 'col-12 col-md-6': showPermissionText, 'col-12': !showPermissionText }">
        <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      </div>
      <div *ngIf="showPermissionText" [ngClass]="{ 'col-12 col-md-6': showPermissionText }">
          <h5>{{'contact_mypascom_account_description_header' |  i18next}}</h5>
          <div><i class="fa fa-user-tie red"></i><span class="ml-1">{{'contact_mypascom_account_admin_description' | i18next }}</span>
          </div>
          <div><i class="fa fa-user-circle red"></i><span class="ml-1">{{'contact_mypascom_account_enabled_description' | i18next }}</span>
          </div>
          <div><i class="fa fa-times-circle red"></i><span class="ml-1">{{'contact_mypascom_account_disabled_description' | i18next }}</span>
          </div>
          <div><span class="red">{{'contact_mypascom_account_disabled_note' | i18next }}</span>
          </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-outline-primary" [disabled]="formGroup.status !== 'VALID'" (click)="onInvite()">
        {{ !showPermissionText && is20CSP ? ('app_add' | i18next) : ('contact_invite_btn' | i18next) }}
      </button>
    </div>
  </div>
</div>
