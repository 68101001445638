<div class="card card-nb h-100" *ngIf="subscription && !subscription.isCancelled">
  <div class="card-body pb-0">
    <div class="row">
      <div class="col"><h2>{{'subscription_pairing_state_headline' | i18next}}</h2></div>
    </div>
    <div class="row">
      <div class="col" *ngIf="subscription.pairing_state === 'paired'">
        {{'subscription_paired_with_text' | i18next: {name: subscription.name} }}
      </div>
      <div class="col" *ngIf="subscription.pairing_state === 'pairing'">
        {{'subscription_in_pairing_process' | i18next}}
      </div>
      <div class="col" *ngIf="subscription.pairing_state === 'unpaired'">
        {{'subscription_not_paired' | i18next}}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <a href="http://doc.pascom.net" target="_blank">{{'subscription_docs_link' | i18next}}</a>
      </div>
    </div>
    <div class="row mt-3 w-100 justify-content-end">
      <app-pairing-button [subscription]="subscription"></app-pairing-button>
    </div>
  </div>
</div>
