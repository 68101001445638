<app-content-header [stepsBack]="1"
                    [headline]="'phonenumber_selected_numbers' | i18next: {selected: selectedPhonenumbers.size, max: blocks.length}">
</app-content-header>

<div class="row align-items-stretch mt-2">
  <app-select-phonenumber-card *ngFor="let b of blocks"
                               (toggle)="onPhonenumberBlockToggled($event)"
                               [blockOffer]="b"  class="col-xs-12 col-xl-4 mb-3">
  </app-select-phonenumber-card>

</div>

<div class="row justify-content-end">
  <div class="p-3">
    <button class="btn btn-outline-primary" [disabled]="selectedPhonenumbers.size === 0" (click)="select()">{{'app_next' | i18next}}</button>
  </div>
</div>
