<div *ngIf="initialized && model">
    <app-invoice-address
            (next)="onInvoiceAddress($event)"
            (backEvent)="onInvoiceAddressBack()"
            [historyBack]="false"
            *ngIf="step === 'address'">
    </app-invoice-address>
    <app-payment-method
            (next)="onPaymentMethod($event)"
            [onlyEmit]="true"
            (backEvent)="onPaymentMethodBack()"
            [historyBack]="false"
            [paymentMethod]="model?.saleOrder?.payment_method ?? model?.subscription?.payment_method"
            *ngIf="isSepa && step === 'payment'">
    </app-payment-method>
    <app-checkout-one-summary
            [subscription]="model.subscription"
            (back)="onSummaryBack()"
            [historyBack]="false"
            (buy)="onBuy()"
            *ngIf="step === 'checkout'">
    </app-checkout-one-summary>
    <app-endcustomer-upgrade-order-complete
            (next)="onComplete()"
            *ngIf="step === 'complete'">
    </app-endcustomer-upgrade-order-complete>
</div>
