<app-workflow-select-license *ngIf="isOnsiteSetup"></app-workflow-select-license>
<app-workflow-upgrade-subscription *ngIf="isUpgradeSubscription"></app-workflow-upgrade-subscription>
<app-workflow-upgrade-one *ngIf="isOneUpgrade || isTariffMigration || isCloudMigration"></app-workflow-upgrade-one>
<app-workflow-view-subscription *ngIf="isViewSubscription"></app-workflow-view-subscription>
<app-welcome-activated *ngIf="isWelcome"></app-welcome-activated>
<app-workflow-cancel-subscription *ngIf="isCancelSubscription"></app-workflow-cancel-subscription>
<app-workflow-management-password-reset *ngIf="isManagementPasswordReset"></app-workflow-management-password-reset>
<app-workflow-change-host *ngIf="isChangeHost"></app-workflow-change-host>
<app-workflow-phonenumbers *ngIf="isPhonenumbers"></app-workflow-phonenumbers>
<app-workflow-itemized-billing *ngIf="isItemizedBilling"></app-workflow-itemized-billing>
<app-workflow-tariff *ngIf="isTariff"></app-workflow-tariff>
<app-workflow-tariff-cancel *ngIf="isTariffCancel"></app-workflow-tariff-cancel>
<div *ngIf="!isInWorkflow">
  <a routerLink="/">{{'not_in_workflow_link' | i18next}}</a>
</div>
