<app-content-header (backEvent)="onBack()" [historyBack]="false" [hint]="'itemized_billing_settings_hint' | i18next" [headline]="'itemized_billing_settings_headline' | i18next"></app-content-header>

<div class="row mt-5 align-items-stretch">
  <app-itemized-billing-card class="col-sm-12 col-lg-6 col-xl-4 mb-3 mb-lg-3 mb-xl-0"
                             headline="itemized_billing_card_invoice_only_headline"
                             text="itemized_billing_card_invoice_only_text">
    <button class="btn btn-primary" style="width: 250px;"
            (click)="onSelect('invoice_only')">{{ 'app_select' | i18next }}</button>
  </app-itemized-billing-card>
  <app-itemized-billing-card class="col-sm-12 col-lg-6 col-xl-4 mb-3 mb-lg-3 mb-xl-0"
                             headline="itemized_billing_card_anonymize_headline"
                             text="itemized_billing_card_anonymize_text">
    <button class="btn btn-primary" style="width: 250px;"
            (click)="onSelect('invoice_anonymize')">{{ 'app_select' | i18next }}</button>
  </app-itemized-billing-card>
  <app-itemized-billing-card class="col-sm-12 col-lg-6 col-xl-4 mb-3 mb-lg-3 mb-xl-0"
                             headline="itemized_billing_card_full_headline"
                             text="itemized_billing_card_full_text">
    <button class="btn btn-primary" style="width: 250px;"
            (click)="onSelect('invoice_full')">{{ 'app_select' | i18next }}</button>
  </app-itemized-billing-card>
</div>
