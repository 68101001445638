<app-card *ngIf="subscription">
<!--  <div class="card-body pb-0">-->
    <div class="d-flex align-items-start justify-content-between">
      <div class="h2 d-flex">
        <div>[{{subscription.appliance_id}}]</div><div class="ml-1">{{subscription.name}}</div>
      </div>
      <span class="badge badge-warning" *ngIf="subscription.is_one_nfr">{{ 'nfr_one_label' | i18next }}</span>
    </div>
    <div class="row mb-2">
      <div class="col-12 p-0 m-0 highlighted">
        <i class="far fa-user red"></i> {{ subscription.customer_name }}
      </div>
    </div>
    <div class="row subinformations">
      <div class="col m-0 p-0">
        <div>{{ 'subscription_type' | i18next }}&nbsp;<i [ngClass]="subscription.typeIcon" class="fa red"></i>&nbsp;
          <app-subscription-type-badge [subscription]="subscription"></app-subscription-type-badge>
          <div class="mt-2" *ngIf="subscription.appliance_type === 'cloud'
                      || subscription.isOne || subscription.isOneTrial || subscription.isOneAppliance">
            {{ 'subscription_url' | i18next }}&nbsp;
            <!-- Use a a href here to not break the layout -->
            <a (click)="goToCloudInstance()" href="#" onclick="return false;">{{ subscription.cloud_url }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3 w-100" *ngIf="subscription.isOne && subscription.isCancelled">
      <div class="mt-3">
          <span [innerHTML]="(subscription.isOneExpired ? 'subscription_expired_on_text' : 'subscription_expires_on_text') | i18next:
                  {date: subscription.end_date | localized_date }"></span>
        <a (click)="undoCancellationSubscription()" class="ml-1 red cursor-pointer">{{ 'undo_cancellation_subscription' | i18next }}</a>
      </div>
    </div>
<!--  </div>-->
</app-card>
<p-confirmDialog #cd header="Confirmation" [key]="'undoSubscriptionDetailsCard'" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
    <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'undo_cancellation_subscription' | i18next}}</button>
  </p-footer>
</p-confirmDialog>
