<div class="content-card-container-bg mt-5">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <h1>{{ 'authentication_failed_headline' | i18next}}</h1>
      <p>
        {{ 'authentication_failed_description' | i18next }}
      </p>

      <button class="btn btn-outline-primary mt-3" (click)="retryLogin()">
        {{ 'authentication_failed_retry_button' | i18next }}
      </button>
    </div>
  </div>
</div>
