import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutorunComponent } from '@myshared/autorun.component';
import {Customer} from '../customer.model';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: []
})
export class CustomerCardComponent implements OnInit {

  @Input() customer: Customer;
  @Input() detaillist = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  }

  public showDetails() {
    if (this.detaillist) {
      this.router.navigate(['/customers', this.customer.id, 'contacts'])
    }
  }

}
