<div *ngIf="credit > 0">
    <app-content-header (backEvent)="onBack()" [stepsBack]="stepsBack"
        [headline]="'migration_information_headline' | i18next"></app-content-header>

  <app-icon-card>
    <div class="h3 red">{{ 'migration_credit_headline' | i18next}}</div>

    <div class="w-100"
      [innerHTML]="'migration_credit' | i18next: {
          concurrentUser: concurrentUser,
          dateFrom: (dateFrom | localized_date),
          dateTill: (dateTill | localized_date),
          prepaid: (prepaidListPrice | localized_currency: {currency: 'EUR'}),
          credit: (credit | localized_currency) }"></div>

    <div class="d-flex justify-content-end mt-2 form-container-footer">
        <button name="continue" class="btn btn-outline-primary"
            (click)="onContinue()">{{ 'app_continue' | i18next }}</button>
    </div>
  </app-icon-card>
</div>
