<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div>
        <img src="assets/images/flamingo.svg" alt="pascom welcome picture">
      </div>
      <div>
        <h1>{{ 'thank_you_partner_headline' | i18next: {email: customerEmail} }}</h1>
        <p class="gray">{{ 'thank_you_partner_content' | i18next}}</p>
        <div class="mt-3 mb-3">
          <button class="btn btn-outline-primary" (click)="onOpenCustomerOrders()">{{ 'thank_you_partner_open_customers_orders' | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
