import {PCFormRow, PCFormsService} from '@myshared/pcform.service';
import {FormGroup} from '@angular/forms';
import {PortNumberBlockDetails} from './port-phonenumber.model';
import {compareNumber} from '@myshared/compareNumberValidator';

export function portNumberBlockForm(pcFormService: PCFormsService) {
  const builder = pcFormService.newBuilder('port_number');
  const row1 = builder.addContainer(PCFormRow, {
    id: 'row1',
    elementClass: {
      control: 'row'
    }
  });
  row1.addTextInput({
    id: 'countryPrefix',
    readOnly: true,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    }
  });
  row1.addTextInput({
    id: 'areaCode',
    autoFocus: true,
    tabIndex: 1,
    placeholder: 'port_number_areacode_placeholder',
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    },
    validators: {
      required: null,
      maxLength: 6,
      pattern: '^[0-9]+'
    }
  });
  row1.addTextInput({
    id: 'mainNumber',
    autoFocus: true,
    tabIndex: 2,
    placeholder: 'port_number_mainnumber_placeholder',
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    },
    validators: {
      required: null,
      pattern: '^[0-9]+'
    }
  });
  row1.addTextInput({
    id: 'blockStart',
    autoFocus: true,
    tabIndex: 3,
    placeholder: 'port_number_blockstart_placeholder',
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    },
    validators: {
      required: null,
      pattern: '^[0-9]+',
      maxLength: 3
    }
  });
  row1.addTextInput({
    id: 'blockEnd',
    autoFocus: true,
    tabIndex: 4,
    placeholder: 'port_number_blockend_placeholder',
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0',
    },
    validators: {
      required: null,
      pattern: '^[0-9]+',
      maxLength: 3,
      compareNumber: {
        name: compareNumber.name,
        args: ['port_number.row1.blockStart', '>=']
      }
    }
  });
  row1.addTextInput({
    id: 'previewFrom',
    readOnly: true,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    }
  });
  row1.addTextInput({
    id: 'previewTo',
    readOnly: true,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    }
  });

  return builder;

}
export function portSingleNumberForm(pcFormService: PCFormsService, countryCode?: string) {
  const builder = pcFormService.newBuilder('port_number');
  const row1 = builder.addContainer(PCFormRow, {
    id: 'row1',
    elementClass: {
      control: 'row'
    }
  });
  row1.addTextInput({
    id: 'countryPrefix',
    readOnly: true,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    }
  });

  const areaCodePlaceholder = countryCode ? `_${countryCode.toLowerCase()}_` : '_';

  row1.addTextInput({
    id: 'areaCode',
    autoFocus: true,
    tabIndex: 1,
    placeholder: `port_number${areaCodePlaceholder}areacode_placeholder`,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    },
    validators: {
      required: null,
      maxLength: 6,
      pattern: '^[0-9]+'
    }
  });
  row1.addTextInput({
    id: 'number',
    autoFocus: true,
    tabIndex: 2,
    placeholder: 'port_number_phonenumber_placeholder',
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    },
    validators: {
      required: null,
      pattern: '^[0-9]+'
    }
  });
  row1.addTextInput({
    id: 'previewNumber',
    readOnly: true,
    elementClass: {
      container: 'form-group-container',
      host: 'col-12 p-0'
    }
  });

  return builder;

}

export function getPortNumberFormData(formGroup: FormGroup, countryPrefix?: string): PortNumberBlockDetails {
  const n = new PortNumberBlockDetails();
  const number = getPortNumber(n, formGroup.value);
  number.country_prefix = countryPrefix ?? '0049';
  return n;
}

export function getPortNumber(blockDetailsInstance: PortNumberBlockDetails, values: any, countryCode?: string): PortNumberBlockDetails {
  const isBlock = values.port_number.row1.mainNumber !== undefined;
  blockDetailsInstance.area_code = values.port_number.row1.areaCode ? (+values.port_number.row1.areaCode) + '' : '';
  if (isBlock) {
    blockDetailsInstance.block_start = values.port_number.row1.blockStart ?? '';
    blockDetailsInstance.block_end = values.port_number.row1.blockEnd ? (+values.port_number.row1.blockEnd) + '' : '';
    blockDetailsInstance.block_start = formatLeadingZeros(blockDetailsInstance.block_start, blockDetailsInstance.block_end);
  } else {
    blockDetailsInstance.block_start = '';
    blockDetailsInstance.block_end = '';
  }

  if (!!values.port_number.row1.mainNumber) {
    blockDetailsInstance.main_number = values.port_number.row1.mainNumber;
  } else if (!!values.port_number.row1.number) {
    blockDetailsInstance.main_number = values.port_number.row1.number;
  } else {
    blockDetailsInstance.main_number = '';
  }
  blockDetailsInstance.country_prefix = countryCode ?? '+49';

  return blockDetailsInstance;
}

function formatLeadingZeros(start: string, end: string): string {
  if (start.length < end.length) {
    const len = end.length - start.length;
    return new Array(len + 1).join('0') + start;
  } else if (start.length > end.length) {
    return +start + '';
  }
  return start;
}
