<div class="card h-100 card-nb" (click)="editDetails()">
  <div class="card-body pb-0">
    <div class="row">
      <div class="col-11 highlighted p-0 m-0">
        <div>{{contact.first_name}}&nbsp;{{contact.last_name}}</div>
        <div>{{contact.email}}</div>
      </div>
      <div class="col d-flex flex-row-reverse p-0 m-0">
        <i class="fa red icon-size-bigger" [ngClass]="mypascomLevelIcon"></i>
      </div>
    </div>
    <div class="row subinformations p-0 m-0">
      <div class="col-6 p-0 m-0 pt-2">
        <div *ngIf="showMypascomLevel">{{mypascomLevel}}</div>
      </div>
      <div class="col-6 p-0 m-0 d-flex flex-row-reverse">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
