<div class="card card-nb h-100" *ngIf="subscription">
  <div class="card-body pb-0">
    <div class="d-flex align-items-start justify-content-between">
      <div class="h2 d-flex">
        <div>[{{subscription.appliance_id}}]</div><div class="ml-1">{{subscription.name}}</div>
      </div>
      <span class="badge badge-warning" *ngIf="subscription.is_one_nfr">{{ 'nfr_one_label' | i18next }}</span>
    </div>
    <div class="row mb-2">
      <div class="col-12 p-0 m-0 highlighted">
        <i class="far fa-user red"></i> {{ subscription.customer_name }}
      </div>
    </div>
    <div class="row subinformations">
      <div class="col m-0 p-0">
        <div>{{ 'subscription_type' | i18next }}&nbsp;<i [ngClass]="subscription.typeIcon" class="fa red"></i>&nbsp;
          <app-subscription-type-badge [subscription]="subscription"></app-subscription-type-badge>
          <div class="mt-2" *ngIf="subscription.appliance_type === 'cloud'
                      || subscription.isOne || subscription.isOneTrial || subscription.isOneAppliance">
            {{ 'subscription_url' | i18next }}&nbsp;
            <!-- Use a a href here to not break the layout -->
            <a *ngIf="subscription.appliance_type === 'cloud' || subscription.isOneAppliance || subscription.isOne || subscription.isOneTrial" (click)="goToCloudInstance()" href="#" onclick="return false;">{{ subscription.cloud_url }}</a>
          </div>
        </div>
      </div>
      <div class="row mt-3 w-100 justify-content-end">
        <button class="btn btn-outline-primary" (click)="doChangeHost()" *ngIf="isCsp && subscription.appliance_type === 'cloud'">{{'subscription_change_host' | i18next}}</button>
      </div>
    </div>
  </div>
</div>
