<app-phonenumber-port-provider *ngIf="step === portSteps.select_provider"
                               (onBack)="back()"
                               (onNext)="onProviderSelected($event)"></app-phonenumber-port-provider>
<app-phonenumber-port-select-size *ngIf="step === portSteps.select_size"
                                  (onBack)="back()"
                                  (onNext)="onPortNumberSizeSelected($event)"></app-phonenumber-port-select-size>
<app-phonenumber-port-block
  *ngIf="step === portSteps.select_phone_numbers && selectedNumberSize === 'block'"
  (onBack)="back()"
  (selected)="onSelectNumbersToPortComplete($event)"></app-phonenumber-port-block>
<app-phonenumber-port-single-number
  *ngIf="step === portSteps.select_phone_numbers && selectedNumberSize === 'single'"
  (onBack)="back()"
  (selected)="onSelectNumbersToPortComplete($event)"></app-phonenumber-port-single-number>
