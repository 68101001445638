<app-content-header backLabel="app_cancel" [stepsBack]="1" [headline]="'upload_port_form_headline' | i18next"></app-content-header>

<div class="d-flex justify-content-center mt-5">
  <div class="card card-nb card-mw-300">
    <div class="card-body">
      <div class="h3 red">{{ 'upload_port_form_headline' | i18next }}</div>
      <div class="mt-3 d-flex justify-content-center text-align-center">
        <app-file-uploader [customUpload]="true"
                           maxFileSize="3670016"
                           styleClass="draggable-buttons"
                           allowedFiles="application/pdf"
                           [showUploadButton]="false"
                           chooseIcon="pi pi-upload"
                           (selected)="changed($event)"></app-file-uploader>
        <!-- maxFileSize: For colt its only possible to upload 5 MB.
             Because we need to do this as an base64, 3.5 MB base64 string = 5 MB real file size-->
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end mt-2">
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="files.length === 0">{{ 'app_next' | i18next}}</button>
</div>
