import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {Router} from "@angular/router";
import {SaleOrder, SaleOrderLine} from "../../buy/buy.model";
import {ConfirmationService} from "primeng/api";
import {I18NextService} from "angular-i18next";
import {OrderService} from "../../order/order.service";
import {SubscriptionService} from "../subscription.service";

@Component({
  selector: 'app-subscription-concurrent-user',
  templateUrl: './subscription-concurrent-user.component.html',
})
export class SubscriptionConcurrentUserComponent extends AutorunComponent implements OnInit {

  @Input() subscription: Subscription;

  public isAdmin: boolean;
  public scheduledOrder: SaleOrder;

  constructor(private m: Model,
              private router: Router,
              private confirmService: ConfirmationService,
              private i18next: I18NextService,
              private subscriptionService: SubscriptionService,
              private orderService: OrderService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.scheduledOrder = this.subscription.scheduledCUOrder;
    });
  }

  public get hasScheduledOrder() {
    return !!this.scheduledOrder;
  }

  public get scheduledOrderCU() {
    return this.scheduledOrder.scheduledCUOrderLine.product_uom_qty;
  }

  public get scheduledDate() {
    return this.scheduledOrder.deliveryDate;
  }

  public cancelOrder() {
    this.confirmService.confirm({
      message: this.i18next.t('confirm_cancel_order_text') as string,
      header: this.i18next.t('confirm_cancel_order') as string,
      acceptLabel: this.i18next.t('confirm_cancel_order') as string,
      key: 'cu',
      accept: () => {
        this.orderService.cancelOrder(this.subscription, this.scheduledOrder.name).subscribe(_ => {
            window.location.reload();
        });
      }
    });
  }

  public openChangeUserWorkflow() {
    if (!this.subscription.isCancelled) {
      this.router.navigate(['/do', 'change-cu', this.subscription.appliance_id]);
      return;
    }

    this.confirmService.confirm({
      header: this.i18next.t('undo_cancellation_subscription_confirm_header') as string,
      message: this.i18next.t('subscription_undo_cancellation_text', {
        workflow: this.i18next.t('subscription_change_users') as string
      }) as string,
      key: 'subscription_concurrent_user',
      accept: () => this.subscriptionService.undoCancellationSubscription(this.subscription).subscribe(() => {
        this.router.navigate(['/do', 'change-cu', this.subscription.appliance_id]);
      })
    });
  }
}
