<div *ngIf="initialized && model">
    <app-subscription-one-downgrade-sla
            *ngIf="step === 'info'"
            (next)="onDowngradeInfo($event)"
            [selectedSLA]="model.selectedSla.short"
            (backEvent)="onDowngradeInfoBack()"
            [historyBack]="false"
            [subscription]="model.subscription"></app-subscription-one-downgrade-sla>
    <app-select-invoice-contact
            [customer]="model.customer"
            [subscription]="model.subscription"
            (back)="onContactBack()"
            [historyBack]="false"
            (selected)="onContact($event)"
            *ngIf="step === 'contact'">
    </app-select-invoice-contact>
    <app-invoice-address
            (next)="onInvoiceAddress($event)"
            (backEvent)="onInvoiceAddressBack()"
            [company]="model.company"
            [onlyEmit]="true"
            [historyBack]="false"
            *ngIf="step === 'address'">
    </app-invoice-address>
    <app-payment-method
            (next)="onPaymentMethod($event)"
            (backEvent)="onPaymentMethodBack()"
            [company]="model.company"
            [onlyEmit]="true"
            [historyBack]="false"
            [paymentMethod]="model?.saleOrder?.payment_method ?? model?.subscription?.payment_method"
            *ngIf="isSepa && step === 'payment'">
    </app-payment-method>
    <app-checkout-one-summary
            [subscription]="model.subscription"
            (back)="onSummaryBack()"
            [historyBack]="false"
            (buy)="onBuy()"
            *ngIf="step === 'checkout'">
    </app-checkout-one-summary>
    <app-partner-downgrade-offer-sent
            *ngIf="step === 'complete'"
            (next)="onComplete()"
            [customerEmail]="model.saleOrder.contact.email"
    ></app-partner-downgrade-offer-sent>
</div>
