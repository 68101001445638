import { AbstractControl } from '@angular/forms';
import { DynamicFormControlModel } from '@ng-dynamic-forms/core';

/**
 * Show validation messages on change
 *
 * Can only be used in combination of angular 9 and ng-dynamic-bootstrap 11
 */
export function errorMessageOnChangeMatcher(control: AbstractControl, model: DynamicFormControlModel, hasFocus: boolean) {
    return (model.updateOn === 'change' || model.updateOn === null) ? control.dirty : control.touched && !hasFocus;
}
