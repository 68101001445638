<app-content-header
  (backEvent)="onBack()"
  [historyBack]="false"
  [headline]="confirmHeadline"></app-content-header>

<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card text-white bg-danger h-100 card-nb">
      <div class="card-body pb-0">
        <h2 class="text-uppercase text-align-center">
          {{ confirmDetailsHeadline }}
        </h2>
      </div>
      <div class="card-body pt-0 pb-0">
        <div class="container p-0">
          <div>
            {{ confirmDetailsText }}
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <button *ngIf="!hideCancelBtn" name="cancel-subscription-abort" class="btn btn-link text-light" (click)="onBack()">
            {{ cancelBtn }}
          </button>
          <button name="cancel-subscription-confirm" class="btn btn-outline-light" (click)="onConfirm()">
            {{ confirmBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
