<ng-template [ngIf]="contacts">
  <app-content-header [historyBack]="false" (backEvent)="onBack()" [headline]="customer?.name"></app-content-header>

  <div class="d-flex align-items-center mb-3">
    <div class="h3">{{ 'company_contacts_header' | i18next }}</div>
    <div class="ml-auto bd-highlight">
      <button *ngIf="!is20CSP" class="btn btn-outline-primary" [routerLink]="['/customers', customerId, 'contacts', 'invite']">{{'company_contacts_invite' | i18next}}</button>
      <button *ngIf="is20CSP" class="btn btn-outline-primary" [routerLink]="['/customers', customerId, 'contacts', 'add']">{{'company_contacts_add' | i18next}}</button>
    </div>
  </div>

  <app-customer-navigation [id]="customerId"></app-customer-navigation>

  <app-filter filterType="checkbox"
              [checkboxFilterText]="'company_contacts_show_disabled' | i18next"
              (checkboxFilterChanged)="showDisabled($event)"
              (searchFilterChanged)="doFilter($event)"
              [placeholder]="'customer_contact_search' | i18next"></app-filter>

  <div class="row align-items-stretch">
    <app-contact-card class="col-xs-12 col-md-6 col-lg-6 mb-3"
                      *ngFor="let c of contacts" [contact]="c"></app-contact-card>
  </div>
</ng-template>



