import {Component, Input, OnInit} from '@angular/core';
import {Block, BlockOffer} from "../../phonenumber.model";

@Component({
  selector: 'app-phonenumber-block',
  templateUrl: './phonenumber-block.component.html'
})
export class PhonenumberBlockComponent implements OnInit {

  @Input() public from: string;
  @Input() public to: string;
  @Input() public length: number;
  @Input() public deliveryDate: string | boolean;

  constructor() {}

  ngOnInit(): void {}
}
