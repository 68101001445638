import {AutorunComponent} from '@myshared/autorun.component';
import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {Order} from '../order.model';
import {OrderService} from '../order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {action, observable} from '@myshared/mobx-connector';
import {filterText} from '@myshared/utils';
import {runInAction} from 'mobx';
import {OrderState} from "../../buy/buy.model";

@Component({
    selector: 'order-list',
    templateUrl: './order-list.component.html',
})
export class OrderListComponent extends AutorunComponent implements OnInit {

    public orders: Order[];
    private offset = 0;
    private restSubRoute: RestSubRoute;
    public state: OrderState
    public dropdownItemName: DropDownItemName;

    @observable private filter$: string;

    constructor(
        private m: Model,
        private orderService: OrderService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        super();
    }


    ngOnInit(): void {
        runInAction(() => {
            this.filter$ = '';
            this.offset = 0;
            this.restSubRoute = 'sale_orders';
        });

        this.activatedRoute.paramMap.subscribe(r => {
            this.restSubRoute = 'sale_orders';
            this.offset = 0;
            this.orders = [];
            switch (r.get('subroute')) {
                case 'quotations': {
                    this.state = 'sent';
                    break;
                }
                case 'orders': {
                    this.state = 'sale';
                    break;
                }
                case 'invoices': {
                    this.restSubRoute = 'invoices';
                    this.state = null;
                    break;
                }
                default:
                    this.state = 'sent';
            }
            this.orderService.refresh(true, this.restSubRoute, this.state, 0);
        });


        this.autorun(() => {
            if (this.router.url.endsWith('orders')) {
                this.dropdownItemName = 'navigation_orders';
            } else if (this.router.url.endsWith('invoices')) {
                this.dropdownItemName = 'navigation_invoices';
            } else {
                this.dropdownItemName = 'navigation_quotations';
            }
            this.orders = filterText(this.m.order.orders, this.filter$);
        });
    }

    onRefresh() {
      this.orderService.refresh(true, this.restSubRoute, this.state, 0);
    }

    get needsPagination(): boolean {
        return this.orders.length >= 20 && this.orders.length % 20 == 0 && this.m.order.loadMore;
    }

    @action
    doFilter(filter: string) {
        this.filter$ = filter;
    }

    public nextPage() {
        this.offset = this.offset + 20;
        this.orderService.refresh(true, this.restSubRoute, this.state, this.offset);
    }

  public showExpired(value: boolean) {
    this.orderService.refresh(true, this.restSubRoute, this.state, this.offset, value);
  }
}

type RestSubRoute = 'sale_orders' | 'invoices';

type DropDownItemName = 'navigation_quotations' | 'navigation_orders' | 'navigation_invoices';
