import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subscription} from "../subscription.model";
import {LocalizedDatePipe} from "@myshared/localized-date.pipe";

@Component({
  selector: 'app-subscription-one-downgrade-sla',
  templateUrl: './subscription-one-downgrade-sla.component.html',
  providers: [LocalizedDatePipe]
})
export class SubscriptionOneDowngradeSlaComponent {
  @Input() subscription: Subscription;
  @Input() public historyBack = true;
  @Input() public backLabel = 'app_back';
  @Input() public selectedSLA;

  @Output() public readonly backEvent = new EventEmitter();
  @Output() public readonly next = new EventEmitter();

  public checked = false;

  constructor(private datePipe: LocalizedDatePipe) {
  }

  onNext() {
    if (this.checked) {
      this.next.emit();
    }
  }

  public onBack() {
    this.backEvent.emit();
  }

  public doCheck(checked: boolean) {
    this.checked = checked;
  }

  get subscriptionSLA() {
    return this.subscription.sla_product.replace('PC-ONE-', '');
  }
}
