import {Injectable} from '@angular/core';
import {runInAction} from 'mobx';
import {map} from 'rxjs/operators';
import {Model} from '../app.model';
import {HttpClient} from '@angular/common/http';
import {Tariff, TariffFlatOffer} from './tariff.model';
import {Observable} from 'rxjs';
import {SaleOrder} from '../buy/buy.model';
import {Subscription} from '../subscription/subscription.model';

@Injectable()
export class TariffService {
  private needsRefresh = true;

  constructor(private http: HttpClient, private m: Model) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.http.get<any[]>('/services/products/flatrates')
        .pipe(map((r) => r.map((item) => Tariff.fromJson(item))))
        .subscribe((r) => runInAction(() => {
          this.m.tariff.flatrates = r;
          this.needsRefresh = false;
        }));
    }
  }

  public createTariffOffer(tariffFlatOffer: TariffFlatOffer): Observable<boolean> {
    return this.http.post<SaleOrder>('/services/subscriptions/offer/flatrate/create', tariffFlatOffer).pipe(map(r => {
      runInAction(() => {
        this.m.buy.currentOrder = SaleOrder.fromJson(r);
      });
      return true;
    }));
  }

    public cancelOrderedTariff(s: Subscription) {
        const data = {
            appliance_id: s.appliance_id
        };
        return this.http.post<any>('/services/subscriptions/order/flatrate/cancel', data);
    }
}
