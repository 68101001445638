import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-phonenumber-port-provider',
  templateUrl: './phonenumber-port-provider.component.html'
})
export class PhonenumberPortProviderComponent implements OnInit {

  selectedProvider: any;
  filteredProviders: any[];
  providers: any[];

  @Input()
  countryCode = 'DE';

  @Output()
  public onNext = new EventEmitter<string>();
  @Output()
  public onBack = new EventEmitter<void>();

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.getProviders().then(p => {
      this.providers = p;
    });
  }

  searchProvider(event) {
    const filtered: any[] = [];

    for (const p of this.providers) {
      if (p.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
        filtered.push(p);
      }
    }

    this.filteredProviders = filtered;
  }

  next() {
    this.onNext.next(this.selectedProvider);
  }

  back() {
    this.onBack.next();
  }

  private getProviders() {
    let providerFile = 'de_providers.json';
    if (this.countryCode !== 'DE') {
      providerFile = `${this.countryCode.toLowerCase()}_providers.json`;
    }
    return this.http.get<any>(`assets/${providerFile}`)
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => data);
  }

}
