<span *ngIf="subscription && !isOne && !isOneTrial">
  <span *ngIf="!subscription.isOneAppliance ">{{ subscription.appliance_type }}</span>
  <span class="text-lowercase" *ngIf="subscription.isOneAppliance ">{{ 'subscription_type_cloud' | i18next }}</span>
  <span *ngIf="subscription.parent_id">
    -&nbsp;<a [routerLink]="['/subscriptions', subscription.parent_id]">{{subscription.parent_name}}</a>
  </span>
  <span *ngIf="subscription.parent_name && !subscription.parent_id">
    -&nbsp;{{subscription.parent_name}}
  </span>
</span>

<span *ngIf="subscription && isOne">
  {{ 'pascom_one_product_name' | i18next }}
</span>
<span *ngIf="subscription && isOneTrial">
  {{ 'pascom_one_trial_product_name' | i18next }}
</span>
