import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AttachedFile} from '../../support/support.model';

@Component({
  selector: 'app-upload-company-license',
  templateUrl: './upload-company-license.component.html'
})
export class UploadCompanyLicenseComponent implements OnInit {

  @Output() selected: EventEmitter<{file_name: string, file_data: string}> =
    new EventEmitter<{file_name: string, file_data: string}>();

  public files: AttachedFile[] = [];

  constructor() {
  }
  ngOnInit() {
  }

  public changed(files: AttachedFile[]) {
    this.files = files;
  }

  public onNext() {
    if (this.files.length === 0) {
      return;
    }

    const file: File = this.files[0].file; // We can only upload one file

    const reader = new FileReader();
    reader.onloadend = () => {
      const fileResult = reader.result.toString().replace(/^[^,]*,/, '');
      const uploadData = {
        file_name: file.name,
        file_data: fileResult
      };
      this.selected.emit(uploadData);
    };
    reader.readAsDataURL(file);
  }
}
