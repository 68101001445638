import {runInAction} from 'mobx';
import {Country} from '../country/country.model';
import {action, computed, observable} from '@myshared/mobx-connector';

export class Profile {
  public city: string;
  public country: Country;
  public id: string;
  public logo: string; // Base64
  public website: string;
  public zip: string;

  public static fromJson(json: any): Profile {
    const p = new Profile();
    p.city = json.city || '';
    p.country = Country.fromJson(json.country || {});
    p.id = json.id || '';
    p.logo = json.logo || '';
    p.website = json.website || '';
    p.zip = json.zip || '';
    return p;
  }
}

export class PartnerTestResult {

  public passed: boolean; // Is the current run successful?
  public failed: boolean; //If this is true, the test failed completely (running out of attempts) and you can't redo it
  public message: string; // i18next translation key

  public static fromJson(json: any): PartnerTestResult {
    const r = new PartnerTestResult();
    r.passed = json.passed || false;
    r.failed = json.failed || false;
    r.message = json.message || '';
    return r;
  }
}

export class PartnerTestAnswer {

  public id: string;
  public text: string;
  public checked: boolean;

  public static fromJson(json: any): PartnerTestAnswer {
    const a = new PartnerTestAnswer();
    a.id = json.id || '';
    a.text = json.text || '';
    a.checked = json.checked || false;
    return a;
  }
}

export class PartnerTestQuestion {
  public id: string;
  public question_text: string;
  public max_answers: number;
  public answers: PartnerTestAnswer[];

  public static fromJson(json: any): PartnerTestQuestion {
    const q = new PartnerTestQuestion();
    q.id = json.id || '';
    q.question_text = json.question_text || '';
    q.max_answers = json.max_answers || 0;
    q.answers = (json.answers || []).map(x => PartnerTestAnswer.fromJson(x));
    return q;
  }
}

export type PartnerTestStatus = 'open'|'passed'|'failed'|'overdue';

export class PartnerTest {

  public id: string;
  public deadline: string;
  public status: PartnerTestStatus;
  public attempts: number;
  public max_attempts: number;
  public name: string;

  public get usedAttempts(): number {
    return this.max_attempts - this.attempts;
  }

  public static fromJson(json: any): PartnerTest {
    const t = new PartnerTest();
    t.id = json.id || '';
    t.deadline = json.deadline || '';
    t.status = json.status || '';
    t.attempts = json.attempts || 0;
    t.max_attempts = json.max_attempts || 0;
    t.name = json.name || '';
    return t;
  }
}

export class CurrentPartnerTest extends PartnerTest {
  public questions: PartnerTestQuestion[];

  public static fromJson(json: any): CurrentPartnerTest {
    const c = (PartnerTest.fromJson(json) as CurrentPartnerTest);
    c.questions = (json.questions || []).map(x => PartnerTestQuestion.fromJson(x));
    return c;
  }
}

export class PartnerModel {
  @observable profiles: Profile[];

  @observable tests: PartnerTest[];
  @observable currentTest: CurrentPartnerTest;

  @computed get inTest(): boolean {
    return this.currentTest.id != '';
  }

  @action resetTest() {
    this.currentTest = CurrentPartnerTest.fromJson({});
  }

  constructor() {
    runInAction(() =>  {
      this.profiles = [];
      this.tests = [];
      this.currentTest = CurrentPartnerTest.fromJson([]);
    });
  }
}
