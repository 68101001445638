<div [class]="'achievement-card ' + cardClass">
  <div class="row no-gutters achievement">
    <div class="achievement-item col-12">
      <div class="d-flex">
        <i class="achievement-icon fas fa-check"></i>
        <div>
          <div>{{ achievement.i18next_name | i18next }}</div>
          <div class="small" *ngIf="descriptionContent !== ''">{{ descriptionContent }}</div>
        </div>
        <div>&nbsp;</div>
        <i *ngIf="helpContent !== ''" class="ml-auto far fa-question-circle achievement-tooltip" [pTooltip]="helpContent"></i>
      </div>

    </div>
  </div>
</div>
