<div class="card card-nb h-100">
  <app-content-header [headline]="'phonenumber_list_headline' | i18next" 
                    hideBackButton="true"
                    class="pl-3 pt-3"></app-content-header>
  <div class="card-body pb-3 pt-0">
    <div [innerHTML]="'phonenumbers_type_summary' | i18next: {
      currentlyInUse: currentlyInUse,
      includedInSubscription: includedInSubscription }"></div>
      <div>
          <div class="d-flex mt-3">
            <div><i class="far fa-question-circle red"></i></div>
            <div class="ml-2">{{ "phonenumbers_type_disclaimer" | i18next: {
                                 monthlyPrice: (monthlyPrice | localized_currency:{vatMarker: true}),
                                 setupPrice: (setupPrice | localized_currency:{vatMarker: true})} }}</div>
          </div>
      </div>
  </div>
</div>
