import {computed, observable, runInAction} from 'mobx';
import {Subscription} from '../subscription/subscription.model';

export class Phonenumber {
  id: number;
  phonenumber: string;
  state?: string;
  subscriptionId?: string;

  public static fromJson(json: any): Phonenumber {
    const p = new Phonenumber();
    p.id = json.id || 0;
    p.phonenumber = json.phonenumber || '';
    p.state = json.state ?? undefined;
    return p;
  }

  public get isActive() {
    return (this?.state === 'active') ?? undefined;
  }
}

export class PhonenumberBlock {
  public blockId: number;
  public txId: string;
  public state: string;
  public numbers: Phonenumber[];

  public static fromJson(json: any): PhonenumberBlock {
    const p = new PhonenumberBlock();
    p.blockId = json.id || 0;
    p.txId = json.refno || '';
    p.state = json.state || '';
    p.numbers = json.numbers?.map(r => Phonenumber.fromJson(r));
    return p;
  }

  public get isReserved(): boolean {
    return this.state !== 'pending_reservation';
  }
}

export class TrunkNumberBlock {
  public trunkNumbers: [];

  public static fromJson(json: any): TrunkNumberBlock {
    const p = new TrunkNumberBlock();
    p.trunkNumbers = (json.trunk_numbers && json.trunk_numbers.length > 0) ? 
                      json.trunk_numbers.map(r => BlockNumber.fromJson(r)) : [];
    return p;
  }
}

export type BlockStatus = 'pending' | 'active';

export class BlockNumber {
  phonenumber: string;
  subscriptionId: string;

  public static fromJson(json: any): BlockNumber {
    const tn = new BlockNumber();
    tn.phonenumber = json.phonenumber;
    tn.subscriptionId = json.subscription_id;
    return tn;
  }

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.phonenumber);
  }
}

export class BlockPartner {
  id: number;
  name: string;

  public static fromJson(json: any): BlockPartner {
    const tp = new BlockPartner();
    tp.id = json.id;
    tp.name = json.name;
    return tp;
  }
}

export class BlockOffer {
  blockId: string;
  fromNumber: string;
  toNumber: string;
  blockSize: number;

  public static fromJson(json: any): BlockOffer {
    const b = new BlockOffer();
    b.blockId = json.block_id ?? '';
    b.fromNumber = json.from_number ?? '';
    b.toNumber = json.to_number ?? '';
    b.blockSize = json.blocksize ?? 0;
    return b;
  }
}

export class Block {
  name: string;
  numbers: BlockNumber[];
  partner: BlockPartner;
  state: string;
  appliance: Subscription;
  fromNumber: string;
  toNumber: string;
  id: number;
  locationIndependent: boolean;
  delivery_date: string | boolean;
  saleOrderName: string;

  public static fromJson(json: any): Block {
    const t = new Block();
    t.name = json?.refno;
    t.numbers = json?.numbers?.map(v => BlockNumber.fromJson(v));
    t.partner = BlockPartner.fromJson(json.partner);
    t.state = json.state || 'pending'; // FIXME: Later: State from the block, not from the single phone number
    t.appliance = Subscription.fromJson(json?.appliance || {});
    t.fromNumber = json?.from_number;
    t.toNumber = json?.to_number;
    t.id = json?.id;
    t.locationIndependent = json?.location_independent;
    t.delivery_date = json.delivery_date ?? false;
    t.saleOrderName = json.originating_sale_order?.name ?? "";
    return t;
  }

  @computed public get status(): string {
    return ['pending', 'active', 'dead'].includes(this.state) ? this.state : 'pending';
  }
}

export class PhonenumberCheckoutSummary {
  monthly: PhonenumberCheckoutSummaryType;
  oneTimeFee: PhonenumberCheckoutSummaryType;
}
export class PhonenumberCheckoutSummaryType {
  included: { quantity: number, price: number, summaryPrice: number };
  additional: { quantity: number, price: number, summaryPrice: number };
}

export class PhonenumberPrice {
  name: string;
  list_price: number;
}

export class PhonenumberModel {
  @observable blocks: Block[];

  constructor() {
    runInAction(() => this.blocks = [] );
  }


}
