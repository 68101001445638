import { Component } from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {PartnerTestService} from '../partnertest.service';
import {PartnerTest} from '../partner.model';
import {Model} from '../../app.model';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
})
export class TestListComponent extends AutorunComponent {

  public tests: PartnerTest[];

  constructor(
    private testService: PartnerTestService,
    private m: Model
  ) { super(); }

  ngOnInit() {
    this.autorun(() => this.tests = this.m.partner.tests);
    this.testService.refresh();
  }

}
