import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {CookieHelper} from '@myshared/cookieHelper';
import {Base64} from 'js-base64';
import {SESSION_ID, STORAGE_ODOO_SESSION_ID} from "@myshared/utils";


if (environment.production) {
  enableProdMode();
}

if (window.location.pathname === '/login-session-user') {
  // IT-946 override session_id *before* launching the application
  const params = new URLSearchParams(window.location.search);
  const session_id_b64 = params.get(SESSION_ID);
  if (session_id_b64) {
    const sessionId = Base64.decode(session_id_b64);
    CookieHelper.set(SESSION_ID, sessionId, 364);
    sessionStorage.setItem(STORAGE_ODOO_SESSION_ID, sessionId);
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
