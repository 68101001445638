import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PortNumbersPortingFormDetails} from '../port-phonenumber.model';

@Component({
  selector: 'app-cancel-provider-connection',
  templateUrl: './cancel-provider-connection.component.html',
  styles: [
  ]
})
export class CancelProviderConnectionComponent implements OnInit {

  @Output() selected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() portingFormDetails: PortNumbersPortingFormDetails;

  public cancelConnection: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  public onNext() {
    this.selected.emit(this.cancelConnection);
  }

  public onCancelConnectionCheckbox(checked: boolean) {
    this.cancelConnection = checked;
  }
}
