import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-change-host-done',
  templateUrl: './subscription-change-host-done.component.html'
})
export class SubscriptionChangeHostDoneComponent implements OnInit {

  @Input() subscription: Subscription;
  @Input() oldCspHost: {aid: string, aname: string};
  @Input() newCspHost: {aid: string, aname: string};
  @Output() changeHostDoneEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onOk() {
    this.changeHostDoneEvent.emit();
  }

}
