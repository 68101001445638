import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionService} from '../../subscription/subscription.service';
import {SaleOrder} from '../../buy/buy.model';
import {BuyService} from '../../buy/buy.service';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-tariff-thankyou',
  templateUrl: './tariff-thankyou.component.html'
})
export class TariffThankyouComponent extends AutorunComponent implements OnInit {

  @Input() orderName: string;
  @Input() applianceId: string;

  public order: SaleOrder;
  public fromInstance = false;

  public backroute: string[];
  public isCloud: boolean;
  public isCsp: boolean;

  constructor(private m: Model,
              private i18next: I18NextService,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router) {  super(); }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('toinstance')) {
      this.fromInstance = true;
    }

    this.backroute = ['/subscriptions', this.aid];
    this.autorun(() => {
      if (!this.route.snapshot.queryParamMap.has('fromwf')) {
        this.backroute = ['/subscriptions', this.aid];
      }
    });

    this.route.queryParamMap.subscribe(params => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }
    });

    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
    });

    if (this.orderName) {
        this.buyService.refresh(this.orderName);
    }
  }

  public openBackroute() {
    window.history.replaceState(null, null, '/subscriptions/' + this.aid);
    this.m.workflow.resetWorkflow();
    this.router.navigate(this.backroute);
  }

  get aid() {
    return this.applianceId ?? '';
  }
}

