import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../profile.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../../app.model';
import {Profile} from '../../partner.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
})
export class ProfileListComponent extends AutorunComponent implements OnInit {

  public profiles: Profile[] = [];

  public get firstProfile() {
    return this.profiles.length > 0 ? this.profiles[0] : null;
  }

  public mode: string;

  constructor(private m: Model,
              private router: Router,
              private profileService: ProfileService
  ) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.profiles = this.m.partner.profiles;
    });

    this.autorun(() => {
      this.mode = this.m.account.currentUser.company_website_profile;
    });

    this.profileService.refresh(true);
  }

  addProfile() {
    this.router.navigate(['/partner', 'profiles', 'add']);
  }

}
