import {Injectable} from '@angular/core';
import {Model} from '../app.model';
import {HttpClient} from '@angular/common/http';
import {runInAction} from 'mobx';
import {BankAccount, Company, Contact, SignupCompany} from './company.model';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {I18NextService} from 'angular-i18next';
import {normalizeLanguageCode} from '@myshared/i18nutils';


@Injectable()
export class CompanyService {

  private needsRefresh = true;

  constructor(private m: Model,
              private http: HttpClient,
              private i18next: I18NextService) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.getMyCompany().subscribe((r: Company) => runInAction(() => {
        this.m.company.company = r;
        this.needsRefresh = false;
      }));
    }
  }

  public getMyCompany(): Observable<Company> {
    return this.http.get<any>('/services/company').pipe(map(
      (r: any) => Company.fromJson(r)
    ))
  }

  public getCompany(id: string) {
    return this.http.get<any>(`/services/company/${id}`).pipe(
      map(r => {
        return Company.fromJson(r);
      }
    ));
  }

  public update(c: Company | SignupCompany, refreshModel = true): Observable<Company> {
    return this.http.post<any>(`/services/company/`, c).pipe(
      map(r => {
        const company = Company.fromJson(r)
        if (refreshModel)  {
          runInAction(() => this.m.company.company = Company.fromJson(r));
        }
        return company;
    }));
  }

  public updateContact(c: Contact): Observable<boolean> {
    return this.http.post<any>('/services/company/contact/' + c.id, c).pipe(
	  tap(() => {
		return true;
    }));
  }

  public inviteContact(c: Contact): Observable<boolean> {
    const data = c as any;
    data.lang = normalizeLanguageCode(this.i18next.language);
    return this.http.post<any>('/services/company/contact/invite', data);
  }

  /**
   * Add contact for CSP
   * @param c
   */
  public addCSPContact(c: Contact): Observable<boolean> {
    const data = c as any;
    data.lang = normalizeLanguageCode(this.i18next.language);
    return this.http.post<any>('/services/company/contact/add', data);
  }

  public updateBankAccount(b: BankAccount): Observable<boolean> {
    return this.http.post<BankAccount>('/services/company/bankaccount', b).pipe(map(r => {
      this.refresh(true);
      return true;
    }));
  }
}
