<div class="mb-3">
  <button name="back" class="btn btn-outline-primary" (click)="back()">{{ 'app_back' | i18next }}</button>
</div>
<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ "support_new_ticket_headline" | i18next: { subscriptionName: subscriptionName } }}</div>
</div>

<div class="card card-nb">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="form-group-container form-group">
          <label>{{ "support_ticket_form_subject_label" | i18next }}</label>
          <input type="text" pInputText [(ngModel)]="subject" class="w-100" autofocus required />
        </div>
        <div class="form-group-container form-group">
          <label>{{ "support_ticket_form_description_label" | i18next }}</label>
          <app-article-editor [(message)]="articleMessage"></app-article-editor>
        </div>
      </div>
    </div>
    <div class="d-flex bd-highlight mt-3 mb-3">
      <div class="mr-auto ticket">
          <app-upload-attachments-form *ngIf="!isRequesting" id="upload-form" [applianceId]="subscription.appliance_id" (onFilesChanged)="onFilesChanged($event)" (onUpload)="onUpload($event)"></app-upload-attachments-form>
      </div>
      <div>
        <button name="detail" class="btn btn-outline-primary" (click)="submitTicket()" [disabled]="isRequesting || isArticleBlank || isSubjectBlank || isUploading">{{'app_submit' | i18next}}</button>
      </div>
  </div>
  </div>
</div>
