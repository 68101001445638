import {runInAction} from 'mobx';
import {observable} from '@myshared/mobx-connector';

export class Achievements {
  certified: Achievement[];
  premium: Achievement[];
  excellence: Achievement[];
  specials: Achievement[];
}

export class Achievement {
  id: string;
  completed: boolean;
  i18next_name?: string;
  i18next_description?: string;
  i18next_help?: string;
  value?: number;
  maxValue?: number;
  addValue?: string;
}

export class AchievementsModel {
  @observable achievements: Achievements;

  constructor() {
    runInAction(() => this.achievements);
  }
}
