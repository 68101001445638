<div *ngIf="initialized">
    <app-content-header [hideBackButton]="true"
                        [headline]="'invite_title' | i18next"></app-content-header>

    <div class="row d-flex justify-content-center mt-2">
      <div class="card card-nb col-sm-12 col-md-8 col-lg-6 mb-3 mb-lg-0">
        <div class="card-body">
          <div class="h3 red">
            {{ 'invite_salutation' | i18next }}<span class="ml-1" *ngIf="contactName">{{contactName}}</span>
          </div>
          <p class="mt-2 mb-4" [innerHTML]="'invite_description' | i18next"></p>
        </div>
      </div>
    </div>
</div>
