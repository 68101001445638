import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SlaProduct} from '../../select-product/product.model';

@Component({
  selector: 'app-sla-card',
  templateUrl: './sla-card.component.html',
  styleUrls: []
})
export class SlaCardComponent {

  @Input() public slaProduct: SlaProduct;
  @Output() public readonly selected = new EventEmitter<string>();

  send(selectedSlaProduct) {
    this.selected.emit(selectedSlaProduct);
  }

  public get disabled() {
    return this.slaSelected;
  }

  public get selectBtnTranslation() {
    return `sla_product_${this.slaProduct.type}`;
  }

  public get billingPeriod() {
    if (!!this.slaProduct.monthlyPayment) {
      return 'price_per_month';
    }
    return 'price_per_year';
  }

  public get cssClasses() {
    let normal, highlight;

    if (this.slaSelected) {
      normal = !this.slaSelected;
      highlight = this.slaSelected;
    } else {
      normal = this.slaProduct.name !== 'MDSL1';
      highlight = this.slaProduct.name === 'MDSL1';
    }
    return { 'normal': normal, 'highlight': highlight };
  }

  public get slaSelected() {
    return this.slaProduct.type === 'selected';
  }
}
