<ng-container *ngIf="customer">
  <app-content-header [historyBack]="false" (backEvent)="onBack()" [headline]="customer.name"></app-content-header>

  <div class="h3 mb-3">{{ 'customer_details_headline' | i18next }}</div>

  <app-customer-navigation [id]="customerId"></app-customer-navigation>

  <div class="card card-nb m-3">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-outline-primary" *ngIf="formGroup.status === 'VALID'" (click)="onSave()">{{'app_save' | i18next}}</button>
      </div>
    </div>
  </div>
</ng-container>
