import {FormControl, ValidatorFn} from '@angular/forms';

/**
 * Form Validator function attached to one FormControl. Validates that value equals otherFormControls value
 * @param {string} otherControlName
 * @returns {ValidatorFn}
 */
export function matchOther (otherControlName: string): ValidatorFn {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate (control: FormControl) {
    if (!control.parent) {
      return null;
    }
    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.root.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }
    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true
      };
    }
    return null;
  };
}
