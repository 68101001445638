import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {Subscription} from '../../subscription/subscription.model';
import {Block} from '../phonenumber.model';
import {PhonenumberService} from '../phonenumber.service';
import {ConfirmationService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-phonenumber-card',
  templateUrl: './phonenumber-card.component.html'
})
export class PhonenumberCardComponent extends AutorunComponent implements OnInit {

  @Input()
  public clickable = true;
  @Input()
  public block: Block;
  public isPartner: boolean;

  constructor(private m: Model,
              private phoneNumberService: PhonenumberService,
              private confirmationService: ConfirmationService,
              private i18next: I18NextService,
              private route: ActivatedRoute,
              private router: Router) {
    super();
  }
  ngOnInit(): void {
    this.autorun(() => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
    });
  }

  get subscription(): Subscription {
    return this.block.appliance;
  }

  public get isBlock(): boolean {
    return this.block.numbers.length > 1;
  }

  public get cssClass() {
    return {
      'pending': this.block.status === 'pending',
      'active': this.block.status === 'active',
      'dead': this.block.status === 'dead',
    };
  }

  public get statusTranslationKey() {
    return 'trunk_status_' + this.block.status;
  }

  public get statusDescriptionTranslationKey() {
    return 'trunk_status_' + this.block.status + '_description';
  }

  public cancelPhoneNumber() {
    if (this.isLocationIndependentNumber) {
      let blockText = '<br><br>';
      const block = this.block;
      if (this.isBlock) {
        blockText += `${this.i18next.t('type') as string}: ${this.i18next.t('phonenumber_type_block') as string}<br>
                     ${this.i18next.t('start') as string}: ${block.fromNumber}<br>
                     ${this.i18next.t('end') as string}: ${block.toNumber}<br>
                     ${this.i18next.t('length') as string}: ${block.numbers.length}`;
      } else {
        blockText += `${this.i18next.t('type') as string}: ${this.i18next.t('phonenumber_type_single') as string}<br>
                     ${this.i18next.t('phonenumber') as string}: ${block.fromNumber}`;
      }

      this.confirmationService.confirm({
        message: this.i18next.t('remove_phone_number_confirm_message') + blockText,
        header: this.i18next.t('remove_phone_number_confirm_title') as string,
        accept: () => this.phoneNumberService.removePhoneNumber(this.block.id)
      });
    }
  }

  public get isLocationIndependentNumber() {
    return this.block.locationIndependent && this.isMySubscriptionOrPartner
      && !this.subscription?.isOneTrial && this.isBlockActive && !this.isBlock;
  }

  public get isBlockActive() {
    return this.block.status === 'active';
  }

  public showDetails() {
    if (this.canShowDetails) {
      this.router.navigate(['/subscriptions',this.subscription?.appliance_id]);
    }
  }

  public get canShowDetails() {
    return this.subscription?.isValid && this.clickable;
  }

  private get isMySubscriptionOrPartner() {
    return this.subscription?.is_my || this.isPartner;
  }
}
