import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {formatByteSize} from '@myshared/utils';
import {Model} from '../app.model';
import {normalizedShortLang} from '@myshared/i18nutils';

@Pipe({
  name: 'formatByteSize'
})
@Injectable()
export class FormatByteSizePipe implements PipeTransform {

  private language: string;

  constructor(private m: Model) {
    this.language = normalizedShortLang(this.m.account.currentUser.lang).toLowerCase();
  }

  transform(value: any, options?: {decimals?: number, iecCalc?: boolean, iecOutput?: boolean}): any {

    const size = formatByteSize(value, options?.iecCalc, options?.iecOutput);
    const formattedNumber =
      new Intl.NumberFormat(this.language, { maximumSignificantDigits: options?.decimals ?? 2 })
              .format(size.value);
    return formattedNumber + ' ' + size.symbol;
  }
}
