<div class="d-flex flex-column mb-3">
  <div class="d-flex flex-row justify-content-end">
    <div class="p-2">{{ 'checkout_summary_subtotal' | i18next }}</div>
    <div class="p-2">{{ summary.summaryPrice | localized_currency }}</div>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <div class="p-2">{{ 'checkout_summary_tax' | i18next }}</div>
    <div class="p-2">{{ getCalculatedVat() | localized_currency }}</div>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <div class="p-2 font-weight-bold">{{ totalLabel | i18next }}</div>
    <div class="p-2 font-weight-bold">{{ getCalculatedSummary() | localized_currency }}</div>
  </div>
</div>
