<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'company_contacts_header' | i18next }}</div>
  <div class="ml-auto bd-highlight">
    <button class="btn btn-outline-primary" routerLink="/company/contacts/invite" >{{'company_contacts_invite' | i18next}}</button>
  </div>
</div>

<app-company-navigation></app-company-navigation>

<app-filter filterType="checkbox"
            [checkboxFilterText]="'company_contacts_show_disabled' | i18next"
            (checkboxFilterChanged)="showDisabled($event)"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'company_contacts_search' | i18next"></app-filter>

<div class="row align-items-stretch">
  <app-contact-card class="col-xs-12 col-md-6 col-lg-6 mb-3 hover-effect" *ngFor="let c of contacts" [contact]="c" [detaillist]="true">
    <button class="btn btn-outline-primary float-right mr-0" [routerLink]="['/company/contacts/', c.id]">
      {{'app_edit' | i18next }}
    </button>
  </app-contact-card>
</div>

