<table class="table w-100 table-borderless">
  <thead class="border-bottom border-dark">
  <tr>
    <th>{{ 'checkout_summary_description' | i18next }}</th>
    <th>{{ 'checkout_summary_quantity' | i18next }}</th>
    <th>{{ 'checkout_summary_unit_price' | i18next }}</th>
    <th>{{ 'checkout_summary_tax' | i18next }}</th>
    <th>{{ totalLabel | i18next }}</th>
  </tr>
  </thead>
  <tr class="border-bottom" *ngIf="summary.included.quantity > 0">
    <td>
      <div>{{ "checkout_phonenumber_included_phonenumber" | i18next }}</div>
      <div *ngIf="orderLines && orderLines.length > 0">{{ translateOrderLineName(orderLines[0].name) }}</div>
    </td>
    <td>{{ summary.included.quantity }}</td>
    <td style="min-width: 90px;">{{summary.included.price | localized_currency}}</td>
    <td style="min-width: 90px;">
      <span *ngIf="orderLines && orderLines.length > 0">{{orderLines[0].tax}}%</span>
    </td>
    <td style="min-width: 130px;">{{summary.included.summaryPrice | localized_currency}}</td>
  </tr>
  <tr class="border-bottom" *ngIf="summary.additional.quantity > 0">
    <td>
      <div>{{ "checkout_phonenumber_addtional_phonenumber" | i18next }}</div>
      <div *ngIf="orderLines && orderLines.length > 0">{{ translateOrderLineName(orderLines[0].name) }}</div>
    </td>
    <td>{{ summary.additional.quantity }}</td>
    <td style="min-width: 90px;">{{summary.additional.price | localized_currency}}</td>
    <td style="min-width: 90px;">
      <span *ngIf="orderLines && orderLines.length > 0">{{orderLines[0].tax}}%</span>
    </td>
    <td style="min-width: 130px;">{{summary.additional.summaryPrice | localized_currency}}</td>
  </tr>
</table>
