import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '../autorun.component';
import {LanguageService} from '../../language.service';
import {Model} from '../../app.model';

@Component({
  selector: 'app-choose-language',
  templateUrl: './choose-language.component.html',
})
export class ChooseLanguageComponent extends AutorunComponent implements OnInit {

  isDE: boolean;

  get isEN() {
    return !this.isDE;
  }

  constructor(private languageService: LanguageService,
              private m: Model
  ) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isDE = this.m.account.currentUser.lang === 'de_DE';
    });
  }

  switchDE() {
    this.languageService.changeLanguage('de_DE');
  }

  switchEN() {
    this.languageService.changeLanguage('en_US');
  }

}
