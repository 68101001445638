import { Component} from '@angular/core';
import {Model} from '../../app.model';
import {PartnerTestService} from '../partnertest.service';
import {runInAction} from 'mobx';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CurrentPartnerTest, PartnerTestQuestion, PartnerTestResult} from '../partner.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-test-execute',
  templateUrl: './test-execute.component.html',
})
export class TestExecuteComponent extends AutorunComponent {

  @observable private testId$: string;

  public currentTest: CurrentPartnerTest = CurrentPartnerTest.fromJson({});

  public currentQuestion: PartnerTestQuestion;
  public questionIdx: number;
  public confirmFinish = false;

  public result: PartnerTestResult;

  constructor(
    private testService: PartnerTestService,
    private route: ActivatedRoute,
    private router: Router,
    private m: Model
  ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      if (this.testId$) {
        this.testService.fetchTest(this.testId$);
      }
    });
    this.autorun( () => {
      if (this.currentTest.id != this.m.partner.currentTest.id) {
        this.currentTest = this.m.partner.currentTest;
        if (this.currentTest.questions.length > 0) {
          this.currentQuestion = this.currentTest.questions[0];
          this.questionIdx = 0;
        }
      }
    });
    this.route.paramMap.subscribe((params: ParamMap) => runInAction(() => this.testId$ = params.get('testId')));
  }

  get canPrevious() {
    return this.questionIdx > 0;
  }

  public previous() {
    if (this.canPrevious) {
      this.questionIdx--;
      this.currentQuestion = this.currentTest.questions[this.questionIdx];
    }
  }

  get canNext() {
    return this.questionIdx < this.currentTest.questions.length - 1;
  }

  get progressPercent(): string {
    const completed = this.questionIdx + 1;
    const todo = this.currentTest.questions.length;
    return (completed / todo * 100) + '%';
  }

  public next() {
    if (this.canNext) {
      this.questionIdx++;
      this.currentQuestion = this.currentTest.questions[this.questionIdx];
    }
  }

  public goBack() {
    this.m.partner.resetTest();
    this.router.navigate(['/partner', 'tests']);
  }

  public doubleCheck() {
    this.confirmFinish = false;
    this.questionIdx = 0;
    this.currentQuestion = this.currentTest.questions[0];
  }

  public submitTest() {
    this.testService.submitTest(this.currentTest).subscribe(r => {
      this.result = r;
    });
  }
}
