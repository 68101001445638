<div *ngIf="profile">
  <div class="card card-nb">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <a href="{{profile.website}}" target="_blank">{{profile.website}}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div>{{profile.zip}}&nbsp;{{profile.city}}</div>
          <div>{{profile.country.code}}&nbsp;{{profile.country.name}}</div>
          <div class="mt-4">
            <button class="btn btn-outline-primary" (click)="editProfile()">{{'app_details' | i18next}}</button>
          </div>
        </div>
        <div class="col-5 profile-image-container">
          <img class="img-fluid profile-image" src="{{logo}}" *ngIf="logo" />
        </div>
      </div>
    </div>
  </div>
</div>
