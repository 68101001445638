import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from '../../subscription/subscription.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {WorkflowService} from '../../workflow/workflow.service';
import {Model} from '../../app.model';

@Component({
  selector: 'app-pairing-button',
  templateUrl: './pairing-button.component.html',
  styleUrls: []
})
export class PairingButtonComponent implements OnInit, OnChanges {

  @Input() public subscription: Subscription;

  public showPair = false;
  public showUnpair = true;

  constructor(private subscriptionService: SubscriptionService,
              private workflowService: WorkflowService,
              private m: Model) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.subscription) {
      const pairingState = this.subscription.pairing_state;
      const applianceType: string = this.subscription.appliance_type;
      const licenseType: string = this.subscription.license_type;
      const inWorkflow = this.m.workflow.inWorkflow;

      // On our standard licenses like pascom ONE or cloud (legacy) we do not have any pair buttons
      this.showUnpair = false;
      this.showPair = false;

      // See pair (only on workflow steps) and unpair if we have a csp or appliance type => applianceType: onsite, CSP
      if (['csp', 'onsite'].includes(applianceType)) {
        this.showPair = inWorkflow && pairingState === 'unpaired'; // We can only pair via the workflow (e.g. if we come from the CSUI)
        this.showUnpair = pairingState !== 'unpaired';

        if (licenseType === 'perpetual') {
          // Special case: Only show the "Unpair" button if the subscription pairing process is not already finished
          this.showUnpair = pairingState === 'pairing';
        }

      }
    }
  }

  public startpairing() {
    this.subscriptionService.startPairing(this.subscription).subscribe((response: {token}) => {
      this.workflowService.executeWorkflowRedirect(response.token);
    });
  }

  public unpair() {
    this.subscriptionService.unpair(this.subscription).subscribe(() => {
      window.location.reload();
    })
  }
}
