import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ItemizedBillingTypes} from '../../subscription/subscription.model';

@Component({
  selector: 'app-change-itemized-billing',
  templateUrl: './change-itemized-billing.component.html',
  styles: [
  ]
})
export class ChangeItemizedBillingComponent implements OnInit {

  @Output() selected: EventEmitter<ItemizedBillingTypes> = new EventEmitter<ItemizedBillingTypes>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onBack() {
    this.back.emit();
  }

  onSelect(selected: ItemizedBillingTypes) {
    this.selected.emit(selected);
  }

}
