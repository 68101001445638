import { Component, OnInit, Input } from '@angular/core';
import {Agreement, Attachment} from '../agreement.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';
import {Model} from '../../app.model';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-agreement-card',
  templateUrl: './agreement-card.component.html',
  styleUrls: []
})
export class AgreementCardComponent extends AutorunComponent implements OnInit {

  @Input() showPreview = true;
  @Input() showNotSignedHint = false;
  @Input() showAttachmentsList = true;

  @Input() set agreement(agreement: Agreement) {
    this._agreement = agreement;
    const agreementLanguage = '_' + this.i18next.language;
    let selectedAttachment: Attachment = null;
    if (this.inlinePdfLink) {
      this.m.network.requestDone();
    }
    if (this.showPreview) {
      if (this._agreement.attachments.length > 0) {
        for (const attachment of this._agreement.attachments) {
          if (attachment.mimeType === 'application/xml') {
            // We want to render the first available xml attachment directly inline as pdf
            if (!selectedAttachment) {
              selectedAttachment = attachment;
            }
            if (attachment.downloadName.indexOf(agreementLanguage) !== -1) {
              selectedAttachment = attachment;
              break;
            }
          }
        }
      }
    }
    if (selectedAttachment) {
      this.m.network.newRequest();
      this.inlinePdfLink = this.createLinkForAttachment(selectedAttachment);
    }
  }
  get agreement(): Agreement {
    return this._agreement;
  }
  private _agreement: Agreement;

  public inlinePdfLink = '';

  constructor(
    private m: Model,
    private messageService: MessageService,
    private i18next: I18NextService,
  ) { super(); }

  ngOnInit() {
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.m.network.requestDone();
  }

  onError(error: any) {
    this.messageService.add({severity: 'error',
      summary: this.i18next.t('error') as string,
      detail: this.i18next.t('render_error') as string});
    this.m.network.requestDone();
  }

  public createLinkForAttachment(attachment): string {
    const type = attachment.mimeType === 'application/pdf' || attachment.mimeType === 'application/xml' ? 'pdf' : 'binary';
    return '/services/agreement/' + this.agreement.id + '/attachment/' + attachment.id + '?type=' + type;
  }

  public printAttachment(attachment: Attachment) {
    const w = window.open(this.createLinkForAttachment(attachment));
    w.document.title = attachment.downloadName;
    w.print();
  }

}
