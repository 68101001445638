import {Component, Input} from '@angular/core';
import { Ticket, TicketState } from '../support.model';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: []
})
export class TicketCardComponent {
  @Input()
  public ticket: Ticket;
  @Input()
  public applianceName: string;

  constructor() {
  }

  get ticketStateClass() {
    return {
      'open': this.ticket.stateId === TicketState.open,
      'new': this.ticket.stateId === TicketState.new,
      'pending-reminder': this.ticket.stateId === TicketState.pendingReminder,
      'pending-close': this.ticket.stateId === TicketState.pendingClose,
      'closed': this.ticket.stateId === TicketState.closed
    };
  }

  get ticketSate() {
    switch (this.ticket.stateId) {
      case TicketState.closed:
        return 'support_ticket_list_show_closed_tickets'
      case TicketState.open:
        return 'support_ticket_list_show_open_tickets'
      case TicketState.pendingReminder:
        return 'support_ticket_list_show_pending_reminder_tickets'
      case TicketState.pendingClose:
        return 'support_ticket_list_show_pending_closed_tickets'
      default:
      case TicketState.new:
        return 'support_ticket_list_show_new_tickets'
    }
  }
}
