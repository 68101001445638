import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-change-host-warning',
  templateUrl: './subscription-change-host-warning.component.html'
})
export class SubscriptionChangeHostWarningComponent implements OnInit {

  @Input() subscription: Subscription;
  @Output() continueChangeHostEvent  = new EventEmitter();
  @Output() backEvent  = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onContinue() {
    this.continueChangeHostEvent.emit();
  }

  back() {
    this.backEvent.emit();
  }

}
