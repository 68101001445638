import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
@Component({
  selector: 'app-top-sticky-message-bar',
  templateUrl: './top-sticky-message-bar.component.html',
  styleUrls: []
})
export class TopStickyMessageBarComponent extends AutorunComponent implements OnInit {

  public isSSO: boolean
  public name: string;

  constructor(private m: Model) { super(); }


  ngOnInit() {
    this.autorun(() => {
      this.isSSO = this.m.account.isSSOUser;
      this.name = this.m.account.currentUser.fullName;
    });
  }
}
