import {Component, OnInit} from "@angular/core";
import {AutorunComponent} from "@myshared/autorun.component";
import {CurrentUser, Tenant} from "../../auth/account.model";
import {Model} from "../../app.model";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
  styleUrls: []
})
export class SelectTenantComponent extends AutorunComponent implements OnInit {

  public tenant: Tenant;
  public accounts: CurrentUser[] = [];

  constructor(private m: Model, private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.tenant = this.m.account.currentUser.tenant;
      this.accounts = this.m.account.currentUser.accounts;
    })
  }

  public selectTenant(id: number) {
    this.authService.switchTenant(id);
  }
}
