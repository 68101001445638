import {Routes} from "@angular/router";
import {WorkflowCspOneTrialCreateComponent} from "./workflow-csp-one-trial-create.component";


export const workflowCspOneTrialCreateRouting: Routes = [
  {
    path: 'csp/one-trial', component: WorkflowCspOneTrialCreateComponent, children: [
      {path: 'customer', component: WorkflowCspOneTrialCreateComponent, data: {step: 'customer'}},
      {path: 'trial-phonenumber', component: WorkflowCspOneTrialCreateComponent, data: {step: 'trial-phonenumber'}},
      {path: 'domain', component: WorkflowCspOneTrialCreateComponent, data: {step: 'domain'}},
      {path: 'instance', component: WorkflowCspOneTrialCreateComponent, data: {step: 'instance'}},
      {path: 'phonenumber', component: WorkflowCspOneTrialCreateComponent, data: {step: 'phonenumber'}},
      {path: 'thank-you', component: WorkflowCspOneTrialCreateComponent, data: {step: 'thank-you'}},
      {path: '', redirectTo: 'customer', pathMatch: 'full'},
    ]
  },
]
