import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {BuyService} from '../../buy/buy.service';
import {firstValueFrom} from "rxjs";
import {Subscription} from "../../subscription/subscription.model";
import {SubscriptionService} from "../../subscription/subscription.service";
import {AutorunComponent} from "@myshared/autorun.component";
import {PaymentMethodCode} from "../../buy/buy.model";

type BuyUpgradeStep =
  | 'already-pcone-subscription'
  | 'creating-offer'
  | 'select-upgrade'
  | 'invoice-address'
  | 'payment-method'
  | 'checkout-summary'
  | 'thank-you';

function validateBuyStep(input: string, orderName: string): BuyUpgradeStep | boolean {
  if (['select-upgrade', 'already-pcone-subscription'].indexOf(input) !== -1) {
    return input as BuyUpgradeStep;
  }
  if (orderName && ['invoice-address', 'payment-method', 'checkout-summary', 'select-instance-name', 'thank-you'].indexOf(input) !== -1) {
    return input as BuyUpgradeStep;
  }
  return false;
}


@Component({
  selector: 'app-workflow-upgrade-subscription',
  templateUrl: './workflow-upgrade-subscription.component.html',
})
export class WorkflowUpgradeSubscriptionComponent extends AutorunComponent implements OnInit {

  constructor(
    private m: Model,
    private route: ActivatedRoute,
    private router: Router,
    private buyService: BuyService,
    private subscriptionService: SubscriptionService
  ) {
    super();
  }

  public orderName: string;
  public step: BuyUpgradeStep;
  public subscription: Subscription;
  public paymentMethod: PaymentMethodCode;

  ngOnInit() {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      if (params.has('id')) {
        this.orderName = params.get('id');
        this.buyService.refresh(this.orderName);
      }
      if (params.has('subroute')) {
        const sr = params.get('subroute');
        if (Number.isInteger(parseInt(sr))) {

          try {
            const subscription = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+sr));
            this.subscription = Subscription.fromJson(subscription);
            if (this.subscription.isClassicFree) {
              this.resetWorkflowAndListSubscriptions();
              return;
            }
          } catch (e) {
            this.resetWorkflowAndListSubscriptions();
            return;
          }

          if (this.subscription.isOne || this.subscription.isOneTrial) {
            this.setStep('already-pcone-subscription');
          } else {
            this.setStep('creating-offer');
            this.buyService.createUpgradeOffer(sr).subscribe(() => {
              this.navigateToStep('select-upgrade', this.m.buy.currentOrder.name);
            }, () => this.router.navigate(['/']));
          }

          return;
        }

        const newStep = validateBuyStep(sr, this.orderName);
        if (newStep !== false) {
          this.setStep(newStep as BuyUpgradeStep);
          return;
        }

        if (this.subscription.isOne || this.subscription.isOneTrial) {
          this.setStep('already-pcone-subscription');
        } else {
          this.navigateToStep('select-upgrade', '');
        }

      }
    });

    this.autorun(() => {
      this.paymentMethod = this.m.buy.currentOrder?.payment_method ?? this.subscription?.payment_method;
    })
  }

  private resetWorkflowAndListSubscriptions() {
    this.m.workflow.resetWorkflow();
    this.router.navigate(['/']);
    return;
  }

  private setStep(step: BuyUpgradeStep) {
    // TODO: Do some validation before actually applying the step
    this.step = step;
  }

  private navigateToStep(step: BuyUpgradeStep, orderId: string) {
    this.router.navigate(['/do', this.m.workflow.request.workflowName, step, orderId], {queryParamsHandling: 'preserve'});
  }

  public onProductSelected() {
    this.navigateToStep('invoice-address', this.orderName);
  }

  public onInvoiceAddressFilled() {
    this.navigateToStep('payment-method', this.orderName);
  }

  public onPaymentMethodSelected() {
    this.navigateToStep('checkout-summary', this.orderName);
  }

  public onBuy() {

    this.buyService.confirmUpgrade().subscribe(confirm => {
      this.navigateToStep('thank-you', confirm.name);
    });
  }

  public onBack() {
    if(this.paymentMethod === 'wire_transfer') {
      history.go(-2);
    } else {
      history.back();
    }
  }

}
