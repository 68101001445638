import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {FormGroup} from '@angular/forms';
import {Contact} from './company.model';
import {I18NextService} from 'angular-i18next';
import { trimInput } from '@myshared/utils';


export function contactForm(pcFormService: PCFormsService, i18next: I18NextService, mypascomAccountValues: string[], roleChangeReadonly = false): PCFormBuilder {
  const builder = pcFormService.newBuilder('contact');

  builder.addTextInput({
    id: 'first_name',
    autoFocus: true,
    tabIndex: 1,
    elementClass: {
      container: 'form-group-container'
    },
    validators: {required: null}
  });
  builder.addTextInput({
    id: 'last_name',
    tabIndex: 2,
    elementClass: {
      container: 'form-group-container'
    },
    validators: {required: null}
  });

  builder.addTextInput({
    id: 'email',
    tabIndex: 3,
    elementClass: {
      container: 'form-group-container'
    },
    validators: {
      required: null,
      email: null
    }
  });


  if (mypascomAccountValues.length > 0) {
    const selectValues = [];
    mypascomAccountValues.forEach(v => selectValues.push({
      label: i18next.t('contact_mypascom_account_' + v + '_label'),
      value: v
    }));

    builder.addSelect<string>({
      id: 'mypascom_account',
      readOnly: roleChangeReadonly,
      tabIndex: 4,
      elementClass: {
        container: 'form-group-container'
      },
      validators: {required: null}
    }, selectValues, selectValues[0].value);
  }

  return builder;
}

export function cspContactForm(pcFormService: PCFormsService): PCFormBuilder {
  const builder = pcFormService.newBuilder('contact');

  builder.addTextInput({
    id: 'first_name',
    autoFocus: true,
    tabIndex: 1,
    elementClass: {
      container: 'form-group-container'
    },
    validators: {required: null}
  });
  builder.addTextInput({
    id: 'last_name',
    tabIndex: 2,
    elementClass: {
      container: 'form-group-container'
    },
    validators: {required: null}
  });

  return builder;
}

export function setContactToForm(formGroup: FormGroup, contact: Contact) {
  formGroup.patchValue({
    'contact': {
      'first_name': contact.first_name,
      'last_name': contact.last_name,
      'email': contact.email,
      'mypascom_account': contact.mypascom_account
    }
  });
}

export function getContactFromForm(formGroup: FormGroup, id: string): Contact {
  const data = formGroup.getRawValue()['contact'];
  const c = new Contact();
  c.id = id;
  c.first_name = trimInput(data.first_name);
  c.last_name = trimInput(data.last_name);
  c.email = trimInput(data.email);
  c.mypascom_account = data.mypascom_account;
  return c;
}
