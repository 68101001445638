import {Country} from '../country/country.model';
import {computed, runInAction} from 'mobx';
import {observable} from '@myshared/mobx-connector';
import {Tenant, TrunkEnvironment} from "../auth/account.model";

export class Company {

  public id: string;
  public name: string;
  public street: string;
  public street2: string;
  public zip: string;
  public city: string;
  public country: Country;
  public vat: string;
  public email: string;
  public contacts: Contact[];
  public bankAccounts: BankAccount[];
  public validate_address?: boolean; // only for pascom one and sending to server for colt api check
  public trunkEnvironment?: TrunkEnvironment;
  public tenant: Tenant;

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.name)
      || predicate(this.street)
      || predicate(this.street2)
      || predicate(this.zip)
      || predicate(this.city)
      || predicate(this.country.name);
  }

  public get hasTrunkAccess(): boolean {
    return this.trunkEnvironment.hasTrunkAccess
  }

  public get tenantId() {
    return this.tenant?.id ?? undefined;
  }

  public static fromJson(json: any): Company {
    const c = new Company();
    c.id = json.id || '';
    c.name = json.name || '';
    c.street = json.street || '';
    c.street2 = json.street2 || '';
    c.zip = json.zip || '';
    c.city = json.city || '';
    c.vat = json.vat || '';
    c.email = json.email || '';
    c.country = Country.fromJson(json.country || {});
    c.contacts = (json.contacts || []).map(v => Contact.fromJson(v));
    c.bankAccounts = (json.bank_accounts || []).map(v => BankAccount.fromJson(v));
    c.trunkEnvironment = TrunkEnvironment.fromJson(json.trunk_environment || {})
    c.tenant = Tenant.fromJson(json.tenant ?? {});
    return c;
  }
}

export class SignupCompany extends Company {
  campaign_id: string;
}

type MypascomAccessLevel = 'disabled' | 'enabled' | 'admin';

export class Contact {
  public id: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public mypascom_account: MypascomAccessLevel;
  public active: boolean;
  public company_id: string;
  public invite_user?: boolean;
  public tenant?: Tenant;

  public static fromJson(json: any): Contact {
    const c = new Contact();
    c.id = json.id || '';
    c.first_name = json.first_name || '';
    c.last_name = json.last_name || '';
    c.email = json.email || '';
    c.mypascom_account = json.mypascom_account || 'disabled';
    c.active = json.active || false;
    c.company_id = json.company_id || '';
    c.tenant = json.tenant ?? {};
    return c;
  }

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.first_name)
      || predicate(this.last_name)
      || predicate(this.email);
  }

  @computed
  public get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }
  @computed
  public get fullNameWithMail() {
    return `${this.fullName} (${this.email})`;
  }
}

export class BankAccount {

  public id: string;
  public acc_number: string;
  public account_holder: string;
  public partner_id?: number;


  public static fromJson(json: any): BankAccount {
    const b = new BankAccount();
    b.id = json.id || '';
    b.acc_number = json.acc_number || '';
    b.account_holder = json.account_holder || '';
    return b;
  }
}

export class CompanyModel {
  @observable company: Company;

  constructor() {
    runInAction(() => this.company = Company.fromJson({}));
  }
}
