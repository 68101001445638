<div *ngIf="block && block.numbers.length > 0" class="card card-nb h-100 phonenumber" [ngClass]="{ 'hover-effect': canShowDetails }">
  <div class="position-absolute" style="top: 0.74rem; right: 0.6rem;">
    <div *ngIf="isLocationIndependentNumber"
         class="custom-dropdown-menu" ngbDropdown display="dynamic" placement="bottom-right">
      <button class="btn bg-transparent p-2 m-0 hide-dropdown-icon" id="dropdownBasic2" ngbDropdownToggle>
        <i class="fas fa-ellipsis-v red icon-size-bigger navbar-profile-icon"></i>&nbsp;
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <div class="cursor-pointer dropdown-item" (click)="cancelPhoneNumber()">
          <span class="d-flex">
            <i class="fa fa-ban red icon-size-big mr-2"></i>
            <span>{{ 'cancel_phone_number' | i18next }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pb-0 d-flex flex-column" (click)="showDetails()">
    <div class="d-flex justify-content-between">
      <app-subscription-title [ngClass]="{'red huline': canShowDetails}" [subscription]="subscription"></app-subscription-title>
    </div>
    <div class="subinformations mt-2" style="min-height: 80px;">
      <div class="d-flex justify-content-between">
        <app-phonenumber-block *ngIf="isBlock"
                               [from]="block.fromNumber"
                               [to]="block.toNumber"
                               [length]="block.numbers.length"
                               [deliveryDate]="block.delivery_date"></app-phonenumber-block>
        <app-phonenumber-single *ngIf="!isBlock"
                                [from]="block.fromNumber"
                                [deliveryDate]="block.delivery_date"
                                [locationIndependent]="block.locationIndependent"></app-phonenumber-single>
      </div>
    </div>
    <div class="row mt-auto subinformations pb-1">
          <div class="d-flex ml-auto align-items-end flex-column h-100">
            <div class="mt-auto" style="min-height: 22px;">
              <span [class]="cssClass" class="mr-2 status-text">{{ statusTranslationKey | i18next }}</span>
              <span>
                <i class="fas fa-info-circle red" [pTooltip]="statusDescriptionTranslationKey | i18next"></i>
              </span>
            </div>
          </div>
    </div>
  </div>
  <div class="m-0 pt-1 status-bg" [class]="cssClass" ></div>
</div>

