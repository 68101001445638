<ng-container *ngIf="initialized">
    <app-select-customer
            *ngIf="step === 'customer'"
            [emitAfterCreated]="false"
            [reloadAfterCreated]="true"
            [historyBack]="false"
            (back)="onCustomerBack()"
            (selected)="onCustomerSelected($event)"></app-select-customer>
    <app-select-invoice-contact
            *ngIf="step === 'contact' && model.customer"
            [customer]="model.customer"
            (selected)="onContactSelected($event)"
            (back)="onContactBack()"></app-select-invoice-contact>
    <app-order-trial-phonenumber
            *ngIf="step === 'trial-phonenumber'"
            (selected)="onTrialPhonenumberSelected($event)"
            (backEmitter)="onTrialPhonenumberBack()"
            [historyBack]="false"></app-order-trial-phonenumber>
    <app-select-instance-name
            *ngIf="step === 'instance'"
            (selected)="onInstanceNameSelected($event)"
            (backEmitter)="onSelectInstanceNameBack()"
            [isTrial]="true"
            selectedDomain=""></app-select-instance-name>
    <app-phonenumber
            *ngIf="step === 'phonenumber'"
            (selected)="onPhonenumberSelected($event)"></app-phonenumber>
    <app-cloud-thankyou
            *ngIf="step === 'thank-you'"
            [orderName]="model.saleOrder?.name"></app-cloud-thankyou>
</ng-container>