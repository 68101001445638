<app-content-header backLabel="app_back" [stepsBack]="1" [headline]="'phonenumber_select_port_date_headline' | i18next"></app-content-header>

<div class="row d-flex justify-content-center mt-2 mx-auto">
    <div class="col-sm-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
        <app-icon-card headline="phonenumber_select_port_date_headline" icon="fas fa-calendar">
            <div class="d-flex justify-content-center" [innerHtml]="'phonenumber_select_port_date_text' | i18next"></div>
            <div class="mt-5 p-fluid">
            <p-calendar [minDate]="minDate" [disabledDays]="[0,6]" dataType="string" disableWeekends="true" dateFormat="yy-mm-dd" firstDayOfWeek="1" [(ngModel)]="selectedDate" name="calendar" [defaultDate]="minDate" readonlyInput="true" inline="true"></p-calendar>
            </div>
            <div class="row d-flex justify-content-center mt-5">
                <button class="btn btn-primary"
                  (click)="onNext()"
                  [disabled]="!selectedDate"
                  [innerHTML]="'app_next_with_date' | i18next: {date: selectedDate | localized_date}">
                </button>
            </div>
        </app-icon-card>
    </div>
</div>
