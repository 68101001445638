import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { PCFormsService, PCFormBuilder } from '@myshared/pcform.service';
import { DynamicFormControlModel, DynamicFormLayout } from '@ng-dynamic-forms/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { companyForm } from '../../company.form';
import { I18NextService } from 'angular-i18next';
import { AutorunComponent } from '@myshared/autorun.component';
import { CountryService } from '../../../country/country.service';
import { Model } from '../../../app.model';
import { CompanyService } from '../../company.service';
import { Company, SignupCompany } from '../../company.model';
import { CookieHelper } from "@myshared/cookieHelper";
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html'
})
export class CompanyCreateComponent extends AutorunComponent implements OnInit {

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  @Output() create = new EventEmitter<void>();

  private builder: PCFormBuilder;

  constructor(private m: Model,
    private pcFormService: PCFormsService,
    private countryService: CountryService,
    private companyService: CompanyService,
    private messageService: MessageService,
    private i18next: I18NextService) {
    super();
  }

  async ngOnInit() {
    this.countryService.traceCountry().subscribe(code => {
      this.builder = companyForm(this.pcFormService, this.m.country.asDropdown, false, false, false, code);
      this.formModel = this.builder.model;
      this.formLayout = this.builder.layout;
      this.formGroup = this.builder.createFormGroup();
      this.builder?.autoFocus();
    })

    this.countryService.refresh();
    this.companyService.refresh();
  }

  onCreate() {
    const c = this.companyDataFromForm as SignupCompany;
    c.email = this.m.account.currentUser.email;
    c.vat = '';
    c.campaign_id = CookieHelper.get('p_cid') ?? '';
    this.companyService.update(c).subscribe(r => {
      if (r) {
        this.messageService.add({
          severity: 'success',
          summary: this.i18next.t('company_update_success_summary') as string,
          detail: this.i18next.t('company_update_success_detail') as string
        });
        this.create.emit();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.i18next.t('company_update_error_summary') as string,
          detail: this.i18next.t('company_update_error_detail') as string
        });
      }
    });
  }

  get companyDataFromForm(): Company {
    const data = this.formGroup.value['company'];
    const c = new Company();
    c.name = trimInput(data['row1'].name);
    c.street = trimInput(data['row1'].street);
    c.street2 = trimInput(data['row2'].street2);
    c.zip = trimInput(data['row2'].zip);
    c.city = trimInput(data['row3'].city);
    c.country = this.m.country.countries.find(cory => cory.code === data['row3'].country);
    c.email = trimInput(data['row4'].invoiceEmail);
    c.vat = trimInput(data['row4'].vat);
    return c;
  }
}
