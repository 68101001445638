import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import { HttpEventType} from '@angular/common/http';
import {I18NextService} from 'angular-i18next';
import { AttachedFile, AttachmentRequest } from '../support.model';
import { SupportService } from '../support.service';
import { concat, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import {FormatByteSizePipe} from '@myshared/format-download-size.pipe';

@Component({
  selector: 'app-upload-attachments-form',
  templateUrl: './upload-attachments-form.component.html',
  providers: [FormatByteSizePipe]
})
export class UploadAttachmentsFormComponent implements OnInit, OnDestroy {
  @Output('onFilesChanged')
  onFilesChanged = new EventEmitter<AttachedFile[]>(); // emit when files uploaded or deleted
  @Output('onUpload')
  onUpload = new EventEmitter<boolean>(); // emits when uploading (true)
  @Input()
  maxFileSize = 10485760;
  @Input()
  maxFileLength = 3;
  @Input()
  applianceId: string;

  attachedFiles: AttachedFile[] = [];
  isUploading: boolean;
  attachmentsRequest: AttachmentRequest[] = [];

  private lastAttachedFilesLength = 0;

  constructor(private i18next: I18NextService,
              private supportService: SupportService,
              private messageService: MessageService,
              private formatByteSize: FormatByteSizePipe) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.attachedFiles = [];
  }

  get isUploadDisabled() {
    return this.attachedFiles.length === this.maxFileLength || this.isUploading;
  }

  onSelect(event) {
    const maxFileSizeFormat = this.formatByteSize.transform(this.maxFileSize);
    this.lastAttachedFilesLength = this.attachedFiles.length; // remind the last attached files length
    if (this.attachedFiles.length === this.maxFileLength) {
      this.messageService.add({severity: 'error',
        summary: this.i18next.t('support_attachment_error_tomanyfiles_summary',
          { maxSize: maxFileSizeFormat, maxLength: this.maxFileLength }) as string,
        detail: this.i18next.t('support_attachment_error_tomanyfiles_detail',
          { maxSize: maxFileSizeFormat, maxLength: this.maxFileLength }) as string
      });
      return;
    }

    let eventFileIndex = 0;
    for (let i = this.attachedFiles.length; i < this.maxFileLength; i++) {
      if (event.files[eventFileIndex]) {
        if (event.files[eventFileIndex].size > this.maxFileSize) {
          this.messageService.add({severity: 'error',
            summary: this.i18next.t('support_attachment_error_tobigfile_summary',
              {filename: event.files[eventFileIndex].name }) as string,
            detail: this.i18next.t('support_attachment_error_tobigfile_detail',
              {filename: event.files[eventFileIndex].name, maxSize: maxFileSizeFormat }) as string
          });
          eventFileIndex++;
          return;
        }
        this.attachedFiles.push({
          event: null,
          file: event.files[eventFileIndex],
          fileId: null,
          isDone: false
        });
        eventFileIndex++;
      }
    }

    if (event.files.length > 3) {
      this.messageService.add({severity: 'warn',
        summary: this.i18next.t('support_attachment_warn_tomanyfilesselected_summary',
          { maxLength: this.maxFileLength }) as string,
        detail: this.i18next.t('support_attachment_warn_tomanyfilesselected_detail',
          { maxSize: maxFileSizeFormat, maxLength: this.maxFileLength }) as string
      });
    }
  }

  emitAndSetIsUploading(isUploading: boolean) {
    this.isUploading = isUploading;
    this.onUpload.emit(this.isUploading);
  }

  uploadFiles(event) {
    if (this.attachedFiles.length > 0) {
      const uploadQueue$: Observable<any>[] = [];
      for (const f of this.attachedFiles) {
        if (!f.isDone) {
          uploadQueue$.push(this.supportService.upload(f.file, this.applianceId));
        }
      }

      let lastId = '';
      let lastIndex: number = this.lastAttachedFilesLength;
      this.emitAndSetIsUploading(true);

      // Subscription sequentially
      concat(...uploadQueue$).subscribe((r) => {
        const attachmentFile: AttachedFile = r[0];
        attachmentFile.event = r[1] ? r[1] : null;

        if (attachmentFile.fileId !== lastId) {
          // Get a new file for upload
          lastId = attachmentFile.fileId;
          this.attachedFiles[lastIndex].fileId = attachmentFile.fileId;
          this.attachedFiles[lastIndex].event = attachmentFile.event;
        } else {
          // write the event for the same file
          this.attachedFiles[lastIndex].event = attachmentFile.event;
        }
        // We done, write the next file (if exists)
        if (attachmentFile.event.type === HttpEventType.Response) {
          this.attachedFiles[lastIndex].isDone = true;
          if ((lastIndex + 1) < this.attachedFiles.length) {
            lastIndex++;
          }
        }
      },
      (error) => {
        this.emitAndSetIsUploading(false);
      },
      () => { // observables done
        this.emitAndSetIsUploading(false);
        this.onFilesChanged.emit(this.attachedFiles);
      });
    }
  }

  removeFile(index) {
    this.attachedFiles.splice(index, 1); // file list
    this.onFilesChanged.emit(this.attachedFiles);
  }

  getFileProgress(index) {
    const fileProgress = this.attachedFiles[index];
    if (fileProgress && fileProgress.event) {
      if (fileProgress.event.type === HttpEventType.UploadProgress) {
        return Math.round((fileProgress.event['loaded'] * 100) / fileProgress.event['total']);
      }
    }
  }

  isUploadDone(index) {
    return this.attachedFiles[index].isDone;
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }
}
