<app-agreement-card *ngIf="agreement" [agreement]="agreement">
  <div content>
    <div>{{'agreement_keep_instance' | i18next: {applianceName: agreement.data.applianceName, applianceId: agreement.data.applianceId, idleDays: agreement.data.idleDays} }}</div>
  </div>
  <div bottom-buttons class="d-flex flex-row justify-content-end align-items-baseline mt-3">
    <button class="btn btn-outline-primary ml-3" type="button" (click)="keepInstance(true)">{{'button_agreement_keep_instance' | i18next}} </button>
    <button class="btn btn-outline-primary ml-3" type="button" (click)="keepInstance(false)">{{'button_agreement_delete_instance' | i18next}} </button>
  </div>
</app-agreement-card>

