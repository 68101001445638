import {BankAccount} from '../company/company.model';
import {PaymentMethodCode} from '../buy/buy.model';

export class PortNumberOffer {
  appliance_id: number;
  number_blocks: PortNumberBlockDetails[];
  payment_details: PortNumberBankAccount;
  phonebill_file: PortNumberUploadForm;
  porting_form_file: PortNumberUploadForm;
  bill_company_name: string;
  delivery_date: string;
  partner_id?: number;
}

export class PortNumberBlockDetails {
  country_prefix: string;
  area_code: string;
  main_number: string;
  block_start: string;
  block_end: string;
}

export class PortNumberBlockDetailsWithPreview extends PortNumberBlockDetails {
  previewNumber: string;
}

export class PortNumberUploadForm {
  file_name: string;
  file_body_b64: string;
}

export class PortNumberBankAccount extends BankAccount {}

export class PortNumbersPortingFormDetails {
  provider: string;
  company_name: string;
  numbers: PortNumberBlockDetails[];
  company_id: number;
  cancel_connection: boolean;
}
