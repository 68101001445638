<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div>
        <img src="../../assets/images/flamingo.svg" alt="pascom welcome picture">
      </div>
      <h1>{{ 'welcome_headline' | i18next: {name: name} }}</h1>
      <p class="gray">{{ 'welcome_text' | i18next }}</p>
      <p class="mt-5">
        <button class="btn btn-outline-primary" (click)="goToWorkflow()">{{
          'welcome_continue_button' | i18next }}
        </button>
      </p>
    </div>
  </div>
</div>
