<div class="row mb-5">
  <div class="col">
    <button class="btn btn-outline-primary mt-2 mr-3" (click)="goBack()"><i class="fas fa-angle-left mr-2"></i>{{'app_back' |  i18next }}</button>
  </div>
</div>
<div *ngIf="!showPascomOneAlreadyMigratedMessage">
  <app-subscription-cards [subscription]="subscription" *ngIf="subscription"></app-subscription-cards>
  <div class="row" *ngIf="!subscription">
    <div class="col">
      <span>{{'no_paired_license'|i18next}}</span>
    </div>
    <div class="col">
      <app-searchbox (filterChanged)="filterLicenses($event)" [placeholder]="'appliance_search_placeholder' | i18next"></app-searchbox>
    </div>
  </div>
  <div class="row align-items-stretch mt-5" *ngIf="!subscription">
    <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let s of subscriptions" [subscription]="s">
      <app-pairing-button [subscription]="s"></app-pairing-button>
    </app-subscription-card>
  </div>
</div>
<app-alert-card *ngIf="showPascomOneAlreadyMigratedMessage"
  [pageHeadline]="'cloud_migration_already_pcone_page_headline' | i18next"
  [cardHeadline]="'cloud_migration_already_pcone_card_headline' | i18next"
  [alertText]="'subscription_details_pcone_from_csui_text' | i18next"
  [showBackToMypascomPage]="true"
></app-alert-card>
