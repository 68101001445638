import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Model} from '../../app.model';

@Component({
  selector: 'app-buy-thankyou-content',
  templateUrl: './buy-thankyou-content.component.html',
})
export class BuyThankyouContentComponent implements OnInit {

  @Input() headline: string;
  @Input() content: string;
  @Input() documentation: string = null;
  @Input() showBackrouteButton = true;
  @Input() backrouteButton: string;
  @Output() public openDocumentationEvent = new EventEmitter<boolean>();
  @Output() public openBackrouteEvent = new EventEmitter<boolean>();

  constructor(private m: Model
  ) {}

  ngOnInit() {
  }

  onOpenDocumentation() {
    this.openDocumentationEvent.emit(true);
  }

  onOpenBackroute() {
    this.openBackrouteEvent.emit(true);
  }


}
