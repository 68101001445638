import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../partner.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
})
export class ProfileCardComponent implements OnInit {

  @Input() profile: Profile;

  get logo(): string {
    if (this.profile.logo) {
      return 'data:image/png;base64,' + this.profile.logo;
    }
    return '';
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  editProfile() {
    this.router.navigate(['/partner', 'profiles', this.profile.id]);
  }

}
