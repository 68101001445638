import {Routes} from "@angular/router";
import {WorkflowPartnerOneDowngradeSlaComponent} from "./workflow-partner-one-downgrade-sla.component";

export const workflowPartnerOneDowngradeSlaRouting: Routes = [
    { path: 'partner/downgrade-sla/:aid', component: WorkflowPartnerOneDowngradeSlaComponent, children: [
        { path: 'info', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'info' } },
        { path: 'contact', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'contact' } },
        { path: 'address', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'address' } },
        { path: 'payment', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'payment' } },
        { path: 'checkout', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'checkout' } },
        { path: 'complete', component: WorkflowPartnerOneDowngradeSlaComponent, data: { step: 'complete' } },
        { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
