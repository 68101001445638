import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PortNumbersPortingFormDetails} from '../port-phonenumber.model';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-download-port-form',
  templateUrl: './download-port-form.component.html',
  styles: [
  ]
})
export class DownloadPortFormComponent implements OnInit {

  @Output() selected: EventEmitter<void> = new EventEmitter<void>();
  @Input() portingFormDetails: PortNumbersPortingFormDetails;

  public isNextDisabled = true;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  public onNext() {
    this.selected.next();
  }

  public createLinkForAttachment(): void {
    // For phone number block we will have an array only with one block numbers inside.
    // In this case, we need to read from this.portingFormDetails.numbers[0]
    // FYI: Single numbers can be added up to 10 times
    this.portingFormDetails.numbers[0].block_start = this.portingFormDetails?.numbers[0]?.block_start ?? '';
    this.portingFormDetails.numbers[0].block_end = this.portingFormDetails?.numbers[0]?.block_end ?? '';

    this.http.post('/services/trunk/portform/', this.portingFormDetails,
      {responseType: 'blob'}).pipe(
      map((res: any) => {
        // rewrite binary blob into a normal pdf for download
        return new Blob([res], {type: 'application/pdf'});
      })).subscribe(r => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(r);
        a.href = objectUrl;
        a.download = 'pascom-porting-form.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.isNextDisabled = false;
    });
  }
}
