<app-content-header (backEvent)="onBack()" [historyBack]="historyBack" [backLabel]="backLabel" [headline]="'invoice_headline' | i18next"></app-content-header>

<div class="card card-nb">
  <div class="card-body">
    <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
    <div class="d-flex justify-content-end mt-2 form-container-footer">
      <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="!formGroup.valid">{{ 'app_next' | i18next}}</button>
    </div>
  </div>
</div>
