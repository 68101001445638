import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-account-failed',
  templateUrl: './verify-account-failed.component.html',
})
export class VerifyAccountFailedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  tryAgain() {
    window.location.reload();
  }

}
