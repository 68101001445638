import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PCFormsService} from "@myshared/pcform.service";
import {WorkflowComponent} from "../workflow.component";
import {firstValueFrom} from "rxjs";
import {SubscriptionService} from "../../subscription/subscription.service";
import {Subscription} from "../../subscription/subscription.model";
import {PhoneNumber} from "../../phonenumber/phoneNumber";
import {I18NextService} from "angular-i18next";

const FALLBACK_NUMBER_MAX_LENGTH = 12; // Max length on Server is 16, but we need to remove 4 numbers because of 0049

@Component({
  selector: 'app-workflow-fallback-number',
  templateUrl: './workflow-fallback-number.component.html'
})
export class WorkflowFallbackNumberComponent extends WorkflowComponent implements OnInit {

  public applianceId: string;
  public fallbackNumber: string = '';
  public subscription: Subscription;
  public validationError = '';
  public inputDirty = false;

  private trunkPrefix: string;

  constructor(protected route: ActivatedRoute, private subscriptionService: SubscriptionService, private i18next: I18NextService) {
    super(route);
  }

  async ngOnInit() {
    super.ngOnInit();

    try {
      const subscriptionJson = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
      this.subscription = Subscription.fromJson(subscriptionJson);
    } catch (e) {
      this.resetWorkflow();
      return;
    }


    if (!this.subscription.isOne || !this.subscription.hasActivePhoneNumber) {
      this.resetWorkflow();
      return;
    }

    this.trunkPrefix = this.subscription.trunkEnvironment.trunkCountryPrefix;
    if (this.subscription.fallbackNumber) {
      this.fallbackNumber = this.getParsedNumber(this.subscription.fallbackNumber);
      if (this.fallbackNumber) {
        this.checkValidation(this.fallbackNumber);
      }
    }
  }

  public onCancel() {
    this.resetWorkflow();
  }
  public onChangeFallbackNumber() {
    this.checkValidation(this.fallbackNumber);
    if (!this.fallbackNumber && this.validationError !== '') return;

    const parsedNumber = this.getParsedNumber(this.fallbackNumber);
    const realFallbackNumber = this.trunkPrefix + parsedNumber;

    this.subscriptionService.setFallbackNumber(this.applianceId, realFallbackNumber)
      .subscribe(() => {
        this.resetWorkflow();
    });
  }

  public onFallbackNumberChange(input: string) {
    this.inputDirty = true;
    this.checkValidation(input);
  }

  public get numberCountryPrefixPreview() {
    return `+${+this.trunkPrefix}`
  }

  private getParsedNumber(fallbackNumber: string) {
    if (!fallbackNumber) return;
    return PhoneNumber.parse(fallbackNumber, this.subscription.trunkEnvironment.tenantCountryCode).nationalNumber
  }

  private checkValidation(fallbackNumber: string) {
    this.validationError = '';
    if (fallbackNumber === '') {
      this.validationError = this.i18next.t('fallback_number_number_error_required').toString();
      return;
    }

    const libPhoneNumberCheck = PhoneNumber.isValid(fallbackNumber);
    if (!libPhoneNumberCheck) {
      this.validationError = this.i18next.t('fallback_number_number_error_phoneNumber').toString();
      return;
    }

    const parsedPhoneNumber = this.getParsedNumber(fallbackNumber)
    if (parsedPhoneNumber && parsedPhoneNumber.length > FALLBACK_NUMBER_MAX_LENGTH) {
      this.validationError = this.i18next.t('fallback_number_number_error_max_length',
          {size: FALLBACK_NUMBER_MAX_LENGTH}).toString();
      return;
    }


  }

}
