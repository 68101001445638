<div class="attachments">
    <div class="d-flex">
        <div class="align-self-start mr-3 p-2">
            <i class="fas fa-paperclip icon-size-bigger attachment-icon"></i>
        </div>
        <div class="d-flex flex-column attachment-list">
            <div class="attached-files">
                <strong>{{ "support_article_attached_files" | i18next: {attachedFiles: attachments.length} }}</strong>
            </div>
            <a *ngFor="let a of attachments" class="btn-link file-link" 
                [href]="downloadLink+'/'+ticketId+'/'+articleId+'/'+a.id"
                target="_blank">{{ a.filename }}</a>
        </div>
    </div>

</div>