<app-agreement-card [showAttachmentsList]="!isAffiliateContract" *ngIf="agreement" [agreement]="agreement">
  <div top-buttons class="d-flex flex-row justify-content-end align-items-baseline mt-3 mb-5 w-100">
    <app-pascom-checkbox *ngIf="agreement.needsCheck" [inputId]="'b1'" [text]="isAffiliateContract ? ('agreement_accept_contract' | i18next) : ('agreement_accept_ack' | i18next)" [accepted]="accepted" (acceptChanged)="accept($event)"></app-pascom-checkbox>
    <button class="btn btn-outline-primary ml-3" type="button" (click)="continue()" [disabled]="agreement.needsCheck && !accepted">{{'app_continue' | i18next}} </button>
  </div>
  <div bottom-buttons class="d-flex flex-row justify-content-end align-items-baseline mt-5">
    <app-pascom-checkbox *ngIf="agreement.needsCheck" [inputId]="'b2'" [text]="isAffiliateContract ? ('agreement_accept_contract' | i18next) : ('agreement_accept_ack' | i18next)" [accepted]="accepted" (acceptChanged)="accept($event)"></app-pascom-checkbox>
    <button class="btn btn-outline-primary ml-3" type="button" (click)="continue()" [disabled]="agreement.needsCheck && !accepted">{{'app_continue' | i18next}} </button>
  </div>
</app-agreement-card>
