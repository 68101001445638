<app-content-header
  *ngIf="pageHeadline"
  [hideBackButton]="true" [headline]="pageHeadline"></app-content-header>

<div class="row d-flex justify-content-center">
  <div class="col-12 col-md-6">
    <app-icon-card [stretch]="false">
      <h3 class="h3 red mb-5 mt-3" *ngIf="cardHeadline" [innerHtml]="cardHeadline"></h3>
      <div class="d-flex align-items-start mb-5" *ngIf="alertText">
        <i class="fas fa-exclamation-triangle red mt-1 mr-1"></i>
        <span class="subinformations" [innerHtml]="alertText"></span>
      </div>
      <ng-content></ng-content>
      <div class="d-flex justify-content-center mb-3 mt-3">
        <button name="continue" class="btn btn-outline-primary"
                (click)="goToMypascom()">{{ 'thank_you_cloud_back' | i18next }}</button>
      </div>
    </app-icon-card>
  </div>
</div>
