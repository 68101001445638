<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'appliance_subscription_headline' | i18next }}</div>
  <div class="d-none d-lg-block ml-3 align-self-stretch">
    <app-searchbox (filterChanged)="filterLicenses($event)" [placeholder]="'appliance_search_placeholder' | i18next"></app-searchbox>
  </div>
  <button *ngIf="isAdmin" class="ml-auto btn btn-outline-primary" (click)="onAddSubscription()">{{'subscription_new_button' | i18next }}</button>
  <button *ngIf="isCsp" class="ml-1 btn btn-outline-primary" (click)="onAddCsp()">{{'partner_add_csphost_btn' | i18next }}</button>

</div>
<div class="d-flex d-lg-none d-block align-self-stretch mb-3">
  <app-searchbox (filterChanged)="filterLicenses($event)" [placeholder]="'appliance_search_placeholder' | i18next"></app-searchbox>
</div>
<p>
  <i class="fas fa-info-circle red"></i><span class="ml-2">{{ 'select_appliance_license_hint' | i18next }}</span>
</p>
<div class="mb-3 mt-3 ml-3">
  <app-pascom-checkbox [text]="'appliance_show_paired' | i18next" (acceptChanged)="showPaired($event)"></app-pascom-checkbox>
</div>
<div class="row align-items-stretch">
    <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let lic of subscriptions" [subscription]="lic">
      <app-pairing-button [subscription]="lic"></app-pairing-button>
    </app-subscription-card>
</div>
