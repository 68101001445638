<app-content-header backLabel="app_cancel"
                    [historyBack]="false"
                    (backEvent)="back()"
                    [headline]="'port_old_provider_headline' | i18next"></app-content-header>

<div class="row d-flex justify-content-center mt-2">
  <div class="col-sm-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
    <app-icon-card headline="port_old_provider_number_headline" icon="fas fa-phone">
      <div [innerHtml]="'port_old_provider_number_text' | i18next"></div>
      <div class="mt-5 p-fluid">
        <p-autoComplete [(ngModel)]="selectedProvider"
                        [suggestions]="filteredProviders"
                        (completeMethod)="searchProvider($event)"
                        [minLength]="2"
                        [placeholder]="'port_number_provider_placeholder' | i18next"
                        styleClass="w-100"></p-autoComplete>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <button class="btn btn-primary" (click)="next()" [disabled]="!selectedProvider">
          {{ 'app_next' | i18next}}
        </button>
      </div>
    </app-icon-card>
  </div>
</div>
