<div class="row">
  <div class="col">
    <div class="card card-nb">
      <div class="card-body">
        <div class="row align-items-stretch mb-3">
          <div class="col-sm-12 col-md-6">
            <dynamic-primeng-form [group]="formGroup" [layout]="formLayout"
                                       [model]="formModel">
            </dynamic-primeng-form>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="d-flex flex-column h-100">
              <div>
                <label>{{ "profile_logo" | i18next }}</label>
              </div>
              <div class="mb-3 mb-md-auto"><img class="img-fluid img-profile-form" *ngIf="logo" src="{{logo}}" /></div>
              <div class="mb-3 mb-md-auto text-align-center" *ngIf="!logo">
                {{ "profile_no_profile_image" | i18next }}
              </div>
              <div class="mb-3">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="validatedCustomFile" (change)="getFile($event)">
                  <label class="custom-file-label" for="validatedCustomFile" *ngIf="fileName">{{ fileName }}</label>
                  <label class="custom-file-label" for="validatedCustomFile" *ngIf="!fileName">{{ "choose_profile_image_file" | i18next }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <button class="btn btn-outline-primary" [disabled]="!formGroup.valid" (click)="onSave()">
            {{'app_save' | i18next}}
          </button>
          <button class="btn btn-link ml-1" (click)="onDelete()">
            {{'app_delete' | i18next}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
