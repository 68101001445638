import {
  WorkflowPartnerOneUpgradeCuComponent
} from "./workflow-partner-one-upgrade-cu/workflow-partner-one-upgrade-cu.component";
import {
  WorkflowPartnerOneUpgradeSlaComponent
} from "./workflow-partner-one-upgrade-sla/workflow-partner-one-upgrade-sla.component";
import {
  WorkflowPartnerOneDowngradeCuComponent
} from "./workflow-partner-one-downgrade-cu/workflow-partner-one-downgrade-cu.component";
import {
  WorkflowPartnerOneDowngradeSlaComponent
} from "./workflow-partner-one-downgrade-sla/workflow-partner-one-downgrade-sla.component";
import {WorkflowPartnerService} from "./workflow-partner.service";
import {
  WorkflowPartnerOneTrialCreateComponent
} from "./workflow-partner-one-trial-create/workflow-partner-one-trial-create.component";

export const workflowPartnerComponents= [
  WorkflowPartnerOneUpgradeCuComponent,
  WorkflowPartnerOneUpgradeSlaComponent,
  WorkflowPartnerOneDowngradeCuComponent,
  WorkflowPartnerOneDowngradeSlaComponent,
  WorkflowPartnerOneTrialCreateComponent,
]

export const workflowPartnerServices = [
  WorkflowPartnerService,
]
