import {Routes} from "@angular/router";
import {WorkflowEndcustomerOneDowngradeCuComponent} from "./workflow-endcustomer-one-downgrade-cu.component";

export const workflowEndcustomerOneDowngradeCuRouting: Routes = [
    { path: 'downgrade-cu/:aid', component: WorkflowEndcustomerOneDowngradeCuComponent, children: [
            { path: 'info', component: WorkflowEndcustomerOneDowngradeCuComponent, data: { step: 'info' } },
            { path: 'address', component: WorkflowEndcustomerOneDowngradeCuComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowEndcustomerOneDowngradeCuComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowEndcustomerOneDowngradeCuComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowEndcustomerOneDowngradeCuComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
