import {Injectable} from '@angular/core';
import {Model} from '../app.model';
import {HttpClient} from '@angular/common/http';
import {runInAction} from 'mobx';
import {map, tap} from 'rxjs/operators';
import {Customer} from './customer.model';
import {I18NextService} from 'angular-i18next';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable()
export class CustomerService {

  private needsRefresh = true;

  constructor(private m: Model,
              private httpClient: HttpClient,
              private i18n: I18NextService) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      return this.getCustomers().subscribe((r) => runInAction(() => {
          this.m.customer.customers = r;
          this.needsRefresh = false;
        }));
    }
  }
  public getCustomers() {
    return this.httpClient.get<any>('/services/customers', {
      params: {
        lang: this.i18n.t('language_code') as string,
      }
    }).pipe(map((r) => r.map((item) => Customer.fromJson(item))));
  }

  public update(c: Customer): Observable<Customer> {
    return this.httpClient.post<any>('/services/customers/' + c.id, c).pipe(
      map(r => Customer.fromJson(r)),
      // FIXME: Probably just replace *one* value?
      tap((r) => {
        runInAction(() => {
          const idx = this.m.customer.customers.findIndex(x => x.id == r.id);
          if (idx !== -1) {
            this.m.customer.customers.splice(idx, 1, r);
          }
        });
        return r;
      })
    );
  }

  public create(c: Customer): Observable<Customer> {
    return this.httpClient.post<any>('/services/add_customer', c)
      .pipe(
        map(r => Customer.fromJson(r)),
        tap((r) => {
          // FIXME: Replace the one value that was updated. For now this is useless, the list route reloads everything anyways
          // The list route reloads everything anyways
          // runInAction(() => {
          //   this.m.customer.customers.push(c);
          //   this.m.customer.customers = this.m.customer.customers.slice().sort((a, b) => a.name.localeCompare(b.name));
          // });
          return r;
        })
      );
  }
}
