<app-content-header [stepsBack]="stepsBack"
                    (backEvent)="back()"
                    [historyBack]="historyBack"
                    [headline]="'order_trial_phonenumber_headline' | i18next"></app-content-header>
<div class="card card-nb">
  <div class="card-body">
    <div class="h3 red">{{ 'order_trial_phonenumber_box_headline' | i18next }}</div>
    <p class="mt-2 mb-4" [innerHTML]="phonenumberDescription | i18next"></p>
    <div class="d-flex justify-content-end mt-2">
      <button name="continue" class="btn btn-link" [disabled]="nextButtonDisabled" (click)="next(false)">{{ 'order_trial_phonenumber_skip_btn' | i18next }}</button>
      <button name="continue" class="btn btn-primary" [disabled]="nextButtonDisabled" (click)="next(true)">{{ 'order_trial_phonenumber_continue_btn' | i18next }}</button>
    </div>
  </div>
</div>
