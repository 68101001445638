<app-content-header [stepsBack]="1" [headline]="'phonenumber_select_blocksize' | i18next"></app-content-header>


<div class="d-flex justify-content-center">
  <div class="price-card w-sm-100 w-md-50 product card card-nb one-upgrade normal">
    <div class="d-flex flex-column">
      <div class="price-card-header">
        <div class="d-flex align-items-center flex-column">
          <div class="subscription-type h3">{{ "phonenumber_select_blocksize" | i18next }}</div>
        </div>
      </div>
      <div class="price-card-body mt-4">
        <div class="container">
          <div class="d-flex justify-content-center concurrent-user">
            <div class="form-group-container">
              <p-dropdown [options]="blockSize" [(ngModel)]="selectedBlockSize" optionLabel="name" optionValue="name"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="container mt-5 mb-5">
          <div class="d-flex justify-content-center text-justify">
            <div class="d-flex w-50">
              <div class="mr-2"><i class="far fa-question-circle red"></i></div>
              <div>{{ "phonenumber_blocksize_hint" | i18next: {count: selectablePhonenumbers, blockSize: selectedBlockSize} }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="price-card-footer">
        <button class="btn btn-primary" style="width: 250px;"
                (click)="onBlockSizeSelected()">{{ 'app_select' | i18next }}</button>
      </div>
    </div>
  </div>
</div>
