<div *ngIf="ticket" class="card card-nb h-100 ticket-card hover-effect">
  <div class="position-absolute" style="top: 0.74rem; right: 1rem; z-index: 100;">
    <div class="custom-dropdown-menu" ngbDropdown display="dynamic" placement="bottom-right">
      <button class="btn bg-transparent p-2 m-0 hide-dropdown-icon" [id]="ticket.number+'dropdown'" ngbDropdownToggle>
        <i class="fas fa-ellipsis-v red icon-size-bigger navbar-profile-icon"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="{{ticket.number+'dropdown'}}" class="position-absolute">
        <div [id]="ticket.number+'ticket-subscription'" [routerLink]="['/subscriptions', ticket.applianceId]" class="cursor-pointer dropdown-item">
            <span class="d-flex">
              <span>{{'support_ticket_view_subscription' | i18next}}</span>
            </span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pb-0" [routerLink]="['/support/ticket', ticket.id]">
    <div class="row">
        <div class="subinformations">
            <span>{{ "support_ticket_number" | i18next}} {{ticket.number }}</span>
        </div>
      <div class="col-12 highlighted p-0 m-0">
        <strong>{{ ticket.title }}</strong>
      </div>
    </div>
    <div class="subinformations mt-3">
      <div class="row">
        <div class="col-12 p-0 m-0 d-flex">
            <span>{{ "support_ticket_subscription" | i18next }} <span>
                  <span>[{{ticket.applianceId}}]</span><span class="ml-1">{{applianceName}}</span>
                </span>
            </span>
        </div>
        <div class="col-12 p-0 m-0">
            <span>{{ "support_ticket_created" | i18next }} {{ ticket.createdAt | localized_datetime }}</span>
        </div>
        <div class="col-12 p-0 m-0" *ngIf="!!ticket.closedAt">
            <span>{{ "support_ticket_closed" | i18next }} {{ ticket.closedAt | localized_datetime }}</span>
        </div>
      </div>
      <div class="d-flex align-items-end flex-column h-100">
        <div>
          <button name="detail" class="btn btn-outline-primary ml-auto" [routerLink]="['/support/ticket', ticket.id]">{{'app_details' | i18next}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row subinformations pb-1 mr-2">
    <div class="d-flex ml-auto align-items-end flex-column mt-auto">
      <div class="mt-auto" style="min-height: 22px;">
        <span class="mr-2 text-task-state" [ngClass]="ticketStateClass">
          {{ ticketSate | i18next }}
        </span>
      </div>
    </div>
  </div>
  <div class="m-0 pt-1 bg-task-state" [ngClass]="ticketStateClass"></div>
</div>
