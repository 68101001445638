import {Component, OnDestroy, OnInit} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {SubscriptionService} from "../../../subscription/subscription.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BuyService} from "../../../buy/buy.service";
import {Model} from "../../../app.model";
import {CustomerService} from "../../../customer/customer.service";
import {BankAccount, Company} from "../../../company/company.model";
import {CompanyService} from "../../../company/company.service";
import {WorkflowCspService} from "../workflow-csp.service";
import {WorkflowCspChangeCuComponent} from "../workflow-csp-change-cu.component";
import {runInAction} from "mobx";

@Component({
  selector: 'app-workflow-csp-one-upgrade-cu',
  templateUrl: './workflow-csp-one-upgrade-cu.component.html',
})
export class WorkflowCspOneUpgradeCuComponent extends WorkflowCspChangeCuComponent implements OnInit, OnDestroy {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected subscriptionService: SubscriptionService,
              protected buyService: BuyService,
              protected m: Model,
              protected customerService: CustomerService,
              protected workflowCspService: WorkflowCspService,
              protected companyService:CompanyService) {
    super(route, router, subscriptionService, buyService, m, customerService, workflowCspService, companyService);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.initWorkflow('upgrade-cu');

    if (!this.isSepa && this.step === 'payment') {
      this.navigate(['address']);
      return;
    }

    this.initialized = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected checkSelectedProduct(ifStatement: boolean) {
    return +this.model.subscription.users === this.model.cu
      || +this.model.subscription.users > this.model.cu;
  }

  public async onInvoiceAddress(c: Company) {
    const company = await firstValueFrom(this.companyService.update(c));

    runInAction(() => {
      this.model.company = company;
    });

    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    await this.createOffer();
    this.navigate(['checkout']);
  }

  public onInvoiceAddressBack() {
    this.router.navigate(['/do', 'change-cu', this.applianceId]);
  }

  public async onPaymentMethod({ bank }: { bank?: BankAccount }) {
    bank.partner_id = +this.model.company.id;
    await firstValueFrom(this.companyService.updateBankAccount(bank));
    await this.createOffer();

    await firstValueFrom(this.buyService.getOfferRecalculate(this.model.saleOrder.name));
    this.navigate(['checkout']);
  }

  public onPaymentMethodBack() {
    this.navigate(['address']);
  }

  public onBuy() {
    this.buyService.confirmUpgrade().subscribe(
      (saleOrder: any) => {
        this.navigate(['complete'])
      }
    );
  }

  public onSummaryBack() {
    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    this.onPaymentMethodBack();
  }

  public onComplete() {
    this.resetWorkflow();
  }

  public get isSepa() {
    return this.model?.saleOrder?.payment_method === 'sepa' || this.model?.subscription?.payment_method === 'sepa';
  }
}
