import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '../../../autorun.component';
import {MaintenanceService} from '../../maintenance.service';
import {Model} from '../../../../app.model';

@Component({
  selector: 'app-maintenance-scheduled',
  templateUrl: './maintenance-scheduled.component.html'
})
export class MaintenanceScheduledComponent extends AutorunComponent implements OnInit {
  public statusUrl: string;

  constructor(private maintenanceService: MaintenanceService, private m: Model) {
    super();
  }
  ngOnInit() {
    this.autorun(() => {
      this.statusUrl = this.m.maintenance.statusUrl;
    });
  }
  doRefresh() {
    this.maintenanceService.refreshApplication();
  }
  openStatusPage() {
    window.open(this.statusUrl, '_status');
  }
}
