<ng-container *ngIf="subscription">
  <app-content-header
    (backEvent)="back()"
    [headline]="'cancel_pascom_one_headline' | i18next:{
  applianceId: subscription.appliance_id, applianceName: subscription.name }">
  </app-content-header>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="card h-100 card-nb">
        <div class="card-body pb-0">
          <div class="">
            <h2 style="min-height:70px;">{{ 'cancel_pascom_one_card_headline' | i18next }}</h2>
          </div>
        </div>
        <div class="card-body pt-0 pb-0">
          <p style="min-height:130px;">{{ 'cancel_pascom_one_card_description' | i18next: {
            date: subscription.nextPossibleEndDate | localized_date,
            untilNextPossibleDate: subscription.nextPossibleCancellationDate | localized_date,
            applianceName: subscription.name,
            interpolation: { escapeValue: false } }
            }}</p>
          <div class="row justify-content-end">
            <button class="btn btn-outline-primary" name="cancel-subscription-endofsubscription" (click)="cancel()">
              {{ "cancel_pascom_one_button"  | i18next:{
              date: subscription.nextPossibleEndDate | localized_date,
              interpolation: { escapeValue: false } } }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col text-align-center mb-5">
    <button class="btn btn-link" name="cancel-subscription-abort" (click)="back()">
      {{ "cancel_pascom_one_abort_button"  | i18next }}
    </button>
  </div>
</ng-container>

