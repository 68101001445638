<app-content-header
  (backEvent)="back()"
  [headline]="'subscription_change_host_headline' | i18next:{ applianceId: subscription.appliance_id, applianceName: subscription.name }"></app-content-header>

<div class="col text-align-center mb-5">
  <div class="h3 red">{{ 'subscription_change_host_warning_header' | i18next }}</div>
</div>

<div class="row align-items-stretch">
  <div class="mb-3 col-md-6 offset-md-3">
    <div class="card h-100 card-nb">
      <div class="card-body pb-0">
        <div class="">
          <h2 style="min-height:70px;">{{ 'subscription_change_host' | i18next }}</h2>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <p style="min-height:130px;"><span class="wrap-text-translation">{{ 'subscription_change_host_warning_text' | i18next:{ applianceName: subscription.name } }}</span></p>
        <div class="row justify-content-end">
          <button class="btn btn-link" name="cancel-subscription-abort" (click)="back()">{{ "subscription_change_host_abort"  | i18next }}</button>
          <button class="btn btn-primary" name="change-host-continue" (click)="onContinue()">{{ "subscription_change_host_continue"  | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

