import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-one-cancel-period',
  templateUrl: './subscription-one-cancel-period.component.html'
})
export class SubscriptionOneCancelPeriodComponent implements OnInit {

  @Input() subscription: Subscription;
  @Output() selectCancelPeriodEvent  = new EventEmitter<boolean>();
  @Output() backEvent  = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  cancel() {
    this.selectCancelPeriodEvent.emit(false);
  }

  back() {
    this.backEvent.emit();
  }

}
