import {
  action as mobxAction,
  computed as mobxComputed,
  observable as mobxObservable
} from 'mobx';


export function action(...args) {
  return new (mobxAction as any)(...args);
}

export function computed(...args) {
  return new (mobxComputed as any)(...args);
}

export function observable(...args) {
  return new (mobxObservable as any)(...args);
}
