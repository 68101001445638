import {HttpClient} from '@angular/common/http';
import {runInAction} from 'mobx';
import {Model} from '../app.model';
import {CurrentPartnerTest, PartnerTest, PartnerTestResult} from './partner.model';
import {I18NextService} from 'angular-i18next';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AffiliateService} from './affiliate/affiliate.service';

@Injectable()
export class PartnerTestService {

  private needsRefresh = true;

  constructor(private m: Model,
              private http: HttpClient,
              private i18next: I18NextService,
              private affiliateService: AffiliateService
  ) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.http.get<any>('/services/partner/tests', {
        params: {
          lang: this.i18next.t('language_code') as string,
        }
      }).pipe(map(r => r.map(i => PartnerTest.fromJson(i)))).subscribe(r => {
        runInAction(() => this.m.partner.tests = r);
      });
    }
  }

  public fetchTest(id: string) {
    this.http.get<any>('/services/partner/test/' + id, {
      params: {
        lang: this.i18next.t('language_code') as string,
      }
    }).subscribe(r => {
      runInAction(() => this.m.partner.currentTest = CurrentPartnerTest.fromJson(r));
    });
  }

  public submitTest(test: CurrentPartnerTest): Observable<PartnerTestResult> {
    const testResult = {
      lang: this.i18next.t('language_code') as string,
      questions_answers: test.questions.map(q => {
        return {
          id: q.id,
          answers: q.answers.filter(a => a.checked).map(a => {
            return {
              id: a.id,
              value: a.checked
            };
          })
        };
      })
    };

    return this.http.post<any>('/services/partner/test/' + test.id, testResult).pipe(map(r => {
      runInAction(() => this.m.partner.currentTest = CurrentPartnerTest.fromJson({}));
      this.affiliateService.refresh(true);
      return PartnerTestResult.fromJson(r);
    }));
  }
}
