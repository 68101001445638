import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-itemized-billing-card',
  templateUrl: './itemized-billing-card.component.html',
  styles: [
  ]
})
export class ItemizedBillingCardComponent implements OnInit {

  @Input() headline: string;
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
