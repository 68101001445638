<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ headline | i18next }}</div>
  <div class="ml-auto bd-highlight">
    <button name="add-subscription" class="btn btn-outline-primary" *ngIf="(filterMy && !isPartner) && isAdmin" [routerLink]="['/subscriptions', 'select-product']" >{{ 'customer_subscription_new_button' | i18next }}</button>
    <button name="add-subscription" class="btn btn-outline-primary" *ngIf="(!filterMy && isPartner) && isAdmin" [routerLink]="['/subscriptions', 'select-product']" >{{ 'partner_customer_subscription_new_button' | i18next }}</button>
    <button name="add-subscription" class="btn btn-link" *ngIf="isSubscriptionsInitialized && isPartner && !isCspOne && this.filterMy && this.isAdmin" [routerLink]="['/partner', 'how-nfr-one-works']" ><i class="fas fa-info-circle red mr-2"></i>{{ 'nfr_one_prepare_headline' | i18next }}</button>
    <button name="add-subscription" class="btn btn-outline-primary" *ngIf="isSubscriptionsInitialized && isPartner && !isCspOne && this.filterMy && this.isAdmin" [routerLink]="['/do', 'one-trial']" >{{ 'partner_create_nfr_one_btn' | i18next }}</button>
  </div>
</div>

<div *ngIf="isPartner">
  <div class="d-inline d-md-none">
    <div ngbDropdown>
      <button class="btn bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
        <span>{{ headline | i18next }}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button class="dropdown-item" routerLink="/subscriptions/my">
          <span>{{ 'nav_subscriptions_my' | i18next }}</span>
        </button>
        <button class="dropdown-item" routerLink="/subscriptions/customer">
          <span>{{ 'nav_subscriptions_customer' | i18next }}</span>
        </button>
        <button class="dropdown-item" routerLink="/subscriptions/pascom-one-migration">
          <span>
            {{ 'pascom_one_migration_days_left_tab' | i18next: {count: countPossiblePascomOneMigrations} }}<i *ngIf="isPascomOneMigrationExpireWarning" class="fa fa-exclamation-triangle text-warning ml-2"></i>
          </span>
        </button>
      </div>
    </div>
  </div>

  <div class="row d-none d-md-inline">
    <div class="col">
      <ul class="nav link-navigation">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/subscriptions/my">{{'nav_subscriptions_my' | i18next}}</a>
        </li>
        <li class="nav-item ml-2" routerLinkActive="active">
          <a class="nav-link" routerLink="/subscriptions/customer">{{'nav_subscriptions_customer' | i18next}}</a>
        </li>
        <li class="nav-item ml-2" routerLinkActive="active" *ngIf="isPartner && countPossiblePascomOneMigrations > 0">
          <a class="nav-link" routerLink="/subscriptions/pascom-one-migration">
            {{ 'pascom_one_migration_days_left_tab' | i18next: {count: countPossiblePascomOneMigrations} }}<i *ngIf="isPascomOneMigrationExpireWarning" class="fa fa-exclamation-triangle text-warning ml-2"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<app-filter [filterType]="!isPascomOneMigration ? 'both' : ''"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'customer_subscription_search' | i18next"
            [multiFilterOptions]="dropdownFilterOptions"
            (multiFilterChanged)="onMultiFilterChanged($event)"
            multiFilterDisplay="chip"
            (checkboxFilterChanged)="showExpired($event)"
            [checkboxFilterText]="'filter_expired_subscriptions' | i18next"
></app-filter>

<app-no-content-message t="no_subscriptions" *ngIf="subscriptions.length === 0"></app-no-content-message>

<div class="row align-items-stretch mt-2">
  <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let lic of subscriptions" [subscription]="lic" [detaillist]="true">
    <button *ngIf="lic.isValid" [name]="lic.name + '-details'" class="btn btn-outline-primary" [routerLink]="['/subscriptions', lic.appliance_id]">{{'app_details' | i18next}}</button>
    <div *ngIf="!lic.isValid" upgrade>
        <button *ngIf="lic.isOneTrial" class="btn btn-outline-primary" (click)="doUpgradeExpiredOneTrial(lic.appliance_id)">{{'subscription_upgrade_plan' | i18next}}</button>
        <button *ngIf="lic.isAnnualCloud && lic.isOneAppliance" class="btn btn-outline-primary" (click)="doUpgradeExpiredCloud(lic.appliance_id)">{{'subscription_upgrade_plan' | i18next}}</button>
    </div>
  </app-subscription-card>
</div>
