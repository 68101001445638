import {Routes} from "@angular/router";
import {WorkflowPartnerOneUpgradeSlaComponent} from "./workflow-partner-one-upgrade-sla.component";

export const workflowPartnerOneUpgradeSlaRouting: Routes = [
    { path: 'partner/upgrade-sla/:aid', component: WorkflowPartnerOneUpgradeSlaComponent, children: [
        { path: 'contact', component: WorkflowPartnerOneUpgradeSlaComponent, data: { step: 'contact' } },
        { path: 'address', component: WorkflowPartnerOneUpgradeSlaComponent, data: { step: 'address' } },
        { path: 'payment', component: WorkflowPartnerOneUpgradeSlaComponent, data: { step: 'payment' } },
        { path: 'checkout', component: WorkflowPartnerOneUpgradeSlaComponent, data: { step: 'checkout' } },
        { path: 'complete', component: WorkflowPartnerOneUpgradeSlaComponent, data: { step: 'complete' } },
        { path: '', redirectTo: 'contact', pathMatch: 'full' },
    ]},
]
