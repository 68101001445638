<p-editor [(ngModel)]="message" [style]="{'height':'200px'}" translate="no">
    <p-header>
        <span class="ql-formats">
            <select class="ql-header">
              <option value="1">Heading</option>
              <option value="2">Subheading</option>
              <option selected>Normal</option>
            </select>
        </span>
        <span class="ql-formats">
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-italic" aria-label="Italic"></button>
            <button class="ql-underline" aria-label="Underline"></button>
        </span>
        <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
        </span>
        <span class="ql-formats">
            <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-link" aria-label="Insert Link"></button>
            <button class="ql-code-block" aria-label="Insert Code Block"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-clean" aria-label="Remove Styles"></button>
        </span>
    </p-header>
</p-editor>
