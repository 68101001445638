<app-content-header [stepsBack]="1" (backEvent)="back()" [headline]="'workflow_management_password_reset_headline' | i18next"></app-content-header>

<div class="row">
  <div class="alert alert-danger" *ngIf="error">{{ 'workflow_management_password_reset_error' | i18next:{error: error | i18next} }}</div>
</div>
<div class="row">
  <div class="card card-nb" *ngIf="subscription">
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-12 h2">
          <app-subscription-title [subscription]="subscription"></app-subscription-title>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 highlighted">
          <i class="far fa-user red"></i> {{ subscription.customer_name }}
        </div>
      </div>
      <div class="row subinformations">
        <div class="col">
          <div>{{ 'subscription_type' | i18next }}&nbsp;<i [ngClass]="subscription.typeIcon" class="fa red"></i>&nbsp;
            <app-subscription-type-badge [subscription]="subscription"></app-subscription-type-badge>
          </div>
        </div>
        <div class="row mt-3 mb-3 w-100 justify-content-end">
          <button class="btn btn-outline-primary" (click)="resetPassword()">
            {{'workflow_management_password_reset_button' | i18next}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
