<app-content-header [headline]="'change_password_header' | i18next"></app-content-header>
<div class="card card-nb">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-outline-primary" [disabled]="formGroup.status !== 'VALID'" (click)="changePassword()">{{'app_change_password' | i18next}}</button>
    </div>
  </div>
</div>

