import {Component} from '@angular/core';

@Component({
  selector: 'app-headline-page',
  templateUrl: './headline-page.component.html',
  styles: [
  ]
})
export class HeadlinePageComponent {
}
