import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {CompanyService} from '../company.service';
import {Contact} from '../company.model';
import {filterText} from '@myshared/utils';
import {runInAction} from 'mobx';
import {Router} from '@angular/router';
import {AutorunComponent} from '@myshared/autorun.component';
import {action, observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-company-contacts',
  templateUrl: './company-contacts.component.html',
  styleUrls: []
})
export class CompanyContactsComponent extends AutorunComponent implements OnInit {

  contacts: Contact[];

  @observable filter$: string;
  @observable private showDisabled$: boolean;

  constructor(
    private m: Model,
    private companyService: CompanyService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    if (this.router.url.endsWith('company')) {
      this.router.navigate(['/company', 'contacts']);
    }
    runInAction(() => {
      this.showDisabled$ = false;
      this.filter$ = '';
    });
    this.autorun(() => {
      this.contacts = filterText<Contact>(this.m.company.company.contacts,
        this.filter$).filter(c => this.showDisabled$ ? c.mypascom_account === 'disabled' : c.mypascom_account !== 'disabled');
    });
    this.companyService.refresh();
  }

  @action public showDisabled(value: boolean) {
    this.showDisabled$ = value;
  }

  @action
  doFilter(value: string) {
    this.filter$ = value;
  }
}
