<div class="card card-nb h-100" *ngIf="subscription">
  <div class="card-body pb-0">
    <div class="row">
      <div class="col"><h2>{{'subscription_support_headline' | i18next}}</h2></div>
    </div>
    <div class="row">
      <div class="col" *ngIf="subscription.support_level === 'email'">
        {{'subscription_has_email_support' | i18next}}
      </div>
      <div class="col" *ngIf="subscription.support_level === 'live'">
        {{'subscription_has_live_support' | i18next}}
      </div>
      <div class="col" *ngIf="subscription.support_level === 'premium'">
        {{'subscription_has_premium_support' | i18next}}
      </div>
      <div class="col" *ngIf="subscription.support_level === 'basic'">
        {{'subscription_has_basic_support' | i18next}}
      </div>
    </div>
    <div class="row w-100 justify-content-end">
      <a href="https://pascom.net/forum/" class="btn btn-outline-primary mt-3" target="_blank">
        {{'subscription_open_forum' | i18next}}
      </a>
      <a *ngIf="subscription.hasSupport" [routerLink]="['/support/ticket/create', subscription.appliance_id]"
         class="btn btn-outline-primary ml-3 mt-3">
        {{'support_ticket_add_button' | i18next}}
      </a>
      <a *ngIf="subscription.support_level === 'live' || subscription.support_level === 'premium'"
         href="tel:{{ dialableSupportNumber }}" class="btn btn-outline-primary ml-3 mt-3">
        {{ 'support_contact_support_number' | i18next: { number: supportNumber} }}
      </a>
    </div>
  </div>
</div>
