import {Component, Input, OnInit} from '@angular/core';
import {Affiliate} from '../affiliate.model';
import {Model} from '../../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {AffiliateService} from '../affiliate.service';
import {PCFormsService} from '@myshared/pcform.service';
import {I18NextService} from 'angular-i18next';
import {MessageService} from 'primeng/api';

const MAX_FILESIZE_BYTES = 10485760; // 10 Mib in bytes

@Component({
  selector: 'app-become-partner',
  templateUrl: './become-partner.component.html'
})
export class BecomePartnerComponent extends AutorunComponent implements OnInit {

  @Input() partnerDescription = '';

  public affiliate: Affiliate;
  public file: File = null;

  constructor(private m: Model,
              private affiliateService: AffiliateService,
              private pcFormService: PCFormsService,
              private messageService: MessageService,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => this.affiliate = this.m.affiliate.affiliate);
  }

  getFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.file = null;
      const file = event.target.files[0];
      if (file.type !== 'application/pdf') {
        this.messageService.add({severity: 'error',
          summary: this.i18next.t('trading_license_wrong_filetype') as string});
        return;
      }
      if (file.size > MAX_FILESIZE_BYTES) {
        this.messageService.add({severity: 'error',
          summary: this.i18next.t('trading_license_filesize_exceeded') as string});
        return;
      }
      this.file = file;
    }
  }

  send() {
    if (this.partnerDescription === '' && !this.file) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      // Result is a data uri like: data:application/pdf;base64,KV19JywKJVBERi0xLjQKMSAwI
      // Split the first part and check the mimetype
      // Then split the second part and use it to upload
      const parts = ('' + reader.result).split(','); // Need to be a string. Error at typescript > 3
      if (parts.length > 1) {
        this.affiliateService.applyForPartnership(this.partnerDescription, parts[1]);
      }
    };
    reader.readAsDataURL(this.file);
  }

  get brochureDownloadLink() {
    const upperLanguage = this.i18next.language.toUpperCase();
    const fileName = 'pascom_18_Reseller_Info_' + upperLanguage + '.pdf';
    return 'https://download.pascom.net/release-archive/mypascom/' + fileName;
  }

  get tenantCountryCode() {
    return this.m.account.currentUser.trunkEnvironment.tenantCountryCode;
  }

  getTradingLicenseByType(type: 'text' | 'headline'): string {
    const tenantCountryCode = this.tenantCountryCode.toLowerCase();
    return `affiliate_trading_license_${type}_${tenantCountryCode}`;
  }

}
