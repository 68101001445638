<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ 'agreements_list_headline' | i18next }}</div>
</div>

<div class="d-inline d-md-none">
  <div ngbDropdown>
    <button class="btn bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
      <span>{{ (agreementType === 'info-all' ? 'agreement_info' : agreementType === 'ack-all' ? 'agreement_ack' : 'agreement_contract') | i18next }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button class="dropdown-item" routerLink="/agreement-management/agreements/info">
        <span>{{ 'agreement_info' | i18next }}</span>
      </button>
      <button class="dropdown-item" routerLink="/agreement-management/agreements/ack">
        <span>{{ 'agreement_ack' | i18next }}</span>
      </button>
      <button class="dropdown-item" routerLink="/agreement-management/agreements/contract">
        <span>{{ 'agreement_contract' | i18next }}</span>
      </button>
    </div>
  </div>
</div>

<div class="row d-none d-md-inline">
  <div class="col">
    <ul class="nav link-navigation">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/agreement-management/agreements/info">{{'agreement_info' | i18next}}</a>
      </li>
      <li class="nav-item ml-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/agreement-management/agreements/ack">{{'agreement_ack' | i18next}}</a>
      </li>
      <li class="nav-item ml-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/agreement-management/agreements/contract">{{'agreement_contract' | i18next}}</a>
      </li>
    </ul>
  </div>
</div>

<app-filter [checkboxFilterText]="'agreement_show_outdated' | i18next"
            filterType="checkbox"
            (checkboxFilterChanged)="showOutdated($event)"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'customer_subscription_search' | i18next"></app-filter>

<app-no-content-message t="no_agreements" *ngIf="agreements.length === 0"></app-no-content-message>

<div class="row align-items-stretch mt-2">
  <app-agreement-card [showPreview]=false [showNotSignedHint]=true class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let a of agreements" [agreement]="a">
    <div content *ngIf="a.type == 'siptrunk-testaccount' && a.acknowledged && a.result.outcome == 'api_success'">
      {{'agreement_siptrunk_content' | i18next: {provider: a.data.provider, customer_id: a.result.provider_customer_id} }}
    </div>
    <div content *ngIf="a.type == 'free-idle-warning'">{{'agreement_free_idle_content' | i18next: {applianceName: a.data.applianceName, applianceId: a.data.applianceId, result: a.result.outcome} }}</div>
  </app-agreement-card>
</div>
