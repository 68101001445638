<div *ngIf="accounts.length > 0" ngbDropdown display="dynamic" placement="bottom-right">
    <button class="btn bg-transparent" id="select_tenant_dropdown" ngbDropdownToggle>
        <span class="">{{ tenant.name }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="select_tenant_dropdown">
        <ng-container *ngFor="let t of accounts">
            <button class="dropdown-item" (click)="selectTenant(t.tenant.id)">
                <span>{{ t.tenant.name }}</span>
            </button>
        </ng-container>
    </div>
</div>