import {Injectable} from '@angular/core';
import {WorkflowRequest} from './workflow.model';
import {Model} from '../app.model';
import {Base64 } from 'js-base64';
import {originFromAnchor} from '@myshared/utils';
import {action} from '@myshared/mobx-connector';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {runInAction} from "mobx";
import {SaleOrder} from "../buy/buy.model";
import {firstValueFrom} from "rxjs";

@Injectable()
export class WorkflowService {

  constructor(private m: Model, private http: HttpClient) {
  }

  @action public parseRequest(rqb64: string): void {
    const request = new WorkflowRequest();

    if (['upgrade-subscription', 'cloud', 'onsite',
      'welcome', 'nfr', 'csphost', 'csp', 'cancel-subscription',
      'change-host', 'upgrade', 'phonenumbers', 'itemized-billing',
      'tariff', 'tariff-cancel', 'tariff-migration','cloud-migration', 'nfr-one', 'trial-partner'
    ].indexOf(rqb64) !== -1) {
      request.w = rqb64;
    } else {
      try {
        const obj = JSON.parse(Base64.decode(rqb64));
        Object.assign(request, obj);
        // FIXME: Actually validate the parsed object before assigning
      } catch (e) {
        console.log('Failed to parse workflow request: ', e, rqb64);
      }
    }
    this.setWorkflowRequest(request);
  }

  @action public setWorkflowRequest(request: WorkflowRequest) {
    this.m.workflow.request = request;
  }


  public executeWorkflowRedirect(token: string) {
    // Parse and construct a valid back URL
    const a: any = document.createElement('a');
    a.href = this.m.workflow.request.redirectUrl;

    const backUrl = originFromAnchor(a) + a.pathname + a.search + (a.search ? '&' : '?') + 't=' + token;
    window.location.replace(backUrl);
  }
}
