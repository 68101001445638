import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-pascom-checkbox',
    templateUrl: './pascom-checkbox.component.html',
})
export class PascomCheckboxComponent implements OnInit {

    @Input() text: string;
    @Input() inputId = 'accept';
    @Input() accepted: boolean;
    @Output() public acceptChanged = new EventEmitter<boolean>();

    constructor(
    ) {
    }

    ngOnInit() {
    }

    onAccept(accepted) {
        this.acceptChanged.emit(accepted);
    }
}
