<ul class="nav link-navigation">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/company/contacts">{{'nav_company_contacts' | i18next}}</a>
  </li>
  <li class="nav-item ml-2" routerLinkActive="active">
    <a class="nav-link" routerLink="/company/details">{{'nav_company_details' | i18next}}</a>
  </li>
  <li class="nav-item ml-2" routerLinkActive="active">
    <a class="nav-link" routerLink="/company/payment-details">{{'payment_details_form_header' | i18next}}</a>
  </li>
</ul>
