<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'subscription_actual_concurrent_user' | i18next:{cu: subscription.users} }}</h2>
        <div class="mt-2" [innerHTML]="'subscription_actual_concurrent_user_details' | i18next: {cu: subscription.users}">
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations"
                          [innerHTML]="'subscription_actual_concurrent_user_end_term_note' | i18next:
                          {date: (subscription.nextPossibleDowngradeDate | localized_date)} "></span>
                </div>
            </div>
            <div class="d-flex mt-3 w-100 justify-content-end">
                <button *ngIf="!hasScheduledOrder" name="subscription-change-user" class="btn btn-outline-primary mt-3"
                        (click)="openChangeUserWorkflow()">
                    {{'subscription_change_users'|i18next}}
                </button>
                <div class="mt-3" *ngIf="hasScheduledOrder">
                    <span>{{ 'downgrade_cu_ordered_text' | i18next: { cu: scheduledOrderCU, date: (scheduledDate | localized_date) } }}</span>
                    <a (click)="cancelOrder()" class="ml-1 red cursor-pointer">{{ 'cancel_order' | i18next }}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog key="cu" #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
        <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'confirm_cancel_order' | i18next}}</button>
    </p-footer>
</p-confirmDialog>

<p-confirmDialog key="subscription_concurrent_user" #cd2 header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" class="btn btn-outline-primary" (click)="cd2.reject()">{{'app_cancel' | i18next}}</button>
        <button type="button" class="btn btn-primary" (click)="cd2.accept()">{{'subscription_undo_cancellation_button' | i18next}}</button>
    </p-footer>
</p-confirmDialog>
