import {Directive, ElementRef} from '@angular/core';
import {FormControlName} from '@angular/forms';

/**
 * Directive to add nativeElement ref to a formControl
 */
@Directive({
  selector: '[formControlName]'
})
export class NativeElementInjectorDirective {
  constructor(private el: ElementRef, private control: FormControlName) {
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
    control.ngOnChanges = function () {
      const result = originFormControlNameNgOnChanges.apply(this, arguments);
      if (this.valueAccessor && this.valueAccessor._elementRef) {
        this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
      }
      return result;
    };
  }
}

