import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../subscription.model';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';

@Component({
  selector: 'app-subscription-cancel-period',
  templateUrl: './subscription-cancel-period.component.html',
  providers: [LocalizedDatePipe]
})
export class SubscriptionCancelPeriodComponent implements OnInit {

  @Input() subscription: Subscription;
  @Output() selectCancelPeriodEvent  = new EventEmitter<boolean>();
  @Output() backEvent  = new EventEmitter();

  constructor(private datePipe: LocalizedDatePipe) { }

  ngOnInit() {
  }

  cancelOnDate() {
    this.selectCancelPeriodEvent.emit(false);
  }

  cancelNow() {
    this.selectCancelPeriodEvent.emit(true);
  }

  get nextInvoiceDate() {
    return this.datePipe.transform(this.subscription.next_invoice);
  }

  get isNextInvoice(): boolean {
    return this.subscription.next_invoice && this.subscription.next_invoice !== '';
  }

  back() {
    this.backEvent.emit();
  }

}
