<div class="price-card card card-nb h-100"
     [ngClass]="{ 'normal': product.name !== 'free', 'highlight': product.name === 'free' }">

  <div class="d-flex flex-column">
    <div class="price-card-header">
      <div class="d-flex align-items-center flex-column">
        <div class="subscription-type h3">{{ product.name }}</div>
        <div class="subscription-description">{{ product.description }}</div>
        <div class="subscription-price mt-auto">
          <span class="price">{{ currentPrice | localized_currency:{vatMarker: currentPrice > 0} }}</span>
          <span class="time-billing">{{'price_per_year' |  i18next }}</span>
        </div>
      </div>
    </div>

    <div class="price-card-body product mt-3">
      <div class="concurrent-user-hint">
        {{'product_select_concurrent_user' | i18next}}<a href="{{concurrentUserLink}}" target="_blank"><i class="far fa-question-circle concurrent-user-icon"></i></a>
      </div>
      <div class="d-flex justify-content-center concurrent-user" *ngIf="product.name === 'free'">
        <div class="form-group-container">
          <div class="form-control">
            {{'price_license_included' |  i18next: {users: product.products[0].users} }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center concurrent-user" *ngIf="product.name !== 'free'">
        <div class="form-group-container">
          <select class="form-control select" [(ngModel)]="selectedProduct" (ngModelChange)="onProductChange()">
            <option *ngFor="let p of product.products" value="{{p.name}}">{{'price_licenses' |  i18next: {users: p.users} }}</option>
          </select>
        </div>
      </div>

      <div class="container" *ngIf="product.features.length <= 3">
        <div class="row justify-content-center features">
          <div class="features-item col-6">
            <div *ngFor="let f of product.features">
              <div class="d-flex align-items-center">
                <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
                <span>{{ f.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" *ngIf="product.features.length > 3">
        <div class="row features">
          <div class="features-item col-6" *ngFor="let f of product.features">
            <div class="d-flex align-items-center">
              <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
              <span>{{ f.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price-card-footer">
      <button [name]="'select-' + product.name" class="btn btn-primary" (click)="send()">{{ product.btnText }}</button>
    </div>
  </div>

</div>
