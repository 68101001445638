import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {Model} from "../app.model";
import {PhoneNumber} from "../phonenumber/phoneNumber";

@Pipe({
  name: 'phonenumber'
})
@Injectable()
export class FormatPhonenumberPipe implements PipeTransform {

  constructor(private m: Model) {}

  transform(value: any): any {
    return PhoneNumber.format(value, this.m.account.currentUser.trunkEnvironment.tenantCountryCode)
  }
}
