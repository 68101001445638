import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {Subscription} from '../../subscription/subscription.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {runInAction} from 'mobx';
import {filterText} from '@myshared/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {action, observable} from '@myshared/mobx-connector';

/**
 * This workflow is only used for listing subscriptions if the user comes from the CSUI
 * Important for onsite migration, or pairing!
 *
 * Pls use this only for this workflow - its wrong naming here, and an old workflow
 * It will only work with a list of subscriptions with type onsite and csp!
 *
 * This Component will be removed, if no onsite is existing anymore (no migration possible)
 */
@Component({
  selector: 'app-workflow-view-subscription',
  templateUrl: './workflow-view-subscription.component.html',
})
export class WorkflowViewSubscriptionComponent extends AutorunComponent implements OnInit {

  @observable public subscription: Subscription;
  @observable public subscriptions: Subscription[];
  public isAdmin: boolean;
  public showPascomOneAlreadyMigratedMessage = false;

  constructor(
    private m: Model,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      const mac = this.m.workflow.userData.mac;
      // We need to check the actual appliance if this is already paired with some license
      const pairedSubscription = this.m.subscription.subscriptions.find(s => s.mac === mac);
      if (pairedSubscription) {
        // If paired, load subscription details for this appliance
        this.getSubscriptionDetail(pairedSubscription.appliance_id)
      } else {
        // if not paired, reset the loaded subscription (because its happen when u click on unpair button, we want to see the list)
        runInAction(() => this.subscription = undefined );
        // Load the list auf subscriptions
        this.filterLicenses();
      }

    });


    this.subscriptionService.refresh(true);
  }

  goBack() {
    window.location.replace(this.m.workflow.request.redirectUrl);
  }

  @action filterLicenses(searchText: string = '') {
    this.subscriptions = filterText(this.m.subscription.subscriptions, searchText)
        .filter(s => (s.appliance_type === 'onsite' || s.appliance_type === 'csp') && s.isValid);
  }

  private getSubscriptionDetail(aid: string) {
    if (!aid) {
      return;
    }
    this.subscriptionService.getSubscriptionDetails(+aid)
      .subscribe({
        next: (r) => {
          const subscription = Subscription.fromJson(r);
          if (subscription.isOne || subscription.isOneTrial) {
            this.showPascomOneAlreadyMigratedMessage = true;
            return;
          }
          runInAction(() => {
            this.subscription = subscription;
          });
        }
      });
  }

}
