import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-partnership-achievement-container',
  templateUrl: './partnership-achievement-container.component.html'
})
export class PartnershipAchievementContainerComponent implements OnInit {
  @Input('i18next-level')
  public i18nextLevel;
  @Input()
  public achievements;
  @Input()
  public benefits;
  @Input()
  public affiliateLevel: boolean;

  constructor() { }

  ngOnInit() {
  }
}
