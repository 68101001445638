<div class="card card-nb h-100" *ngIf="subscription">
  <div class="card-body pb-0 d-flex flex-column">
    <div class="">
      <h2>{{ 'pcone_details_itemized_billing' | i18next }}</h2>
    </div>
   <div class="">{{ 'pcone_details_itemized_billing_' + subscription.itemizedBillingType + "_text" | i18next }}</div>
    <div class="w-100 d-flex justify-content-end mt-auto mb-4">
      <button class="btn btn-outline-primary" (click)="doUpdateItemizedBilling()">
        {{ 'pcone_details_update_itemized_billing_button' | i18next }}
      </button>
    </div>
  </div>
</div>

