<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div>
        <img src="assets/images/flamingo.svg" alt="pascom welcome picture">
      </div>
      <div>
        <h1 *ngIf="headline">{{ headline | i18next}}</h1>
        <p *ngIf="content" class="gray">{{ content | i18next}}</p>
        <span *ngIf="documentation" class="btn btn-link no-border" (click)="onOpenDocumentation()">{{ documentation | i18next }}</span>
        <div class="mt-3 mb-3">
          <button class="btn btn-outline-primary" *ngIf="showBackrouteButton" (click)="onOpenBackroute()">{{ backrouteButton | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
