import {runInAction} from 'mobx';
import {BankAccount, Company, Contact} from '../company/company.model';
import {computed, observable} from '@myshared/mobx-connector';
import {PhonenumberBlock} from '../phonenumber/phonenumber.model';
import {Tenant} from "../auth/account.model";

// draft = pending - sale = confirmed
export type OrderState = 'sent' | 'draft' | 'sale' | 'cancelled';

export class SaleOrder {
  public name: string;
  public amount_tax: number;
  public amount_untaxed: number;
  public amount_total: number;
  public lines: SaleOrderLine[];
  public payment_method: PaymentMethodCode;
  public invoice_address: Company;
  public customer_address: Company;
  public aid: string;
  public lid: string;
  public subscription: string;
  public next_invoice: string;
  public current_product: string;
  public current_sla_product: string;
  public current_users: number;
  public order_state: OrderState;
  public contact: Contact;
  public payment_details: BankAccount;
  applianceName?: string;
  nextPossibleEndDate?: string;
  public deliveryDate: string;
  public subscription_cancelled: boolean;
  public tenant?: Tenant;

  get product(): string {
    if (this.lines && this.lines.length > 0 ) {
      for (let i = 0; i < this.lines.length; i++) {
        if (this.lines[i].product.startsWith('PC-')) {
          return this.lines[i].product;
        }
      }
    }
    return '';
  }

  get slaProduct(): string {
    if (this.lines && this.lines.length > 0 ) {
      for (let i = 0; i < this.lines.length; i++) {
        if (this.lines[i].product.startsWith('MDSL')) {
          return this.lines[i].product;
        }
      }
    }
    return '';
  }

  public get scheduledSLAOrderLine() {
    return this.lines.find(l => l.product.match(new RegExp('^PC-ONE-SLA[123]$')));
  }
  public get scheduledCUOrderLine() {
    return this.lines.find(l => l.product.match(new RegExp('^PC-ONE$')));
  }

  public static fromJson(json: any): SaleOrder {
    const s = new SaleOrder();
    s.name = json.name || '';
    s.amount_tax = json.amount_tax || 0;
    s.amount_untaxed = json.amount_untaxed || 0;
    s.amount_total = json.amount_total || 0;
    s.lines = (json.lines || []).map(v => SaleOrderLine.fromJson(v));
    s.invoice_address = Company.fromJson(json.invoice_address || {});
    s.customer_address = Company.fromJson(json.customer_address || {});
    s.aid = json.aid || '';
    s.lid = json.lid || '';
    s.subscription  = json.subscription || '';
    s.payment_method = json.payment_method || 'sepa';
    s.next_invoice = json.next_invoice || '';
    s.current_product = json.current_product || '';
    s.current_sla_product = json.current_sla_product || '';
    s.current_users = json.current_users ?? undefined;
    s.order_state = json.order_state ?? undefined;
    s.contact = json.contact ?? undefined;
    s.payment_details = json.payment_details ?? undefined;
    s.applianceName = json.appliance_name ?? undefined;
    s.nextPossibleEndDate = json.next_possible_end_date ?? undefined;
    s.deliveryDate = json.delivery_date || '';
    s.subscription_cancelled = json.subscription_cancelled || false;
    s.tenant = Tenant.fromJson(json.tenant ?? {});
    return s;
  }
}

export type PaymentMethodCode = 'sepa'|'wire_transfer';

export class SaleOrderLine {
  public name: string;
  public product: string;
  public discount: number;
  public price_unit: number;
  public product_uom_qty: number;
  public price_subtotal: number;
  public tax: number;
  public priceTax: number;

  public static fromJson(json: any): SaleOrderLine {
    const l = new SaleOrderLine();
    l.name = json.name || '';
    l.product = json.product || '';
    l.discount = json.discount || 0;
    l.price_unit = json.price_unit || 0;
    l.product_uom_qty = json.product_uom_qty || 0;
    l.price_subtotal =  json.price_subtotal || 0;
    l.tax =  json.tax || 0;
    l.priceTax =  json.price_tax || 0;
    return l;
  }
}

export class BuyModel {
  @observable public currentOrder: SaleOrder;
  @observable public phonenumberBlock: PhonenumberBlock[];

  @computed get isCloudProduct(): boolean {
    return this.currentOrder && this.currentOrder.product.indexOf('CLOUD') !== -1;
  }

  @computed get isPhonenumberReserved(): boolean {
    return this.phonenumberBlock.length > 0 && !!!this.phonenumberBlock.find(r => !r.isReserved);
  }

  constructor() {
    runInAction(() => {
      this.currentOrder = SaleOrder.fromJson({});
      this.phonenumberBlock = [];
    });
  }

  resetCurrentOrder() {
    runInAction(() => {
      this.currentOrder = SaleOrder.fromJson({});
      this.phonenumberBlock = [];
    });
  }


}
