<div *ngIf="test">
  <div class="card card-nb">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h3>{{test.name}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <span>{{'partner_test_attempts' | i18next: { attempts: test.attempts, max_attempts: test.max_attempts} }}<br /></span>
          <span *ngIf="test.status === 'open'">{{'partner_test_deadline' | i18next }} {{test.deadline | localized_date}}<br /></span>
        </div>
        <div class="col-12 col-lg-4 text-lg-center">
          <a href="http://doc.pascom.net/" target="_blank">{{'partner_test_training_resources' | i18next}}</a>
        </div>
        <div class="col-12 col-lg-4">
          <button *ngIf="test.status === 'open'" class="btn btn-outline-primary float-lg-right mt-2 mt-lg-0"
                  (click)="startTest()">
            {{'partner_test_start_btn' | i18next}}
          </button>
          <span *ngIf="test.status !== 'open'"
                [class]="(test.status ==='overdue' || test.status === 'failed' ? 'red' : '') + ' float-lg-right mt-2 mt-lg-0'">
            {{ "partner_test_status_"+test.status | i18next}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
