import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {WorkflowRequest} from '../workflow.model';
import {WorkflowService} from '../workflow.service';
import {AutorunComponent} from '@myshared/autorun.component';

@Component({
  selector: 'app-workflow-manager',
  templateUrl: './workflow-manager.component.html',
  styleUrls: []
})
export class WorkflowManagerComponent extends AutorunComponent implements OnInit {

  public currentWorkflow: WorkflowRequest;

  public isOnsiteSetup: boolean;
  public isUpgradeSubscription: boolean;
  public isViewSubscription: boolean;
  public isWelcome: boolean;
  public isInWorkflow: boolean;
  public isCancelSubscription: boolean;
  public isManagementPasswordReset: boolean;
  public isChangeHost: boolean;
  public isOneUpgrade: boolean;
  public isPhonenumbers: boolean;
  public isItemizedBilling: boolean;
  public isTariff: boolean;
  public isTariffCancel: boolean;
  public isTariffMigration: boolean;
  public isCloudMigration: boolean;
  public isCreateNfrOne: boolean;
  public isOneTrialPartner: boolean;

  constructor(private router: Router, private route: ActivatedRoute, public m: Model, private _wSvc: WorkflowService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.currentWorkflow = this.m.workflow.request;
      this.isOnsiteSetup = this.m.workflow.isOnsiteSetup;
      this.isUpgradeSubscription = this.m.workflow.isUpgradeSubscription;
      this.isViewSubscription = this.m.workflow.isCsui;
      this.isWelcome = this.m.workflow.isWelcome;
      this.isCancelSubscription = this.m.workflow.isCancelSubscription;
      this.isManagementPasswordReset = this.m.workflow.isManagementPasswordReset;
      this.isInWorkflow = this.m.workflow.inWorkflow;
      this.isChangeHost = this.m.workflow.isChangeHost;
      this.isOneUpgrade = this.m.workflow.isOneUpgrade;
      this.isPhonenumbers = this.m.workflow.isPhonenumbers;
      this.isItemizedBilling = this.m.workflow.isItemizedBilling;
      this.isTariff = this.m.workflow.isTariff;
      this.isTariffCancel = this.m.workflow.isTariffCancel;
      this.isTariffMigration = this.m.workflow.isTariffMigration;
      this.isCloudMigration = this.m.workflow.isCloudMigration;
      this.isCreateNfrOne = this.m.workflow.isNfrOne;
      this.isOneTrialPartner = this.m.workflow.isOneTrialPartner;
    });
    this.route.paramMap.subscribe((params: ParamMap) => this._wSvc.parseRequest(params.get('b64')));
  }
}
