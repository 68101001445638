import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {CookieHelper} from '@myshared/cookieHelper';
import {Observable, Subject, switchMap, timer} from 'rxjs';
import {runInAction} from 'mobx';
import {Model} from '../app.model';
import {map} from 'rxjs/operators';
import {CurrentUser} from './account.model';
import {LoginResponse, OidcSecurityService, StsConfigHttpLoader} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {normalizeLanguageCode} from '@myshared/i18nutils';
import {I18NextService} from 'angular-i18next';
import {LogLevel} from 'angular-auth-oidc-client';
import {MessageService} from "primeng/api";
import {environment} from "../../environments/environment";
import {SESSION_ID, STORAGE_ODOO_SESSION_ID} from "@myshared/utils";
import {Company} from "../company/company.model";

export interface EmailInfo {
  exists: boolean;
  needsVerification: boolean;
}

export interface OAuthSessionStore {
  authWellKnownEndPoints: {
    issuer: string;
    jwksUri: string;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    userInfoEndpoint: string;
    endSessionEndpoint: string;
    checkSessionIframe: string;
    revocationEndpoint: string;
    introspectionEndpoint: string;
    parEndpoint: string;
  },
  authStateControl: string;
  authNonce: string;
  codeVerifier: string;
}

// Idle timer for 3 hours. Will be reset by resetIdleTimer on API call
const IDLE_TIME = 3 * 60 * 60000; // 3 hours

export const authConfigFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<any>(`/services/config`).pipe(
    map((responseData: any) => {
      return {
          authority: responseData.AUTH_URL + `/auth/realms/pascom`,
          redirectUrl: window.location.origin,
          postLogoutRedirectUri: window.location.origin,
          clientId: 'mypascom',
          scope: 'openid profile email',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true, // this is required if the id_token is not returned
          triggerRefreshWhenIdTokenExpired: false, // required when refreshing the browser if id_token is not updated after the first authentication
          logLevel: (!environment.production ? LogLevel.Warn : LogLevel.None), // Set to warn, because on debug level the console will spam a log ot refresh token logs.
          // The amount of offset allowed between the server creating the token, and the client app receiving the id_token.
          // The diff in time between the server time and client time is also important in validating this value. All times are in UTC.
          maxIdTokenIatOffsetAllowedInSeconds: 451 // ~7.5 min
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

const SESSION_STORAGE_TENANT_ID = 'tenant_id';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // This event will be called to reset the idle timer
  private readonly idleTimer$ = new Subject<void>();

  constructor(
    private m: Model,
    private httpClient: HttpClient,
    private oidcService: OidcSecurityService,
    private router: Router,
    private i18next: I18NextService,
    private messageService: MessageService
  ) {}

  public get isMypascomCookie(): boolean {
    return CookieHelper.exists(SESSION_ID);
  }

  public get isAuthorized(): boolean {
    return this.isMypascomCookie && !!this.m.account.currentUser && this.m.account.isAuthorized;
  }

  /**
   * On app startup we will check authentication with keycloak
   */
  public checkAuthorization() {
    // We need to check the 'whitelisted' non auth required urls first
    if (!this.handleNonAuthUrls()) {
      this.oidcService.checkAuth().subscribe({
        next: (loginResponse: LoginResponse) => this.login(loginResponse),
        error: (err: any) => {
          window.location.replace(`${window.location.origin}`);
        }
      });
    }
  }

  public requestPhoneVerificatioon() {
    if (this.m.account.hasSMSValidationTag) {
      this.httpClient.get<any>('/services/auth/phone/start').subscribe({
        next: (r) => { console.log('phone number 2fa started', r) }
      });
    }
  }

  /**
   * Send auth code to API
   */
  public login(loginResponse: LoginResponse): void {
    const { isAuthenticated, userData, accessToken, idToken, errorMessage } = loginResponse;

    if (errorMessage) {
      this.m.account.setAuthorizationFailed();
      this.clearAuthCookie();
      return;
    }

    if (!this.handleSSOSession()) {
      this.setLatestUrl(window.location.href); // Remember last used URL before we redirect to Keycloak
      if (isAuthenticated) {
        this.m.account.setKeycloakAuthorized();
        this.m.account.setKeycloakUser(userData);
        this.loginOdoo({accessToken, idToken});
      } else {
        this.oidcService.authorize();
      }
    }
  }

  private handleSSOSession() {
    const ssoSessionStorage = sessionStorage.getItem(STORAGE_ODOO_SESSION_ID) ?? '';
    const odooSessionId = CookieHelper.get(SESSION_ID) ?? '';

    if ((!!ssoSessionStorage && !! odooSessionId) && ssoSessionStorage === odooSessionId) {
      this.loginOdooSSO();
      return true;
    }

    return false;
  }

  private handleNonAuthUrls(): boolean {
    if (window.location.pathname.startsWith('/invite') || window.location.pathname.startsWith('/offer')) {
      this.m.account.setUnauthenticated();
      return true;
    }

    return false;
  }

  public logout(): void {
    this.m.network.newRequest(); // Show loading screen, after redirect loading screen will disappear
    this.httpClient.post<any>('/services/auth/logout', {}).subscribe({
      next: () => {
        this.clearCookiesAndLogoff();
      }
    });
  }

  public clearCookiesAndRevokeToken() {
    this.clearAuthCookie();
    this.oidcService.logoffAndRevokeTokens().subscribe(r => {
      window.location.reload();
    });
  }

  public clearCookiesAndLogoff() {
    this.clearAuthCookie();
    this.oidcService.logoff();
  }

  public clearAuthCookie() {
    CookieHelper.delete(SESSION_ID);
    sessionStorage.removeItem(STORAGE_ODOO_SESSION_ID);
  }

  public activate(token: string): Observable<boolean> {
    // FIXME: Needs to be done directly with keycloak + odoo
    return this.httpClient.post<any>('/services/auth/activate', {
      signup_token: token
    }).pipe(map((r) => {
      runInAction(() => this.m.account.setAuthorized(CurrentUser.fromJson(r)));
      return true;
    }));
  }

  public activateInvite(token: string): Observable<{required_action: string}> {
    return this.httpClient.post<{required_action: string, user: any}>('/services/company/contact/accept-invite', {
      signup_token: token
    }).pipe(map(r => {
      runInAction(() => this.m.account.currentUser = CurrentUser.fromJson(r.user));
      return {
        required_action: r.required_action ?? ''
      };
    }));
  }

  public setLatestUrl(url: string) {
    if (url === '/') {
      return;
    }
    if (!window.sessionStorage.getItem('mypascom_lastUrl')) {
      window.sessionStorage.setItem('mypascom_lastUrl', url);
    }
  }

  /**
   * get a saved URL on session store
   *
   * We cannot ignore params on the URL when we come back to mypascom (e.g. from keycloak)
   * In this case, we need to look on the query params of the saved url
   */
  public getLatestUrl(): { navigationUrl: string, queryParams?: {} } {
    const lastUrl = window.sessionStorage.getItem('mypascom_lastUrl') ?? window.location.origin;
    const url = new URL(lastUrl)

    // Is there any URL query param existing?
    const urlParams = new URLSearchParams(url.search);

    // Convert from ?foo=12&bar=test to an object for angulars router navigation
    const params: { [p: string]: any } = {};
    urlParams.forEach((value: any, key: string) => {
      params[key] = value;
    })

    // If pathname is existing, return pathname with params (if exists) as object
    if (!!url.pathname && url.pathname !== '') {
      return { navigationUrl: url.pathname, queryParams: params ?? undefined };
    }

    // Call '/' root if there is no 'pathname' existing on the url and ignore any params,
    // because the url is maybe corrupted
    return {navigationUrl: '/'};
  }

  public navigateLatestUrl() {
    // Read the last url navigation values
    const navigation = this.getLatestUrl();
    // redirect to this URL on application state (not a redirect, otherwise we will end up in the auth again)
    this.router.navigate([navigation.navigationUrl], {
      queryParams: navigation.queryParams ?? undefined
    });
    // We are finished. We can remove the URL
    this.removeLatestUrl();
  }

  public removeLatestUrl(): void {
    window.sessionStorage.removeItem('mypascom_lastUrl');
  }

  public getOidcAuthUrl(): string {
    return this.oidcService.getConfiguration().authority;
  }

  private loginOdoo(tokens: {accessToken: string, idToken: string}) {
    // Send the current language from i18next to the server. It will only have an effect on new created users
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + tokens.accessToken,
        'x-pc-mypascom-id': tokens.idToken,
        language: normalizeLanguageCode(this.i18next.language) ?? 'en_US'
      }),
    };

    this.httpClient.post<any>('/services/auth/login',
      { tenant_id: this.getTenantIdFromStorage() },
      httpOptions).subscribe({
        next: (r) => {
          runInAction(() => {
            this.m.account.setAuthorized(CurrentUser.fromJson(r));
            this.startIdleTimer();
            this.navigateLatestUrl();
          })
        },
        error: (e: HttpErrorResponse) => {
          // Login on odoo failed, and we get: dynamic_users_not_allowed we want to logout the user from keycloak as well
          if (e.error.message == 'dynamic_users_not_allowed') {
            this.clearCookiesAndLogoff();
            return;
          }

          // If the user is disabled, show a message on mypascom frontend
          if (e.error.message === 'user_disabled') {
            this.m.account.setOdooDisabled();
          }

          // All Other problems will end up in Auth failed page
          this.m.account.setAuthorizationFailed()
          this.clearAuthCookie();
        }
    });
  }

  private loginOdooSSO() {
    this.m.account.setSSOUser();
    this.httpClient.get<any>('/services/auth/user', {observe: 'response'}).subscribe({
      next: (r) => {
        runInAction(() => {
          if (r.status === 204) {
            this.m.account.setAuthorizationFailed()
            this.clearAuthCookie();
          } else {
            this.m.account.setAuthorized(CurrentUser.fromJson(r.body));
            this.navigateLatestUrl();
          }
        })
      },
      error: () => {
        this.m.account.setAuthorizationFailed()
        this.clearAuthCookie();
      }
    });
  }

  /**
   * Resets the idle timer
   */
  public resetIdleTimer() {
    this.idleTimer$.next();
  }

  public switchTenant(id: number) {
    if (id <= 0) {
      return;
    }

    this.httpClient.post('/services/auth/switchtenant', {
      tenant_id: +id
    }).subscribe({
      next: (r) => {
        this.setTenantIdToStorage(id.toString())
        if (this.m.account.isSSOUser) {
          // If we logged in as SSO, we need to rewrite the session store, otherwise we will be logged out.
          const odooSessionId = CookieHelper.get(SESSION_ID) ?? '';
          sessionStorage.setItem(STORAGE_ODOO_SESSION_ID, odooSessionId);
          this.loginOdooSSO();
        } else {
          window.location.reload();
        }
      },
      error: err => {
        this.messageService.add({severity: 'error',
          summary: this.i18next.t('error') as string,
          detail: this.i18next.t('cannot_change_tenant') as string});
      }
    })
  }

  public tenantMigration(customerId: number) {
    return this.httpClient.post('/services/auth/tenant_migration', {
      company_id: customerId
    }).pipe(map((r: any) => Company.fromJson(r)));
  }

  /**
   * Begin idle timer and log out after 3 hours.
   * On any API call, the timer will reset
   *
   * @private
   */
  private startIdleTimer(): void {
    this.idleTimer$.pipe(
      switchMap(() => timer(IDLE_TIME, IDLE_TIME))
    ).subscribe(_ => this.logout())
  }

  private setTenantIdToStorage(id: string) {
    sessionStorage.setItem(SESSION_STORAGE_TENANT_ID, id);
  }

  public getTenantIdFromStorage() {
    const tenantId =  sessionStorage.getItem(SESSION_STORAGE_TENANT_ID);
    return +tenantId ?? undefined;
  }
}
