import {Component, Input, OnInit} from '@angular/core';
import {PC_BASIC_CLOUD_FREE, PC_BASIC_ONSITE_FREE, Subscription} from '../subscription.model';
import {ActivatedRoute, Router} from '@angular/router';
import {BuyService} from '../../buy/buy.service';
import {Model} from '../../app.model';
import {SubscriptionService} from '../subscription.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {WorkflowRequest} from '../../workflow/workflow.model';
import {Base64} from 'js-base64';
import {slaProductNames} from '../../buy/select-product/product.model';

@Component({
  selector: 'app-subscription-user-details-card',
  templateUrl: './subscription-user-details-card.component.html',
})
export class SubscriptionUserDetailsCardComponent extends AutorunComponent implements OnInit {

  @Input() subscription: Subscription;

  public get canDelete() {
    return this.subscription.product === PC_BASIC_ONSITE_FREE || this.subscription.product === PC_BASIC_CLOUD_FREE;
  }

  public confirmDelete = false;

  public isAdmin: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private m: Model) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
    });

    // At workflow we dont get any detail subscription information from the server
    // We need to load them for e.g. support data
    if (this.m.workflow.inWorkflow && this.subscription.isValid) {
      if (!this.subscription?.support_level) {
        this.subscriptionService.getSubscriptionDetails(+this.subscription.appliance_id)
          .subscribe((r) => {
            this.subscription = Subscription.fromJson(r);
        });
      }
    }

  }

  public doUpgradeSubscription() {
    this.router.navigate(['/do', 'upgrade-subscription', this.subscription.appliance_id], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public doCancelSubscription() {
    this.router.navigate(['/do', 'cancel-subscription', this.subscription.appliance_id], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public get isUpgradeAvailable(): boolean {
    const maxSlaOption = slaProductNames[slaProductNames.length - 1];
    return this.isAdmin && !this.subscription.isCancelled && !this.subscription.isOnsitePerpetual && !this.subscription.is_nfr &&
      !(this.subscription.appliance_type === 'csp') &&
      (this.subscription.support_level !== 'premium' ||
        (this.subscription.support_level === 'premium' &&
          (this.subscription.users !== '1024' || this.subscription.sla_product !== maxSlaOption)));
  }
}
