import {Component, EventEmitter, Output, AfterViewInit} from '@angular/core';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {InstanceOfferData} from '../buy.service';
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-select-csphost-name',
  templateUrl: './select-csphost-name.component.html',
})
export class SelectCsphostNameComponent extends AutorunComponent implements AfterViewInit {

  @Output() public readonly selected = new EventEmitter<InstanceOfferData>();

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;

  constructor(private pcFormService: PCFormsService,
              private m: Model) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      if (this.m.workflow.inWorkflow) {
        this.builder = this.pcFormService.newBuilder('csphost');

        this.builder.addTextInput({
          id: 'name',
          elementClass: {
            container: 'form-group-container pbx-name-input d-flex flex-column flex-md-row justify-content-center align-items-center',
            control: 'white'
          },
          validators: {
            required: null,
            pattern: '(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\\.)+[a-zA-Z]{2,63}$)'
          }
        });

        this.formModel = this.builder.model;
        this.formLayout = this.builder.layout;
        this.formGroup = this.builder.createFormGroup();
      }
    });
  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  onContinue() {
    // FIXME: A click on here should immediately trigger validation
    if (this.formGroup.status === 'VALID') {
      const name = this.formGroup.value['csphost']['name'];
      this.selected.emit({
        name: trimInput(name),
      });
    }
  }

  public back() {
    this.m.workflow.resetWorkflow();
  }
}
