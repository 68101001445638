<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'subscription_actual_sla' | i18next:{sla: oneSlaProductName} }}</h2>
        <div class="mt-2" [innerHTML]="oneSlaProductDetailsTranslation | i18next">
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations" [innerHTML]="'subscription_actual_sla_end_term_note' | i18next:
                    {date: (subscription.nextPossibleDowngradeDate | localized_date)}"></span>
                </div>
            </div>
            <div class="d-flex mt-auto w-100 justify-content-end">
                <button name="subscription-change-user" class="btn btn-outline-primary mt-3"
                        *ngIf="!hasScheduledOrder"
                        (click)="openChangeSLAWorkflow()">
                    {{'subscription_change_sla'|i18next}}
                </button>
                <div class="mt-3" *ngIf="hasScheduledOrder">
                    <span>{{ 'downgrade_sla_ordered_text' | i18next: { sla: scheduledOrderSLA, date: (scheduledDate | localized_date) } }}</span>
                    <span (click)="cancelOrder()" class="ml-1 red cursor-pointer">{{ 'cancel_order' | i18next }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog key="sla" #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
        <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'confirm_cancel_order' | i18next}}</button>
    </p-footer>
</p-confirmDialog>

<p-confirmDialog key="subscription_change_sla" #cd2 header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" class="btn btn-outline-primary" (click)="cd2.reject()">{{'app_cancel' | i18next}}</button>
        <button type="button" class="btn btn-primary" (click)="cd2.accept()">{{'subscription_undo_cancellation_button' | i18next}}</button>
    </p-footer>
</p-confirmDialog>
