import {Routes} from "@angular/router";
import {WorkflowEndcustomerOneUpgradeCuComponent} from "./workflow-endcustomer-one-upgrade-cu.component";

export const workflowEndcustomerOneUpgradeCuRouting: Routes = [
    { path: 'upgrade-cu/:aid', component: WorkflowEndcustomerOneUpgradeCuComponent, children: [
            { path: 'address', component: WorkflowEndcustomerOneUpgradeCuComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowEndcustomerOneUpgradeCuComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowEndcustomerOneUpgradeCuComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowEndcustomerOneUpgradeCuComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'address', pathMatch: 'full' },
    ]},
]
