<app-content-header [headline]="'customer_add_new_headline' | i18next"></app-content-header>
<div class="row">
  <div class="col">
    <div class="card card-nb">
      <div class="card-body">
        <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
        <div class="d-flex justify-content-end mt-2">
          <button class="btn btn-outline-primary" [disabled]="formGroup?.status !== 'VALID'" (click)="onSave()">{{'customer_create' | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
