<div *ngIf="summary">
  <app-content-header
    (backEvent)="back()"
    backLabel="app_cancel"
    [historyBack]="false"
    [hideBackButton]="hideBackBtn"
    [hint]="'checkout_summary_hint' | i18next"
    [headline]="'checkout_summary_headline' | i18next">
  </app-content-header>

  <div class="card card-nb mt-2" *ngIf="saleOrder">
    <div class="card-body">
      <div class="d-flex flex-column flex-md-row justify-content-start">
        <div class="card card-gray card-nb card-mw-300">
          <div class="card-body">
            <div class="row">
              <div class="col highlighted">
                <div class="card-h1">{{ 'checkout_summary_invoice_address' | i18next }}</div>
                <div>{{saleOrder.invoice_address.name}}</div>
                <div>{{saleOrder.invoice_address.street}}</div>
                <div>{{saleOrder.invoice_address.street2}}</div>
                <div>{{saleOrder.invoice_address.zip}}&nbsp;{{saleOrder.invoice_address.city}}</div>
                <div>{{saleOrder.invoice_address.country.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="d-flex flex-column justify-content-start w-100 mb-3">
        <div class="d-flex flex-column flex-md-row">
          <div class="mr-1"><i class="fa fa-user red"></i><span>&nbsp;{{ 'checkout_summary_payment_method' | i18next }}&nbsp;{{ paymentMethod }}</span></div>
        </div>
        <div *ngIf="maskedIban">{{ 'summary_payment_details' | i18next: { iban: maskedIban } }}</div>
      </div>



      <div class="h3">{{ 'checkout_phonenumber_summary_monthly_payments' | i18next }}</div>

      <!--product-->

      <app-checkout-phonenumber-summary-products
        totalLabel="checkout_summary_monthly_total"
        [orderLines]="saleOrder.lines"
        [tenantCountryCode]="trunkEnv.tenantCountryCode"
        [summary]="summary.monthly"></app-checkout-phonenumber-summary-products>

      <!--price-->

      <app-checkout-phonenumber-summary-price-details
        totalLabel="checkout_summary_monthly_total"
        [summary]="summary.monthly.additional"></app-checkout-phonenumber-summary-price-details>

      <div class="h3">{{ 'checkout_phonenumber_summary_onetime_setup_fee' | i18next }}</div>

      <!--product-->


      <app-checkout-phonenumber-summary-products
        [orderLines]="saleOrder.lines"
        [summary]="summary.oneTimeFee"></app-checkout-phonenumber-summary-products>

      <!--price-->

      <app-checkout-phonenumber-summary-price-details
        [summary]="summary.oneTimeFee.additional"></app-checkout-phonenumber-summary-price-details>

      <h3 class="h3">{{ 'checkout_summary_dynamic_monthly_payments' | i18next }}</h3>

      <div [innerHtml]="'checkout_summary_download_country_pricelist' | i18next:
    {country: countryTranslationCode}"></div>
      <div [innerHtml]="'summary_services_country_description' | i18next:
    {country: countryTranslationCode}"></div>

      <div class="d-flex flex-column justify-content-end mt-5 mb-3">
        <div class="row justify-content-end">
          <app-pascom-checkbox class="align-self-end"
                               [ngClass]="{ 'col-12 col-lg-10 col-xl-8': trunkEnv.hasTrunkAccess }"
                               [text]="'checkout_one_summary_country_accept_terms' | i18next:
                             {country: countryTranslationCode}"
                               (acceptChanged)="acceptAGB($event)"></app-pascom-checkbox>
        </div>
        <div class="row justify-content-end" *ngIf="isTKGNeeded && trunkEnv.hasTrunkAccess">
          <app-pascom-checkbox
                  class="col-12 col-lg-10 col-xl-8 align-self-end"
                  inputId="checkbox_tkg"
                  [text]="tkgText  | i18next"
                  (acceptChanged)="acceptTKG($event)"></app-pascom-checkbox>
        </div>
        <button class="btn btn-outline-primary align-self-end" type="submit" (click)="onBuy()"
                [disabled]="!acceptedAGB || (isTKGNeeded && trunkEnv.hasTrunkAccess && !acceptedTKG)">
          {{ buyBtnTranslation }}
        </button>
      </div>
    </div>
  </div>
</div>

