<div class="d-flex flex-column h-100">
  <app-content-header [historyBack]="false" (backEvent)="back()" [headline]="'sla_product_select_headline' | i18next"></app-content-header>
  <div class="row mt-5 align-items-stretch">
    <app-sla-card *ngFor="let p of slaViewProducts"
                  class="col-sm-12 col-lg-6 col-xl-4 mb-3 mb-xl-0"
                  [slaProduct]="p"
                  (selected)="onSelected($event)"></app-sla-card>
  </div>
  <div class="ml-auto mt-auto p-3 small">{{ 'vat_not_included' | i18next }}</div>
</div>
