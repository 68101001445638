import {
    Component,
    OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  ParamMap, Router
} from '@angular/router';
import {
    AutorunComponent
} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {Subscription} from "../../subscription/subscription.model";
import {TariffFlatOffer} from "../../tariff/tariff.model";
import {SubscriptionService} from "../../subscription/subscription.service";
import {TariffService} from "../../tariff/tariff.service";

type TariffStep = 'tariff-cancel-confirm';

@Component({
    selector: 'app-workflow-tariff-cancel',
    templateUrl: './workflow-tariff-cancel.component.html'
})
export class WorkflowTariffCancelComponent extends AutorunComponent implements OnInit {

    public step: TariffStep;
    public applianceId: string;
    public subscription: Subscription;

    constructor(
      private route: ActivatedRoute,
      private m: Model,
      private router: Router,
      private subscriptionService: SubscriptionService,
      private tariffService: TariffService
    ) {
        super();
        this.route.paramMap.subscribe((params: ParamMap) => {
          if (params.has('subroute')) {
            const sr = params.get('subroute');

            if (!this.applianceId && +sr > 0) {
              this.applianceId = sr;
            }

            if (this.applianceId && !this.subscription) {
              this.subscriptionService.getSubscriptionDetails(+this.applianceId).subscribe(r => {
                this.subscription = Subscription.fromJson(r);

                if (this.subscription.isOne) {
                  this.setStep('tariff-cancel-confirm');
                } else {
                  this.cancelWorkflow();
                }

              });
            }
          }
      });
    }


    ngOnInit(): void {  }
    private setStep(step: TariffStep) {
      this.step = step;
    }

    onBack() {
        this.cancelWorkflow();
    }

    onConfirm() {
        this.tariffService.cancelOrderedTariff(this.subscription).subscribe(r => {
            this.cancelWorkflow();
        });
    }

  public cancelWorkflow() {
      this.m.workflow.resetWorkflow();
      if (!!this.applianceId) {
          this.router.navigate(['/', 'subscriptions', this.applianceId]);
      } else {
          this.router.navigate(['/', 'subscriptions']);
      }
  }
}
