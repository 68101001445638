<ul class="nav link-navigation">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" [routerLink]="['/customers', id, 'contacts']">{{'nav_customer_contacts' | i18next}}</a>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" [routerLink]="['/customers', id, 'details']">{{'nav_customer_details' | i18next}}</a>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" [routerLink]="['/customers', id, 'subscriptions']">{{'nav_customer_subscriptions' | i18next}}</a>
  </li>
</ul>
