import {Component, Input} from '@angular/core';
import {Subscription} from '../subscription.model';
import {I18NextService} from "angular-i18next";

@Component({
  selector: 'app-subscription-support-type-card',
  templateUrl: './subscription-support-type-card.component.html',
})
export class SubscriptionSupportTypeCardComponent {

  @Input() subscription: Subscription;

  constructor(private i18next: I18NextService) { }

  public get supportNumber() {
    const tenantCountryCode = this.subscription.trunkEnvironment.tenantCountryCode.toLowerCase();
    return this.i18next.t(`support_phone_number_${tenantCountryCode || 'de'}`);
  }

  public get dialableSupportNumber() {
    return this.supportNumber.toString().replace(/\s/g, '');
  }

}
