import {autorun, runInAction} from 'mobx';
import {BehaviorSubject} from 'rxjs';
import {observable} from '@myshared/mobx-connector';

export class Country {

  constructor(
    public code: string,
    public name: string,
    public vat_required: boolean,
  ) {}

  public static fromJson(json: any): Country {
    return new Country(
      json.code || '',
      json.name || json.displayName || '',
      json.vatRequired || false
    );
  }
}

export class CountryModel {
  @observable public countries: Country[];

  public readonly asDropdown = new BehaviorSubject<{label, value}[]>([]);

  constructor() {
    runInAction(() => this.countries = []);
    autorun(() => {
      this.asDropdown.next(this.countries.map(c => ({label: c.name, value: c.code})));
    });
  }
}
