<app-content-header
  (backEvent)="back.emit()"
  [stepsBack]="stepsBack"
  [historyBack]="historyBack"
  [headline]="'select_customer_select_or_create' | i18next"
  [hint]="!is20CSP ? ('select_customer_hint' | i18next) : ''">
</app-content-header>

<div class="row mt-5">
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_choose_customer" *ngIf="!isCreating">
    <div class="d-flex flex-column w-100">
      <p-dropdown class="d-flex flex-column w-100"
                  optionLabel="label"
                  optionValue="value"
                  [options]="multiselectableCustomer"
                  [(ngModel)]="selectedCustomer"
                  [emptyMessage]="'app_empty_multiselect' | i18next"
                  [filter]="true"
                  scrollHeight="300px"
                  filterBy="label">
        <ng-template pTemplate="selectedItem">
          <div class="customer-item customer-item-value" *ngIf="selectedCustomer">
            <div class="customer-item d-flex justify-content-between">
              <div>{{selectedCustomer.name}}</div>
              <div class="badge-icon-container" style="position: relative;">
                <i class="far fa-edit red icon-size-bigger"></i>
                <span class="badge badge-pill badge-primary">{{ selectedCustomer.appliance_ids.length }}</span>
              </div>
            </div>
            <div class="subinformations" style="font-size: .75rem;">
              <div>{{selectedCustomer.street}}&nbsp;{{selectedCustomer.street2}}</div>
              <div>{{selectedCustomer.zip}}&nbsp;{{selectedCustomer.city}}, {{selectedCustomer.country.code}}&nbsp;- {{selectedCustomer.country.name}}</div>
            </div>
          </div>
        </ng-template>
        <ng-template let-customer pTemplate="item">
          <div class="customer-item d-flex justify-content-between">
            <div>{{customer.label}}</div>
            <div class="badge-icon-container" style="position: relative;">
              <i class="far fa-edit red icon-size-bigger"></i>
              <span class="badge badge-pill badge-primary">{{ customer.value.appliance_ids.length }}</span>
            </div>
          </div>
          <div class="subinformations" style="font-size: .75rem;">
            <div>{{customer.value.street}}&nbsp;{{customer.value.street2}}</div>
            <div>{{customer.value.zip}}&nbsp;{{customer.value.city}}, {{customer.value.country.code}} - {{customer.value.country.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="d-flex justify-content-end mt-3 form-container-footer">
      <button class="btn btn-outline-primary" (click)="onAddCustomer()">
        {{ 'select_customer_add_button' | i18next }}
      </button>
      <button class="btn btn-primary" (click)="selectCustomer()">{{ 'select_customer_select_button' | i18next }}</button>
    </div>
  </app-icon-card>
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_add_customer" *ngIf="isCreating">
    <div class="d-flex flex-column w-100">
      <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      <div class="d-flex justify-content-end align-items-center mt-3">
      </div>
      <div class="d-flex justify-content-end align-items-center mb-3">
        <button class="btn btn-link" *ngIf="multiselectableCustomer.length > 0" (click)="isCreating = false">
          {{ 'select_customer_select_existing_button' | i18next }}
        </button>
        <button [disabled]="formGroup?.status !== 'VALID'" class="btn btn-primary" (click)="addCustomer()">
          {{ 'select_customer_create_next_button' | i18next }}
        </button>
      </div>
    </div>
  </app-icon-card>
</div>
