import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';

@Component({
  selector: 'app-select-nfr-type',
  templateUrl: './select-nfr-type.component.html',
})
export class SelectNfrTypeComponent extends AutorunComponent implements OnInit {

  @Output() public readonly selected = new EventEmitter<string>();

  public isCsp: boolean;

  constructor(
    private m: Model
  ) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isCsp = this.m.account.currentUser.companyIsCsp;
    });
  }

  public selectCsp() {
    this.selected.next('csp');
  }

  public selectCloud() {
    this.selected.next('nfr_cloud');
  }

  public selectOnsite() {
    this.selected.next('nfr');
  }

  public back() {
    this.m.workflow.resetWorkflow();
  }
}
