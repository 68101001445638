import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Product, ProductCard, slaProductNames, SlaProduct} from '../select-product/product.model';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {BuyService} from '../buy.service';
import {SaleOrder} from '../buy.model';
import {I18NextService} from 'angular-i18next';
import {MessageService} from 'primeng/api';
import {PC_BASIC_CLOUD_FREE, PC_BASIC_ONSITE_FREE} from "../../subscription/subscription.model";

@Component({
  selector: 'app-select-upgrade-product',
  templateUrl: './select-upgrade-product.component.html',
})
export class SelectUpgradeProductComponent extends AutorunComponent implements OnInit {
  public basic: ProductCard;
  public premium: ProductCard;

  public order: SaleOrder;
  public selectedBasicProduct: string;
  public selectedPremiumProduct: string;
  public selectedSlaProduct: string;
  public isBasic: boolean;
  public isFree: boolean;
  public isCurrentBasic: boolean;
  public isCurrentPremium: boolean;
  public currentProduct: string;
  public currentSlaProduct: string;
  public remainingDays: string;
  public concurrentUserLink: string;
  public slaOptionLink: string;
  public lockDropdowns: boolean;

  private readonly emptyProduct: Product = {
    list_price: -1,
    name: 'NONE',
    users: '0'
  };
  private readonly emptySlaProduct: SlaProduct = {
    list_price: -1,
    name: 'NONE',
    features: []
  };

  public isPartner: boolean;

  public get currentUsers(): string {
    if (this.currentProduct && !this.isFree) {
      return this.currentProduct.split('-').slice(-1)[0];

    }
    return '0';
  }

  public get upgradePrice(): number {
    let price = 0;
    if (this.order) {
      // don't display price while fetching new data from server
      if (this.lockDropdowns) {
        return 0;
      }
      // don't display price if no product is selected
      if (this.selectedBasicProduct === 'NONE' && this.selectedPremiumProduct === 'NONE' &&  this.selectedSlaProduct === 'NONE') {
        return 0;
      }
      for (let i = 0; i < this.order.lines.length; i++) {
        price += this.order.lines[i].price_unit;
      }
    }
    return price;
  }

  @Output() public readonly next = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private m: Model,
    private i18next: I18NextService,
    private buyService: BuyService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    this.concurrentUserLink = this.i18next.t('concurrent_user_link') as string;
    this.slaOptionLink = this.i18next.t('sla_option_link') as string;
    this.lockDropdowns = false;
    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
      this.currentProduct = this.order.current_product;
      this.currentSlaProduct = this.order.current_sla_product;
      this.isFree = this.currentProduct === PC_BASIC_CLOUD_FREE || this.currentProduct === PC_BASIC_ONSITE_FREE;
      this.isCurrentBasic = this.currentProduct.toLowerCase().includes('basic');
      this.isCurrentPremium = this.currentProduct.toLowerCase().includes('premium');
      const orderProduct = this.order.product;
      const orderSlaProduct = this.order.slaProduct;
      this.isBasic = orderProduct.toLowerCase().includes('basic');
      this.selectedPremiumProduct = 'NONE';
      this.selectedSlaProduct = 'NONE';
      this.selectedBasicProduct = 'NONE';
      if (orderProduct && orderProduct != this.currentProduct) {
        if (this.isBasic) {
          this.selectedBasicProduct = orderProduct;
        } else {
          this.selectedPremiumProduct = orderProduct;
        }
      }
      if (orderSlaProduct && orderSlaProduct != this.currentSlaProduct) {
        this.selectedSlaProduct = orderSlaProduct;
      }
      this.remainingDays = this.getRemainingDays(this.order.next_invoice);
    } );
    this.autorun(() => this.isPartner = this.m.account.currentUser.companyIsPartner);
    this.autorun(() => {
      this.basic = null;
      this.premium = null;
      this.isBasic = null;
      if (this.m.buy.currentOrder.aid) {
        this.http.get<{ basic, premium, sla }>('/services/products/' + this.m.buy.currentOrder.aid + '/upgrades').subscribe(r => {
          if (r.basic && r.basic.length > 0) {
            this.basic = {
              name: 'basic',
              description: this.i18next.t('product_basic_description') as string,
              products: [this.emptyProduct, ...r.basic],
              slaProducts: [],
              features: [
                {
                  'name': this.i18next.t('product_feature_forumsupport') as string,
                  'highlighted': false
                },
                {
                  'name': this.i18next.t('product_feature_all') as string,
                  'highlighted': false
                },
                {
                  'name': this.i18next.t('product_feature_hosting') as string,
                  'highlighted': false
                }
              ],
              btnText: this.i18next.t('product_buy_basic') as string
            };
          }
          if ((r.premium && r.premium.length > 0) || (r.sla && r.sla.length > 0)) {
            // get the available sla products from server
            const slaProducts = [this.emptySlaProduct];
            for (let i = 0; i < slaProductNames.length; i++) {
              const r_el = r.sla.find(obj => obj.name === slaProductNames[i]);
              if (r_el) {
                slaProducts.push({name: r_el.name, list_price: r_el.list_price, features: []});
              }
            }
            this.premium = {
              name: 'premium',
              description: this.i18next.t('product_premium_description') as string,
              products: [this.emptyProduct, ...r.premium],
              slaProducts: slaProducts,
              features: [
                {
                  'name': this.i18next.t('product_feature_forumsupport') as string,
                  'highlighted': false
                },
                {
                  'name': this.i18next.t('product_feature_phone_email_support') as string,
                  'highlighted': true
                },
                {
                  'name': this.i18next.t('product_feature_all') as string,
                  'highlighted': false
                },
                {
                  'name': this.i18next.t('product_feature_remotesupport') as string,
                  'highlighted': true
                },
                {
                  'name': this.i18next.t('product_feature_hosting') as string,
                  'highlighted': false
                },
                {
                  'name': this.i18next.t('product_feature_sla_option') as string,
                  'highlighted': true
                }
              ],
              btnText:  this.i18next.t('product_buy_premium') as string
            };
          }
        });
      }
    });
  }

  onNext() {
    this.next.emit(true);
  }

  onPremiumProductChange($event) {
    this.switchProduct(this.selectedPremiumProduct, this.selectedSlaProduct);
  }

  onBasicProductChange($event) {
    this.switchProduct(this.selectedBasicProduct, 'NONE');
  }

  private switchProduct(product: string, slaProduct: string) {
    this.lockDropdowns = true;
    if ((product && product !== 'NONE') || (slaProduct && slaProduct !== 'NONE') ) {
      this.buyService.changeProduct(product, slaProduct)
      .subscribe({
        next: () => {
          this.lockDropdowns = false;
        },
        error: () => {
          this.lockDropdowns = false;
        }
      });
    } else {
      this.lockDropdowns = false;
      this.messageService.add({severity: 'error',
        summary: this.i18next.t('error') as string,
        detail: this.i18next.t('product_none_selected') as string});
    }
  }


  private getRemainingDays (toDate): string {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date();
    const secondDate = new Date(toDate);
    const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
    return diffDays.toString() || '';
  }

  public back() {
    this.m.workflow.resetWorkflow();
  }
}
