<app-cloud-status></app-cloud-status>

<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ "support_ticket_list_headline" | i18next }}</div>
  <div class="ml-auto bd-highlight">
    <button name="add-ticket" class="btn btn-outline-primary" [routerLink]="['/support/ticket/create']" >{{ 'support_ticket_add_button' | i18next }}</button>
  </div>
</div>

<app-filter [onSearching]="isSearching"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'support_ticket_search_placeholder' | i18next"
            filterType="both"
            [multiFilterOptions]="dropdownFilterOptions"
            [multiSelectedDefault]="selectedDefault"
            (multiFilterChanged)="onReceivedOptions($event)"
            multiFilterDisplay="chip"
            [multiPlaceholder]="'support_dropdown_filter_states' | i18next"
            (checkboxFilterChanged)="showTicketsCreatedByMe($event)"
            [checkboxFilterText]="'support_ticket_list_show_my_tickets' | i18next"
></app-filter>

<app-no-content-message t="no_tickets" *ngIf="tickets.length === 0"></app-no-content-message>

<div class="row align-items-stretch mt-3 mb-3">
  <app-ticket-card class="col-xs-12 col-md-6 col-lg-6 mb-3"
                   *ngFor="let t of tickets" [ticket]="t"
                   [applianceName]="getSubscriptionName(t.applianceId)"></app-ticket-card>
</div>


<div class="d-flex justify-content-center mb-3" *ngIf="tickets && needsPagination">
  <button (click)="nextPage()" name="load-more-ticket" class="btn btn-outline-primary">{{ 'support_ticket_load_more_button' | i18next }}</button>
</div>
