import { Component, OnInit } from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {NavigationService} from "../../navigation.service";
import {I18NextService} from "angular-i18next";

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: []
})
export class SideNavigationComponent extends AutorunComponent implements OnInit {

  public isPartner: boolean;
  public isMypascomAdmin: boolean;
  public partnerAffiliateRouterLink: string;
  public hasAgreements: boolean;
  public canEnterPhonenumbers = false;
  public isPartnershipAllowed: boolean;
  public showMenu: boolean;

  constructor(private m: Model,
              private navigationService: NavigationService,
              private i18next: I18NextService) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.isMypascomAdmin = this.m.account.currentUser.mypascom_account === 'admin';
      this.hasAgreements = this.m.agreement.agreements.length > 0;
      this.partnerAffiliateRouterLink = this.m.affiliate.routerLink;
      this.canEnterPhonenumbers = this.m.account.hasTrunkAccess;
      this.isPartnershipAllowed = !this.m.affiliate.isPartnerDead;
      this.showMenu = this.navigationService.isMenu;
    });
  }

  get partnerMediaLink() {
    return this.m.account.partnerMediaLink(this.i18next);
  }
}
