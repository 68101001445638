import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../profile.service';
import {Profile} from '../../partner.model';
import {ActivatedRoute} from '@angular/router';
import {Model} from '../../../app.model';
import {runInAction} from 'mobx';
import {AutorunComponent} from '@myshared/autorun.component';
import {observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
})
export class ProfileViewComponent extends AutorunComponent implements OnInit {

  public profile: Profile;

  @observable private profileId: string;

  constructor(private profileService: ProfileService,
              private m: Model,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.has('profileId')) {
        runInAction(() => this.profileId = params.get('profileId'));
      }
    });
    this.autorun(() => {
      this.profile = this.m.partner.profiles.find(p => p.id == this.profileId);
    });
    this.profileService.refresh();
  }

}
