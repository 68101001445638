<app-headline-page>
    {{ pageHeader | i18next }}
</app-headline-page>

<app-card>
    <app-fa-icon class="d-flex mb-3 mx-auto" *ngIf="!!icon" [icon]="icon" size="3xl"></app-fa-icon>

    <app-headline-content *ngIf="!!cardHeader"
                          class="d-flex mx-auto">
        {{ cardHeader | i18next }}
    </app-headline-content>

    <div class="mx-auto text-center" [innerHTML]="text | i18next"></div>
</app-card>

<div *ngIf="!!buttonText"
     class="d-flex justify-content-end mt-3">
    <button class="btn btn-outline-primary" (click)="next.emit()">
        {{ buttonText | i18next }}
    </button>
</div>

