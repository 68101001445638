import {PCFormBuilder, PCFormContainer, PCFormRow, PCFormsService} from '@myshared/pcform.service';
import {FormGroup, Validators} from '@angular/forms';
import {Company} from './company.model';
import {Country} from '../country/country.model';
import {Observable} from 'rxjs';
import { trimInput } from '@myshared/utils';

/**
 * Creates a company form
 *
 * countries = 'CountrySelectionDisabled' will deactivate the possibility to edit the country. This is important, because we
 * dont want to change the country after customer was created
 * https://pascom.atlassian.net/browse/IT-1815
 *
 * @param pcFormService
 * @param countries
 * @param invoiceEmailRequired
 */
export function companyForm(pcFormService: PCFormsService,
                            countries: Observable<{label, value}[]> | 'CountrySelectionDisabled',
                            invoiceEmailRequired: boolean = false,
                            showInvoiceEmail = true,
                            showVat = true,
                            countrycode: string = 'DE'): PCFormBuilder {
  const builder = pcFormService.newBuilder('company');
  const row1 = builder.addContainer(PCFormRow, {
    id: 'row1',
    elementClass: {
      control: 'row'
    }
  });
  row1.addTextInput({
    id: 'name',
    autoFocus: true,
    tabIndex: 1,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });
  row1.addTextInput({
    id: 'street',
    tabIndex: 2,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });

  const row2 = builder.addContainer(PCFormRow, {
    id: 'row2',
    elementClass: {
      control: 'row'
    }
  });
  row2.addTextInput({
    id: 'street2',
    tabIndex: 3,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
  });
  row2.addTextInput({
    id: 'zip',
    tabIndex: 4,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });

  const row3 = builder.addContainer(PCFormRow, {
    id: 'row3',
    elementClass: {
      control: 'row'
    }
  });
  row3.addTextInput({
    id: 'city',
    tabIndex: 5,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });

  // If countries given, show a selectable country field, otherwise a readonly input
  // We need to do it, because ng-dynamic-forms cannot set select on readOnly
  // also its not possible anymore to change country https://pascom.atlassian.net/browse/IT-1815
  (row3 as PCFormContainer).addSelect<string>({
    id: 'country',
    tabIndex: 6,
    readOnly: countries === 'CountrySelectionDisabled',
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  }, countries, countrycode);

  const row4 = builder.addContainer(PCFormRow, {
    id: 'row4',
    elementClass: {
      control: 'row'
    }
  });

  if (showInvoiceEmail) {
    const invoiceEmailDefinition = {
      id: 'invoiceEmail',
      tabIndex: 7,
      elementClass: {
        container: 'form-group-container',
        host: 'col-md-6 col-12'
      },
    } as any;
    if (invoiceEmailRequired) {
      invoiceEmailDefinition.validators = {required: null};
    }

    row4.addTextInput(invoiceEmailDefinition);
  }

  if (showVat) {
    const vatDefinition = {
      id: 'vat',
      tabIndex: 8,
      elementClass: {
        container: 'form-group-container',
        host: 'col-md-6 col-12'
      },
      validators: {required: null}
    } as any;
    row4.addTextInput(vatDefinition);
  }

  return builder;
}

export function setVatRequirement(formGroup: FormGroup, countries: Country[]) {
  formGroup.get('company.row3.country').valueChanges.subscribe(val => {
    if (formGroup.get('company.row4.vat')) {
      formGroup.get('company.row4.vat').clearValidators();
      countries.find(c => {
        // After customer selects a country for company, it is not possible to change this anymore.
      // See https://pascom.atlassian.net/browse/IT-1815
      // With ng-dyanmic-form it is not possible to have a readonly drop-down. Because of this,
      // customer see two different input types: Selectable drop-down country with code as value,
      // and readonly input field with a display name as value.
      const countryCheckDisplayName = val.length > 2 && c.name === val && c.vat_required;
      const countryCheckCode = val.length === 2 && c.code === val && c.vat_required;
      if (countryCheckDisplayName || countryCheckCode) {
          formGroup.get('company.row4.vat').setValidators(Validators.required);
          return true;
        }
      });
      formGroup.get('company.row4.vat').updateValueAndValidity();
    }
  });
}


export function setCompanytoForm(formGroup: FormGroup, company: Company) {
  formGroup.patchValue({
    'company': {
      'row1': {
        'name': company.name,
        'street': company.street
      },
      'row2': {
        'street2': company.street2,
        'zip': company.zip
      },
      // it is not possible to change the country, in this case we will fill up a input with the name
      // See https://pascom.atlassian.net/browse/IT-1815
      'row3': {
        'city': company.city,
        'country': company.country.name

      },
      'row4': {
        'invoiceEmail': company.email,
        'vat': company.vat
      }
    }
  });
}

export function getCompanyFromForm(formGroup: FormGroup, company: Company): Company {
  const data = formGroup.value['company'];
  const c = new Company();
  c.id = company.id;
  c.name = trimInput(data['row1'].name);
  c.street = trimInput(data['row1'].street);
  c.street2 = trimInput(data['row2'].street2);
  c.zip = trimInput(data['row2'].zip);
  c.city = trimInput(data['row3'].city);
  // only use the given country instead of choose one
  // Counties cannot changed anymore (https://pascom.atlassian.net/browse/IT-1815)
  c.country = company.country;
  c.email = trimInput(data['row4'].invoiceEmail ?? '');
  c.vat = trimInput(data['row4'].vat ?? '');
  return c;
}
