import { Component, OnInit } from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {Agreement, AgreementType} from '../agreement.model';
import {AgreementService} from '../agreement.service';

@Component({
  selector: 'app-agreement-ack',
  templateUrl: './agreement-ack.component.html',
  styleUrls: []
})
export class AgreementAckComponent extends AutorunComponent implements OnInit {

  public agreement: Agreement;
  public accepted = false;
  public isAffiliateContract = false;

  constructor(
    private m: Model,
    private agreementService: AgreementService
  ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.agreement = this.m.agreement.activeAgreement;
      if (this.agreement) {
        this.isAffiliateContract = this.agreement.type === AgreementType.Contract;
      }
    });
  }
  public accept(accepted) {
    this.accepted = accepted;
  }

  public continue() {
    this.agreementService.accept(this.agreement.id);
  }

}
