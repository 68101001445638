import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-phonenumber-single',
  templateUrl: './phonenumber-single.component.html'
})
export class PhonenumberSingleComponent implements OnInit {

  @Input() public from: string;
  @Input() public deliveryDate: string | boolean;
  @Input() public locationIndependent: boolean;

  constructor() {}

  ngOnInit(): void {}

}
