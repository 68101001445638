<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{ "phonenumber_list_headline" | i18next }}</div>
  <div class="ml-auto bd-highlight">
    <button name="add-phonenumber" class="btn btn-outline-primary"
            *ngIf="isAdmin" [routerLink]="['/do', 'phonenumbers']" >{{ 'phonenumber_add_button' | i18next }}</button>
  </div>
</div>

<app-filter [onSearching]="isSearching"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'phonenumber_search_placeholder' | i18next"></app-filter>

<app-no-content-message *ngIf="phoneNumberBlocks.length === 0" t="no_phonenumbers"></app-no-content-message>

<div class="row mt-3 mb-3" *ngIf="phoneNumberBlocks.length > 0">
  <app-phonenumber-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let t of phoneNumberBlocks"
                        [block]="t"></app-phonenumber-card>
</div>

<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_back' | i18next}}</button>
    <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'cancel_phone_number_confirm_button' | i18next}}</button>
  </p-footer>
</p-confirmDialog>
