import { Component, OnInit, AfterViewInit } from '@angular/core';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {Model} from '../../app.model';
import {CountryService} from '../../country/country.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {CompanyService} from '../company.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {MessageService} from 'primeng/api';
import {companyForm, getCompanyFromForm, setCompanytoForm, setVatRequirement} from '../company.form';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-details.component.html',
  styleUrls: []
})
export class CompanyDetailsComponent extends AutorunComponent implements OnInit, AfterViewInit {

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;

  constructor(private m: Model,
              private pcFormService: PCFormsService,
              private countryService: CountryService,
              private companyService: CompanyService,
              private messageService: MessageService,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    // Second value 'CountrySelectionDisabled will disable the country selection field
    this.builder = companyForm(this.pcFormService, 'CountrySelectionDisabled', true);

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.autorun(() => {
      setCompanytoForm(this.formGroup, this.m.company.company);
      setVatRequirement(this.formGroup, this.m.country.countries);
    });
    this.countryService.refresh();
    this.companyService.refresh();
  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  onUpdate() {
    const c = getCompanyFromForm(this.formGroup, this.m.company.company);
    this.companyService.update(c).subscribe(r => {
      if (r) {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('company_update_success_summary') as string,
          detail: this.i18next.t('company_update_success_detail') as string});
      } else {
        this.messageService.add({severity: 'error',
          summary: this.i18next.t('company_update_error_summary') as string,
          detail: this.i18next.t('company_update_error_detail') as string});
      }
    });
  }
}
