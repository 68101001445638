import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-card',
  templateUrl: './icon-card.component.html',
  styles: [
  ]
})
export class IconCardComponent implements OnInit {

  @Input() headline = '';
  @Input() headlineText = '';
  @Input() icon: string;
  @Input() header: string;
  @Input() stretch = true;

  constructor() { }

  ngOnInit(): void {
  }

}
