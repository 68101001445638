<nav class="sidebar-nav" *ngIf="showMenu">
  <ul class="nav">
    <li class="nav-item" routerLinkActive="active">
      <!--<a class="nav-link d-flex flex-row" [routerLink]="isPartner ? '/subscriptions/customer' : '/subscriptions/my'">-->
      <a class="nav-link d-flex flex-row" routerLink="/subscriptions">
        <span class="nav-link-icon icon col-1"><i class="fas fa-user-edit icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_subscriptions' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" *ngIf="canEnterPhonenumbers">
      <a class="nav-link d-flex flex-row" routerLink="/phonenumbers">
        <span class="nav-link-icon icon col-1"><i class="fas fa-phone-alt icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_phonenumbers' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link d-flex flex-row" routerLink="/support">
        <span class="nav-link-icon icon col-1"><i class="fas fa-headset icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_support' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isMypascomAdmin" routerLinkActive="active">
      <a class="nav-link d-flex flex-row" [routerLink]="['/orders']">
        <span class="nav-link-icon icon col-1"><i class="fas fa-shopping-cart icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{'navigation_orders' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isPartner" routerLinkActive="active">
      <a class="nav-link d-flex flex-row" routerLink="/customers">
        <span class="nav-link-icon icon col-1"><i class="fa fas fa-users icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_customers' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isMypascomAdmin" routerLinkActive="active">
      <a class="nav-link d-flex flex-row" routerLink="/company">
        <span class="nav-link-icon icon col-1"><i class="far fa-building icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_company' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isMypascomAdmin && isPartnershipAllowed" routerLinkActive="active">
      <a class="nav-link d-flex flex-row" [routerLink]="['/partner']">
        <span class="nav-link-icon icon col-1"><i class="far fa-handshake icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_partner' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" *ngIf="hasAgreements">
      <a class="nav-link d-flex flex-row" routerLink="/agreement-management/agreements">
        <span class="nav-link-icon icon col-1"><i class="fas fa-file-signature icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_agreement' | i18next }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="isPartner && !!partnerMediaLink">
      <a class="nav-link d-flex flex-row" target="partnerdownload"
         [href]="partnerMediaLink">
        <span class="nav-link-icon icon col-1"><i class="fas fa-cloud-download-alt icon-size-big"></i></span>
        <span class="nav-link-text nav-text-md col-11">{{ 'navigation_partner_downloads' | i18next }}</span>
      </a>
    </li>
  </ul>
</nav>
