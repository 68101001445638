import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Model} from './app.model';
import {AuthService} from './auth/auth.service';
import {autorun} from 'mobx';

@Injectable()
export class IsAdminGuard implements CanActivate {

  constructor(private m: Model, private authService: AuthService, private router: Router) {
  }
  canActivate() {
    let resolve: (boolean) => void;
    const activatePromise = new Promise<boolean>(function(ok) {
      resolve = ok;
    });
    let autosub = null;
    autosub = autorun(() => {
      if (!this.m.account.isInitialized || !this.m.account.isAuthorized) {
        return;
      }
      if (this.m.account.currentUser.mypascom_account !== 'admin') {
        this.router.navigate(['/subscriptions', 'my']);
        resolve(false);
      } else {
        resolve(true);
      }
      if (autosub) {
        autosub();
      }
    });
    return activatePromise;
  }
}
