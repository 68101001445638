import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {AttachedFile} from '../../support/support.model';
import {HttpEventType} from '@angular/common/http';
import {I18NextService} from 'angular-i18next';
import {SupportService} from '../../support/support.service';
import {MessageService} from 'primeng/api';
import {FormatByteSizePipe} from '@myshared/format-download-size.pipe';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  providers: [FormatByteSizePipe]
})
export class FileUploaderComponent extends AutorunComponent implements OnInit {
  @Input() maxFileSize = 10485760;
  @Input() maxFileLength = 1;
  @Input() isUploading: boolean;
  @Input() allowedFiles: string;
  @Input() uploadUrl: string;
  @Input() multipleFiles = false;
  @Input() customUpload = false;
  @Input() uploadLabel = 'file_uploader_upload';
  @Input() chooseLabel = 'file_uploader_choose';
  @Input() styleClass = '';
  @Input() chooseIcon = 'pi pi-plus';
  @Input() showUploadButton = true;

  attachedFiles: AttachedFile[] = [];
  private lastAttachedFilesLength = 0;

  @Output() selected: EventEmitter<AttachedFile[]> = new EventEmitter<AttachedFile[]>();
  @Output() uploaded: EventEmitter<File[]> = new EventEmitter<File[]>();

  constructor(private i18next: I18NextService,
              private supportService: SupportService,
              private messageService: MessageService,
              private formatByteSize: FormatByteSizePipe) {
    super();
  }

  ngOnInit() {
    this.allowedFiles = this.allowedFiles.replace(/\s/g, ''); // removes white spaces
  }

  get isUploadDisabled() {
    return this.attachedFiles.length === this.maxFileLength || this.isUploading;
  }

  onSelect(event) {
    if (this.maxFileLength === 1) {
      this.attachedFiles = []; // reset the files array if we can only select one file
    }
    const maxFileSizeFormat = this.formatByteSize.transform(this.maxFileSize);
    this.lastAttachedFilesLength = this.attachedFiles.length; // remind the last attached files length
    if (this.isMaxAllowedFiles(maxFileSizeFormat)) {
      return;
    }

    let eventFileIndex = 0;
    for (let i = this.attachedFiles.length; i < this.maxFileLength; i++) {
      const actualFile = event.files[eventFileIndex];
      if (actualFile) {
        if (actualFile.size > this.maxFileSize) {
          this.messageService.add({severity: 'error',
            summary: this.i18next.t('file_uploader_filesize_error_headline',
              {filename: actualFile.name }) as string,
            detail: this.i18next.t('file_uploader_filesize_error_description',
              {filename: actualFile.name, maxSize: maxFileSizeFormat }) as string});
          eventFileIndex++;
          return;
        }
        if (!this.checkFileForAllowedType(actualFile)) {
          this.messageService.add({severity: 'error',
            summary: this.i18next.t('file_uploader_filetype_error_headline',
              {filename: actualFile.name, allowed: this.displayAllowedFileTypesTranslation }) as string,
            detail: this.i18next.t('file_uploader_filetype_error_description',
              {filename: actualFile.name, allowed: this.displayAllowedFileTypesTranslation }) as string});
          eventFileIndex++;
          return;
        }
        this.attachedFiles.push({
          event: null,
          file: event.files[eventFileIndex],
          fileId: null,
          isDone: false
        });
        eventFileIndex++;
      }
    }

    this.selected.emit(this.attachedFiles);
  }

  private isMaxAllowedFiles(maxFileSizeFormat: string): boolean {
    if (this.attachedFiles.length === this.maxFileLength) {
      this.messageService.add({
        severity: 'error',
        summary: this.i18next.t('file_uploader_to_many_files_error_headline', {
          maxSize: maxFileSizeFormat,
          count: this.maxFileLength
        }) as string,
        detail: this.i18next.t('file_uploader_to_many_files_error_description', {
          maxSize: maxFileSizeFormat,
          count: this.maxFileLength
        }) as string
      });
      return true;
    }
    return false;
  }

  /**
   * OnUpload Event fired after upload is done, or custom upload is enabled
   *
   * On custom upload this is only a emitter to use the upload on a component
   * @param event
   */
  public onUpload(event) {
    const selectedFiles = event.files;
    this.uploaded.emit(selectedFiles);
  }

  public removeFile(index) {
    this.attachedFiles.splice(index, 1); // file list
    this.selected.emit(this.attachedFiles);
  }

  public getFileProgress(index) {
    const fileProgress = this.attachedFiles[index];
    if (fileProgress && fileProgress.event) {
      if (fileProgress.event.type === HttpEventType.UploadProgress) {
        return Math.round((fileProgress.event['loaded'] * 100) / fileProgress.event['total']);
      }
    }
  }

  public isUploadDone(index) {
    return this.attachedFiles[index].isDone;
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  checkFileForAllowedType(file) {
    const checkTypes = this.allowedFiles.split(',');
    for (const t of checkTypes) {
      const type = t.replace( '*', '.\*' );
      if (new RegExp(type).test(file.type)) {
        return true;
      }
    }
    return false;
  }

  get displayAllowedFileTypesTranslation() {
    const allowedFiles = this.allowedFiles.split(',');
    const allowedFilesTranslation = [];

    for (const type of allowedFiles) {
      if (type === 'audio/*' || type === 'video/*' || type === 'image/*') {
        // change e.g. image/* to images and add translation
        allowedFilesTranslation.push(this.i18next.t('upload_allowed_type_' + type.replace('/*', 's')) as string);
      } else {
        // everything else like application/pdf to pdf or .pdf tp pdf
        // we do not need a direct translation for file extensions
        allowedFilesTranslation.push(type
          .replace('application/', '')
          .replace('.', ''));
      }
    }

    return allowedFilesTranslation.join(', ');
  }
}
