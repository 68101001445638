import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-phonenumbers-type-summary',
  templateUrl: './phonenumbers-type-summary.component.html',
})
export class PhonenumbersTypeSummaryComponent {
  @Input() public headline: string;
  @Input() public includedInSubscription: number;
  @Input() public currentlyInUse: number;
  @Input() public highlighted = false;
  @Input() public setupPrice: number;
  @Input() public monthlyPrice: number;
}