<div class="card card-nb" [ngClass]="{'h-100' : stretch}">
  <div class="d-flex card-header header-red card-nb shadow-sm" *ngIf="!!header">
    <div class="h3 mx-auto">{{ header | i18next }}</div>
  </div>
  <div class="card-body pb-0 pt-0">
    <div class="p-3 d-flex flex-column h-100">
      <div class="h3 mx-auto red" *ngIf="headline">{{ headline | i18next }}</div>
      <div *ngIf="headlineText" [innerHtml]="headlineText | i18next"></div>
      <i *ngIf="!!icon" [ngClass]="icon" class="icon-size-biggest mx-auto mb-5 mt-5 item-self-center"></i>
      <ng-content></ng-content>
    </div>
  </div>
</div>

