<app-content-header
  (backEvent)="back()"
  [headline]="'subscription_change_host_confirm_headline' | i18next:{ applianceId: subscription.appliance_id, applianceName: subscription.name }"></app-content-header>

<div class="col text-align-center mb-5">
  <div class="h3 red">{{ 'subscription_change_host_confirm_warning_header' | i18next:{currentHostName: subscription.parent_name, newHostName: newCspHost.aname} }}</div>
</div>

<div class="row align-items-stretch">
  <div class="mb-3 col-md-6 offset-md-3">
    <div class="card text-white bg-danger h-100 card-nb">
      <div class="card-body pb-0">
        <div class="">
          <h2 style="min-height:70px;">{{ 'subscription_change_host_confirm_label' | i18next }}</h2>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <p style="min-height:130px;"><span class="wrap-text-translation">{{ 'subscription_change_host_confirm_text' | i18next:{applianceName: subscription.name, currentHostName: subscription.parent_name, newHostName: newCspHost.aname} }}</span></p>
        <div class="row justify-content-end">
          <button class="btn btn-link text-light" name="cancel-subscription-abort" (click)="back()">{{ "subscription_change_host_confirm_abort"  | i18next }}</button>
          <button class="btn btn-outline-light" name="change-host-confirm" (click)="onConfirm()">{{ "subscription_change_host_confirm_button"  | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

