import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  getPortNumberFormData,
  getPortNumber,
  portNumberBlockForm,
} from '../../phonenumber-port-form';
import {
  PortNumberBlockDetails,
} from '../../port-phonenumber.model';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-phonenumber-port-block',
  templateUrl: './phonenumber-port-block.component.html'
})
export class PhonenumberPortBlockComponent implements OnInit {
  @Output() public readonly selected = new EventEmitter<PortNumberBlockDetails[]>();
  @Output() public readonly onBack = new EventEmitter();

  public formModel: DynamicFormControlModel[];
  public formGroup: FormGroup;
  public formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;


  constructor(public pcFormService: PCFormsService,
              public messageService: MessageService,
              public i18next: I18NextService) {
  }

  ngOnInit(): void {
    this.changeForm();
  }

  back() {
    this.onBack.emit();
  }

  public onNext() {
    const numbers = [getPortNumberFormData(this.formGroup)];
    this.selected.next(numbers);
  }

  public get formDisabled() {
    return !this.formGroup.valid;
  }

  private setPreviewPhoneNumber(formData: AbstractControl, preview: PortNumberBlockDetails) {
    this.patchPreviewValuesForBlock(preview, formData);
  }

  private patchPreviewValuesForBlock(previewNumber: PortNumberBlockDetails, formData: AbstractControl) {
    // +previewNumber.area_code (+) will transform string into number on the output of the condition
    // to be sure to have no leading 0 for area code
    // E.g. area code: 030 on input transformed into 30 for preview.
    const preview = previewNumber.country_prefix + ' '
      + (+previewNumber.area_code > 0 ? +previewNumber.area_code : '') + ' '
      + previewNumber.main_number + ' ';
    const previewFrom = preview + previewNumber.block_start;
    const previewTo = preview + previewNumber.block_end;
    formData.get('previewFrom').patchValue(previewFrom, {emitEvent: false});
    formData.get('previewTo').patchValue(previewTo, {emitEvent: false});
  }

  private changeForm() {
    this.createNumberBlockForm();

    const blockDetailsPreview = new PortNumberBlockDetails();
    this.formGroup.valueChanges.subscribe(r => {
      this.setPreviewPhoneNumber(this.formData, getPortNumber(blockDetailsPreview, r));
    });

    this.formData.patchValue({
      'countryPrefix': '+49'
    }, {emitEvent: false});
  }

  private createNumberBlockForm() {
    this.builder = portNumberBlockForm(this.pcFormService);
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();
  }

  private get formData() {
    return this.formGroup.get('port_number').get('row1');
  }

}
