import {Routes} from "@angular/router";
import {WorkflowPartnerOneUpgradeCuComponent} from "./workflow-partner-one-upgrade-cu.component";

export const workflowPartnerOneUpgradeCuRouting: Routes = [
    { path: 'partner/upgrade-cu/:aid', component: WorkflowPartnerOneUpgradeCuComponent, children: [
            { path: 'contact', component: WorkflowPartnerOneUpgradeCuComponent, data: { step: 'contact' } },
            { path: 'address', component: WorkflowPartnerOneUpgradeCuComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowPartnerOneUpgradeCuComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowPartnerOneUpgradeCuComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowPartnerOneUpgradeCuComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'contact', pathMatch: 'full' },
    ]},
]
