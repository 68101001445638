import {runInAction} from 'mobx';
import {action, computed, observable} from '@myshared/mobx-connector';
import {pcDate} from "../../date.service";

// https://compas.pascom.net/pages/viewpage.action?pageId=43160411
export class Affiliate {
  public company: number;
  public status: AffiliateStatus;
  public since: string;
  public partner_year_starts: string;
  public partner_year_ends: string;
  public contact_info: string;
  public revenue1: number;
  public subscriptions1: number;
  public ac_rev2k: boolean;
  public ac_rev5k: boolean;
  public ac_rev20k: boolean;
  public ac_new1: boolean;
  public ac_new4: boolean;
  public ac_new8: boolean;
  public ac_hp: boolean;
  public ac_csp: boolean;
  public ac_test: boolean;
  public ac_training: boolean;
  public next_level: AffiliateLevel;
  public level: AffiliateLevel;

  public static fromJson(json: any): Affiliate {
    const c = new Affiliate();
    c.company = json.company || 0;
    c.status = json.status || 'none';
    c.since = json.since || '';
    c.partner_year_starts = json.partner_year_starts || '';
    c.partner_year_ends = json.partner_year_ends || '';
    c.contact_info = json.contact_info || '';
    c.revenue1 = json.revenue1 || 0;
    c.subscriptions1 = json.subscriptions1 || 0;
    c.ac_rev2k = json.ac_rev2k || false;
    c.ac_rev5k = json.ac_rev5k || false;
    c.ac_rev20k = json.ac_rev20k || false;
    c.ac_new1 = json.ac_new1 || false;
    c.ac_new4 = json.ac_new4 || false;
    c.ac_new8 = json.ac_new8 || false;
    c.ac_hp = json.ac_hp || false;
    c.ac_csp = json.ac_csp || false;
    c.ac_test = json.ac_test || false;
    c.ac_training = json.ac_training || false;
    c.next_level = json.next_level || 'registered';
    c.level = json.level || 'registered';
    return c;
  }

  @computed
  get isRegistered() {
    return this.level === "registered";
  }
}

export type AffiliateStatus = 'none'
  |'new'
  |'pending'
  |'active'
  |'dead';

export type AffiliateLevel = 'registered'
   | 'certified'
   | 'premium'
   | 'excellence';


export class AffiliateModel {
  @observable affiliate: Affiliate;

  constructor() {
    runInAction(() => {
      this.affiliate = Affiliate.fromJson({});
    });
  }

  @action
  setAffiliate(affiliate: any) { // FIXME check the any type maybe a json
    this.affiliate = Affiliate.fromJson(affiliate);
  }

  @computed
  get isInitialized() {
    return this.affiliate.company !== 0;
  }

  @computed
  get isPartnerDead() {
    return this.affiliate.status === 'dead';
  }

  @computed
  get routerLink() {
    if (this.affiliate.status === 'active') {
      return '/partner/level';
    } else if (this.affiliate.status === 'pending' || this.affiliate.status === 'new' ) {
      return '/become-a-partner';
    }
  }

  @computed
  get isPartner() {
    return this.affiliate.status === 'active';
  }

  @computed
  get isPartnerPending() {
    return this.affiliate.status === 'new' || this.affiliate.status === 'pending';
  }

  @computed
  get remainingPartnerDays() {
    return pcDate(this.affiliate.partner_year_ends).diffInDays(Date.now());
  }

  @computed
  get isNextLevelLesserThanActual() {
    // fixme bugfix IT-911
    const levels = ['registered', 'certified', 'premium', 'excellence'];
    const currentLevelKey = levels.indexOf(this.affiliate.level);
    const nextLevelKey = levels.indexOf(this.affiliate.next_level);
    return nextLevelKey < currentLevelKey;
  }

  @computed
  get partnerYearDays() {
    return Math.ceil((Date.parse(this.affiliate.partner_year_ends) - Date.parse(this.affiliate.partner_year_starts)) / 86400000);
  }

}
