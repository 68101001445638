<app-content-header
  (backEvent)="back.emit()"
  [headline]="'select_cloud_domain_headline' | i18next">
</app-content-header>

<div class="row mt-5">
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_cloud_domain">
    <div class="d-flex flex-column w-100">
      <p-dropdown class="d-flex flex-column w-100"
                 optionLabel="label"
                 optionValue="value"
                 [options]="multiselectableDomains"
                 [(ngModel)]="selectedDomain"
                 [emptyMessage]="'app_empty_multiselect' | i18next"
                 [filter]="true"
                 filterBy="label">
      </p-dropdown>
    </div>
    <div class="d-flex justify-content-end mt-3 form-container-footer">
      <button class="btn btn-primary" (click)="selectDomain()">{{ 'select_cloud_domain_button' | i18next }}</button>
    </div>
  </app-icon-card>
</div>


