import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Ticket, TicketState} from '../support.model';
import { Model } from '../../app.model';
import { SupportService } from '../support.service';
import { runInAction } from 'mobx';
import i18next from 'i18next';
import {IDropdownItem} from '@myshared/pascom-dropdown-multiselect/pascom-dropdown';
import {Subscription} from "../../subscription/subscription.model";
import {SubscriptionService} from "../../subscription/subscription.service";


@Component({
  selector: 'ticket-list',
  templateUrl: './ticket-list.component.html'
})
export class TicketListComponent extends AutorunComponent implements OnInit {

  public tickets: Ticket[];
  public page = 1; // no model, because its a "temp" variable only for the support listr context
  public isSearching = false;
  public selectedDefault: IDropdownItem[] = [];
  public dropdownFilterOptions = [];
  public subscriptions: Subscription[] = undefined;

  private searchTimeout;
  private isOnlyMyTickets = false;
  private searchQuery = '';
  private selectedFilterIds = [];
  private timeout;

  constructor(
    private m: Model,
    private supportService: SupportService,
    private subscriptionService: SubscriptionService,
  ) { super(); }

  ngOnInit() {
    this.dropdownFilterOptions = [
      {name: i18next.t('support_ticket_list_show_new_tickets'), code: 'new'},
      {name: i18next.t('support_ticket_list_show_open_tickets'), code: 'open'},
      {name: i18next.t('support_ticket_list_show_pending_reminder_tickets'), code: 'pending_reminder'},
      {name: i18next.t('support_ticket_list_show_pending_closed_tickets'), code: 'pending_closed'},
      {name: i18next.t('support_ticket_list_show_closed_tickets'), code: 'closed'}
    ];

    this.subscriptionService.refresh();

    this.selectedDefault = this.dropdownFilterOptions.filter(r => r.code !== 'closed' && r.code !== 'my');
    this.autorun(() => {
      this.tickets = this.m.support.tickets;
      this.isSearching = false;
      this.subscriptions = this.m.subscription.subscriptions;
    });
    this.onReceivedOptions(this.selectedDefault);
  }
  showTicketsCreatedByMe(e: boolean) {
    this.isOnlyMyTickets = e;
    this.refreshAndResetPage(true);
  }

  onReceivedOptions(selectedItems: IDropdownItem[]) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.selectedFilterIds = [];
    if (selectedItems.length > 0) {
      selectedItems.forEach(item => {
        switch (item.code) {
          case 'new':
            this.selectedFilterIds.push(TicketState.new);
            break;
          case 'open':
            this.selectedFilterIds.push(TicketState.open);
            break;
          case 'pending_reminder':
            this.selectedFilterIds.push(TicketState.pendingReminder);
            break;
          case 'pending_closed':
            this.selectedFilterIds.push(TicketState.pendingClose);
            break;
          case 'closed':
            this.selectedFilterIds.push(TicketState.closed);
            break;
        }
      });
    } else {
      this.selectedFilterIds = [TicketState.new, TicketState.open, TicketState.pendingReminder, TicketState.pendingClose, TicketState.closed]
    }
    this.timeout = setTimeout(() => {
      this.refreshAndResetPage(true)
    }, 500);
  }

  nextPage() {
    this.refreshAndResetPage(false);
  }

  get needsPagination(): boolean {
    return this.tickets.length >= (this.page * 10);
  }

  doFilter(e: string) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.isSearching = true;
    const self = this;
    this.searchTimeout = setTimeout(function() {
      runInAction(() => {
        self.searchQuery = e;
        self.refreshAndResetPage(true);
      });
    }, 1000);
  }

  refreshAndResetPage(reset: boolean) {
    this.page = reset ? 1 : (this.page + 1);
    this.supportService.refresh(this.page, this.searchQuery, this.isOnlyMyTickets, this.selectedFilterIds);
  }

  getSubscriptionName(aid: string): string {
    return this.subscriptions.find((sub: Subscription) => +sub.appliance_id === +aid)?.name;
  }
}
