<app-content-header [historyBack]="false" (backEvent)="onBack()" [headline]="'subscription_details' | i18next"></app-content-header>
<app-alert-message *ngIf="subscription && status && status.status !== 'api_loading'" [alertType]="status.messageBg">
  <div *ngIf="status.status === 'pbx_instance_paused'">
    {{'instance_paused_due_to_payment_issues' | i18next: {date: subscription.pausedSince | localized_date,
        interpolation: {escapeValue: false} } }}
  </div>
  <div *ngIf="status.status !== 'pbx_instance_paused'">
    {{ status.status | i18next }}
  </div>
</app-alert-message>
<app-subscription-cards [subscription]="subscription"></app-subscription-cards>

<ng-container *ngIf="hasTrunkCards">
  <div class="row">
    <div class="h3">{{'subscription_trunk_header' | i18next }}</div>
  </div>
  <div class="row align-items-stretch">
    <div class="col-sm-12 col-md-6 mb-3" *ngIf="hasTariffDetail">
      <app-tariff-card [isAdmin]="isAdmin" [subscription]="subscription"></app-tariff-card>
    </div>
    <div class="col-sm-12 col-md-6 mb-3" *ngIf="showItemizedBilling">
      <app-subscription-itemized-billing [subscription]="subscription"></app-subscription-itemized-billing>
    </div>
  </div>
</ng-container>

<div class="row" *ngIf="subscription && subscription.appliance_type === 'csp' && childSubscriptions.length > 0">
  <div class="h3">{{'subscription_csp_childs_header' | i18next: {count: childSubscriptions.length} }}</div>
</div>

<div class="row align-items-stretch" *ngIf="subscription && subscription.appliance_type === 'csp' && childSubscriptions.length > 0">
  <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let s of childSubscriptions" [subscription]="s">
    <button class="btn btn-outline-primary" [routerLink]="'/subscriptions/' + s.appliance_id">{{'app_details' | i18next}}</button>
  </app-subscription-card>
</div>

<ng-container *ngIf="hasPhonenumbers">
  <h3 class="h3">{{'phonenumber_list_headline' | i18next }}</h3>
  <div class="row align-items-stretch">
    <app-phonenumber-card [clickable]="false" class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let t of trunks" [block]="t"></app-phonenumber-card>
  </div>
  <p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
      <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
      <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'app_delete' | i18next}}</button>
    </p-footer>
  </p-confirmDialog>
</ng-container>
