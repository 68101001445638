<app-content-header
  [hideBackButton]="true"
  [headline]="'cancel_subscription_headline' | i18next:{ applianceId: applianceId, applianceName: applianceName }">
</app-content-header>

<app-icon-card>
    <h3 class="h3 red mb-3 mt-3" [innerHtml]="'cancel_subscription_confirm_date_warning_text' | i18next: {
      nextInvoice: pascomOneEndDate }"></h3>


    <p class="mb-5" [innerHTML]="'confirm_cancel_customer_subscription_text' | i18next: {date: pascomOneEndDate, applianceId: applianceId, applianceName: applianceName }"></p>

    <div class="row">
        <div class="col-xl-6 offset-xl-3">
            <div class="card text-white bg-danger h-100 card-nb">
                <div class="card-body pb-0">
                    <h2 class="text-uppercase text-align-center">{{ "warning_headline" | i18next }}</h2>
                </div>
                <div class="card-body pt-0 pb-0">
                    <div class="container p-0">
                        <div class="row align-content-start">
                            <div class="col p-0">
                                <ul class="list-unstyled">
                                    <li *ngFor="let warn of warningPointsList">
                                        <i class="fas fa-plus-circle features-icon mr-2"></i><span>{{ warn }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column justify-content-end mt-5 mb-3">
        <app-pascom-checkbox
                class="align-self-end"
                inputId="accept"
                [text]="'confirm_cancel_customer_subscription_accept_term'  | i18next: {date: pascomOneEndDate}"
                (acceptChanged)="onAccept($event)"></app-pascom-checkbox>
        <button class="btn btn-outline-primary align-self-end" type="submit" (click)="onConfirm()"
                [disabled]="!accept">
            {{ 'cancel_pascom_one_button' | i18next: {date: pascomOneEndDate } }}
        </button>
    </div>

</app-icon-card>