import {action, computed, observable, runInAction} from 'mobx';

export enum MaintenanceState {
  API_AVAILABLE,
  API_UNREACHABLE,
  API_AVAILABLE_CHECK,
  API_AVAILABLE_CHECK_FAILED,
  SCHEDULED_MAINTENANCE
}

export class MaintenanceModel {
  @observable state: MaintenanceState;
  @observable isRefreshButtonShown: boolean;
  @observable statusUrl: string;

  constructor() {
    runInAction(() => {
      this.isRefreshButtonShown = false;
      this.state = MaintenanceState.API_AVAILABLE;
    });
  }

  @action public showRefreshButton() {
    this.isRefreshButtonShown = true;
  }

  @computed get isReachable() {
    return this.state === MaintenanceState.API_AVAILABLE;
  }

  @computed get isChecking() {
    return this.state === MaintenanceState.API_AVAILABLE_CHECK;
  }

  @computed get isScheduledMaintenance() {
    return this.state === MaintenanceState.SCHEDULED_MAINTENANCE;
  }

  @action public setApiAvailable() {
    this.state = MaintenanceState.API_AVAILABLE;
  }

  @action public setApiUnreachable() {
    this.state = MaintenanceState.API_UNREACHABLE;
  }
  @action public setApiChecking() {
    this.state = MaintenanceState.API_AVAILABLE_CHECK;
  }
  @action public setApiCheckFailed() {
    this.state = MaintenanceState.API_AVAILABLE_CHECK_FAILED;
  }
  @action public setScheduledMaintenance() {
    this.state = MaintenanceState.SCHEDULED_MAINTENANCE;
  }
  @action public setStatusUrl(url: string) {
    this.statusUrl = url;
  }

}
