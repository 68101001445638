import {Component, Input, OnInit} from '@angular/core';
import { Article } from '../support.model';
import { SupportService } from '../support.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: []
})
export class ArticleCardComponent implements OnInit {

  @Input()
  public article: Article;
  @Input()
  public ticketId: number;

  constructor(private supportService: SupportService) {
  }

  ngOnInit(): void {
  }

  public get isCustomer(): boolean {
    return this.article.sender === 'Customer';
  }

  public get hasAttachment(): boolean {
    return this.article.attachments.length > 0;
  }

  /**
   * Replace the img Tag from zammad with our own generated preview img tag with hyperlink
   */
  public get articleBody() {
    // This will only happened if somebody copied a img into the zammad ticket textfield (copy and paste)
    return this.article.body.replace(/<img .*?>/, (m) => this.getInlineClickableImg(m));
  }

  /**
   * Callback function to replace the url from zammad to the correct API url
   * and generated hyperlink with img tag
   *
   * @param match
   * @private
   */
  private getInlineClickableImg(match: string): string {
    // search for the url ids (5/6/7) to generate a tag with inline img
    const imgUrl = match
      // search for the inline string on the src= element (is 1 on the matched array)
      .match(/src="(.*?)"/)[1]
      // replace zammad url with correct pascom api url
      .replace(/\/api\/v1\/ticket_attachment/, '')
      // remove parameters on wrong domain (if existing)
      .replace('?view=inline', '');

    const url = this.supportService.downloadLink + imgUrl;
    const imgTag = '<img class="support-thumbnail" src="' + url + '">';
    return '<a target="_blank" href="' + url + '">' + imgTag + '</a>';
  }
}
