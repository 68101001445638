<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <div class="h3">{{'navigation_orders' | i18next}}</div>
</div>

<div class="d-inline d-md-none">
    <div ngbDropdown>
        <button class="btn bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
            <span>{{dropdownItemName | i18next}}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" routerLink="/orders/quotations">
                <span>{{'navigation_quotations' | i18next}}</span>
            </button>
            <button class="dropdown-item" routerLink="/orders/orders">
                <span>{{'navigation_orders' | i18next}}</span>
            </button>
            <button class="dropdown-item" routerLink="/orders/invoices">
                <span>{{'navigation_invoices' | i18next}}</span>
            </button>
        </div>
    </div>
</div>
<div class="row d-none d-md-inline">
    <div class="col">
        <ul class="nav link-navigation">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/orders/quotations">{{'navigation_quotations' | i18next}}</a>
            </li>
            <li class="nav-item ml-2" routerLinkActive="active">
                <a class="nav-link" routerLink="/orders/orders">{{'navigation_orders' | i18next}}</a>
            </li>
            <li class="nav-item ml-2" routerLinkActive="active">
                <a class="nav-link" routerLink="/orders/invoices">{{'navigation_invoices' | i18next}}</a>
            </li>
        </ul>
    </div>
</div>

<app-filter [filterType]="state === 'sent' ? 'checkbox' : ''"
            [checkboxFilterText]="'show_expired_quotation' | i18next"
            (checkboxFilterChanged)="showExpired($event)"
            (searchFilterChanged)="doFilter($event)"
            [placeholder]="'search_orders' | i18next"></app-filter>

<div *ngIf="state === 'sent'" class="p-3">
  <div class="alert alert-warning border shadow-sm" role="alert">
    {{ 'order_phone_number_price_hint' | i18next }}
  </div>
</div>

<div class="row align-items-stretch mt-2">
    <app-order-card
      class="col-xs-12 col-md-6 col-lg-6 mb-3"
      *ngFor="let order of orders" [state]="state" [order]="order"
      (refresh)="onRefresh()"
      [ngClass]="order.attachment ? 'hover-effect' : ''">
    </app-order-card>
</div>

<div class="row mt2 red card-h1" *ngIf="!orders || orders.length === 0">
    <div class="col">
        <span>{{'no_orders' | i18next}}</span>
    </div>
</div>

<div class="d-flex justify-content-center mb-3" *ngIf="orders && needsPagination">
    <button (click)="nextPage()" name="load-more-orders"
            class="btn btn-outline-primary">{{ 'order_load_more_button' | i18next }}</button>
</div>
