import {Component, Output, OnInit, EventEmitter, Input} from '@angular/core';
import {Model} from "../../app.model";
import {I18NextService} from "angular-i18next";
import {AutorunComponent} from "@myshared/autorun.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nfr-one-prepare',
  templateUrl: './nfr-one-prepare.component.html',
  styles: [
  ]
})
export class NfrOnePrepareComponent extends AutorunComponent implements OnInit {
  @Input() isWorkflow = false;

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  public accepted: boolean;

  constructor(private m: Model, private i18next: I18NextService, private router: Router) { super(); }

  ngOnInit(): void {
    this.autorun(() => {
      // This restriction is for the info page only. The workflow itself will do the restriction for CSP
      if (!this.isWorkflow && this.m.account.currentUser.companyIsPC20Csp) {
        this.router.navigate(['/']);
        return;
      }
    })
  }

  public onNext() {
    this.selected.emit();
  }

  public get getNfrOnePartnerCu() {
    switch(this.m.affiliate.affiliate.level) {
      case "certified":
        return 10;
      case "premium":
        return 25;
      case "excellence":
        return 50;
      default:
        return 0
    }
  }

  public get partnerLevelTranslation() {
    return this.i18next.t('partnership_partner_level_' + this.m.affiliate.affiliate.level);
  }

  public accept(v: boolean) {
    this.accepted = v;
  }

  public onBack() {
    this.back.emit();
  }



}
