import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { ActivatedRoute } from '@angular/router';
import { I18NextService } from 'angular-i18next';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {tariffNameTranslationKey} from '@myshared/utils';
import {pcDate} from "../../../date.service";

@Component({
    selector: 'app-tariff-place-order',
    templateUrl: './tariff-place-order.component.html',
    providers: [LocalizedDatePipe]
})
export class TariffPlaceOrderComponent extends AutorunComponent implements OnInit {

    public selectedDate: { date: string, code: string };
    public dates = [];

    @Input() selectedTariff: string;
    @Input() currentTariff: string;

    @Output() public back = new EventEmitter<number>();
    @Output() public selected = new EventEmitter<string>();

    constructor(private route: ActivatedRoute,
                private i18next: I18NextService,
                private datePipe: LocalizedDatePipe
    ) {
        super();
    }

    ngOnInit(): void {
      for (let i = 1; i <= 3; i++) {
        const date = pcDate().set({ date: 1 }).add({ months: i });
        // date is for the correct display of formatted localized date on the select box
        // code will be an unformatted date to format for server transfer
        this.dates.push({
          date: date.formatDate(), // localized date
          code: date.format() // yyyy-mm-dd date
        });
      }

      this.selectedDate = this.dates[0];
    }

    public onBack() {
        this.back.emit();
    }

    get selectedTariffTranslationKey() {
        return this.i18next.t(tariffNameTranslationKey(this.selectedTariff)) as string;
    }

    get currentTariffTranslationKey() {
        return this.i18next.t(tariffNameTranslationKey(this.currentTariff)) as string;
    }

    onOrder() {
        const formattedDate = this.selectedDate.code;
        this.selected.emit(formattedDate);
    }
}
