import {Routes} from "@angular/router";
import {WorkflowEndcustomerOneTrialCreateComponent} from "./workflow-endcustomer-one-trial-create.component";

export const workflowEndcustomerOneTrialCreateRouting: Routes = [
    { path: 'one-trial', component: WorkflowEndcustomerOneTrialCreateComponent, children: [
            { path: 'nfr', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'nfr' } },
            { path: 'trial-phonenumber', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'trial-phonenumber' } },
            { path: 'instance', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'instance' } },
            { path: 'phonenumber', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'phonenumber' } },
            { path: 'thank-you', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'thank-you' } },
    ]},
    // Old URL from email to show prepare trial state
    { path: 'trial/thank-you/:so', component: WorkflowEndcustomerOneTrialCreateComponent, data: { step: 'prepare-trial' } },
    { path: 'trial', redirectTo: 'one-trial' }
]