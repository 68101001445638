import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Model} from './app.model';
import {AuthService} from './auth/auth.service';
import {I18NextService} from 'angular-i18next';

import * as messagesDEV from '@translation/base.json';
import * as messagesEN from '@translation/en.json';
import * as messagesDE from '@translation/de.json';
import {environment} from '../environments/environment';

import * as numeral from 'numeral';

import {browserLanguage, normalizedShortLang} from '@myshared/i18nutils';
import {AutorunComponent} from '@myshared/autorun.component';
import {AgreementService} from './agreement-management/agreement.service';
import {AffiliateService} from './partner/affiliate/affiliate.service';
import {NavigationService} from "./navigation.service";
import {AnalyticsService} from "./analytics.service";
import {setDefaultOptions} from "date-fns";
import {de, enGB} from "date-fns/locale";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent extends AutorunComponent implements OnInit {

  public isAuthorized: boolean;
  public isInitialized: boolean;
  public showMenu: boolean;
  public isAgreementNeeded: boolean;
  public initializedApiCall = false;
  public inCompany: boolean;
  public isMaintenance: boolean;
  public isAuthorizationFailed: boolean;
  public isOdooAccountDisabled: boolean;

  public showStickyMessages = false;

  // Date Service needs to be loaded here, to use localized date
  constructor(
    public authService: AuthService,
    private m: Model,
    public router: Router,
    public route: ActivatedRoute,
    private i18next: I18NextService,
    private agreementService: AgreementService,
    private navigationService: NavigationService,
    private affiliateService: AffiliateService,
    private analyticsService: AnalyticsService) {
    super();

    /* Internet Explorer uses userLanguage */
    const lang = normalizedShortLang(browserLanguage());

    // Because of the new module exporting (esnext) on the root tsconfig.ts we cannot use messageDEV directly
    // We have to use messageDEV.default to get access to the JSON instead of the module. Only commonjs (old one)
    // will work without the default parameter after the variable.
    i18next.init({
      fallbackLng: ['en', 'dev'],
      debug: !environment.production,
      load: 'languageOnly', // Only use the "de" portion of de-AT for language resolution
      lng: lang,
      interpolation: {
        escapeValue: false
      },
      returnEmptyString: false,
      ns: ['translation'],
      resources: {
        dev: { translation: (messagesDEV as any).default },
        en: { translation: (messagesEN as any).default },
        de: { translation: (messagesDE as any).default },
      }
    });

    setDefaultOptions({ locale: (lang === 'de' ? de : enGB),  weekStartsOn: 1 });

    // NOTE: taken from numeral/locales/de.js. Thousands delimiter changed to '.' instead of ' '
    numeral.register('locale', 'de', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function (number) {
        return '.';
      }
    });

    numeral.locale(lang);
  }

  ngOnInit(): void {
    this.authService.checkAuthorization();
    this.analyticsService.initTagManagerNavListener();
    this.autorun(() => {
      // initialising the authentication parameter
      this.isInitialized = this.m.account.isInitialized;
      this.isAuthorized = this.m.account.isAuthorized;
      this.isOdooAccountDisabled = this.m.account.isOdooDisabled;
      this.isAuthorizationFailed = this.m.account.isAuthorizationFailed;
      // Initialise more parameter for main view (e.g. if maintenance mode is enabled)
      this.isMaintenance = !this.m.maintenance.isReachable || this.m.maintenance.isScheduledMaintenance;
      // We get a ExpressionChangedAfterItHasBeenCheckedError if we do the showMenu update like this, solution is:
      // Promise.then creates a microtask. The microtask queue is processed after the current synchronous code has
      // finished executing hence the update to the property will happen after the verification step.
      this.isAgreementNeeded = this.m.agreement.agreementsToAck;
      // if we have on this position no company on the user!
      this.inCompany = this.isInitialized && this.m.account.isInCompany;
      this.showStickyMessages = this.m.account.isSSOUser;
      this.showMenu = this.navigationService.isMenu;
      // Needs a point where we call this at the beginning of application, but after auth
      // this will only happen if we get some info back from our model (autorun)
      if (!this.initializedApiCall && this.isAuthorized) {
        this.affiliateService.refresh(true);
        this.agreementService.refresh(true);
        this.initializedApiCall = true;
      }
    });
  }
}
