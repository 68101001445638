import {Component, Input, OnInit} from '@angular/core';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: []
})
export class ProductCardComponent implements OnInit {

  @Input() public product;

  constructor(
    private i18next: I18NextService
  ) { }

  ngOnInit() {
  }
}
