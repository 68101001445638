import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Model} from './app.model';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private readonly noLoadingForUrl = [
    '/services/appliances/state'
  ]

  constructor(private m: Model) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const url of this.noLoadingForUrl) {
      if (new RegExp(request.url).test(url)) {
        return next.handle(request);
      }
    }

    this.m.network.newRequest();
    return next.handle(request).pipe(finalize(() => this.m.network.requestDone()));
  }
}
