import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { Model } from '../../app.model';
import { I18NextService } from "angular-i18next";
import { Subscription } from "../../subscription/subscription.model";
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {Product} from "../../buy/select-product/product.model";
import {pcDate} from "../../date.service";

@Component({
    selector: 'app-migration-credit',
    templateUrl: './migration-credit.component.html',
    styleUrls: []
})
export class MigrationCreditComponent extends AutorunComponent implements OnInit {

    @Input() public stepsBack = 1;
    @Input() subscription: Subscription;
    @Input() activeProduct: Product;

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly continue = new EventEmitter<void>();

    public concurrentUser: number;
    public dateFrom: Date;
    public dateTill: Date;
    public prepaidListPrice: number;
    public credit: number;

    constructor(
        private m: Model,
        private i18next: I18NextService,
        private http: HttpClient) {
        super();
    }

    ngOnInit() {
        this.autorun(async () => {
            if (this.m.workflow.inWorkflow) {
                const credits = await firstValueFrom(this.http.get('/services/appliance/' + this.subscription.appliance_id + '/calculate_credit'))
                const creditMove = credits["PC-CREDIT-MOVE"] as number;
                const prepaid = credits["CURRENT_RECURRING_AMOUNT"] as number;

              if (creditMove === 0) {
                    this.continue.emit()
                    return
                }

              this.credit = creditMove;
              this.concurrentUser = +this.subscription.users;
              this.dateFrom = pcDate(this.subscription?.next_invoice).sub({ years: 1 }).toDate();
              this.dateTill = pcDate(this.subscription?.next_invoice).toDate();
              this.prepaidListPrice = prepaid;
            }
        });
    }

    onContinue() {
        this.continue.emit()
    }

    onBack() {
        this.back.emit();
    }

}


