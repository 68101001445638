<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'fallback_number_headline' | i18next }}</h2>
        <div class="mt-2" *ngIf="subscription.fallbackNumber"
             [innerHTML]="'fallback_number_details' | i18next: {fallbackNumber: subscription.formattedFallbackNumber}"></div>
        <div class="mt-2" *ngIf="!subscription.fallbackNumber"
             [innerHTML]="'fallback_number_not_configured' | i18next"></div>
        <div class="d-flex flex-column">
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations">{{ 'fallback_number_note' | i18next }}</span>
                </div>
            </div>
            <div class="d-flex mt-auto w-100 justify-content-end mt-3">
                <button *ngIf="subscription.fallbackNumber" name="subscription-change-user" class="btn btn-link"
                        (click)="removeFallbackNumber()">
                    {{'fallback_number_remove'|i18next}}
                </button>
                <button name="subscription-change-user" class="btn btn-outline-primary"
                        (click)="openChangeFallbackNumberWorkflow()">
                    {{'fallback_number_button'|i18next}}
                </button>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog key="fallback-number" #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
        <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'app_yes' | i18next}}</button>
    </p-footer>
</p-confirmDialog>
