import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SubscriptionListComponent} from './subscription/subscription-list/subscription-list.component';
import {SubscriptionViewComponent} from './subscription/subscription-view/subscription-view.component';
import {CustomerListComponent} from './customer/customer-list/customer-list.component';
import {AddCustomerComponent} from './customer/add-customer/add-customer.component';
import {CompanyDetailsComponent} from './company/company-details/company-details.component';
import {CompanyContactsComponent} from './company/company-contacts/company-contacts.component';
import {ContactDetailsComponent} from './company/contact-details/contact-details.component';
import {ContactInviteComponent} from './company/contact-invite/contact-invite.component';
import {IsAdminGuard} from './is-admin.guard';
import {IsPartnerGuard} from './is-partner.guard';
import {ChangePasswordComponent} from './settings/change-password/change-password.component';
import {ProfileListComponent} from './partner/profile/profile-list/profile-list.component';
import {ProfileViewComponent} from './partner/profile/profile-view/profile-view.component';
import {PartnerViewComponent} from './partner/affiliate/partner-view/partner-view.component';
import {AgreementListComponent} from './agreement-management/agreement-list/agreement-list.component';
import {TestListComponent} from './partner/test-list/test-list.component';
import {TestExecuteComponent} from './partner/test-execute/test-execute.component';
import {CompanyPaymentDetailsComponent} from './company/company-payment-details/company-payment-details.component';
import { TicketListComponent } from './support/ticket-list/ticket-list.component';
import { TicketDetailsComponent } from './support/ticket-details/ticket-details.component';
import { CreateTicketComponent } from './support/create-ticket/create-ticket.component';
import {OrderListComponent} from './order/order-list/order-list.component';
import {MaintenanceComponent} from '@myshared/maintenance/maintenance/maintenance.component';
import {PhonenumberListComponent} from './phonenumber/phonenumber-list/phonenumber-list.component';
import {CustomerDetailsComponent} from './customer/customer-details/customer-details.component';
import {CustomerContactsComponent} from './customer/customer-contacts/customer-contacts.component';
import {CustomerSubscriptionsComponent} from './customer/customer-subscriptions/customer-subscriptions.component';
import {InviteComponent} from './invite/invite.component';
import {AuthGuard} from './auth/auth.guard';
import {CompanyGuard} from './company/company.guard';
import {
  CompanyChooseNewExistingComponent
} from './company/company-choose-new-existing/company-choose-new-existing.component';
import {OfferCompleteComponent} from "./buy/offer-complete/offer-complete.component";
import { SelectSubscriptionProductComponent } from './subscription/select-subscription-product/select-subscription-product.component';
import {NfrOnePrepareComponent} from "./buy/nfr-one-prepare/nfr-one-prepare.component";
import {workflowRouting} from "./workflow/workflow-routing";


const routes: Routes = [
  ...workflowRouting, // Adds all workflow
  {path: 'offer/:offer/complete',   component: OfferCompleteComponent},
  {path: 'choose-company', component: CompanyChooseNewExistingComponent, canActivate: [AuthGuard]},
  {path: 'invite', component: InviteComponent },
  {path: 'agreement-management/agreements', component: AgreementListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'agreement-management/agreements/info', component: AgreementListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'agreement-management/agreements/ack', component: AgreementListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'agreement-management/agreements/contract', component: AgreementListComponent,
    canActivate: [AuthGuard, CompanyGuard]},
  {path: 'customers', component: CustomerListComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard] },
  {path: 'customers/add', component: AddCustomerComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard, IsAdminGuard] },
  {path: 'customers/:customerId/contacts', component: CustomerContactsComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]  },
  {path: 'customers/:customerId/contacts/invite', component: ContactInviteComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]  },
  {path: 'customers/:customerId/contacts/add', component: ContactInviteComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]  },
  {path: 'customers/:customerId/details', component: CustomerDetailsComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]  },
  {path: 'customers/:customerId/subscriptions', component: CustomerSubscriptionsComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]  },
  {path: 'company', component: CompanyContactsComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'company/contacts', component: CompanyContactsComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'company/contacts/invite', component: ContactInviteComponent,
    canActivate: [AuthGuard, CompanyGuard, IsAdminGuard]},
  {path: 'company/contacts/:contactId', component: ContactDetailsComponent,
    canActivate: [AuthGuard, CompanyGuard] },
  {path: 'company/details', component: CompanyDetailsComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'company/payment-details', component: CompanyPaymentDetailsComponent,
    canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'become-a-partner', component: PartnerViewComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'maintenance', component: MaintenanceComponent },
  {path: 'partner', component: PartnerViewComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/level', component: PartnerViewComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/profiles', component: ProfileListComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/profiles/add', component: ProfileViewComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/profiles/:profileId', component: ProfileViewComponent,
    canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/how-nfr-one-works', component: NfrOnePrepareComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard, IsPartnerGuard] },
  {path: 'partner/tests', component: TestListComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'partner/test/:testId', component: TestExecuteComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard] },
  {path: 'phonenumbers', component: PhonenumberListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'settings/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'subscriptions', component: SubscriptionListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'subscriptions/my', component: SubscriptionListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'subscriptions/customer', component: SubscriptionListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'subscriptions/pascom-one-migration', component: SubscriptionListComponent,
    canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]},
  {path: 'subscriptions/select-product', component: SelectSubscriptionProductComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'subscriptions/partner/select-product', component: SelectSubscriptionProductComponent, canActivate: [AuthGuard, CompanyGuard, IsPartnerGuard]},
  {path: 'subscriptions/:applianceId', component: SubscriptionViewComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'orders/:subroute', component: OrderListComponent, canActivate: [AuthGuard, CompanyGuard, IsAdminGuard]},
  { path: 'orders', redirectTo: '/orders/quotations' },
  {path: 'support', component: TicketListComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'support/ticket/create', component: CreateTicketComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'support/ticket/create/:applianceId', component: CreateTicketComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: 'support/ticket/:id', component: TicketDetailsComponent, canActivate: [AuthGuard, CompanyGuard]},
  {path: '',   redirectTo: '/subscriptions', pathMatch: 'full'},
  {path: '**',   redirectTo: '/subscriptions', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
