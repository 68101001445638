import {Component, OnDestroy, OnInit} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {SubscriptionService} from "../../../subscription/subscription.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BuyService} from "../../../buy/buy.service";
import {Model} from "../../../app.model";
import {HttpClient} from "@angular/common/http";
import {BankAccount, Contact} from "../../../company/company.model";
import {CompanyService} from "../../../company/company.service";
import {CustomerService} from "../../../customer/customer.service";
import {WorkflowPartnerService} from "../workflow-partner.service";
import {WorkflowPartnerChangeSlaComponent} from "../workflow-partner-change-sla.component";
import {runInAction} from "mobx";

@Component({
  selector: 'app-workflow-partner-one-downgrade-sla',
  templateUrl: './workflow-partner-one-downgrade-sla.component.html',
})
export class WorkflowPartnerOneDowngradeSlaComponent extends WorkflowPartnerChangeSlaComponent implements OnInit, OnDestroy {
  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected subscriptionService: SubscriptionService,
              protected buyService: BuyService,
              protected m: Model,
              protected http: HttpClient,
              protected workflowPartnerService: WorkflowPartnerService,
              protected customerService: CustomerService,
              protected companyService: CompanyService) {
    super(route, router, subscriptionService, buyService, m, http, workflowPartnerService, customerService, companyService);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.initWorkflow("downgrade-sla");

    if (!this.isSepa && this.step === 'payment') {
      this.navigate(['address']);
      return;
    }

    this.initialized = true;
  }

  protected checkSelectedProduct(ifStatement: boolean) {
    return this.model.subscriptionSla.index === this.model.selectedSla.index
      || this.model.subscriptionSla.index < this.model.selectedSla.index
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onDowngradeInfo(e: any) {
    this.navigate(['contact']);
  }

  public onDowngradeInfoBack() {
    // Switch to workflow change cu
    this.router.navigate(['/do', 'change-sla', this.applianceId]);
  }

  public onContact(c: Contact) {
    runInAction(() => {
      this.model.contact = c;
    });
    this.navigate(['address']);
  }

  public onContactBack() {
    this.navigate(['info']);
  }

  public async onInvoiceAddress(e: any) {
    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    await this.createOffer();
    this.navigate(['checkout']);
  }

  public onInvoiceAddressBack() {
    // Switch to workflow change cu
    this.navigate(['contact']);
  }

  public async onPaymentMethod({ bank }: { bank?: BankAccount }) {
    bank.partner_id = +this.model.company.id;
    await firstValueFrom(this.companyService.updateBankAccount(bank));
    await this.createOffer();

    this.navigate(['checkout']);
  }

  public onPaymentMethodBack() {
    this.navigate(['address']);
  }

  public onBuy() {
    this.buyService.confirmPartnerQuotation().subscribe(
      (saleOrder: any) => {
        this.navigate(['complete'])
      }
    );
  }

  public onSummaryBack() {
    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    this.onPaymentMethodBack();
  }

  public onComplete() {
    this.resetWorkflow();
  }

  public get isSepa() {
    return this.model?.saleOrder?.payment_method === 'sepa' || this.model?.subscription?.payment_method === 'sepa';
  }
}
