<app-select-invoice-contact [customer]="selectedCustomer" (selected)="onContactSelected($event)"
                    *ngIf="step === 'select-contact'"></app-select-invoice-contact>
<app-tariff-select (back)="cancelWorkflow()"
                   [currentTariff]="subscription.tariffPlanDetail?.currentPlan"
                   [subscription]="subscription"
                   (selected)="onTariffSelected($event)"
                   *ngIf="subscription && step === 'choose-tariff'"></app-tariff-select>
<app-tariff-place-order
        (back)="onBackTariffDeliveryDate()"
        [selectedTariff]="tariffFlatOffer.tariff_plan"
        [currentTariff]="subscription.tariffPlanDetail.currentPlan"
        (selected)="onSelectTariffDeliveryDate($event)"
        *ngIf="subscription && tariffFlatOffer && step === 'delivery-date'"></app-tariff-place-order>
<!--Partner-->
<app-invoice-address
  [company]="company"
  [onlyEmit]="true"
  [historyBack]="false"
  (backEvent)="onInvoiceBack()"
  [backLabel]="'app_back'" [validateViaApi]="true"
  (next)="onCustomerInvoiceAddressFilled($event)" *ngIf="step === 'customer-invoice-address'"></app-invoice-address>
<app-payment-method
  [company]="company"
  [historyBack]="false"
  [onlyEmit]="true"
  (backEvent)="onPartnerPaymentBack()"
  [backLabel]="'app_back'" (next)="onPaymentMethodSelected($event)"
  *ngIf="step === 'customer-payment-method'"></app-payment-method>
<!--is my-->
<app-invoice-address [historyBack]="false" (backEvent)="onInvoiceBack()" [backLabel]="'app_back'"  [validateViaApi]="true" (next)="onInvoiceAddressFilled($event)" *ngIf="step === 'invoice-address'"></app-invoice-address>
<app-payment-method [paymentMethod]="paymentMethod" [historyBack]="false" (backEvent)="onPaymentBack()" [backLabel]="'app_back'" (next)="onPaymentMethodSelected($event)" *ngIf="step === 'payment-method'"></app-payment-method>
<app-checkout-one-summary
  backLabel="app_cancel"
  [historyBack]="false"
  [subscription]="subscription"
  (back)="onSummaryBack()"
  (buy)="onBuy()"
  *ngIf="subscription && step === 'checkout-summary'"></app-checkout-one-summary>
<app-tariff-thankyou
        *ngIf="step === 'thank-you'"
        [applianceId]="applianceId"></app-tariff-thankyou>
<app-buy-thankyou-partner  *ngIf="step === 'thank-you-partner'" [orderName]="orderName"></app-buy-thankyou-partner>
