import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-checkout-phonenumber-summary-price-details',
  templateUrl: './checkout-phonenumber-summary-price-details.component.html',
  styleUrls: []
})
export class CheckoutPhonenumberSummaryPriceDetailsComponent implements OnInit {

  @Input() summary: { quantity: number, price: number, summaryPrice: number };
  @Input() totalLabel = 'checkout_summary_total';

  constructor() {
  }

  ngOnInit() {
  }

  getCalculatedVat() {
    return this.summary.summaryPrice * 0.19;
  }

  getCalculatedSummary() {
    return this.getCalculatedVat() + this.summary.summaryPrice;
  }
}
