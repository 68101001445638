import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Country} from './country.model';
import {I18NextService} from 'angular-i18next';
import {catchError, map} from 'rxjs/operators';
import {Model} from '../app.model';
import {runInAction} from 'mobx';
import {EMPTY, Observable, of} from 'rxjs';


@Injectable()
export class CountryService {

  private needsRefresh = true;

  constructor(
    private httpClient: HttpClient,
    private i18n: I18NextService,
    private m: Model) { }

  /**
   * Trace the users country over cloudflare
   * It will always return a statement with traced country, or default value DE
   * Errors will be caught, and returns an observable with standard value DE
   */
  public traceCountry(): Observable<string> {
    return this.httpClient.get('https://www.cloudflare.com/cdn-cgi/trace', {responseType: 'text'}).pipe(
      map(r => {
        let matches = r.match(/loc=([A-Z]{2})/i)
        if (matches !== null && matches.length > 1) {
          return matches[1]
        }
        return 'DE'
      }),
      catchError(() => of('DE'))
    );
  }

  public refresh(force: boolean = false): void {
    if (this.needsRefresh || force) {
      const dachCountries = [];
      this.httpClient.get<any[]>('/services/countries', {
        params: {
          lang: this.i18n.t('language_code') as string,
        }
      }).pipe(map((lst: [])  => {
        const countries = lst.map(v => Country.fromJson(v));
        return countries.filter(c => {
          if (c.code === 'AT' || c.code === 'CH' || c.code === 'DE') {
            dachCountries.push(c);
            return false;
          }
          return true;
        });
      }))
        .subscribe(lst => runInAction(() => {
          this.m.country.countries = [...dachCountries, ...lst];
          this.needsRefresh = false;
        }));
    }
  }
}
