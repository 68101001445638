import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';

@Component({
  selector: 'app-phonenumber',
  templateUrl: './phonenumber.component.html'
})
export class PhonenumberComponent extends AutorunComponent implements OnInit {

  @Output() public selected = new EventEmitter<string>();
  @Output() public back = new EventEmitter<void>();

  @Input() public hideBackButton = false;

  public tenantCountryCode: string;

  readonly PC_ONE_NEW_NUMBER = 'PC-ONE-NEW-NUMBER';

  accepted: boolean;
  public phonenumber: string;

  constructor(private m: Model) {
    super();
  }

  ngOnInit(): void {
    this.autorun(() => {
      // search for a phone number on the order
      this.phonenumber = this.getPhonenumber();
      this.tenantCountryCode = this.m.account.currentUser.trunkEnvironment.tenantCountryCode;
    });
  }

  accept(v: boolean) {
    this.accepted = v;
  }

  onBuy() {
    this.selected.emit('');
  }

  /**
   * Get a single phone number after reservation
   *
   * Fallback situation: When it comes to a side refresh, we will loose the block number data, in this case
   * we will parse the string text on the order lines.
   */
  getPhonenumber(): string {
    for (const line of this.m.buy.currentOrder.lines) {
      if (line.product === this.PC_ONE_NEW_NUMBER) {
        const matches = line.name.match(/(\d+)/);
        if (matches) {
          return matches[0];
        }
      }
    }

    return '';
  }

  public backEmitted() {
    this.back.emit();
  }

  public get testPhoneNumberHint() {
    const tenantCountryCode = this.tenantCountryCode.toLowerCase();
    return `test_phonenumber_hint_${tenantCountryCode}`;
  }

  public get acceptTermsText() {
    const tenantCountryCode = this.tenantCountryCode.toLowerCase();
    return `test_phonenumber_accept_terms_${tenantCountryCode}`;
  }

}
