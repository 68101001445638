<div *ngIf="blockOffer" class="card card-nb h-100 selectable" (click)="select()"
     [ngClass]="{ 'selected': this.isSelected }">
  <div class="card-body pb-0">
    <div class="d-flex">
      <div class="align-items-start mr-3">
        <i class="far fa-check-circle icon-size-bigger-plus" *ngIf="isSelected"></i>
        <i class="far fa-circle icon-size-bigger-plus" *ngIf="!isSelected"></i>
      </div>
      <div class="flex-grow-1 mr-3 mt-2">
        <app-phonenumber-block *ngIf="isBlock" [from]="blockOffer.fromNumber"
                               [to]="blockOffer.toNumber" [length]="blockOffer.blockSize"></app-phonenumber-block>
        <app-phonenumber-single *ngIf="!isBlock" [from]="blockOffer.fromNumber"></app-phonenumber-single>
      </div>
      <div class="align-items-end">
        <i *ngIf="isBlock" class="fa fa-phone-square-alt icon-size-bigger-plus" [ngClass]="{ 'red': !isSelected }"></i>
        <i *ngIf="!isBlock" class="fa fa-phone-alt icon-size-bigger" [ngClass]="{ 'red': !isSelected }"></i>
      </div>
    </div>
    <div class="row subinformations">

      <div class="col-12 col-sm-4 p-0 m-0">
        <div class="d-flex align-items-end flex-column h-100 mt-2">
        </div>
      </div>
    </div>
  </div>
</div>

