import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {pcDate} from "../date.service";

@Pipe({
  name: 'localized_date'
})
@Injectable()
export class LocalizedDatePipe implements PipeTransform {

  constructor() {}

  transform(value: any): any {
    return pcDate(value).formatDate();
  }
}
