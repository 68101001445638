import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Model} from '../../../app.model';
import {AutorunComponent} from '../../autorun.component';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent extends AutorunComponent implements OnInit {
  public isChecking: boolean;
  public isScheduled: boolean;
  public isOutsideRouter = false;

  constructor(private router: Router, private m: Model) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      if (this.m.maintenance.isReachable) {
        this.router.navigate(['/subscriptions', 'my']);
      }

      this.isChecking = this.m.maintenance.isChecking;
      this.isScheduled = this.m.maintenance.isScheduledMaintenance;
    });
  }
}
