import { Component, EventEmitter, OnInit, Output, AfterViewInit, Input } from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { Model } from '../../app.model';
import { CustomerService } from '../../customer/customer.service';
import { Customer } from '../../customer/customer.model';
import { DynamicFormControlModel, DynamicFormLayout } from '@ng-dynamic-forms/core';
import { FormGroup } from '@angular/forms';
import { CountryService } from '../../country/country.service';
import { PCFormsService, PCFormBuilder } from '@myshared/pcform.service';
import { CompanyService } from '../../company/company.service';
import { filterText } from '@myshared/utils';
import { action, observable } from '@myshared/mobx-connector';
import { MessageService } from 'primeng/api';
import { I18NextService } from 'angular-i18next';
import { TFunctionDetailedResult } from "i18next";
import { companyForm, getCompanyFromForm } from "../../company/company.form";
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html'
})
export class SelectCustomerComponent extends AutorunComponent implements OnInit, AfterViewInit {

  public customers: Customer[];

  public multiselectableCustomer: { label: string; value: Customer }[] = [];
  public selectedCustomer: Customer;
  public isCreating = false;
  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;
  is20CSP: boolean;

  @observable private filter$: string;

  @Input() public stepsBack = 1;
  @Input() public reloadAfterCreated = true;
  @Input() public emitAfterCreated = true;
  @Input() public historyBack = true;

  @Output() public selected = new EventEmitter<Customer>();
  @Output() public back = new EventEmitter<void>();

  private builder: PCFormBuilder;

  constructor(private m: Model,
    private customerService: CustomerService,
    private pcFormService: PCFormsService,
    private countryService: CountryService,
    private companyService: CompanyService,
    private messageService: MessageService,
    private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    this.doFilter('');
    this.countryService.traceCountry().subscribe(code => {
      this.builder = this.pcFormService.newBuilder('company');
      this.builder.addTextInput({
        id: 'name',
        elementClass: {
          container: 'form-group-container'
        },
        autoFocus: true,
        validators: { required: null }
      });
      this.builder.addTextInput({
        id: 'street',
        elementClass: {
          container: 'form-group-container'
        },
        validators: { required: null }
      });
      this.builder.addTextInput({
        id: 'street2',
        elementClass: {
          container: 'form-group-container'
        },
      });
      this.builder.addTextInput({
        id: 'zip',
        elementClass: {
          container: 'form-group-container'
        },
        validators: { required: null }
      });
      this.builder.addTextInput({
        id: 'city',
        elementClass: {
          container: 'form-group-container'
        },
        validators: { required: null }
      });

      // ng-dynamic-bootstrap cannot disable select. If we have a pascom one, we will show a disabled
      // input with germany as location on it.
      this.builder.addSelect({
        id: 'country',
        validators: { required: null },
      }, this.m.country.asDropdown, code);
      this.formModel = this.builder.model;
      this.formLayout = this.builder.layout;
      this.formGroup = this.builder.createFormGroup();
      this.builder?.autoFocus();
    })

    this.autorun(() => {
      this.is20CSP = this.m.account.currentUser.companyIsPC20Csp;
      this.customers = filterText(this.m.customer.customers, this.filter$);
      this.multiselectableCustomer = this.customers.map(c => {
        return {
          label: c.name,
          value: c
        }
      });
      if (this.multiselectableCustomer.length <= 0) {
        this.isCreating = true;
      } else {
        this.isCreating = false;
      }
    });

    this.countryService.refresh();
    this.companyService.refresh();
    this.customerService.refresh(true);
  }

  ngAfterViewInit() {
    this.builder?.autoFocus();
  }

  @action doFilter(value: string) {
    this.filter$ = value;
  }

  addCustomer() {
    const data = this.formGroup.value['company'];
    const country = data.country;

    const c = new Customer(
      '',
      trimInput(data.name),
      trimInput(data.street),
      trimInput(data.street2),
      trimInput(data.zip),
      trimInput(data.city),
      this.m.country.countries.find(c => country == c.code),
      []
    );
    this.customerService.create(c).subscribe(created => {
      if (this.reloadAfterCreated) {
        this.customerService.refresh(true);
      }
      if (this.emitAfterCreated) {
        this.selected.emit(created);
      } else {
        this.messageService.add({
          severity: 'success',
          summary: this.i18next.t('success') as string,
          detail: this.i18next.t('costumer_created', { name: created.name }) as string
        });
        this.formGroup.reset();
        this.selectedCustomer = created;
        this.next();
      }
    });
  }

  selectCustomer() {
    if (!this.selectedCustomer) return;
    this.next();
  }

  private next() {
    this.selected.emit(this.selectedCustomer);
  }

  /**
   * We need to check if this is a pascom one license workflow
   * of a classic annual workflow, because this view is for both
   */
  public get isOne() {
    return this.m.workflow.isOneTrial || this.m.workflow.isOneUpgrade;
  }

  public onAddCustomer() {
    this.isCreating = true;
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();
    this.builder?.autoFocus();
  }

}
