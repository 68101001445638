<div ngbDropdown display="dynamic" placement="bottom-right">
    <button class="btn bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
        <i class="far fa-user-circle red icon-size-big navbar-profile-icon"></i>&nbsp;
        <span class="navbar-profile-text d-none d-sm-inline">{{ name }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div class="dropdown-menu-headline pt-1 px-2 pb-1">{{ 'account' | i18next }}</div>
        <div class="px-2">
            <span class="navbar-profile-text">{{ name }}</span>
        </div>
        <div class="pl-2">
            <a [href]="accountManagerUrl" target="_pascom_manage_account">
                <span class="mr-2">{{ 'manage_account' | i18next }}</span>
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
        </div>
        <div class="profile-menu divider"></div>
        <div class="dropdown-menu-headline px-2 pb-1">{{ 'mypascom_settings' | i18next }}</div>
        <button class="dropdown-item p-2" (click)="openSettings()" *ngIf="isUserInCompany">
            <span class="d-flex justify-content-between">
                <i class="fa-regular fa-user icon-size-big mr-2"></i>
              <span>{{ 'profile' | i18next }}</span>
            </span>
        </button>
        <app-choose-language></app-choose-language>
        <div class="profile-menu divider"></div>
        <button class="dropdown-item p-2" (click)="logout()">
            <span class="d-flex justify-content-between">
              <i class="fas fa-sign-out-alt icon-size-big mr-2"></i>
              <span>{{ 'app_logout' | i18next }}</span>
            </span>
        </button>
    </div>
</div>
