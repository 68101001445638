<div class="d-flex flex-column h-100">
  <app-content-header [stepsBack]="2" (backEvent)="back()"
                      [headline]="'product_select_headline' | i18next"></app-content-header>

  <div class="col text-align-center mb-5">
    <div class="h3 red">{{ 'product_select_subheadline' | i18next }}</div>
    <div *ngIf="!isFree">{{ 'product_select_content' | i18next: {option: currentProduct, days: remainingDays} }}</div>
    <div
      *ngIf="!isFree && !isBasic && currentSlaProduct">{{ 'sla_product_select_content' | i18next: {slaOption: currentSlaProduct | i18next} }}</div>
  </div>
  <div class="row mt-2 justify-content-around">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5" *ngIf="basic">
      <div class="price-card card card-nb normal upgrade">

        <div class="price-card-header upgrade">
          <div class="d-flex align-items-center flex-column">
            <div class="subscription-type h3">{{ basic.name }}</div>
            <div class="subscription-description">{{ basic.description }}</div>
            <div class="subscription-price mt-auto" *ngIf="isBasic && upgradePrice !== 0">
              <div class="d-flex flex-column flex-md-row align-items-baseline">
                <span class="price">{{ upgradePrice | localized_currency: {vatMarker: true} }}</span>
                <span class="time-billing m-auto m-md-2">{{ 'product_select_upgrade_price' | i18next }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="price-card-body upgrade">
          <div class="concurrent-user-hint">
            {{'product_select_concurrent_user' | i18next}}<a href="{{concurrentUserLink}}" target="_blank"><i
            class="far fa-question-circle concurrent-user-icon"></i></a>
          </div>
          <div class="d-flex justify-content-center concurrent-user">
            <div class="form-group-container">
              <select [disabled]="lockDropdowns" class="form-control select" [(ngModel)]="selectedBasicProduct"
                      (ngModelChange)="onBasicProductChange($event)">
                <option *ngFor="let p of basic.products" value="{{p.name}}">
                  {{(p.name !== 'NONE') ? ('price_licenses' |  i18next: {users: p.users}) : ((isCurrentBasic && !isFree) ? ('current_licenses' |  i18next: {users: currentUsers}) : ('product_please_select' | i18next)) }}
                </option>
              </select>
            </div>
          </div>

          <div class="container" *ngIf="basic && basic.features && basic.features.length <= 3">
            <div class="row justify-content-center features">
              <div class="features-item col-6">
                <div *ngFor="let f of basic.features">
                  <div class="d-flex align-items-center">
                    <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
                    <span>{{ f.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container" *ngIf="basic && basic.features && basic.features.length > 3">
            <div class="row features">
              <div class="features-item col-6" *ngFor="let f of basic.features">
                <div class="d-flex align-items-center">
                  <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
                  <span>{{ f.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center price-card-footer flex-column h-100">
          <div class="mb-auto">&nbsp;</div>
          <div>
            <button class="btn btn-primary" (click)="onNext()"
                    [disabled]="selectedBasicProduct === 'NONE'">{{ basic.btnText }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5" *ngIf="premium">
      <div class="price-card card card-nb normal upgrade">

        <div class="price-card-header upgrade">
          <div class="d-flex align-items-center flex-column">
            <div class="subscription-type h3">{{ premium.name }}</div>
            <div class="subscription-description">{{ premium.description }}</div>
            <div class="subscription-price mt-auto" *ngIf="!isBasic && upgradePrice !== 0">
              <div class="d-flex flex-column flex-md-row align-items-baseline">
                <span class="price">{{ upgradePrice | localized_currency:{vatMarker: true} }}</span>
                <span class="time-billing m-auto m-md-2">{{ 'product_select_upgrade_price' | i18next }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="price-card-body upgrade">
          <div class="concurrent-user-hint" *ngIf="premium && premium.products && premium.products.length > 1">
            {{'product_select_concurrent_user' | i18next}}<a href="{{concurrentUserLink}}" target="_blank"><i
            class="far fa-question-circle concurrent-user-icon"></i></a>
          </div>
          <div class="d-flex justify-content-center concurrent-user"
               *ngIf="premium && premium.products && premium.products.length > 1">
            <div class="form-group-container">
              <select class="form-control select" [disabled]="lockDropdowns" [(ngModel)]="selectedPremiumProduct"
                      (ngModelChange)="onPremiumProductChange($event)">
                <option *ngFor="let p of premium.products" value="{{p.name}}">
                  {{(p.name !== 'NONE') ? ('price_licenses' |  i18next: {users: p.users}) : ((isCurrentPremium) ? ('current_licenses' |  i18next: {users: currentUsers}) : ('product_please_select' | i18next))}}
                </option>
              </select>
            </div>
          </div>

          <div class="concurrent-user-hint" *ngIf="premium && premium.slaProducts && premium.slaProducts.length > 1">
            {{'product_select_sla_option' | i18next}}<a href="{{slaOptionLink}}" target="_blank"><i
            class="far fa-question-circle concurrent-user-icon"></i></a>
          </div>
          <div class="d-flex justify-content-center sla-option"
               *ngIf="premium && premium.slaProducts && premium.slaProducts.length > 1">
            <div class="form-group-container">
              <select class="form-control select" [disabled]="lockDropdowns" [(ngModel)]="selectedSlaProduct"
                      (ngModelChange)="onPremiumProductChange($event)">
                <option *ngFor="let p of premium.slaProducts" value="{{p.name}}">
                  {{(p.name !== 'NONE') ? (p.name | i18next) : (currentSlaProduct ? ('current_sla' | i18next: {sla: currentSlaProduct | i18next}) : ('product_please_select' | i18next)) }}
                </option>
              </select>
            </div>
          </div>

          <div class="container" *ngIf="premium && premium.features && premium.features.length <= 3">
            <div class="row justify-content-center features">
              <div class="features-item col-6">
                <div *ngFor="let f of premium.features">
                  <div class="d-flex align-items-center">
                    <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
                    <span>{{ f.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container" *ngIf="premium && premium.features && premium.features.length > 3">
            <div class="row features">
              <div class="features-item col-6" *ngFor="let f of premium.features">
                <div class="d-flex align-items-center">
                  <i class="fas fa-plus-circle features-icon" [ngClass]="{'red': f.highlighted}"></i>
                  <span>{{ f.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center price-card-footer flex-column h-100">
          <div class="mb-auto">&nbsp;</div>
          <div>
            <button class="btn btn-primary" (click)="onNext()"
                    [disabled]="(selectedPremiumProduct === 'NONE' && selectedSlaProduct === 'NONE') || ( (selectedPremiumProduct === 'NONE' || selectedSlaProduct === 'NONE') && currentProduct.includes('BASIC')) || (!currentSlaProduct && selectedSlaProduct === 'NONE')">
              {{ premium.btnText }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="text-align-center mt-5 mb-5">
    <div>{{ 'product_select_info' | i18next }}
    </div>
    <div *ngIf="isPartner">{{ 'product_select_partner_discount_not_included' | i18next }}</div>
  </div>
  <div class="ml-auto mt-auto p-3 small">{{ 'vat_not_included' | i18next }}</div>
</div>
