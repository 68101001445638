import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  getPortNumberFormData,
  getPortNumber,
  portSingleNumberForm
} from '../../phonenumber-port-form';
import {
  PortNumberBlockDetails,
  PortNumberBlockDetailsWithPreview
} from '../../port-phonenumber.model';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-phonenumber-port-one-number',
  templateUrl: './phonenumber-port-one-number.component.html'
})
export class PhonenumberPortOneNumberComponent implements OnInit {

  @Output() public readonly selected = new EventEmitter<PortNumberBlockDetails[]>();
  @Output() public readonly onBack = new EventEmitter();

  @Input()
  trunkPrefix: string;
  @Input()
  countryCode: string;

  public formModel: DynamicFormControlModel[];
  public formGroup: FormGroup;
  public formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;


  constructor(public pcFormService: PCFormsService,
              public messageService: MessageService,
              public i18next: I18NextService) {
  }

  ngOnInit(): void {
    this.changeForm();
  }

  public back() {
    this.onBack.emit();
  }

  public next() {
    const blockDetailsFormData = getPortNumberFormData(this.formGroup, this.trunkPrefix) as PortNumberBlockDetailsWithPreview;
    blockDetailsFormData.previewNumber = this.formData.get('previewNumber').value;
    // get the disabled area code value because parent cannot read the value of disabled controls
    // We need to transform area code to an int (+someValue+'' = deletes leading 0) and back to a string for the object
    blockDetailsFormData.area_code = +this.formData.get('areaCode').value + '';

    // Transform to array. We do need only one number, but we need to have an array for the API port form generator
    const numbers: PortNumberBlockDetailsWithPreview[] = [];
    numbers[0] = blockDetailsFormData;

    this.selected.next(numbers);
  }

  private setPreviewPhoneNumber(formData: AbstractControl, preview: PortNumberBlockDetails) {
    this.patchPreviewValuesForSingleNumber(preview, formData);
  }

  private patchPreviewValuesForSingleNumber(preview: PortNumberBlockDetails, formData: AbstractControl) {
    const areaCode = this.formData.get('areaCode').value;
    // +areaCode (+) will transform string into number on the output of the condition
    // to be sure to have no leading 0 for area code
    // E.g. area code: 030 on input transformed into 30 for preview.
    const previewFrom = preview.country_prefix + ' '
      + (+areaCode > 0 ? +areaCode : '') + ' '
      + preview.main_number;
    formData.get('previewNumber').patchValue(previewFrom, {emitEvent: false});
  }

  private changeForm() {
    this.builder = portSingleNumberForm(this.pcFormService, this.countryCode.toLowerCase());
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    const countryCodeFormatted = this.trunkPrefix.replace(/^00/, '+');

    const blockDetailsPreview = new PortNumberBlockDetails();
    this.formGroup.valueChanges.subscribe((r: any) => {
      this.setPreviewPhoneNumber(this.formData, getPortNumber(blockDetailsPreview, r, countryCodeFormatted));
    });

    this.formData.patchValue({
      'countryPrefix': countryCodeFormatted
    }, {emitEvent: false});
  }

  private get formData() {
    return this.formGroup.get('port_number').get('row1');
  }

}
