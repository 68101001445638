<div class="d-flex flex-column h-100">
  <app-content-header [stepsBack]="1" [historyBack]="false" (backEvent)="onBack()"
                      [headline]="!isCloudMigration ? ('upgrade_one_select_cu'| i18next)
                      : ('cloud_migration_select_cu') | i18next: { users: subscription.users }"></app-content-header>

  <div class="mb-5"><i class="far fa-question-circle red"></i>
    <span class="ml-1" [innerHTML]="'upgrade_one_what_is_cu' | i18next"></span>
  </div>

  <div class="d-flex justify-content-center">
    <div class="price-card w-sm-100 w-md-50 product card card-nb one-upgrade"
         [ngClass]="{ 'normal': !product.highlighted, 'highlight': product.highlighted }">
      <div class="d-flex flex-column">
        <div class="price-card-header product one-upgrade">
          <div class="d-flex align-items-center flex-column">
            <div class="subscription-price mt-auto">
              <div class="price">{{ calculatedSumPrice | localized_currency:{vatMarker: true} }}</div>
              <div
                class="time-billing product">{{ "upgrade_one_billing_info" |  i18next: {cuPrice: calculatedCuPrice | localized_currency:{vatMarker: true} } }}</div>
            </div>
          </div>
        </div>

        <div class="price-card-body product one-product one-upgrade full-with-min-h mt-5">
          <p-inputNumber [(ngModel)]="cu" mode="decimal"
                         (ngModelChange)="onCuChanged($event)"
                         [showButtons]="true"
                         [min]="this.cuMin" [max]="this.cuMax"
                         [format]="false"
                         [size]="4"
                         (onBlur)="onCuBlur($event)"
                         buttonLayout="horizontal" inputId="minmax-buttons"
                         decrementButtonClass="p-button-primary"
                         incrementButtonClass="p-button-primary"
                         incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"></p-inputNumber>
          <div class="cu-info mt-3 mb-3">
            <div class="red" *ngIf="cuError !== ''">{{ "upgrade_one_cu_error_" + cuError | i18next:{
              cuMin: cuMin,
              cuMax: cuMax
            } }}</div>
          </div>
          <div class="alert alert-warning mb-3" *ngIf="subscription.isAnnualCloud && subscription.isOneAppliance && cu < +subscription.users" [innerHTML]="'upgrade_one_annual_cloud_fewer_cu_warning' | i18next"></div>
          <div class="container mb-3" *ngIf="product.features.length">
            <div class="d-flex justify-content-center features">
              <div class="d-flex flex-column align-items-start">
                <div *ngFor="let f of calculatedProduct">
                  <div class="d-flex align-items-center">
                    <i class="fas fa-check product-features-icon"></i>
                    <span>{{ f.value }} {{ f.translation | i18next }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-alert-message *ngIf="subscription.isOne && cu !== subscriptionUsers" class="text-align-block" [alertType]="cu < subscriptionUsers ? 'warning' : 'success'">
            <div>
              <i class="mr-3" [ngClass]="{ 'fa fa-level-down': cu < subscriptionUsers, 'fa-solid fa-arrow-trend-up': cu > subscriptionUsers }"></i>
              <span [innerHTML]="(cu < subscriptionUsers ? 'downgrade_users_hint' : 'upgrade_users_hint') | i18next: { newCU: cu, oldCU: subscriptionUsers }"></span>
            </div>
          </app-alert-message>
        </div>
        <div class="price-card-footer">
          <button [name]="'select-' + product.name" class="btn btn-primary"
                  [disabled]="cuError || (subscription.isOne && subscriptionUsers === cu)"
                  [class]="{ 'btn-primary': product.highlighted, 'btn-outline-primary' : !product.highlighted }"
                  (click)="onNext()">
            {{ translationBtnText | i18next }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="ml-auto mt-auto p-3 small">{{ 'vat_not_included' | i18next }}</div>
</div>
