import {Component, OnInit} from '@angular/core';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {customerForm, getCustomerFromForm} from '../customer.form';
import {Model} from '../../app.model';
import {CountryService} from '../../country/country.service';
import {CustomerService} from '../customer.service';
import {Router} from '@angular/router';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: []
})
export class AddCustomerComponent implements OnInit {
  private builder: PCFormBuilder;

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  constructor(private m: Model,
              private countryService: CountryService,
              private customerService: CustomerService,
              private router: Router,
              private pcFormService: PCFormsService) {
  }

  async ngOnInit() {
    this.countryService.traceCountry().subscribe(code => {
      this.builder = customerForm(this.pcFormService, this.m.country.asDropdown, code);
      this.formModel = this.builder.model;
      this.formLayout = this.builder.layout;
      this.formGroup = this.builder.createFormGroup();
      this.builder?.autoFocus();
    })

    this.countryService.refresh();
  }

  onSave() {
    const c = getCustomerFromForm(this.formGroup, this.m.country.countries, null);
    this.customerService.create(c).subscribe({
      next: () => {
        this.router.navigate(['/customers'])
      },
      error: (e) => {
        console.log('Error!', e)
      }
    });
  }


}
