import {Routes} from "@angular/router";
import {WorkflowCspOneUpgradeSlaComponent} from "./workflow-csp-one-upgrade-sla.component";

export const workflowCspOneUpgradeSlaRouting: Routes = [
    { path: 'csp/upgrade-sla/:aid', component: WorkflowCspOneUpgradeSlaComponent, children: [
            { path: 'address', component: WorkflowCspOneUpgradeSlaComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowCspOneUpgradeSlaComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowCspOneUpgradeSlaComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowCspOneUpgradeSlaComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'address', pathMatch: 'full' },
    ]},
]
