import { Component, OnInit } from '@angular/core';
import {Agreement} from '../agreement.model';
import {Model} from '../../app.model';
import {AgreementService} from '../agreement.service';
import {AutorunComponent} from '@myshared/autorun.component';

@Component({
  selector: 'app-agreement-free-idle-warning',
  templateUrl: './agreement-free-idle-warning.component.html',
  styleUrls: []
})
export class AgreementFreeIdleWarningComponent extends AutorunComponent implements OnInit {

  public agreement: Agreement;

  constructor(
    private m: Model,
    private agreementService: AgreementService,
  ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.agreement = this.m.agreement.activeAgreement;
    });
  }

  public keepInstance(keep: boolean) {
    this.agreementService.keepInstance(this.agreement.id, keep);
  }
}
