import {Routes} from "@angular/router";
import {WorkflowEndcustomerOneUpgradeSlaComponent} from "./workflow-endcustomer-one-upgrade-sla.component";

export const workflowEndcustomerOneUpgradeSlaRouting: Routes = [
    { path: 'upgrade-sla/:aid', component: WorkflowEndcustomerOneUpgradeSlaComponent, children: [
            { path: 'address', component: WorkflowEndcustomerOneUpgradeSlaComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowEndcustomerOneUpgradeSlaComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowEndcustomerOneUpgradeSlaComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowEndcustomerOneUpgradeSlaComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'address', pathMatch: 'full' },
    ]},
]
