import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Model} from '../../app.model';

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
  private readonly initialWaitingTime = 10000; // How long to wait for the first API available check
  private readonly checkingTime = 10000; // Interval time to check the API side
  private readonly redirectLinkTimeout = 60000; // How long it will takes to show the manuel refresh Button
  private readonly checkingTimeout = 600000; // How long the complete check takes

  constructor(private router: Router, private m: Model) {}

  public reconnect(checkUrl: string) {
    this.m.maintenance.setApiUnreachable();
    this.router.navigate(['/maintenance']);
    this.doReconnect(checkUrl);
  }

  public scheduledMaintenance(statusUrl: string) {
    this.m.maintenance.setStatusUrl(statusUrl);
    this.m.maintenance.setScheduledMaintenance();
    this.router.navigate(['/maintenance']);
  }

  public refreshApplication(url?: string) {
    const redirectUrl = url ? url : window.location.origin;
    window.location.replace(redirectUrl);
  }

  doReconnect(checkUrl: string) {
    const self = this;
    this.m.maintenance.setApiChecking();

    let mainRefreshTimeout = null;

    // This timeout will define, how long we will try to reconnect to the api
    const rebootTimeout = setTimeout(() => {
      if (!this.m.maintenance.isReachable) {
        if (mainRefreshTimeout !== null) {
          // if we have a main reboot timer, delete this one
          clearTimeout(mainRefreshTimeout);
        }
        this.m.maintenance.setApiCheckFailed();
      }
    }, this.checkingTimeout);

    // This timeout define, how long it will take to show a manuel refresh button
    const manualLinkTimeout = setTimeout(() => {
      if (!this.m.maintenance.isReachable) {
        this.m.maintenance.showRefreshButton();
      }
    }, this.redirectLinkTimeout);

    // The main timeout function to check the API on specified intervals
    const mainRefresh = () => {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === XMLHttpRequest.DONE) {
          if (xmlhttp.status && xmlhttp.status < 300) {
            // the main reboot timeout
            self.m.maintenance.setApiAvailable();
            // if the main reboot timeout get a request from the server, delete our timeout for the manual link
            clearTimeout(rebootTimeout);
            clearTimeout(manualLinkTimeout);

            self.refreshApplication(window.location.hostname);
          } else {
            mainRefreshTimeout = setTimeout(mainRefresh, self.checkingTime);
          }
        }
      };

      xmlhttp.open('GET', checkUrl, true);
      xmlhttp.setRequestHeader('Cache-Control', 'no-cache');
      xmlhttp.setRequestHeader('Pragma', 'no-cache');
      xmlhttp.setRequestHeader('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
      xmlhttp.setRequestHeader('If-Modified-Since', '0');
      xmlhttp.send();
    };

    // Give the host system enough time to actually shut down
    setTimeout(mainRefresh, this.initialWaitingTime);
  }
}
