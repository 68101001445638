import {runInAction} from 'mobx';
import {action, observable, computed} from '@myshared/mobx-connector';
import {pcDate} from "../date.service";

export enum AgreementType {
  Info = 'info-all',
  Ack = 'ack-all',
  Contract = 'affiliate-contract',
  FreeIdleWarning = 'free-idle-warning',
  SipTrunkTestAccount = 'siptrunk-testaccount'
}

export class Agreement {

  constructor(
    public id: number,
    public type: string,
    public acknowledged: string,
    public fromDate: string,
    public untilDate: string,
    public subject: string,
    public info: string,
    public userEmail: string,
    public attachments: Attachment[],
    public data: any,
    public result: any
  ) {}

  public static fromJson(json: any): Agreement {
    return new Agreement(
      json.id || 0,
      json.type || '',
      json.acknowledged || '',
      json.from_date || '',
      json.until_date || '',
      json.subject || '',
      json.info || '',
      json.user_email || '',
      (json.attachments || []).map(v => Attachment.fromJson(v)),
      json.data || '',
      json.result || ''
    );
  }

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.subject)
      || predicate(this.info);
  }

  public get needsCheck(): boolean {
    return this.type == AgreementType.Ack
      || this.type == AgreementType.Contract;
  }

  public get isOutdated(): boolean {
    return (this.untilDate && pcDate().isAfter(this.untilDate));
  }
}

export class Attachment {
  constructor(
    public id: number,
    public displayName: string,
    public mimeType: string,
  ) {}

  public static fromJson(json: any): Attachment {
    return new Attachment(
      json.id || 0,
      json.display_name || '',
      json.mimetype || ''
    );
  }

  public get downloadName(): string {
    // in case of xml we need to reset the downloadname to pdf, so that the file is not corrupt
    if (this.mimeType === 'application/xml') {
      return this.displayName.split('.')[0] + '.pdf';
    }
    return this.displayName;
  }
}

export class AgreementModel {
  @observable isInitialized: boolean;
  @observable public agreements: Agreement[];

  constructor() {
    runInAction(() => {
      this.agreements = [];
      this.isInitialized = false;
    });
  }

  @computed get activeAgreement(): Agreement {
    return this.agreements.filter(a => (a.type != AgreementType.SipTrunkTestAccount
      && !a.acknowledged && (!a.untilDate || pcDate().isBefore(a.untilDate))))[0];
  }

  @computed get agreementsToAck(): boolean {
    return this.agreements.filter(a => (a.type != AgreementType.SipTrunkTestAccount
      && !a.acknowledged && (!a.untilDate || pcDate().isBefore(a.untilDate)))).length > 0;
  }

  @action setInitialized() {
    this.isInitialized = true;
  }
}
