<app-content-header (backEvent)="onBack()" [historyBack]="historyBack" [hint]="'checkout_summary_hint' | i18next" [headline]="'checkout_summary_headline' | i18next"></app-content-header>

<div class="card card-nb mt-2">
  <div class="card-body">
    <div class="d-flex flex-column flex-md-row" [ngClass]="positionInvoice">
      <div class="card card-gray card-nb card-mw-300">
        <div class="card-body">
          <div class="row">
            <div class="col highlighted">
              <div class="card-h1">{{ 'checkout_summary_invoice_address' | i18next }}</div>
              <div>{{saleOrder.invoice_address.name}}</div>
              <div>{{saleOrder.invoice_address.street}}</div>
              <div>{{saleOrder.invoice_address.street2}}</div>
              <div>{{saleOrder.invoice_address.zip}}&nbsp;{{saleOrder.invoice_address.city}}</div>
              <div>{{saleOrder.invoice_address.country.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-gray card-nb card-mw-300" *ngIf="customerAddress">
        <div class="card-body">
          <div class="row">
            <div class="col highlighted">
              <div class="card-h1">{{ 'checkout_summary_customer_address' | i18next }}</div>
              <div>{{saleOrder.customer_address.name}}</div>
              <div>{{saleOrder.customer_address.street}}</div>
              <div>{{saleOrder.customer_address.street2}}</div>
              <div>{{saleOrder.customer_address.zip}}&nbsp;{{saleOrder.customer_address.city}}</div>
              <div>{{saleOrder.customer_address.country.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex flex-column flex-md-row justify-content-start w-100">
      <div class="mb-3 pr-3"><i class="fa red" [ngClass]="typeIcon"></i><span>&nbsp;{{ type }}</span></div>
      <div class="mb-3 pr-3"><i class="fa fa-user red"></i><span>&nbsp;{{ 'checkout_summary_payment_method' | i18next }}&nbsp;{{ paymentMethod }}</span></div>
    </div>

    <table class="table w-100 table-borderless d-none d-lg-table">
      <thead class="border-bottom border-dark">
      <tr>
        <th>{{ 'checkout_summary_description' | i18next }}</th>
        <th>{{ 'checkout_summary_quantity' | i18next }}</th>
        <th>{{ 'checkout_summary_unit_price' | i18next }}</th>
        <th *ngIf="hasDiscount">{{ 'checkout_summary_discount' | i18next }}</th>
        <th>{{ 'checkout_summary_tax' | i18next }}</th>
        <th>{{ 'checkout_summary_total' | i18next }}</th>
      </tr>
      </thead>
      <tr *ngFor="let line of saleOrder.lines" class="border-bottom">
        <td>{{line.name}}</td>
        <td>{{line.product_uom_qty}}</td>
        <td style="min-width: 90px;">{{line.price_unit | localized_currency}}</td>
        <td *ngIf="hasDiscount">{{line.discount}}%</td>
        <td>
          <span>{{line.tax}}%</span>
        </td>
        <td style="min-width: 90px;">{{line.price_subtotal | localized_currency}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">{{ 'checkout_summary_subscriptions_renewed' | i18next }}</td>
        <td>{{ 'checkout_summary_subtotal' | i18next }}</td>
        <td colspan="2">{{saleOrder.amount_untaxed | localized_currency}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">&nbsp;</td>
        <td>{{'checkout_summary_tax' | i18next }}</td>
        <td colspan="2">{{saleOrder.amount_tax | localized_currency}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">&nbsp;</td>
        <td class="font-weight-bold">{{ 'checkout_summary_total' | i18next }}</td>
        <td colspan="2" class="font-weight-bold text-nowrap">{{saleOrder.amount_total | localized_currency}}</td>
      </tr>
    </table>

    <div class="d-flex flex-column d-lg-none mb-3">
      <div class="border-bottom" *ngFor="let line of saleOrder.lines">
        <div class="p-2 font-weight-bold">{{line.name}}</div>
        <div class="d-flex flex-row">
          <div class="p-2">{{ 'checkout_summary_quantity' | i18next }}</div>
          <div class="p-2 ml-auto">{{line.product_uom_qty}}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2">{{ 'checkout_summary_unit_price' | i18next }}</div>
          <div class="p-2 ml-auto">{{line.price_unit | localized_currency}}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2">{{ 'checkout_summary_discount' | i18next }}</div>
          <div class="p-2 ml-auto">{{line.discount}}%</div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2">{{ 'checkout_summary_tax' | i18next }}</div>
          <div class="p-2 ml-auto">
            <span>{{line.priceTax}}%</span>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-2">{{ 'checkout_summary_subtotal' | i18next }}</div>
          <div class="p-2 ml-auto">{{line.price_subtotal | localized_currency}}</div>
        </div>
      </div>
      <div class="p-2">{{ 'checkout_summary_subscriptions_renewed' | i18next }}</div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2">{{ 'checkout_summary_subtotal' | i18next }}</div>
        <div class="p-2">{{ saleOrder.amount_untaxed | localized_currency }}</div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2">{{ 'checkout_summary_tax' | i18next }}</div>
        <div class="p-2">{{ saleOrder.amount_tax | localized_currency }}</div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2 font-weight-bold">{{ 'checkout_summary_total' | i18next }}</div>
        <div class="p-2 font-weight-bold">{{ saleOrder.amount_total | localized_currency }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center mb-3">
      <app-pascom-checkbox class="align-self-end" [text]="'checkout_summary_accept_terms' | i18next" (acceptChanged)="accept($event)"></app-pascom-checkbox>
      <button class="btn btn-outline-primary" type="submit" (click)="onBuy()"
              [disabled]="!accepted">{{ 'checkout_summary_buy_now' | i18next}}
      </button>
    </div>
  </div>
</div>
