import {Component, Input, OnInit} from '@angular/core';
import {Achievement} from '../../achievements.model';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-partnership-achievement-progress-card',
  templateUrl: './partnership-achievement-progress-card.component.html'
})
export class PartnershipAchievementProgressCardComponent implements OnInit {

  @Input()
  public achievement: Achievement;
  public roundedProgress = true;

  constructor(private i18next: I18NextService) { }

  ngOnInit() {
  }

  get helpContent() {
    if (this.achievement.i18next_help) {
      return this.i18next.t([this.achievement.i18next_help, '']) as string;
    }

    return '';
  }

  get descriptionContent() {
    if (this.achievement.i18next_description) {
      return this.i18next.t([this.achievement.i18next_description, '']) as string;
    }

    return '';
  }

  get progressState() {
    return Math.floor((this.achievement.value / this.achievement.maxValue) * 100);
  }

  get progressClass() {
    return 'progress-' + this.progressState;
  }

}
