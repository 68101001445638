<app-headline-page>
    {{ 'partner_offer_sent' | i18next }}
</app-headline-page>

<app-card>
    <app-fa-icon class="d-flex mb-3 mx-auto" icon="fa-thumbs-up" size="3xl"></app-fa-icon>

    <app-headline-content class="d-flex mx-auto">
        {{ 'partner_upgrade_offer_sent_headline' | i18next: {email: customerEmail} }}
    </app-headline-content>

    <div class="d-flex mx-auto"
         [innerHTML]="'partner_upgrade_offer_sent_content' | i18next: {email: customerEmail}"></div>
</app-card>

<div class="d-flex justify-content-end mt-3">
    <button class="btn btn-outline-primary" (click)="next.emit()">
        {{ 'back_subscription_details' | i18next }}
    </button>
</div>

