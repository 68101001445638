import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from '../subscription.model';
import { AutorunComponent } from '@myshared/autorun.component';
import { Model } from '../../app.model';
import { ActivatedRoute, Router } from '@angular/router';
import { slaProductNames } from 'src/app/buy/select-product/product.model';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: []
})
export class SubscriptionCardComponent extends AutorunComponent implements OnInit {

  private _subscription: Subscription;
  @Input()
  public set subscription(s: Subscription) {
    this._subscription = s;
  }
  public get subscription(): Subscription {
    return this._subscription;
  }

  @Input()
  showSubscriptionDetailLink = false;

  @Input()
  detaillist = false;

  public childCount = 0;
  public isAdmin = false;
  public isPartner: boolean;

  constructor(
    private m: Model,
    private route: ActivatedRoute,
    private router: Router) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.calculateChildCount();
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.isPartner = this.m.account.currentUser.companyIsPartner;
    });
  }

  public get isOneAndCancelled() {
    return this.subscription.isOne && this.subscription.cancelled;
  }

  public get status(): { status: string; color: any } {
    return this.subscription.pbxStatus;
  }

  public get cssClasses() {
    return this.subscription.migratableSubscription && this.isPartner
      ? { 'red': this.subscription.migrationDaysLeft <= 90,
        'text-warning': this.subscription.migrationDaysLeft <= 180 }
      : {};
  }

  private calculateChildCount() {
    if (this.subscription) {
      this.childCount = this.m.subscription.subscriptions.filter(s => s.parent_id === this.subscription.appliance_id).length;
    }
  }

  public showDetails() {
    if(this.canShowDetails) {
      this.router.navigate(['/subscriptions', this._subscription.appliance_id])
    }
  }

  public get canShowDetails() {
    return this.detaillist && this.subscription.isValid;
  }

  private get isUpgradeAvailable(): boolean {
    const maxSlaOption = slaProductNames[slaProductNames.length - 1];
    return this.isAdmin &&
      !this.subscription.isCancelled &&
      !this.subscription.isOnsitePerpetual &&
      !this.subscription.is_nfr &&
      !(this.subscription.appliance_type === 'csp') &&
      (this.subscription.support_level !== 'premium' || (this.subscription.support_level === 'premium' && (this.subscription.users !== '1024' || this.subscription.sla_product !== maxSlaOption)));
  }

  public get canUpgradeAfterExpired() {
    return this.subscription.isOneTrial || this.isUpgradeAvailable || this.subscription.canMigrateCloudAfterExpired;
  }

}
