import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {Output} from '@angular/core';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.css']
})
export class ContentHeaderComponent implements OnInit {

  @Input() public stepsBack = 1;
  @Input() public headline;
  @Input() public hint;
  @Input() public hideBackButton = false;
  @Input() public historyBack = true;
  @Input() public backLabel = 'app_back';
  @Output() backEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public back() {
    this.backEvent.emit();
    if (this.historyBack) {
      if (this.stepsBack === 1) {
        history.back();
      } else {
        history.go(-this.stepsBack);
      }
    }
  }

}
