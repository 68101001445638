import {Component, OnInit} from '@angular/core';
import {Model} from '../../../../app.model';
import {AffiliateService} from '../../affiliate.service';
import {Affiliate} from '../../affiliate.model';
import {Benefits} from '../../benefits.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {pcDate} from "../../../../date.service";

@Component({
  selector: 'app-partnership-view',
  templateUrl: './partnership-view.component.html'
})
export class PartnershipViewComponent extends AutorunComponent implements OnInit {
  public achievements;
  public benefits: Benefits;
  public affiliate: Affiliate;
  public isNextLevelLesserAsActual;
  public hasCompletedSpecialAchievements = false;

  constructor(private m: Model, private affiliateService: AffiliateService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.achievements = this.affiliateService.getAchievements();
      this.hasCompletedSpecialAchievements = this.affiliateService.hasCompletedSpecialAchievements();
      this.benefits = this.affiliateService.getBenefits();
      this.affiliate = this.m.affiliate.affiliate;
      this.isNextLevelLesserAsActual = this.m.affiliate.isNextLevelLesserThanActual;
    });
    this.affiliateService.refresh(true);
  }

  get localizedEndOfPeriodDate() {
    return pcDate(this.affiliate.partner_year_ends).formatDate();
  }

  get remainingPartnerDays() {
    return this.m.affiliate.remainingPartnerDays;
  }

  public get partnerLevelAsString() {
    return this.affiliate.level;
  }

  public get nextPartnerLevelAsString() {
    return this.affiliate.next_level;
  }

  private levelDataWithKey(dataKey: number) {
    for (const level in this.achievements) {
      if (!this.achievements[level][dataKey].completed) {
        return this.achievements[level][dataKey];
      }
    }
  }

  public get revenueProgress() {
    const levelDataWithKey = this.levelDataWithKey(0);
    return Math.floor((levelDataWithKey.value / levelDataWithKey.maxValue) * 100);
  }

  public get subscriptionsProgress() {
    const levelDataWithKey = this.levelDataWithKey(1);
    return Math.floor((levelDataWithKey.value / levelDataWithKey.maxValue) * 100);
  }

  public get partnerYearPercentageElapsed() {
    return (100 - this.m.affiliate.remainingPartnerDays * 100 / this.m.affiliate.partnerYearDays) + '%';
  }

}
