<!--For phone numbers-->
<app-checkout-phonenumber-summary [subscription]="null"
                                  [phoneNumbers]="phoneNumbers"
                                  (buy)="onBuyPhoneNumbers()"
                                  [hideBackBtn]="true"
                                  *ngIf="step === 'phone-numbers-checkout-summary'"></app-checkout-phonenumber-summary>

<app-buy-thankyou-content
  *ngIf="step === 'phone-numbers-thank-you'"
  headline="thank_you_confirm_phonenumber_headline"
  content="thank_you_confirm_phonenumber_content"
  [showBackrouteButton]="false"
></app-buy-thankyou-content>

<!--For upgrading annual cloud to pascom one-->
<app-checkout-one-summary
  (buy)="onBuyUpgrade()"
  [migrationCredit]="migrationCredit"
  [hideBackBtn]="true"
  [subscription]="subscription"
  *ngIf="step === 'upgrade-checkout-summary'"></app-checkout-one-summary>

<!--For upgrading existing pascom one-->
<app-checkout-one-summary
  (buy)="onBuyFlatrate()"
  [hideBackBtn]="true"
  [subscription]="subscription"
  *ngIf="step === 'flatrate-checkout-summary'"></app-checkout-one-summary>

<app-subscription-cancel-customer-confirm
        *ngIf="step === 'cancel-subscription'"
        [applianceId]="saleOrder.aid"
        [applianceName]="saleOrder.applianceName"
        [nextPossibleEndDate]="saleOrder.nextPossibleEndDate"
        (confirm)="onSubscriptionCancelConfirm()"></app-subscription-cancel-customer-confirm>

<app-buy-thankyou-content
  *ngIf="step === 'upgrade-thank-you'"
  headline="thank_you_confirm_upgrade_one_headline"
  content="thank_you_confirm_upgrade_one_content"
  [showBackrouteButton]="false"
></app-buy-thankyou-content>

<app-buy-thankyou-content
  *ngIf="step === 'flatrate-thank-you'"
  headline="thank_you_confirm_tariff_headline"
  content="thank_you_confirm_tariff_content"
  [showBackrouteButton]="false"
></app-buy-thankyou-content>

<app-buy-thankyou-content
        *ngIf="step === 'cancel-done'"
        headline="confirm_cancel_customer_done_headline"
        content="confirm_cancel_customer_done_content"
        [showBackrouteButton]="false"
></app-buy-thankyou-content>

<app-buy-thankyou-content
        *ngIf="step === 'subscription-cancelled' "
        headline="subscription_already_cancelled"
        content="subscription_already_cancelled_content"
        [showBackrouteButton]="false"
></app-buy-thankyou-content>

<div *ngIf="step === 'offer-not-existing' || step === 'offer-cancelled'">
  <div class="content-card-container-bg">
    <div class="content-card-container">
      <div class="content-card text-align-center">
        <div>
          <img src="assets/images/animals/wal.svg" alt="pascom welcome picture">
        </div>
        <div *ngIf="step === 'offer-not-existing'">
          <h1 [innerHTML]="'partner_offer_not_existing_headline' | i18next"></h1>
          <p class="gray" [innerHTML]="'partner_offer_not_existing_content' | i18next"></p>
        </div>
      <div *ngIf="step === 'offer-cancelled'">
          <h1 [innerHTML]="'partner_offer_cancelled_headline' | i18next"></h1>
          <p class="gray" [innerHTML]="'partner_offer_cancelled_content' | i18next"></p>
        </div>
      </div>
    </div>
  </div>
</div>
