import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkflowComponent} from "../../workflow.component";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Model} from "../../../app.model";
import {observable, runInAction} from "mobx";
import {SaleOrder} from "../../../buy/buy.model";
import {firstValueFrom} from "rxjs";
import {BuyService, InstanceOfferData} from "../../../buy/buy.service";
import {AffiliateService} from "../../../partner/affiliate/affiliate.service";
import {MessageService} from "primeng/api";
import {I18NextService} from "angular-i18next";
import {Subscription} from "../../../subscription/subscription.model";
import {SubscriptionService} from "../../../subscription/subscription.service";
import {WorkflowPartnerService} from "../workflow-partner.service";
import {Customer} from "../../../customer/customer.model";
import {Contact} from "../../../company/company.model";

interface WorkflowModel {
  saleOrder?: SaleOrder,
  isTrialNumberSelected?: boolean,
  instanceOffer?: InstanceOfferData,
  customer?: Customer,
  contact?: Contact,
}


@Component({
  selector: 'app-workflow-partner-one-trial-create',
  templateUrl: './workflow-partner-one-trial-create.component.html'
})
export class WorkflowPartnerOneTrialCreateComponent extends WorkflowComponent implements OnInit, OnDestroy {

  @observable
  public model: WorkflowModel;

  private subscriptions: Subscription[];

  // If partner is ordering NFR for him self, he will order trial like end customer with additional info
  public isPartner: boolean;
  private hasTrunkAccess: boolean;

  constructor(protected route: ActivatedRoute,
              private m: Model,
              private router: Router,
              private buyService: BuyService,
              private partnerService: WorkflowPartnerService,
              private affiliateService: AffiliateService,
              private messageService: MessageService,
              private i18next: I18NextService,
              private subscriptionService: SubscriptionService) {
    super(route);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.autorun(() => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.hasTrunkAccess = this.m.account.hasTrunkAccess;
    });

    if (!this.isPartner || this.m.account.currentUser.companyIsPC20Csp) {
      this.resetWorkflow();
      return;
    }

    await this.initWorkflow();

    // Make sure that if we have a sales order, only the phone number view and thank you can be navigated.
    this.router.events.subscribe((r: any) => {
      const whitelistStep = ['phonenumber', 'thank-you'].includes(this.step);
      if (!(r instanceof NavigationEnd)) return;
      if (!!this.model?.saleOrder?.name && !whitelistStep) {
        this.router.navigate(['/subscriptions', this.model.saleOrder.aid], {replaceUrl: true});
        return;
      }
    });

    this.initialized = true;

    // Always start from here after reloading. We will lose our data, and has not so many steps.
    this.navigate(['customer']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private async initWorkflow() {
    const so = this.route.snapshot.queryParamMap.get('so');

    // We need the subscription to check if we have a trial on this account.
    if (!this.subscriptions) {
      this.subscriptions = await firstValueFrom(this.subscriptionService.getSubscriptions());
    }

    // Prepare the Model for this Workflow
    const model: WorkflowModel = {};

    // If we already have a sale order on our purchase model, we load it from the API.
    if (!this.m.buy.currentOrder?.name && so) {
      runInAction(async () => {
        model.saleOrder = await firstValueFrom(this.buyService.getSaleOrder(so));
      })
    }

    runInAction(() => {
      this.model = model
    });
  }

  public onCustomerSelected(customer: Customer) {
    runInAction(() => {
      this.model.customer = customer;
    })

    // Check if we already have a trial on the selected customer.
    if (this.hasOneTrial(this.model.customer.id)) {
      this.messageService.add({
        severity: 'error',
        summary: this.i18next.t('error') as string,
        detail: this.i18next.t('no_more_trial_allowed') as string
      });
      this.router.navigate(['/'], { replaceUrl: true });
      return;
    }

    this.navigate(['contact']);
  }
  public onCustomerBack() {
    this.resetWorkflow();
  }

  public onContactSelected(contact: Contact) {
    runInAction(() => {
      this.model.contact = contact;
    });

    if (this.model.customer.hasTrunkAccess) {
      this.navigate(['trial-phonenumber'], true);
    } else {
      this.navigate(['instance'], true);
    }
  }
  public onContactBack() {
    this.navigate(['costumer']);
  }

  public onTrialPhonenumberSelected(isSelected: boolean) {
    runInAction(() => {
      this.model.isTrialNumberSelected = isSelected;
    });

    this.navigate(['instance']);
  }

  public onTrialPhonenumberBack() {
    this.navigate(['contact']);
  }

  public async onInstanceNameSelected(instanceOfferData: InstanceOfferData) {
    runInAction(() => {
      this.model.instanceOffer = instanceOfferData;
    })

    await this.createOrder();
    this.affiliateService.refresh(true);
  }

  public onSelectInstanceNameBack() {
    if (this.hasTrunkAccess) {
      this.navigate(['trial-phonenumber']);
      return;
    }

    this.navigate(['contact']);
  }

  public onPhonenumberSelected(_: string) {
    this.navigate(['thank-you']);
  }

  private async createOrder() {
    try {
      const saleOrder = await firstValueFrom(this.partnerService.oneTrialCreateOrder({
        instance_name: this.model.instanceOffer.name,
        acquire_phone_number: this.model.isTrialNumberSelected ? 'location_independent_number' : undefined,
        customer_contact_id: this.model.contact.id,
      }));
      runInAction(() => {
        this.model.saleOrder = saleOrder;
        this.m.buy.currentOrder = saleOrder;
      });
      if (this.model.isTrialNumberSelected) {
        this.navigate(['phonenumber'], true);
      } else {
        this.navigate(['thank-you'], true);
      }
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.i18next.t('error') as string,
        detail: this.i18next.t('error_trial_create_sale_order_partner') as string
      });
      throw e;
    }
  }

  private navigate(url: any[], replace = false) {
    this.router.navigate(['/do', 'partner', 'one-trial', ...url], {
      replaceUrl: replace,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: {
        so: this.model?.saleOrder?.name ?? undefined,
      },
    });
  }

  public hasOneTrial(companyId: string) {
    return !!this.subscriptions.find(r => r.isOneTrial && +r.customer_id === +companyId);
  }
}
