import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-phonenumber-type-card',
  templateUrl: './phonenumber-type-card.component.html',
  styleUrls: []
})
export class PhonenumberTypeCardComponent {

  @Input() public typeText: string;
  @Input() public included = 0;
  @Input() public currentlyInUse = 0;
  @Input() public highlighted = false;
  @Input() public setupPrice: number;
  @Input() public monthlyPrice: number;

  public get cssClasses() {
    return { 'normal': !this.highlighted, 'highlight': this.highlighted };
  }
}
