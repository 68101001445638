<div class="d-flex flex-column">
  <div class="d-flex">
    <div class="align-self-start"><i class="fa fa-list-ol red mr-1"></i></div>
    <div>
      <span>{{ 'phonenumber_type_single' | i18next }}</span>
      <span *ngIf="locationIndependent"> {{ 'location_independent_label' | i18next }}</span>
    </div>
  </div>
  <div class="row m-0 p-0">
    <div class="col col-sm-5 m-0 p-0">{{ 'phonenumber' | i18next }}:</div>
    <div class="col col-sm-7 m-0 p-0 text-nowrap">{{ from | phonenumber }}</div>
  </div>
  <div class="row m-0 p-0 mt-2" *ngIf="deliveryDate">
    <div class="col-12 m-0 p-0">{{ 'phonenumber_delivery_date' | i18next }}:</div>
    <div class="col-12 m-0 p-0 ml-auto text-nowrap">{{ deliveryDate | localized_date }}</div>
  </div>
</div>
