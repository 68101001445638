import {Routes} from "@angular/router";
import {WorkflowPartnerOneTrialCreateComponent} from "./workflow-partner-one-trial-create.component";


export const workflowPartnerOneTrialCreateRouting: Routes = [
  {
    path: 'partner/one-trial', component: WorkflowPartnerOneTrialCreateComponent, children: [
      {path: 'customer', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'customer'}},
      {path: 'contact', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'contact'}},
      {path: 'trial-phonenumber', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'trial-phonenumber'}},
      {path: 'instance', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'instance'}},
      {path: 'phonenumber', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'phonenumber'}},
      {path: 'thank-you', component: WorkflowPartnerOneTrialCreateComponent, data: {step: 'thank-you'}},
      {path: '', redirectTo: 'customer', pathMatch: 'full'},
    ]
  },
]
