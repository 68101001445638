<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'affiliate_partnership_headline' | i18next }}</div>
</div>
<app-partnership-navigation></app-partnership-navigation>
<div class="row">
  <h3>{{'partner_test_headline' | i18next}}</h3>
</div>
<div class="row mt-2">
  <app-test-card class="col-12 col-xl-6" *ngFor="let test of tests" [test]="test">
  </app-test-card>
</div>

