<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'affiliate_partnership_headline' | i18next }}</div>
</div>

<div class="row align-items-stretch mb-3">
  <div class="col-xs-12 col-md-12 col-lg-12 mb-sm-3 mb-md-0">
    <div class="card h-100 card-nb card-nm">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12 highlighted">
            <span>{{ 'affiliate_become_partner_headline' | i18next }}</span>
          </div>
        </div>
        <div class="row subinformations">
          <div class="col-12">
            {{ 'affiliate_become_partner_content_text' | i18next }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row align-items-stretch mb-3">
  <div class="col-xs-12 col-md-12 col-lg-12">
    <div class="card h-100 card-nb card-nm">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12 highlighted">
            <span>{{ 'affiliate_company_description_headline' | i18next }}</span>
          </div>
        </div>
        <div class="row subinformations">
          <div class="col-12">
            {{ 'affiliate_company_description_content_text' | i18next }}
          </div>
        </div>
        <div class="row subinformations">
          <div class="col-12">
            <div class="form-group">
              <textarea name="partner_description" pInputTextarea class="form-control" id="description" cols="30" rows="10" minlength="50" maxlength="3000" [(ngModel)]="partnerDescription" required autofocus></textarea>
            </div>
            <p class="red" *ngIf="partnerDescription.length < 50">{{ 'affiliate_still_chars' | i18next: {number: (50 - partnerDescription.length)} }}</p>
            <p *ngIf="partnerDescription.length >= 50">{{partnerDescription.length}}/3000</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row align-items-stretch mb-3">
  <div class="col-xs-12 col-md-12 col-lg-12">
    <div class="card h-100 card-nb card-nm">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12 highlighted">
            <span>{{ getTradingLicenseByType('headline') | i18next }}</span>
          </div>
        </div>
        <div class="row subinformations">
          <div class="col-12">
            {{ getTradingLicenseByType('text') | i18next }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <input class="w-75" type="file" name="file" accept="application/pdf" (change)="getFile($event)">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end mt-2 mb-3">
  <button (click)="send()" [disabled]="partnerDescription.length < 50 || !file" class="btn btn-outline-primary">{{'affiliate_apply_partnership_text' | i18next }}</button>
</div>
