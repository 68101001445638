import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-change-host-confirm',
  templateUrl: './subscription-change-host-confirm.component.html'
})
export class SubscriptionChangeHostConfirmComponent implements OnInit {

  @Input() subscription: Subscription;
  @Input() newCspHost: {aid: string, aname: string};
  @Output() changeHostConfirmedEvent = new EventEmitter();
  @Output() backEvent  = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onConfirm() {
    this.changeHostConfirmedEvent.emit();
  }

  back() {
    this.backEvent.emit();
  }

}
