import {action, computed, runInAction} from 'mobx';
import {observable} from '@myshared/mobx-connector';
import {classicMigrationEndDate} from "@myshared/utils";
import {TrunkEnvironment} from "../auth/account.model";
import {DateSetValues, pcDate} from "../date.service";
import {PaymentMethodCode, SaleOrder} from "../buy/buy.model";
import {Phonenumber} from "../phonenumber/phonenumber.model";
import {PhoneNumber} from "../phonenumber/phoneNumber";

export const PC_BASIC_CLOUD_FREE = 'PC-BASIC-CLOUD-FREE';
export const PC_BASIC_ONSITE_FREE = 'PC-BASIC-ONSITE-FREE';
export const PC_ONE_TRIAL = 'PC-ONE-TRIAL';

export type ItemizedBillingTypes = 'invoice_only' | 'invoice_anonymize' | 'invoice_full';

export type SubscriptionBootstate = {
  instance_name: string;
  bootstate: string;
}

export class Subscription {

  public name: string;
  public instance_name: string;
  public license_id: string;
  public appliance_id: string;
  public pairing_state: string;
  public update_channel: string;
  public mac: string;
  public is_v3: boolean;
  public is_nfr: boolean;
  public is_my: boolean;
  public users: string;
  public appliance_type: string;
  public customer_name: string;
  public customer_id: string;
  public support_level: string;
  public license_code: string;
  public license_type: string;
  public legacy_updater_url: string;
  public next_invoice: string;
  public end_date: string;
  public product: string;
  public sla_product: string;
  public cloud_url: string;
  public offline_update_url: string;
  public lic_url: string;
  public parent_id: string;
  public parent_name: string;
  public has_support: boolean;
  public cancelled: boolean; // pascom one only attribute
  public nextPossibleCancellationDate: string; // pascom one only attribute
  public nextPossibleEndDate: string; // pascom one only attribute
  public pcCredit: number; // pascom one only attribute
  public itemizedBillingType: ItemizedBillingTypes; // pascom one only attribute
  public tariffPlanDetail: TariffPlanDetail; // pascom one only attribute
  public pausedSince: string;
  public subscription_customer_id: number;
  public bootstate: string | null;
  public migrationDaysLeft?: number;
  public migrationEndDate?: string;
  public is_one_nfr: boolean;
  public trunkEnvironment?: TrunkEnvironment;
  public orders?: SaleOrder[];
  public nextPossibleDowngradeDate?: string;
  public tenantMigrationEligible: boolean;
  public isLocalStored?: boolean;
  public storedTenantId?: number; // Locally stored tenant ID item for the subscription (does not come from the API)
  public fallbackNumber?: string;
  public trunkNumbers: Phonenumber[];
  public payment_method: PaymentMethodCode;

  get typeIcon(): string {
    if (!this.is_v3) {
      return 'fa-save';
    } else if (this.appliance_type === 'onsite') {
      return 'fa-home';
    }
    return 'fa-cloud';
  }

  get isCancelled(): boolean {
    return !!this.end_date;
  }

  get licenseTypeFormatted(): string {
    switch (this.license_type) {
      case 'annual_cloud':
        return 'annual';
      case 'nfr_cloud':
        return 'nfr';
      case 'free_cloud':
        return 'free';
      case 'one_trial':
        return 'one trial';
      default:
        return this.license_type;
    }
  }

  // A subscription is valid until an end date is set
  // and/or the end date is in future
  get isValid(): boolean {
    if (!this.end_date) {
      return true;
    }
    // We need to set time to zero, because we only want to compare the dates
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const validDate = new Date(this.end_date);
    validDate.setHours(0, 0, 0, 0);
    return now <= validDate;
  }

  get isOneExpired(): boolean {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const endDate = new Date(this.end_date);
    endDate.setHours(0, 0, 0, 0);
    return now > endDate;
  }

  /**
   * This will add the ability to upgrade to an annual cloud with an appliance type after the expiration date.
   * It's only possible for 30 days after the end day.
   */
  get canMigrateCloudAfterExpired() {
    if (this.isOneAppliance && this.isAnnualCloud) {
      const dateOptions: DateSetValues = {hours: 0, minutes: 0, seconds: 0, milliseconds: 0};
      const now = pcDate().set(dateOptions);
      const validDate = pcDate(this.end_date).set(dateOptions).add({days: 30});
      const diffDays = validDate.diffInDays(now.toDate());

      return diffDays > 0;
    }

    return false;
  }

  get isOnsitePerpetual(): boolean {
    return this.appliance_type === 'onsite' && this.license_type === 'perpetual';
  }

  get isPerpetual(): boolean {
    return this.license_type === 'perpetual';
  }

  get isOnsite(): boolean {
    return this.appliance_type === 'onsite';
  }

  get isCloud(): boolean {
    return this.appliance_type === 'cloud';
  }

  get isCsp(): boolean {
    return this.appliance_type === 'csp';
  }
  get isClassicFree(): boolean {
    return this.license_type === 'free' || this.license_type === 'free_cloud';
  }

  get isBasicSupport(): boolean {
    return this.support_level === 'basic';
  }

  get hasSupport(): boolean {
    return this.has_support;
  }

  get isOneTrial() {
    return this.license_type === 'one_trial';
  }

  get isOne() {
    return this.license_type === 'one';
  }

  get isAnnualCloud() {
    return this.license_type === 'annual_cloud';
  }

  get isOneAppliance() {
    return this.appliance_type === 'one';
  }

  public get isNonPascomOneLicenseType() {
    return !this.isOne && !this.isOneTrial;
  }

  /**
   * Check possibility to migrate only appliance type one to pascom ONE
   */
  public get canMigrateTariffPlan() {
    return !this.isCancelled
      && this.isOneAppliance
      && this.isNonPascomOneLicenseType;
  }

  /**
   * Checks possibility to migration from appliance type onsite and cloud
   *
   * This will also include appliances with parent appliance type CSP
   */
  public get canMigrateToPascomOne() {
    return !this.isCancelled && (this.isCloud || this.isOnsite);
  }

  public get migratableSubscription() {
    return this.canMigrateTariffPlan || this.canMigrateToPascomOne;
  }

  /**
   * Gets the difference between end date and now
   *
   * Days left for a pascom ONE trial
   *
   * @return number
   */
  get trialDaysLeft(): number {
    if (this.isOneTrial && this.end_date) {
      // The calculation with the moment package is much more easier to do as manually
      // With moment.diff its more accurate and easier to implement
      const momentSettings: DateSetValues = {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}; // Time is 00:000:00 for better comparison
      // const now = moment().set(momentSettings);
      const now = pcDate().set(momentSettings);
      // const validDate = moment(this.end_date).set(momentSettings);
      const validDate = pcDate(this.end_date).set(momentSettings);
      return validDate.diffInDays(now.toDate()); // Get the difference between valid date and now in days
    }

    return 0;
  }

  public get hasActivePhoneNumber(): boolean {
    const active = this.trunkNumbers?.filter((number: Phonenumber) => {
      return number.isActive
    }) ?? [];

    return active.length > 0;
  }

  get pbxStatus(): { status: string; color: any, messageBg: string } {
    if (this.pausedSince) {
      return {
        status: 'pbx_instance_paused',
        color: 'dead',
        messageBg: 'danger'
      };
    }

    if (this.bootstate === 'api_loading') {
      return {
        status: 'api_loading',
        color: { 'loading': true },
        messageBg: 'secondary'
      };
    }

    if (this.bootstate) {
      let colorTxt = 'pending';
      let messageBg = 'warning';
      if (this.bootstate === 'down' || this.bootstate === 'failed') {
        colorTxt = 'dead';
        messageBg = 'danger';
      }
      if (this.bootstate === 'unknown') {
        colorTxt = 'unknown';
      }
      if (this.bootstate === 'running') {
        colorTxt = 'active';
        messageBg = 'success';
      }
      return {
        status: 'pbx_bootstate_' + this.bootstate,
        color: { [colorTxt]: true },
        messageBg
      };
    }

    return null;
  }

  get formattedFallbackNumber() {
    if (!this.fallbackNumber) return '';
    return PhoneNumber.format(this.fallbackNumber, this.trunkEnvironment.tenantCountryCode);
  }

  public static fromJson(json: any): Subscription {
    const s = new Subscription();
    s.name = json.name || '';
    s.instance_name = json.instance_name || '';
    s.license_id = json.license_id || '';
    s.appliance_id = json.appliance_id || '';
    s.pairing_state = json.pairing_state || '';
    s.update_channel = json.update_channel || '';
    s.mac = json.mac || '';
    s.is_v3 = json.is_v3 || false;
    s.is_nfr = json.is_nfr || false;
    s.is_one_nfr = json.is_one_nfr || false;
    s.is_my = json.is_my || false;
    s.users = json.users || '0';
    s.license_code = json.license_code || '';
    s.appliance_type = json.appliance_type || '';
    s.customer_name = json.customer_name || '';
    s.customer_id = json.customer_id || '';
    s.support_level = json.support_level || '';
    s.license_type = json.license_type ||  '';
    s.legacy_updater_url = json.legacy_updater_url || '';
    s.next_invoice = json.next_invoice || '';
    s.end_date = json.end_date || '';
    s.product = json.product || '';
    s.sla_product = json.sla_product || '';
    s.cloud_url = json.cloud_url || '';
    s.offline_update_url = json.offline_update_url || '';
    s.lic_url = json.lic_url || '';
    s.parent_id = json.parent_id || '';
    s.parent_name = json.parent_name || '';
    s.has_support = json.has_support || false;
    s.cancelled = json.cancelled || false;
    s.nextPossibleCancellationDate = json.next_possible_cancellation_date || '';
    s.nextPossibleEndDate = json.next_possible_end_date || '';
    s.pcCredit = json.pc_credit ?? 0;
    s.pausedSince = json.paused_since ?? '';
    // It is possible to get an false from the API e.g. classic
    // But we use itemized_billing_type only on pascom ONE.
    if (json?.itemized_billing_type && (typeof json.itemized_billing_type === 'string')) {
      s.itemizedBillingType = json.itemized_billing_type;
    } else {
      // We will end up here for a classic product. But we dont use it.
      // Also used als fallback, because this is the standard value for pascom ONE.
      s.itemizedBillingType = 'invoice_only';
    }
    s.tariffPlanDetail = TariffPlanDetail.fromJson(json?.tariff_plan_details);
    s.subscription_customer_id = json.subscription_customer_id ?? undefined;
    s.trunkEnvironment = TrunkEnvironment.fromJson(json.trunk_environment || {})

    if (s.migratableSubscription) {
      const migrationEndDate = classicMigrationEndDate(s);
      s.migrationEndDate = migrationEndDate.format();
      const diffDays = migrationEndDate.diffInDays(Date.now());
      s.migrationDaysLeft = diffDays >= 0 ? diffDays : 0;
    }

    s.orders = json.orders?.map(saleOrder => SaleOrder.fromJson(saleOrder));
    s.nextPossibleDowngradeDate = json.next_possible_downgrade_date ?? undefined;
    s.tenantMigrationEligible = json.tenant_migration_eligible ?? false;

    // We need this information from local store
    s.storedTenantId = json.storedTenantId ?? undefined;
    s.isLocalStored = json.isLocalStored ?? undefined;

    s.fallbackNumber = json.one_trunk_fallback_phonenumber ?? '';

    s.trunkNumbers = [];
    if (json.trunk_numbers) {
      s.trunkNumbers = json.trunk_numbers.map((number: any) => Phonenumber.fromJson(number)) ?? [];
    }

    s.payment_method = json.payment_method ?? undefined;

    return s;
  }

  toJSON() {
    return {
      "name": this.name,
      "instance_name": this.instance_name,
      "mac": this.mac,
      "appliance_id": this.appliance_id,
      "license_id": this.license_id,
      "support_level": this.support_level,
      "pairing_state": this.pairing_state,
      "license_type": this.license_type,
      "customer_name": this.customer_name,
      "customer_id": this.customer_id,
      "tenant_migration_eligible": this.tenantMigrationEligible,
      "subscription_customer_id": this.subscription_customer_id,
      "is_v3": this.is_v3,
      "users": this.users,
      "appliance_type": this.appliance_type,
      "is_my": this.is_my,
      "is_nfr": this.is_nfr,
      "is_one_nfr": this.is_one_nfr,
      "product": this.product,
      "sla_product": this.sla_product,
      "update_channel": this.update_channel,
      "license_code": this.license_code,
      "next_invoice": this.next_invoice,
      "end_date": this.end_date,
      "cloud_url": this.cloud_url,
      "offline_update_url": this.offline_update_url,
      "parent_id": this.parent_id,
      "parent_name": this.parent_name,
      "has_support": this.hasSupport,
      "cancelled": this.cancelled,
      "itemized_billing_type": this.itemizedBillingType,
      "paused_since": this.pausedSince,
      "tariff_plan_details": {
        "current_tariff_plan": this.tariffPlanDetail.currentPlan,
        "ordered_tariff_plan": this.tariffPlanDetail.orderedPlan,
        "ordered_tariff_plan_activation_date": this.tariffPlanDetail.orderedPlanActivationDate
      },
      "orders": this.orders,
      "next_possible_cancellation_date": this.nextPossibleCancellationDate,
      "next_possible_end_date": this.nextPossibleEndDate,
      "next_possible_downgrade_date": this.nextPossibleDowngradeDate,
      "trunk_environment": {
        "tenant_country_code": this.trunkEnvironment.tenantCountryCode,
        "trunk_country_prefix": this.trunkEnvironment.trunkCountryPrefix,
        "trunk_country_location_independent_prefix": this.trunkEnvironment.trunkCountryLocationIndependentPrefix
      },
      // We need this information from local store
      "storedTenantId": this.storedTenantId,
      "isLocalStored": this.isLocalStored,
      "fallback_number": this.fallbackNumber,
      "payment_method": this.payment_method,
    }
  }

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.name)
      || predicate(this.instance_name)
      || predicate(this.customer_name)
      || predicate(this.appliance_id.toString())
      || predicate(this.parent_name);
  }

  public setBootstate(bootstate: string) {
    this.bootstate = bootstate;
  }

  public get scheduledSLAOrder() {
    return this.orders?.find(s => !!s.deliveryDate && !!s.scheduledSLAOrderLine);
  }

  public get scheduledCUOrder() {
    return this.orders?.find(s => !!s.deliveryDate && !!s.scheduledCUOrderLine);
  }
}

export class TariffPlanDetail {
  currentPlan: string;
  orderedPlan: string;
  orderedPlanActivationDate: string;

  public static fromJson(json: any): TariffPlanDetail {
    const tariff = new TariffPlanDetail();
    tariff.currentPlan = json?.current_tariff_plan ?? '';
    tariff.orderedPlan = json?.ordered_tariff_plan ?? '';
    tariff.orderedPlanActivationDate = json?.ordered_tariff_plan_activation_date ?? '';

    return tariff;
  }
}

export class SubscriptionModel {
  @observable subscriptions: Subscription[];
  @observable subscriptionBoostates: Map<string, SubscriptionBootstate[]>;
  @observable initialized: boolean;

  constructor() {
    runInAction(() => {
      this.subscriptions = [];
      this.subscriptionBoostates = new Map<string, SubscriptionBootstate[]>();
    });
  }

  @computed get hasNfrOne() {
    return !!this.subscriptions.find(s => s.is_one_nfr);
  }

  @action setSubscriptions(s: Subscription[]) {
    if (!this.initialized) {
      this.initialized = true;
    }
    this.subscriptions = s;
    if (this.subscriptionBoostates.size > 0) this.setAllSubscriptionsBootstate()
  }

  @action setSubscriptionBoostates(bootstate: {}) {
    for (const i in bootstate) {
      this.subscriptionBoostates.set(i, bootstate[i]);
    }
  }

  @action setAllSubscriptionsBootstate() {
    let subscriptions: Subscription[] = [];
    subscriptions = this.subscriptions.map(s => {
      if (s.bootstate !== null && s.isValid) {
        s.setBootstate(this.findBootstate(s));
      }
      return s;
    });
    this.subscriptions = subscriptions;
  }
  @action setAllSubscriptionsBootstateToUnknown() {
    let subscriptions: Subscription[] = [];
    subscriptions = this.subscriptions.map(s => {
      if (s.bootstate === 'api_loading') {
        s.setBootstate('unknown');
      }
      return s;
    });
    this.subscriptions = subscriptions;
  }

  /**
   * Find the bootstate for an appliance
   * @param subscription
   */
  public findBootstate(subscription) {
    return this.subscriptionBoostates.get(subscription.parent_name)
      ?.find(b => b.instance_name === subscription.instance_name)?.bootstate ?? '';
  }

  /**
   * Checks subscriptions for existing pascom one trials
   * Optional companyName: For partner we need to check the customer name additionally
   * @param companyName: optional to check also the name
   */
  public hasOneTrial(companyName?: string) {
    if (!!companyName) {
      return !!this.subscriptions.find(r => r.isOneTrial && r.customer_name === companyName);
    }
    return !!this.subscriptions.find(r => r.isOneTrial);
  }

  public hasMyOneTrial() {
    return !!this.subscriptions.find(r => r.is_my && r.isOneTrial);
  }
}
