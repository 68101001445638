import {Component, OnDestroy, OnInit} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {SubscriptionService} from "../../../subscription/subscription.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BuyService} from "../../../buy/buy.service";
import {Model} from "../../../app.model";
import {runInAction} from "mobx";
import {HttpClient} from "@angular/common/http";
import {BankAccount, Company} from "../../../company/company.model";
import {CompanyService} from "../../../company/company.service";
import {CustomerService} from "../../../customer/customer.service";
import {WorkflowCspService} from "../workflow-csp.service";
import {WorkflowCspChangeSlaComponent} from "../workflow-csp-change-sla.component";
/**
 * Workflow to upgrade CUs as a customer
 */
@Component({
  selector: 'app-workflow-csp-one-upgrade-sla',
  templateUrl: './workflow-csp-one-upgrade-sla.component.html',
})
export class WorkflowCspOneUpgradeSlaComponent extends WorkflowCspChangeSlaComponent implements OnInit, OnDestroy {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected subscriptionService: SubscriptionService,
              protected buyService: BuyService,
              protected m: Model,
              protected http: HttpClient,
              protected workflowCspService: WorkflowCspService,
              protected customerService: CustomerService,
              protected companyService: CompanyService) {
    super(route, router, subscriptionService, buyService, m, http, workflowCspService, customerService, companyService);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.initWorkflow('upgrade-sla');

    if (!this.isSepa && this.step === 'payment') {
      this.navigate(['address']);
      return;
    }

    this.initialized = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected checkSelectedProduct(ifStatement: boolean) {
    return this.model.subscriptionSla.index === this.model.selectedSla.index
      || this.model.subscriptionSla.index > this.model.selectedSla.index;
  }

  public async onInvoiceAddress(c: Company) {
    const company = await firstValueFrom(this.companyService.update(c));

    runInAction(() => {
      this.model.company = company;
    });

    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    await this.createOffer();
    this.navigate(['checkout']);
  }

  public onInvoiceAddressBack() {
    // Switch to workflow change cu
    this.router.navigate(['/do', 'change-sla', this.applianceId]);
  }

  public async onPaymentMethod({ bank }: { bank?: BankAccount }) {
    bank.partner_id = +this.model.company.id;
    await firstValueFrom(this.companyService.updateBankAccount(bank));
    await this.createOffer();

    await firstValueFrom(this.buyService.getOfferRecalculate(this.model.saleOrder.name));
    this.navigate(['checkout']);
  }

  public onPaymentMethodBack() {
    this.navigate(['address']);
  }

  public onBuy() {
    this.buyService.confirmUpgrade().subscribe(
      (saleOrder: any) => {
        this.navigate(['complete'])
      }
    );
  }

  public onSummaryBack() {
    if (this.isSepa) {
      this.navigate(['payment']);
      return;
    }

    this.onPaymentMethodBack();
  }

  public onComplete() {
    this.resetWorkflow();
  }

  public get isSepa() {
    return this.model?.saleOrder?.payment_method === 'sepa' || this.model?.subscription?.payment_method === 'sepa';
  }
}
