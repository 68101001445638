<app-content-header [stepsBack]="1"
                    [headline]="'phonenumber_selected_numbers' | i18next: {selected: selectedPhoneNumber ? 1 : 0, max: 1}">
</app-content-header>

<div class="row align-items-stretch mt-2">
  <app-select-loc-ind-phonenumber-card *ngFor="let b of blocks"
                    [blockOffer]="b"
                    [isSelected]="b.blockId === selectedPhoneNumber"
                    (toggle)="onSelectionChange($event)" class="col-xs-12 col-xl-4 mb-3" >
  </app-select-loc-ind-phonenumber-card>
</div>

<div class="row justify-content-end">
  <div class="p-3">
    <button class="btn btn-outline-primary" [disabled]="selectedPhoneNumber === ''" (click)="select()">{{'app_next' | i18next}}</button>
  </div>
</div>
