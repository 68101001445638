import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  getPortNumberFormData,
  getPortNumber,
  portSingleNumberForm
} from '../../phonenumber-port-form';
import {
  PortNumberBlockDetails,
  PortNumberBlockDetailsWithPreview
} from '../../port-phonenumber.model';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-phonenumber-port-single-number',
  templateUrl: './phonenumber-port-single-number.component.html'
})
export class PhonenumberPortSingleNumberComponent implements OnInit {

  private readonly MAX_SELECTABLE_SINGLE_PHONE_NUMBERS = 10;

  @Output() public readonly selected = new EventEmitter<PortNumberBlockDetails[]>();
  @Output() public readonly onBack = new EventEmitter();

  public formModel: DynamicFormControlModel[];
  public formGroup: FormGroup;
  public formLayout: DynamicFormLayout;
  public singlePhoneNumbers: PortNumberBlockDetailsWithPreview[] = [];

  private builder: PCFormBuilder;


  constructor(public pcFormService: PCFormsService,
              public messageService: MessageService,
              public i18next: I18NextService) {
  }

  ngOnInit(): void {
    this.changeForm();
  }

  addNumber() {
    if (this.singlePhoneNumbers.length < this.maxSelectableSingleNumbers) {
      this.formData.get('areaCode').disable({emitEvent: true});
      const blockDetailsFormData = getPortNumberFormData(this.formGroup) as PortNumberBlockDetailsWithPreview;
      blockDetailsFormData.previewNumber = this.formData.get('previewNumber').value;
      // get the disabled area code value because parent cannot read the value of disabled controls
      // We need to transform area code to an int (+someValue+'' = deletes leading 0) and back to a string for the object
      blockDetailsFormData.area_code = +this.formData.get('areaCode').value + '';

      for (const existingNumber of this.singlePhoneNumbers) {
        if (blockDetailsFormData.previewNumber === existingNumber.previewNumber) {
          this.messageService.add({severity: 'error',
            summary: this.i18next.t('port_number_already_selected_error_title') as string,
            detail: this.i18next.t('port_number_already_selected_error_description') as string});
          return;
        }
      }

      this.singlePhoneNumbers.push(blockDetailsFormData);
      this.formData.get('number').reset();
    } else {
      this.messageService.add({severity: 'error',
        summary: this.i18next.t('port_number_max_selected_error_title',
          { max: this.maxSelectableSingleNumbers }) as string,
        detail: this.i18next.t('port_number_max_selected_error_description',
          { max: this.maxSelectableSingleNumbers }) as string
      });
    }
  }

  removeSingleNumber(id: number) {
    this.singlePhoneNumbers.splice(id, 1);

    if (this.singlePhoneNumbers.length === 0) {
      this.formData.get('areaCode').enable({emitEvent: true});
    }
  }

  public back() {
    this.onBack.emit();
  }

  public next() {
    const numbers = this.singlePhoneNumbers as PortNumberBlockDetails[];
    this.selected.next(numbers);
  }

  public get formDisabled() {
    return this.singlePhoneNumbers.length === 0;
  }

  public get maxSelectableSingleNumbers() {
    return this.MAX_SELECTABLE_SINGLE_PHONE_NUMBERS;
  }

  private setPreviewPhoneNumber(formData: AbstractControl, preview: PortNumberBlockDetails) {
    this.patchPreviewValuesForSingleNumber(preview, formData);
  }

  private patchPreviewValuesForSingleNumber(preview: PortNumberBlockDetails, formData: AbstractControl) {
    const areaCode = this.formData.get('areaCode').value;
    // +areaCode (+) will transform string into number on the output of the condition
    // to be sure to have no leading 0 for area code
    // E.g. area code: 030 on input transformed into 30 for preview.
    const previewFrom = preview.country_prefix + ' '
      + (+areaCode > 0 ? +areaCode : '') + ' '
      + preview.main_number;
    formData.get('previewNumber').patchValue(previewFrom, {emitEvent: false});
  }

  private changeForm() {
    this.singlePhoneNumbers = [];
    this.createSinglePhoneNumberForm();

    const blockDetailsPreview = new PortNumberBlockDetails();
    this.formGroup.valueChanges.subscribe(r => {
      this.setPreviewPhoneNumber(this.formData, getPortNumber(blockDetailsPreview, r));
    });

    this.formData.patchValue({
      'countryPrefix': '+49'
    }, {emitEvent: false});
  }

  private createSinglePhoneNumberForm() {
    this.builder = portSingleNumberForm(this.pcFormService);
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();
  }

  private get formData() {
    return this.formGroup.get('port_number').get('row1');
  }

}
