import {observable} from '@myshared/mobx-connector';
import {runInAction} from 'mobx';

export class Order {

    public id: string;
    public name: string;
    public date: string;
    public state: string;
    public amount_untaxed: string;
    public product: string;
    public quantity: string;
    public customer_id: string;
    public customer_name: string;
    public appliance_id: string;
    public appliance_name: string;
    public attachment: string;
    public expired: boolean;
    public deliveryDate: string;


    public static fromJson(json: any): Order {
        const order = new Order();
        order.id = json.id || '';
        order.name = json.name || '';
        order.date = json.date || '';
        order.state = json.state || '';
        order.amount_untaxed = json.amount_untaxed || '';
        order.product = json.product || '';
        order.quantity = json.quantity || '';
        order.customer_id = json.customer_id || '';
        order.customer_name = json.customer_name || '';
        order.appliance_id = json.appliance_id || '';
        order.appliance_name = json.appliance_name || '';
        order.attachment = json.attachment || '';
        order.expired = json.expired || false;
        order.deliveryDate = json.delivery_date || '';
        return order;
    }

    public getProductNameForPreview() {
        const matches = this.product.match(/\[PC-(.*?)]/);
        let result: string;
        if (matches != null) {
            result = matches[1];
        } else {
            result = this.product;
        }
        if (result.length >= 50) {
            result = result.substr(0, 47).concat('...');
        }
        return result;
    }

    public matchesFilter(predicate: (filter: string) => boolean): boolean {
        return predicate(this.name) || predicate(this.customer_name);
    }
}

export class OrderModel {
    @observable orders: Order[];
    public loadMore = true;

    constructor() {
        runInAction(() => this.orders = []);
    }
}
