import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PCFormsService} from '@myshared/pcform.service';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {PortNumberBlockDetails, PortNumbersPortingFormDetails} from "../port-phonenumber.model";

@Component({
  selector: 'app-phonenumber-port',
  templateUrl: './phonenumber-port.component.html'
})
export class PhonenumberPortComponent implements OnInit {


  @Input()
  public countryCode: string;

  @Output()
  public selected = new EventEmitter<PortNumbersPortingFormDetails>();

  constructor(public pcFormService: PCFormsService,
              public messageService: MessageService,
              public i18next: I18NextService) {
  }

  ngOnInit(): void {
  }


}
