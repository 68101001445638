import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { PCFormsService, PCFormBuilder } from '@myshared/pcform.service';
import { DynamicFormControlModel, DynamicFormLayout } from '@ng-dynamic-forms/core';
import { FormGroup } from '@angular/forms';
import { AutorunComponent } from '@myshared/autorun.component';
import { Model } from '../../app.model';
import { I18NextService } from "angular-i18next";
import {Subscription} from "../../subscription/subscription.model";
import { trimInput } from '@myshared/utils';

@Component({
    selector: 'app-cloud-migration-information',
    templateUrl: './cloud-migration-information.component.html',
    styleUrls: []
})
export class CloudMigrationInformationComponent extends AutorunComponent implements OnInit {

    @Input() public stepsBack = 1;
    @Input() public readonly subscription: Subscription;

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly continue = new EventEmitter<void>();

    formModel: DynamicFormControlModel[];
    formGroup: FormGroup;
    formLayout: DynamicFormLayout;

    isCloud: boolean;
    isCsp: boolean;
    cspHost: { aid: string, aname: string };
    compatibilityAccepted: boolean = false;
    dataAccepted: boolean = false;
    guidelineAccepted: boolean = false;

    private builder: PCFormBuilder;

    constructor(private pcFormService: PCFormsService,
        private m: Model,
        private i18next: I18NextService) {
        super();
    }

    ngOnInit() {
        this.autorun(() => {
            if (this.m.workflow.inWorkflow) {
                this.isCloud = this.subscription.isCloud || this.m.buy.isCloudProduct || this.m.workflow.isOneTrial;
                this.isCsp = this.subscription.isCsp;
                this.cspHost = this.m.workflow.userData;
            }
        });
    }

    private get formId(): string {
        if (this.isCsp || this.cspHost.aid) {
            return 'cspinstance';
        } else if (this.isCloud) {
            return 'instancename';
        } else {
            return 'onsitename';
        }
    }

    onContinue() {
        this.continue.emit()
    }

    onBack() {
        this.back.emit();
    }

    nameToLowerCase(event) {
        const formId = {};
        formId[this.formId] = {
            name: trimInput(this.formGroup.value[this.formId]['name'].toLowerCase())
        };

        this.formGroup.setValue(formId);
    }

    private get instanceType(): string {
        // isCSP will be in our case 'cloud' because if we want to create cshost than we send csp to the server
        // and we will have the same length like cloud or onsite.
        // https://git.pascom.net/pascom/odoo-compas/merge_requests/443
        if (this.isCloud || this.isCsp) {
            return 'cloud';
        } else {
            return 'onsite';
        }
    }
}


