import {Component, OnInit} from '@angular/core';
import { Model } from '../../app.model';
import { AutorunComponent } from '@myshared/autorun.component';
import { SubscriptionService } from '../../subscription/subscription.service';
import { Subscription } from '../../subscription/subscription.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: []
})
export class CreateTicketComponent extends AutorunComponent implements OnInit {
  public subscription: Subscription = null;
  public side: Side = 'none';

  constructor(private m: Model,
              private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router) {
        super();
  }

  ngOnInit(): void {
    const applianceId = this.route.snapshot.paramMap.get('applianceId');
    if (applianceId) {
      this.autorun(() => {
        const subscription = this.m.subscription.subscriptions.find(s => '' + s.appliance_id === applianceId && s.isValid);
        if (subscription && subscription.hasSupport) {
          this.selectSubscription(subscription); // has support, enter create ticket
        } else if (subscription && !subscription.hasSupport) {
          this.router.navigate(['/subscriptions', subscription.appliance_id]); //no support, redirect to subscription details again
        } else {
          this.side = 'subscriptions'; // not found or other, show the list
        }
      });
      this.subscriptionService.refresh(true);
    } else {
      this.side = 'subscriptions'; // no appliance id from the params, show the subscription list to select appliance
    }
  }

  selectSubscription(subscription: Subscription) {
    this.subscription = subscription;
    this.side = 'ticket';
  }
}

type Side = 'none' | 'subscriptions' | 'ticket';
