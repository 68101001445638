<div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
  <app-content-header [stepsBack]="historyBackSteps" [historyBack]="false" (backEvent)="doBack()" [headline]="'my_subscription_headline' | i18next"></app-content-header>
</div>

<app-filter (searchFilterChanged)="doFilter($event)" [placeholder]="'customer_subscription_search' | i18next"></app-filter>

<app-no-content-message *ngIf="subscriptions.length === 0" t="no_one_subscriptions"></app-no-content-message>

<div class="row align-items-stretch mt-2">
  <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let lic of subscriptions" [subscription]="lic">
    <button [name]="lic.name + '-details'" class="btn btn-outline-primary mr-0" (click)="select(lic.appliance_id)">{{'app_select' | i18next}}</button>
  </app-subscription-card>
</div>
