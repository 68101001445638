import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from '../../subscription/subscription.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {PhonenumberService} from '../../phonenumber/phonenumber.service';
import {BlockNumber, PhonenumberPrice} from '../../phonenumber/phonenumber.model';
import {HttpClient} from '@angular/common/http';
import {PC_ONE_PHONE_NUMBERS} from "../select-product/product.model";
import { PhonenumberType } from 'src/app/workflow/workflow-phonenumbers/workflow-phonenumbers.component';

@Component({
  selector: 'app-select-phonenumber-type',
  templateUrl: './select-phonenumber-type.component.html',
})
export class SelectPhonenumberTypeComponent extends AutorunComponent implements OnInit {

  @Input() subscription: Subscription;
  @Input() trunkNumbers: BlockNumber[];
  @Output() selected: EventEmitter<PhonenumberType> = new EventEmitter<PhonenumberType>();

  public monthlyPrice = 0.2;
  public setupPrice = 2;

  public usedInSubscription = 0;

  constructor(private m: Model,
              private phonenumberService: PhonenumberService,
              private http: HttpClient) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.usedInSubscription = this.phonenumberService
        .getPhonenumberUsedInSubscriptionSize(this.subscription.appliance_id, this.m.phonenumber.blocks);
    });
    this.http.get<{pc_one_new_number_surplus: PhonenumberPrice[], pc_one_number_surplus: PhonenumberPrice[]}>
    ('/services/products/cloud').subscribe(r => {
      this.monthlyPrice = r.pc_one_number_surplus[0]?.list_price;
      this.setupPrice = r.pc_one_new_number_surplus[0]?.list_price;
    });
  }

  selectNew() {
    this.selected.emit('new');
  }

  selectPort() {
    this.selected.emit('port');
  }

  selectLocInd() {
    this.selected.emit('loc-independent');
  }

  get includedInSubscription(): number {
    return +this.subscription.users * PC_ONE_PHONE_NUMBERS;
  }

}
