import {Routes} from "@angular/router";
import {WorkflowEndcustomerOneDowngradeSlaComponent} from "./workflow-endcustomer-one-downgrade-sla.component";

export const workflowEndcustomerOneDowngradeSlaRouting: Routes = [
    { path: 'downgrade-sla/:aid', component: WorkflowEndcustomerOneDowngradeSlaComponent, children: [
        { path: 'info', component: WorkflowEndcustomerOneDowngradeSlaComponent, data: { step: 'info' } },
        { path: 'address', component: WorkflowEndcustomerOneDowngradeSlaComponent, data: { step: 'address' } },
        { path: 'payment', component: WorkflowEndcustomerOneDowngradeSlaComponent, data: { step: 'payment' } },
        { path: 'checkout', component: WorkflowEndcustomerOneDowngradeSlaComponent, data: { step: 'checkout' } },
        { path: 'complete', component: WorkflowEndcustomerOneDowngradeSlaComponent, data: { step: 'complete' } },
        { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
