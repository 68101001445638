<app-content-header (backEvent)="onBack()" [stepsBack]="stepsBack"
    [headline]="'migration_information_headline' | i18next"></app-content-header>


<app-icon-card>
  <div class="alert alert-warning" role="alert" [innerHtml]="'migration_warn' | i18next"></div>

  <div class="h3 mt-4 red">{{ 'migration_checklist_headline' | i18next}}</div>

  <span class="">{{ 'before_continuing' | i18next }}</span>
  <div class="mt-4">
      <app-pascom-checkbox [inputId]="'compatibilityAccepted'" [text]="'migration_compatibility' | i18next" (acceptChanged)="compatibilityAccepted = !compatibilityAccepted"></app-pascom-checkbox>
      <app-pascom-checkbox [inputId]="'dataAccepted'" [text]="'migration_data_pc18' | i18next" (acceptChanged)="dataAccepted = !dataAccepted"></app-pascom-checkbox>
      <app-pascom-checkbox [inputId]="'guidelineAccepted'" [text]="'migration_guideline' | i18next" (acceptChanged)="guidelineAccepted = !guidelineAccepted"></app-pascom-checkbox>
  </div>
  <div class="d-flex justify-content-end mt-2 form-container-footer">
      <button name="continue" class="btn btn-outline-primary" [disabled]="!compatibilityAccepted || !dataAccepted || !guidelineAccepted"
          (click)="onContinue()">{{ 'app_continue' | i18next }}</button>
  </div>
</app-icon-card>
