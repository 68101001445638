import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-pairing-state-card',
  templateUrl: './subscription-pairing-state-card.component.html',
})
export class SubscriptionPairingStateCardComponent implements OnInit {

  @Input() subscription: Subscription;

  constructor() { }

  ngOnInit() {
  }

}
