<div class="badge rounded-pill bg-info recommended-product" *ngIf="badgeTitle !== ''">{{ badgeTitle | i18next }}</div>
<div class="price-card tariff-card product card card-nb h-100"
     [ngClass]="{ 'normal': !currentlyUsed, 'highlight': currentlyUsed }">
    <div class="d-flex flex-column">
        <div class="price-card-header product">
            <div class="d-flex align-items-center flex-column">
                <div class="subscription-type product h3">{{ displayName | i18next }}</div>
                <div class="subscription-price mt-auto">
                    <div class="price text-nowrap">+{{ tariff.listPrice | localized_currency:{vatMarker: true } }}</div>
                    <div class="time-billing tariff">{{ 'price_per_month' | i18next }}</div>
                </div>
            </div>
        </div>

        <div class="price-card-body product one mt-5">
            <div class="container" *ngIf="tariff.zones.length > 0">
                <div class="h3 red">{{ 'tariff_including_minutes' | i18next }}</div>
                <div class="d-flex justify-content-center features">
                    <div class="d-flex flex-column align-items-start">
                        <div *ngFor="let zone of tariff.zones">
                            <div class="d-flex align-items-center">
                                <div>
                                    <span class="font-weight-bold red mr-1">{{ zone.freeMinutes }}</span>
                                    <span>{{ getZoneTranslationKey(zone.name) | i18next }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-card-footer">
            <button *ngIf="!currentlyUsed"
                    (click)="onTariffSelected()"
                    [name]="'select-' + displayName"
                    class="btn btn-primary"
                    [class]="{ 'btn-primary': highlighted, 'btn-outline-primary' : !highlighted }">
                {{ 'app_select' | i18next }}
            </button>
            <div *ngIf="currentlyUsed" class="badge rounded-pill currently-used">{{ 'product_currently_used' | i18next}}</div>
        </div>
    </div>
</div>
