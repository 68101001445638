import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {SaleOrder} from '../buy.model';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: []
})
export class CheckoutSummaryComponent extends AutorunComponent implements OnInit {

  @Input() historyBack = true;

  @Output() public readonly buy = new EventEmitter<string>();
  @Output() public readonly back = new EventEmitter<void>();

  public saleOrder: SaleOrder;
  public customerAddress: boolean;
  public appliance_name: string;

  public productType: string;
  public accepted: false;

  public get hasDiscount(): boolean {
    if (this.saleOrder) {
      return this.saleOrder.lines.reduce((acc, l) => l.discount > 0 || acc, false);
    }
    return false;
  }

  constructor(
    private m: Model,
    private i18next: I18NextService,
    ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.appliance_name = this.m.workflow.userData.aname || 'pascom.cloud';
      this.saleOrder = this.m.buy.currentOrder;
      // Search for a product name like PC-ANNUAL-CLOUD-8 and check if includes the word CLOUD
      this.productType = this.saleOrder.product.toUpperCase().includes('CLOUD') ? 'cloud' : 'onsite';
      this.customerAddress = this.saleOrder.customer_address.id !== '';
    });
  }

  onBuy() {
    this.buy.emit('');
  }

  onBack() {
    if (!this.historyBack) {
      this.back.emit();
    }
  }

  get positionInvoice(): string {
    if (this.customerAddress) {
      return 'justify-content-around';
    } else {
      return 'justify-content-start';
    }
  }

  get type(): string {
    if (this.productType === 'cloud') {
      return this.i18next.t('checkout_summary_type_cloud_host', {host: this.appliance_name}) as string;
    } else if (this.productType === 'onsite') {
      return this.i18next.t('checkout_summary_type_onsite') as string;
    }
    return '';
  }

  get typeIcon(): string {
    if (this.productType === 'cloud') {
      return 'fa-cloud';
    } else if (this.productType === 'onsite') {
      return 'fa-home';
    }
    return '';
  }

  get paymentMethod(): string {
    if (this.saleOrder.payment_method === 'sepa') {
      return this.i18next.t('checkout_summary_payment_sepa') as string;
    } else {
      return this.i18next.t('checkout_summary_payment_wire') as string;
    }
  }

  // public back() {
  //   window.history.back();
  // }

  public accept(accepted) {
    this.accepted = accepted;
  }

}
