import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {CustomerService} from '../customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {runInAction} from 'mobx';
import {CountryService} from '../../country/country.service';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';
import {action, observable} from '@myshared/mobx-connector';
import {Customer} from '../customer.model';
import {filterText} from '@myshared/utils';

@Component({
  selector: 'app-customer-subscriptions',
  templateUrl: './customer-subscriptions.component.html',
  styleUrls: []
})
export class CustomerSubscriptionsComponent extends AutorunComponent implements OnInit {
  @observable filter$: string;

  public customerId: string;
  public customer: Customer;
  customerSubscriptions: Subscription[];
  public isAdmin: boolean;

  constructor(private route: ActivatedRoute,
              private m: Model,
              private customerService: CustomerService,
              private countryService: CountryService,
              private subscriptionService: SubscriptionService,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId');
    runInAction(() => {
      this.filter$ = '';
    });
    this.customerService.refresh();
    this.subscriptionService.refresh();

    this.autorun(() => {
      this.customer = this.m.customer.customers.find((x) => x.id == this.customerId);
      this.isAdmin = this.m.account.isMypascomAdmin;
      if (this.customer) {
        this.customerSubscriptions = filterText<Subscription>(this.m.subscription.subscriptions, this.filter$)
          .filter(s => this.customer.appliance_ids.indexOf(s.appliance_id as any) !== -1);
      }
    });
  }

  onAddSubscription() {
    this.router.navigate(['/subscriptions/select-product'], {
      queryParams: { customerid: this.customerId }
    });
  }

  @action
  doFilter(value: string) {
    this.filter$ = value;
  }

  public onBack() {
    this.router.navigate(['/customers']);
  }

  doUpgradeExpiredOneTrial(applianceId: string) {
    this.router.navigate(['/do', 'upgrade', applianceId, ''], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public doUpgradeExpiredCloud(applianceId: string) {
    this.router.navigate(['/do', 'tariff-migration', applianceId]);
  }
}
