import { Component, OnInit } from '@angular/core';
import {Agreement, AgreementType} from '../agreement.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {filterText} from '@myshared/utils';
import {observable, runInAction} from 'mobx';
import {Router} from '@angular/router';
import {action} from '@myshared/mobx-connector';

@Component({
  selector: 'app-agreement-list',
  templateUrl: './agreement-list.component.html',
  styleUrls: []
})
export class AgreementListComponent extends AutorunComponent implements OnInit {

  public agreements: Agreement[];
  @observable private filter$: string;
  @observable private showOutdated$: boolean;

  constructor(
    private m: Model,
    private router: Router
  ) { super(); }

  ngOnInit() {
    if (this.router.url.endsWith('agreements')) {
      this.router.navigate(['/agreement-management', 'agreements', 'info']);
    }

    runInAction(() => {
      this.filter$ = '';
      this.showOutdated$ = false;
    });
    this.autorun(() => {
      let agreementTypes;
      this.router.url.endsWith('info') ? agreementTypes = [AgreementType.Info, AgreementType.SipTrunkTestAccount] : this.router.url.endsWith('ack') ? agreementTypes = [AgreementType.Ack, AgreementType.FreeIdleWarning] : agreementTypes = [AgreementType.Contract];
      this.agreements = filterText(this.m.agreement.agreements.filter(a => (agreementTypes.includes(a.type))), this.filter$).filter(s => this.showOutdated$ ? s.isOutdated : !s.isOutdated);
    });
  }

  @action
  doFilter(filter: string) {
    this.filter$ = filter;
  }

  @action
  public showOutdated(value: boolean) {
    this.showOutdated$ = value;
  }

  get agreementType() {
    let agreementType;
    this.router.url.endsWith('info') ? agreementType = AgreementType.Info : this.router.url.endsWith('ack')
      ? agreementType = AgreementType.Ack : agreementType = AgreementType.Contract;
    return agreementType;
  }
}
