import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {SubscriptionService} from '../subscription.service';
import {Subscription} from '../subscription.model';
import {filterText} from '@myshared/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {AutorunComponent} from '@myshared/autorun.component';
import {runInAction} from 'mobx';
import {action, observable} from '@myshared/mobx-connector';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {IDropdownItem} from "@myshared/pascom-dropdown-multiselect/pascom-dropdown";
import {I18NextService} from "angular-i18next";

@Component({
  selector: 'subscription-list',
  templateUrl: './subscription-list.component.html',
  providers: [LocalizedDatePipe],
  styleUrls: []
})
export class SubscriptionListComponent extends AutorunComponent implements OnInit {

  public subscriptions: Subscription[];
  public isPartner: boolean;
  public filterMy: boolean;
  public hasNfr: boolean;
  public isCsp: boolean;
  public isAdmin: boolean;
  public numberOfExpiredSubscriptions: number;
  public nfr: Subscription[];
  public isPascomOneMigration = false;
  public isPascomOneMigrationExpireWarning: boolean;
  public isSubscriptionsInitialized: boolean;
  public countPossiblePascomOneMigrations = 0;
  public hasNfrOne: boolean;
  public isCspOne: boolean;
  public dropdownFilterOptions: IDropdownItem[] = []

  private applianceStatusLoaded = false;

  @observable private filter$: string;
  @observable private showExpired$: boolean;
  @observable private showFilter$: string[];

  private cspFilterInitialized = false;

  constructor(
    private m: Model,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private datePipe: LocalizedDatePipe,
    private route: ActivatedRoute,
    private i18next: I18NextService
  ) { super(); }

  ngOnInit() {
    runInAction(() => {
      this.showExpired$ = false;
      this.showFilter$ = [];
      this.filter$ = '';
    });
    this.dropdownFilterOptions = [
      {name: (this.i18next.t('filter_pascom_one') as string), code: 'one'},
      {name: (this.i18next.t('filter_legacy_onsite') as string), code: 'onsite'},
      {name: (this.i18next.t('filter_legacy_cloud') as string), code: 'cloud'}
    ]

    this.autorun(() => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.isCsp = this.m.account.currentUser.companyIsCsp;
      this.isCspOne = this.m.account.currentUser.companyIsPC20Csp;
      this.hasNfrOne = this.m.subscription.hasNfrOne;
      this.isAdmin = this.m.account.isMypascomAdmin;
      if (!this.isPartner && (this.router.url.endsWith('customer') || this.router.url.endsWith('subscriptions'))) {
        this.router.navigate(['/subscriptions/my']);
      } else if (this.isPartner && this.router.url.endsWith('subscriptions')) {
        this.router.navigate(['/subscriptions/customer']);
      } else {
        // enforce license fetch from the backend
        // only when the redirection to /my or /customer already happened
        // because ngOnInit is called multiple times during redirections
        this.subscriptionService.refresh(true);
      }
    });

    this.autorun(() => {
      this.isSubscriptionsInitialized = this.m.subscription.initialized;
      this.filterMy = this.router.url.endsWith('my');

      if (!this.cspFilterInitialized && this.filterMy && (this.isCsp || this.isCspOne)) {
        this.dropdownFilterOptions = [...this.dropdownFilterOptions, {name: (this.i18next.t('filter_csp') as string), code: 'csp'}];
        this.cspFilterInitialized = true;
      }

      this.isPascomOneMigration = this.router.url.endsWith('pascom-one-migration');
      this.nfr = this.m.subscription.subscriptions.filter(l => l.is_nfr);
      this.hasNfr = this.nfr.length > 0;
      this.numberOfExpiredSubscriptions = this.m.subscription.subscriptions.filter(l => this.filterMy ? l.is_my : !l.is_my).filter(s => !s.isValid).length;
      if (this.m.subscription.subscriptions.length > 0 && !this.applianceStatusLoaded) {
        this.subscriptionService.refreshSubscriptionsBootstate();
        this.applianceStatusLoaded = true;
      }

      // We need always calculate the number of possible migratable subscriptions
      const subscriptionsToMigrate = this.m.subscription.subscriptions
        .filter(l => l.migratableSubscription && l.isValid)
        .filter(l => !this.isPartner ? l.is_my : true);
      // Get the count to show on the tab menu (xx left)
      this.countPossiblePascomOneMigrations = subscriptionsToMigrate.length;
      // If subscription to migrate remaining days are eq or below 60, we will get a warning on the tab menu
      this.isPascomOneMigrationExpireWarning = subscriptionsToMigrate
        .filter(s => {
          return s.migrationDaysLeft <= 60
        }).length > 0;

      if (this.isPascomOneMigration) {
        // Show migratable subscriptions on the list with usable search bar
        this.subscriptions = filterText(subscriptionsToMigrate, this.filter$);
      } else {
        // When we are not on the migration tab, we can use our normal way to show the subscriptions
        const subscriptions = this.m.subscription.subscriptions
          .filter(l => this.filterMy ? l.is_my : !l.is_my)
          .filter(s => this.showExpired$ ? !s.isValid : s.isValid)
          .filter(s => {
            if (this.showFilter$.length === 0) return true;
            if (this.showFilter$.includes('one') && (s.isOne || s.isOneTrial)) return true
            if (this.showFilter$.includes('cloud') && (s.isCloud || s.isOneAppliance) && (s.isAnnualCloud || s.isClassicFree)) return true
            if (this.showFilter$.includes('onsite') && s.isOnsite) return true
            if (this.showFilter$.includes('csp') && s.isCsp) return true
            return false
        });
        this.subscriptions = filterText(subscriptions, this.filter$)
      }

      if (!this.isPartner && this.m.subscription.initialized && this.m.subscription.subscriptions.length === 0 && this.m.account.isMypascomAdmin) {
        this.router.navigate(['/subscriptions', 'select-product']);
      }

      if (this.isPascomOneMigration) {
        this.subscriptions.sort((a, b) => a.migrationDaysLeft - b.migrationDaysLeft);
      }
    });
  }

  public onMultiFilterChanged(items: IDropdownItem[]) {
    const filter: string[] = [];
    items.map(r => { filter.push(r.code) });

    runInAction(() => {
      this.showFilter$ = filter;
    })
  }

  @action
  public showExpired(value: boolean) {
    this.showExpired$ = value;
  }

  @action
  doFilter(filter: string) {
    this.filter$ = filter;
  }

  doUpgradeExpiredOneTrial(applianceId: string) {
    this.router.navigate(['/do', 'upgrade', applianceId, ''], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public doUpgradeExpiredCloud(applianceId: string) {
    this.router.navigate(['/do', 'tariff-migration', applianceId]);
  }

  public doChangeCU(applianceId: string) {
    this.router.navigate(['/do', 'change-cu', applianceId]);
  }

  public doChangeSLA(applianceId: string) {
    this.router.navigate(['/do', 'change-sla', applianceId]);
  }

  public get headline() {
    if (this.filterMy) {
      return 'my_subscription_headline'
    }

    if (this.isPascomOneMigration) {
      return 'pascom_one_migration_headline';
    }

    return 'customer_subscription_headline';
  }

  public get partnerCanAddNfrOne() {
    return this.subscriptionService.partnerCanCreateNfrOne;
  }

  public get partnerCanCreateNfrOne() {
    return this.isSubscriptionsInitialized && this.filterMy
      && this.isAdmin && this.partnerCanAddNfrOne;
  }

  public get partnerCanCreateTrial() {
    return this.isSubscriptionsInitialized && this.filterMy && this.isAdmin
      && this.isPartner && !this.isCspOne && this.hasNfrOne;
  }
}
