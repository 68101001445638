import {Component, Output, OnInit, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-phonenumber-port-prepare',
  templateUrl: './phonenumber-port-prepare.component.html',
  styles: [
  ]
})
export class PhonenumberPortPrepareComponent implements OnInit {

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onNext() {
    this.selected.emit();
  }

}
