import {Component, Input, OnChanges, OnInit, SimpleChanges, AfterViewInit} from '@angular/core';
import {Profile} from '../../partner.model';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {FormGroup} from '@angular/forms';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {Model} from '../../../app.model';
import {CountryService} from '../../../country/country.service';
import {ProfileService} from '../../profile.service';
import {MessageService} from 'primeng/api';
import {Observable, Subscriber} from 'rxjs';
import {I18NextService} from 'angular-i18next';
import {Router} from '@angular/router';
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
})
export class ProfileDetailsComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() public profile: Profile;

  public newLogo: string; // Data Uri if logo has changed
  public fileName: string;

  get logo(): string {
    if (this.newLogo) {
      return 'data:image/png;base64,' + this.newLogo;
    } else if (this.profile && this.profile.logo) {
      return 'data:image/png;base64,' + this.profile.logo;
    }
    return '';
  }

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;

  constructor(private pcFormService: PCFormsService,
              private countryService: CountryService,
              private profileService: ProfileService,
              private messageService: MessageService,
              private i18next: I18NextService,
              private router: Router,
              private m: Model) {
  }

  ngOnInit() {
    this.builder = this.pcFormService.newBuilder('profile');

    this.builder.addTextInput({
      id: 'website',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'zip',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'city',
      validators: {required: null}
    });
    this.builder.addSelect<string>({
      id: 'country',
      validators: {required: null}
    }, this.m.country.asDropdown);

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.countryService.refresh();
  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile && this.formGroup) {
      this.formGroup.setValue({
        profile: {
          website: this.profile.website,
          zip: this.profile.zip,
          city: this.profile.city,
          country: this.profile.country.code
        }
      });
    }
  }

  getFile(event) {
    this.fileName = event.target.files[0].name;
    if (event.target.files && event.target.files.length > 0) {
      this.resize(event.target.files[0], 720, 720).subscribe({
        next: (r) => {
          this.newLogo = r.replace('data:image/png;base64,', '');
        },
        error: (e) => {
          console.log('Failed to resize image', e);
          this.resetImageChange();
          this.messageService.add({severity: 'error', summary: this.i18next.t('profile_load_image_failed') as string});
        }
      });
    }
  }

  resize(file: File, maxWidth: number, maxHeight: number): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      const image = new Image();
      image.onerror = (e) => observer.error(e);
      image.onload = () => {
        const canvas = document.createElement('canvas');

        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        observer.next(canvas.toDataURL('image/png'));
        observer.complete();
      };
      image.src = URL.createObjectURL(file);
    });
  }

  onSave() {
    if (this.formGroup.valid) {
      const data = this.formGroup.value.profile;
      const p = Profile.fromJson({});
      p.id = this.profile ? this.profile.id : '';
      p.website = trimInput(data.website);
      p.zip = trimInput(data.zip);
      p.city = trimInput(data.city);
      p.country = this.m.country.countries.find(c => c.code == data.country);
      if (this.newLogo) {
        p.logo = this.newLogo;
      }

      this.profileService.save(p).subscribe(
        () => {
          this.messageService.add({ severity: 'success', summary: 'Saved!', detail: '' });
          this.resetImageChange();
          if (this.m.account.currentUser.company_website_profile === 'multiprofile') {
            this.router.navigate(['/partner', 'profiles']);
          }
        });
    }
  }

  resetImageChange() {
    this.fileName = '';
  }

  onDelete() {
    const cleanup = () => {
      this.messageService.add({ severity: 'success', summary: 'Deleted!', detail: '' });
      if (this.m.account.currentUser.company_website_profile === 'multiprofile') {
        this.router.navigate(['/partner', 'profiles']);
      } else {
        this.formGroup.reset({});
        this.newLogo = '';
      }
    };
    if (this.profile && this.profile.id) {
      this.profileService.deleteProfile(this.profile.id).subscribe(() => cleanup());
    } else {
      cleanup();
    }
  }

}
