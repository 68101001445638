import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-pending',
  templateUrl: './partner-pending.component.html'
})
export class PartnerPendingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
