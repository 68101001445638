import {runInAction} from 'mobx';
import {action, computed, observable} from '@myshared/mobx-connector';

export class NetworkState {
  public requestCount = 0;
}

export class AppNetworkModel {

  @observable public state: NetworkState;

  constructor() {
    runInAction(() => this.state = new NetworkState());
  }

  @computed get isLoading() {
    return this.state.requestCount > 0;
  }

  @action public newRequest() {
    const newState = new NetworkState();
    newState.requestCount = this.state.requestCount + 1;
    this.state = newState;
  }

  @action public requestDone() {
    const newState = new NetworkState();
    newState.requestCount = this.state.requestCount > 0 ? this.state.requestCount - 1 : 0;
    this.state = newState;
  }
}
