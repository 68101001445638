import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from "../../customer/customer.model";
import {Subscription} from "../../subscription/subscription.model";
import {Company, Contact} from "../../company/company.model";
import {DynamicFormControlModel, DynamicFormLayout} from "@ng-dynamic-forms/core";
import {FormGroup} from "@angular/forms";
import {PCFormBuilder, PCFormsService} from "@myshared/pcform.service";
import {contactForm, cspContactForm, getContactFromForm} from "../../company/contact.form";
import {I18NextService} from "angular-i18next";
import {MessageService} from "primeng/api";
import {CompanyService} from "../../company/company.service";
import {Model} from "../../app.model";

@Component({
  selector: 'app-select-invoice-contact',
  templateUrl: './select-invoice-contact.component.html'
})
export class SelectInvoiceContactComponent implements OnInit {
  @Input() public subscription: Subscription;
  @Input() public customer: Customer | Company;
  @Input() public historyBack?: boolean = true;
  @Output() public readonly selected = new EventEmitter<Contact>();
  @Output() public readonly back = new EventEmitter();

  public multiselectableContacts: { label: string; value: Contact }[] = [];
  public selectedContact: Contact;
  public contact: Contact;
  public isCreating = false;
  public formModel: DynamicFormControlModel[];
  public formGroup: FormGroup;
  public formLayout: DynamicFormLayout;
  public isPC20CSP: boolean;

  private builder: PCFormBuilder;
  private sendCustomerInvitation = false;

  constructor(
    private pcFormService: PCFormsService,
    private i18next: I18NextService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private m: Model
  ) { }

  ngOnInit(): void {
    this.isPC20CSP = this.m.account.currentUser.companyIsPC20Csp;
    if (!this.customer.contacts || this.customer.contacts.length <= 0) {
      this.onAddContact();
      return;
    }
    if (this.subscription) {
      this.contact = this.customer.contacts.find(c => +c.id === this.subscription.subscription_customer_id)
    } else {
      this.contact = this.customer.contacts[0] ?? undefined;
    }

    this.selectedContact = this.contact;

    this.multiselectableContacts = this.customer.contacts.map(c => {
      return {
        label: this.isPC20CSP ? c.fullName : c.fullNameWithMail,
        value: c
      }
    });
  }

  public onAddContact() {
    this.isCreating = true;
    if (this.isPC20CSP) {
      this.builder = cspContactForm(this.pcFormService);
    } else {
      this.builder = contactForm(this.pcFormService, this.i18next, []);
    }
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();
  }

  public accept(e) {
    this.sendCustomerInvitation = e;
  }

  public addContact() {
    if (this.formGroup.status !== 'VALID') return;
    const c = getContactFromForm(this.formGroup, '');
    c.company_id = this.customer.id;
    c.invite_user = this.sendCustomerInvitation;

    if (this.isPC20CSP) {
      c.email = undefined;
      c.invite_user = undefined;
      this.companyService.addCSPContact(c).subscribe(r => {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('success') as string,
          detail: this.i18next.t('contact_created') as string});
        this.selectedContact = Contact.fromJson(r);
        this.next();
      });
      return;
    }

    this.companyService.inviteContact(c).subscribe((r) => {
      if (this.sendCustomerInvitation) {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('success') as string,
          detail: this.i18next.t('contact_invited') as string});
      } else {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('success') as string,
          detail: this.i18next.t('contact_created') as string});
      }
      this.selectedContact = Contact.fromJson(r);
      this.next();
    });
  }

  public selectContact() {
    if (!this.selectedContact) return;
    this.next();
  }

  private next() {
    this.selected.emit(this.selectedContact);
  }


}
