import {Component, Output, OnInit, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-phonenumber-at-contact-sales',
  templateUrl: './phonenumber-at-contact-sales.component.html',
  styles: [
  ]
})
export class PhonenumberAtContactSalesComponent implements OnInit {

  @Output()
  public selected = new EventEmitter<void>()

  constructor() { }

  ngOnInit(): void {
  }

  public onBackOverview() {
    this.selected.emit();
  }
}
