<div [class]="'achievement-card'">
  <div class="row no-gutters achievement">
    <div class="achievement-item col-12">
      <div class="d-flex">
        <div class="w-100">
          <div class="d-flex">
            <i class="achievement-icon fas fa-play" *ngIf="achievement.maxValue && achievement.maxValue > 0"></i>
            <i class="achievement-icon fas fa-times" *ngIf="!achievement.maxValue || achievement.maxValue <= 0"></i>
            <div class="w-100">
              <div>
                <span>{{ achievement.i18next_name | i18next }}</span>
                <span *ngIf="achievement.maxValue && achievement.maxValue > 0">
                  <span>
                    (<span *ngIf="achievement.addValue && achievement.addValue !== ''">{{ achievement.value | localized_currency }} / {{ achievement.maxValue | localized_currency }}</span>
                    <span *ngIf="!achievement.addValue || achievement.addValue === ''">{{ achievement.value }} / {{ achievement.maxValue }}</span>)
                  </span>
                </span>
              </div>
              <div class="progress" *ngIf="achievement.maxValue && achievement.maxValue > 0">
                <div class="progress-bar" role="progressbar"  [ngStyle]="{ width: ((achievement.value / achievement.maxValue) * 100) + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="small mt-1" *ngIf="descriptionContent !== ''">{{ descriptionContent }}</div>
              </div>
            <i *ngIf="helpContent !== ''" class="far fa-question-circle achievement-tooltip ml-auto" [pTooltip]="helpContent"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

