<div class="m-3 d-flex flex-column flex-md-row align-items-md-end align-self-md-end">
  <app-pascom-checkbox *ngIf="filterType === 'checkbox'"
                       class="d-none d-md-block"
                       (acceptChanged)="checkboxFilterChanged.emit($event)"
                       [text]="checkboxFilterText"></app-pascom-checkbox>
  <app-pascom-dropdown
    class="mb-2 mb-md-0"
    *ngIf="filterType === 'multi' || filterType === 'both'"
    [dropdownOptions]="multiFilterOptions"
    [selectedDropdownOptions]="multiSelectedDefault"
    (selectedOptionsEventEmitter)="multiFilterChanged.emit($event)"
    [placeholder]="multiPlaceholder"
    [display]="multiFilterDisplay"
    [filter]="multiFilterSearch"></app-pascom-dropdown>

  <div class="d-flex ml-md-auto">
    <div class="d-none d-md-block ml-auto filter-search-wrapper">
      <app-searchbox (filterChanged)="searchFilterChanged.emit($event)" [placeholder]='placeholder' [onSearching]="onSearching"></app-searchbox>
    </div>

    <div class="d-flex d-md-none w-100">
      <app-searchbox (filterChanged)="searchFilterChanged.emit($event)" [placeholder]="placeholder" [onSearching]="onSearching"></app-searchbox>
    </div>


  </div>
</div>
<div class="d-block d-md-none ml-3 mb-3">
  <app-pascom-checkbox *ngIf="filterType === 'checkbox'"
                       (acceptChanged)="checkboxFilterChanged.emit($event)"
                       [text]="checkboxFilterText"></app-pascom-checkbox>
</div>
<div class="ml-3 mb-3">
  <app-pascom-checkbox *ngIf="filterType === 'both'"
                       (acceptChanged)="checkboxFilterChanged.emit($event)"
                       [text]="checkboxFilterText"></app-pascom-checkbox>
</div>



