<div *ngIf="!inCompany">
  <app-content-header [hideBackButton]="step === 'start'"
                      [historyBack]="false"
                      (backEvent)="onBack()"
                      [headline]="'company_choose_headline' | i18next"></app-content-header>
  <div class="row d-flex justify-content-center mt-2">
    <div class="card card-nb col-sm-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
      <div class="card-body">
        <div *ngIf="step === 'start'">
          <div class="h3 red">{{ 'company_choose_details_headline' | i18next }}</div>
          <p class="mb-3">{{ 'company_choose_details_description' | i18next }}</p>
          <div (click)="createCompany()" class="d-flex flex-row justify-content-between btn btn-outline-primary btn-bigger mt-3">
            <i class="fas fa-edit icon-size-biggest"></i>
            <div class="align-self-center">{{ 'company_choose_new_btn' | i18next }}</div>
            <div class="icon-size-biggest"></div>
          </div>
          <div (click)="selectCompany()" class="d-flex flex-row justify-content-between btn btn-outline-primary btn-bigger mt-3">
            <i class="fas fa-user-plus icon-size-biggest"></i>
            <div class="align-self-center">{{ 'company_choose_existing_btn' | i18next }}</div>
            <div class="icon-size-biggest"></div>
          </div>
        </div>
        <app-company-add-existing *ngIf="step ==='select'"></app-company-add-existing>
        <app-company-create *ngIf="step === 'create'" (create)="onCreated()"></app-company-create>
      </div>
    </div>
  </div>
</div>
