<app-content-header [historyBack]="false" [hideBackButton]="true" [headline]="'phonenumber_unavailable_headline' | i18next"></app-content-header>

<div class="mt-5">
  <app-icon-card>
    <h3 class="h3 red mb-3">{{ 'phonenumber_unavailable_card_headline' | i18next }}</h3>
    <div class="mb-3 d-flex">
      <div class="subinformations" [innerHTML]="'phonenumber_unavailable_card_content' | i18next"></div>
    </div>
  </app-icon-card>
  <div class="d-flex justify-content-end mt-5 form-container-footer">
    <button name="continue" class="btn btn-outline-primary" (click)="onBack()">{{ 'phonenumber_unavailable_back_button' | i18next }}</button>
  </div>
</div>
