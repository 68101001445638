<app-content-header [stepsBack]="1" [headline]="'upload_company_license_headline' | i18next"></app-content-header>

<div class="d-flex">
  <div><i class="far fa-question-circle red mr-2"></i></div>
  <div>{{ "upload_company_license_continue_hint" | i18next }}</div>
</div>

<div class="d-flex justify-content-center mt-5">
  <div class="card card-nb card-mw-300">
    <div class="card-body">
          <div class="container">
            <div class="d-flex justify-content-center features">
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center">
                  <i class="fas fa-check product-features-icon red mr-2"></i>
                  <span>{{ "upload_company_license_phone_bill_hint" | i18next }} <span
                    class="text-uppercase">{{ "app_or" | i18next }}</span></span>
                </div>
                <div class="d-flex align-items-center">
                  <i class="fas fa-check product-features-icon red mr-2"></i>
                  <span>{{ "upload_company_license_register_hint" | i18next }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-center text-align-center">
            <app-file-uploader [customUpload]="true"
                               styleClass="draggable-buttons"
                               allowedFiles="application/pdf"
                               [showUploadButton]="false"
                               chooseIcon="pi pi-upload"
                               (selected)="changed($event)"></app-file-uploader>
          </div>
        </div>
  </div>
</div>

<div class="d-flex justify-content-end mt-2">
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="files.length === 0">{{ 'file_uploader_upload_continue' | i18next}}</button>
</div>
