<ng-container *ngIf="initialized">
    <app-nfr-one-prepare
            *ngIf="isPartner && step === 'nfr'"
            (selected)="onNfrOnePrepareSelected()"
            (back)="onNfrOnePrepareBack()"
            [isWorkflow]="true"></app-nfr-one-prepare>
    <app-order-trial-phonenumber
            *ngIf="step === 'trial-phonenumber'"
            (selected)="onTrialPhonenumberSelected($event)"
            (backEmitter)="onTrialPhonenumberBack()"
            [historyBack]="false"></app-order-trial-phonenumber>
    <app-select-instance-name
            *ngIf="step === 'instance'"
            (selected)="onInstanceNameSelected($event)"
            (backEmitter)="onSelectInstanceNameBack()"
            [isTrial]="true"
            selectedDomain=""></app-select-instance-name>
    <app-phonenumber
            (selected)="onPhonenumberSelected($event)"
            *ngIf="step === 'phonenumber'"></app-phonenumber>
    <app-cloud-thankyou
            *ngIf="step === 'thank-you' || step === 'prepare-trial'"
            [orderName]="model.saleOrder?.name"></app-cloud-thankyou>
</ng-container>
