import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-no-content-message',
  templateUrl: './no-content-message.component.html',
  styleUrls: []
})
export class NoContentMessageComponent implements OnInit {
  @Input() t = 'no_content';
  @Input() icon = 'fas fa-info-circle';

  constructor() { }

  ngOnInit() {
  }
}
