import {Component, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../app.model';
import {AuthService} from '../auth/auth.service';
import {Base64} from 'js-base64';
import {WorkflowService} from '../workflow/workflow.service';
import {I18NextService} from 'angular-i18next';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: []
})
export class VerifyAccountComponent extends AutorunComponent implements OnInit {

  public token: string;
  public message = '';

  constructor(
    private m: Model,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private i18next: I18NextService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
      this.autorun(() => {
      this.token = this.m.workflow.userData.t;
      if (this.token) {
        this.authService.activate(this.token).subscribe(() => {
          this.router.navigate(['/do', 'welcome', Base64.encodeURI(this.m.workflow.userData.c)]);
        });
      }
    });
  }

  resendMail() {
    const email = this.m.account.currentUser.email;
    this.httpClient.post<any>('/services/auth/resend_activation_email', {
      email: email,
    }).subscribe(() => {
      this.messageService.add({severity: 'info',
        summary: this.i18next.t('info') as string,
        detail: this.i18next.t('verify_message_resent') as string});
    });
  }
}
