<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div>
        <img src="assets/images/erdmaennchen.svg" alt="Pascom pending">
      </div>

      <h1>{{ 'partner_pending_headline' | i18next}}</h1>
      <p class="gray">{{ 'partner_pending_description' | i18next}}</p>
    </div>
  </div>
</div>
