<app-content-header backLabel="app_back"
                    [historyBack]="false"
                    (backEvent)="back()"
                    [headline]="'port_select_block_size_headline' | i18next"></app-content-header>

<div class="row align-items-stretch">
  <div class="col-sm-12 col-lg-6 mb-3 mb-lg-0">
    <app-icon-card headline="port_select_single_number_headline" icon="fa fa-list-ol">
      <div [innerHtml]="'port_select_single_number_text' | i18next"></div>
      <div class="d-flex flex-column justify-content-end h-100">
        <button class="btn btn-primary" (click)="onPortSingleNumber()">
          {{ 'port_select_single_number_button' | i18next}}
        </button>
      </div>
    </app-icon-card>
  </div>
  <div class="col-sm-12 col-lg-6 mb-3 mb-lg-0">
    <app-icon-card headline="port_select_block_headline" icon="fa fa-th-large">
      <div [innerHtml]="'port_select_block_text' | i18next"></div>
      <div class="d-flex flex-column justify-content-end h-100">
        <button class="btn btn-primary" (click)="onPortBlock()">
          {{ 'port_select_block_button' | i18next}}
        </button>
      </div>
    </app-icon-card>
  </div>
</div>
