<app-headline-page>
    {{ 'order_complete' | i18next }}
</app-headline-page>

<app-card>
    <app-fa-icon class="d-flex mb-3 mx-auto" icon="fa-check" size="3xl"></app-fa-icon>

    <app-headline-content class="d-flex mx-auto">
        {{ 'endcustomer_upgrade_order_complete_headline' | i18next }}
    </app-headline-content>

    <div class="d-flex mx-auto" [innerHTML]="'endcustomer_upgrade_order_complete_content' | i18next"></div>
</app-card>

<div class="d-flex justify-content-end mt-3">
    <button class="btn btn-outline-primary" (click)="next.emit()">
        {{ 'back_subscription_details' | i18next }}
    </button>
</div>

