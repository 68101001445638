import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {BuyService} from '../buy.service';
import {SaleOrder} from '../buy.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionService} from '../../subscription/subscription.service';

@Component({
  selector: 'app-buy-phonenumber-thankyou',
  templateUrl: './buy-phonenumber-thankyou.component.html'
})
export class BuyPhonenumberThankyouComponent extends AutorunComponent implements OnInit {

  @Input() orderName: string;

  public order: SaleOrder;
  public isUpgrade: boolean;
  public fromInstance = false;

  public backroute: string[];
  public isCloud: boolean;
  public isCsp: boolean;

  constructor(private m: Model,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router) {  super(); }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('toinstance')) {
      this.fromInstance = true;
    }
    this.backroute = ['/phonenumbers'];
    this.autorun(() => {
      if (!this.route.snapshot.queryParamMap.has('fromwf')) {
        this.backroute = ['/phonenumbers'];
      }
    });

    this.route.queryParamMap.subscribe(params => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }
    });

    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
    });
    this.buyService.refresh(this.orderName);
  }

  public openBackroute() {
    window.history.replaceState(null, null, '/phonenumbers');
    this.m.workflow.resetWorkflow();
    this.router.navigate(this.backroute);
  }

  public openDocumentation() {
    window.open('http://doc.pascom.net/');
  }
}
