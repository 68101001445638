import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockOffer} from '../../../phonenumber/phonenumber.model';

@Component({
  selector: 'app-select-phonenumber-card',
  templateUrl: './select-phonenumber-card.component.html',
})
export class SelectPhonenumberCardComponent implements OnInit {
  @Input() blockOffer: BlockOffer;
  @Output() toggle:
    EventEmitter<{ id: string, selected: boolean}> = new EventEmitter<{id: string; selected: boolean}>();
  isSelected = false;
  constructor() {
  }

  ngOnInit() {
  }

  get isBlock() {
    return this.blockOffer.blockSize > 1;
  }

  select() {
    this.isSelected = !this.isSelected;
    this.toggle.emit({ id: this.blockOffer.blockId, selected: this.isSelected});
  }
}
