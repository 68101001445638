import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Model} from '../../app.model';
import {I18NextService} from 'angular-i18next';
import {Affiliate, AffiliateStatus} from './affiliate.model';
import {runInAction} from 'mobx';
import {Achievement, Achievements} from './achievements.model';
import {Router} from '@angular/router';
import {Benefits} from './benefits.model';

@Injectable()
export class AffiliateService {

  private needsRefresh = true;

  constructor(private m: Model,
              private httpClient: HttpClient,
              private i18next: I18NextService,
              private router: Router) {

  }

  public applyForPartnership(contract_info: string, trading_license_b64: string) {
    this.httpClient.post<any>('/services/partnership', {
      contract_info: contract_info,
      trading_license: trading_license_b64
    }).subscribe((r) => runInAction(() => this.m.affiliate.setAffiliate(r)));
  }

  public refresh(force: boolean = false) {
    if ((this.needsRefresh || force) && this.m.account.isMypascomAdmin) {
      this.httpClient.get<any[]>('/services/partnership')
        .subscribe((r) => runInAction(() => {
          this.m.affiliate.setAffiliate(r);
          this.needsRefresh = false;
        }));
    }
  }

  public getAchievements() {
    return this.createAchievementsList();
  }

  public hasCompletedSpecialAchievements() {
    let found = false;
    this.createSpecialsAchievements().forEach(achievement => {
      // if at least one achievement is completed return true
      if (achievement.completed) {
        found = true;
      }
    });
    // otherwise, there are no completed achievements
    return found;
  }

  public getBenefits() {
    return this.createBenefitsList();
  }

  public checkRoutes(affiliate: Affiliate, status?: AffiliateStatus) {
    // if (!status) {
    //   this.router.navigate(['/become-a-partner']);
    //   return;
    // }
    // if (affiliate.status === status) {
    //   return;
    // }
	//
    // console.log("Status", status);
	//
    // switch (status) {
    //   case AffiliateStatus.pending:
    //     this.router.navigate(['/partner', 'pending']);
    //     break;
    //   case AffiliateStatus.new:
    //   case AffiliateStatus.active:
    //     this.router.navigate(['/partner', 'level']);
    //     break;
    //   case AffiliateStatus.dead:
    //     this.router.navigate(['/']);
    //     break;
    //   default:
    //     this.router.navigate(['/']);
    //     break;
    // }
  }

  /**
   * Merge all created achievement lists into one object
   *
   * @returns {Achievements}
   */
  private createAchievementsList() {
    const achievements: Achievements = new Achievements();

    const storedAchievements = this.createStoredAchievements();
    achievements.specials = this.createSpecialsAchievements();
    achievements.certified = this.createCertifiedAchievements(storedAchievements);
    achievements.premium = this.createPremiumAchievements(storedAchievements);
    achievements.excellence = this.createExcellenceAchievements(storedAchievements);

    return achievements;
  }

  /**
   * Create the benefits list for the levels
   *
   * @returns {Benefits}
   */
  private createBenefitsList() {
    const benefits: Benefits = new Benefits();
    benefits.certified = ['partner_page_listing', '15_margin', '10_concurrent_user_nfr', 'free_live_support'];
    benefits.premium = ['partner_page_listing', '20_margin', '25_concurrent_user_nfr', 'free_live_support'];
    benefits.excellence = ['partner_page_listing', '25_margin', '50_concurrent_user_nfr', 'free_live_support'];
    return benefits;
  }

  /**
   * Generate the i18next keys for the achievements
   *
   * isDescriptionAdded and isHelpAdded is needed for achievements without any description or help text
   * e.g. all achievements with number values like ac_rev2k or ac_new1
   *
   * @param {string} id
   * @param {boolean} isDescriptionAdded
   * @param {boolean} isHelpAdded
   * @returns {{i18next_name: string}} maybe with additional i18next_description or i18next_help
   */
  private getI18nextAchievement(id: string, isDescriptionAdded = true, isHelpAdded = true) {
    const i18nextAchievement = {
      'i18next_name': id + '_name'
    };

    if (isDescriptionAdded) {
      i18nextAchievement['i18next_description'] = id + '_description';
    }
    if (isHelpAdded) {
      i18nextAchievement['i18next_help'] = id + '_help';
    }

    return i18nextAchievement;
  }

  /**
   * Create the "stored" data model achievements
   *
   * @returns {Achievement[]}
   */
  private createStoredAchievements(): Achievement[] {
    const storedAchievements: Achievement[] = [];
    storedAchievements.push({ id: 'ac_hp', ...this.getI18nextAchievement('ac_hp'), completed: this.m.affiliate.affiliate['ac_hp']});
    storedAchievements.push({ id: 'ac_test', ...this.getI18nextAchievement('ac_test'), completed: this.m.affiliate.affiliate['ac_test']});
    return storedAchievements;
  }

  /**
   * Create the special achievements
   *
   * @returns {Achievement[]}
   */
  private createSpecialsAchievements(): Achievement[] {
    const storedAchievements: Achievement[] = [];
    const storedAchievementKeys = ['ac_csp'];

    for (const key of storedAchievementKeys) {
      storedAchievements.push({ id: key, ...this.getI18nextAchievement(key), completed: this.m.affiliate.affiliate[key]});
    }
    return storedAchievements;
  }

  /**
   * Create and merge the relevant certified achievements data
   *
   * @param {Achievement[]} storedAchievements
   * @returns {Achievement[]}
   */
  private createCertifiedAchievements(storedAchievements: Achievement[]) {
    let achievement: Achievement[];
    achievement = [
      {id: 'ac_rev2k', ...this.getI18nextAchievement('ac_rev2k', false, true),
        completed: this.m.affiliate.affiliate.ac_rev2k,
        value: this.m.affiliate.affiliate.revenue1, maxValue: 2000, addValue: 'show_currency'},
      {id: 'ac_new1', ...this.getI18nextAchievement('ac_new1', false, true),
        completed: this.m.affiliate.affiliate.ac_new1,
        value: this.m.affiliate.affiliate.subscriptions1, maxValue: 1},
      ...storedAchievements
    ];
    return achievement;
  }

  /**
   * Create and merge the relevant premium achievements data
   *
   * @param {Achievement[]} storedAchievements
   * @returns {Achievement[]}
   */
  private createPremiumAchievements(storedAchievements: Achievement[]) {
    let achievement: Achievement[];
    achievement = [
      {id: 'ac_rev5k', ...this.getI18nextAchievement('ac_rev5k', false, true),
        completed: this.m.affiliate.affiliate.ac_rev5k,
        value: this.m.affiliate.affiliate.revenue1, maxValue: 5000, addValue: 'show_currency'},
      {id: 'ac_new4', ...this.getI18nextAchievement('ac_new4', false, true),
        completed: this.m.affiliate.affiliate.ac_new4,
        value: this.m.affiliate.affiliate.subscriptions1, maxValue: 4},
      ...storedAchievements,
      {id: 'ac_training', ...this.getI18nextAchievement('ac_training'),
        completed: this.m.affiliate.affiliate.ac_training}
    ];
    return achievement;
  }

  /**
   * Create and merge the relevant excellence achievements data
   *
   * @param {Achievement[]} storedAchievements
   * @returns {Achievement[]}
   */
  private createExcellenceAchievements(storedAchievements: Achievement[]) {
    let achievement: Achievement[];
    achievement = [
      {id: 'ac_rev20k', ...this.getI18nextAchievement('ac_rev20k', false, true),
        completed: this.m.affiliate.affiliate.ac_rev20k,
        value: this.m.affiliate.affiliate.revenue1, maxValue: 20000, addValue: 'show_currency'},
      {id: 'ac_new8', ...this.getI18nextAchievement('ac_new8', false, true),
        completed: this.m.affiliate.affiliate.ac_new8,
        value: this.m.affiliate.affiliate.subscriptions1, maxValue: 8},
      ...storedAchievements,
      {id: 'ac_training', ...this.getI18nextAchievement('ac_training'),
        completed: this.m.affiliate.affiliate.ac_training}
    ];
    return achievement;
  }


}
