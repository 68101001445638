<div class="d-flex justify-content-center mt-5" *ngIf="subscription && subscription.isOne && isAdmin">
  <button name="cancel-subscription" *ngIf="!subscription.cancelled" class="btn btn-link"
          (click)="doCancelSubscription()">
    {{'cancel_subscription'|i18next}}
  </button>
  <button name="cancel-subscription" *ngIf="subscription.cancelled"
          class="btn btn-link"
          (click)="undoCancellationSubscription()">
    {{'undo_cancellation_subscription' | i18next}}
  </button>
</div>

<p-confirmDialog #cd header="Confirmation" [key]="'undoSubscription'" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{'app_cancel' | i18next}}</button>
    <button type="button" class="btn btn-primary" (click)="cd.accept()">{{'subscription_undo_cancellation_button' | i18next}}</button>
  </p-footer>
</p-confirmDialog>
