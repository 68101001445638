import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-close-ticket-warning',
    templateUrl: './close-ticket-warning.component.html',
    styleUrls: []
  })
  export class CloseTicketWarningComponent implements OnInit {
    @Output('back')
    back: EventEmitter<void> = new EventEmitter<void>();
    @Output('confirm')
    confirm: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    ticketNumber: string;

    constructor() {}

    ngOnInit() {}

    onBack() {
      this.back.emit();
    }

    onConfirm() {
      this.confirm.emit();
    }
  }
