<p-fileUpload name="file-upload[]"
              (uploadHandler)="uploadFiles($event)"
              (onSelect)="onSelect($event)"
              multiple="true"
              [auto]="true"
              [chooseLabel]="'app_upload_attachment' | i18next"
              customUpload="true">
  <ng-template pTemplate="toolbar">
    <div class="font-small">{{ "support_attachment_file_info" | i18next: { maxLength: maxFileLength, maxSize: (maxFileSize | formatByteSize) } }}</div>
  </ng-template>
  <ng-template let-file pTemplate="file"></ng-template>
  <ng-template pTemplate="content">
    <div *ngIf="attachedFiles.length">
      <div class="p-fileupload-row font-small" *ngFor="let f of attachedFiles; let i = index;">
        <div *ngIf="f.file && f.file.objectURL && isImage(f.file)"><img [src]="f.file.objectURL" width="50px" /></div>
        <div *ngIf="f.file && !isImage(f.file)" class="text-right">
          <i class="fas fa-paperclip"></i>
        </div>
        <div *ngIf="f.file">{{f.file.name}}</div>
        <div *ngIf="f.file">{{ f.file.size | formatByteSize }}</div>
        <div *ngIf="f.file">
          <span *ngIf="!isUploadDone(i) && getFileProgress(i) > 0">{{ getFileProgress(i) }}%</span>
          <button *ngIf="isUploadDone(i)" type="button" icon="pi pi-times" pButton (click)="removeFile(i)"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-fileUpload>



