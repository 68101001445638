import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-phonenumber-bill-company-name',
  templateUrl: 'phonenumber-bill-company-name.component.html'
})
export class PhonenumberBillCompanyNameComponent implements OnInit {

  @Input() companyName: string;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  selectedCompanyName: string;

  constructor() {
  }

  ngOnInit() {
    this.selectedCompanyName = this.companyName;
  }

  public onNext() {
  this.selected.emit(this.selectedCompanyName);
  }

}
