import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnership-navigation',
  templateUrl: './partnership-navigation.component.html'
})
export class PartnershipNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
