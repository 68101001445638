import {Component, EventEmitter, OnInit, Output} from "@angular/core";

@Component({
  selector: 'app-phonenumber-port-select-size',
  templateUrl: './phonenumber-port-select-size.component.html'
})
export class PhonenumberPortSelectSizeComponent implements OnInit {

  @Output()
  public onNext = new EventEmitter<'single' | 'block'>();
  @Output()
  public onBack = new EventEmitter();

  ngOnInit(): void {
  }

  back() {
    this.onBack.emit();
  }

  onPortSingleNumber() {
    this.onNext.next('single');
  }

  onPortBlock() {
    this.onNext.next('block');
  }

}
