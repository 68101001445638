<app-content-header *ngIf="!isCsp" [stepsBack]="2" (backEvent)="back()" [hint]="'select_hosting_hint' | i18next" [headline]="'select_hosting_headline' | i18next"></app-content-header>
<app-content-header *ngIf="isCsp" [stepsBack]="2" (backEvent)="back()" [hint]="'select_hosting_hint_csp' | i18next" [headline]="'select_hosting_headline' | i18next"></app-content-header>

<div class="d-flex text-align-center h-75 flex-column flex-md-row w-100 justify-content-center">
  <div *ngIf="isCsp" class="d-flex flex-column align-items-center justify-content-center">
    <div (click)="selectCsp()" class="d-flex justify-content-center align-items-center circle-red m-2"><i class="fa fa-cloud white icon-size-biggest"></i></div>
    <button class="btn btn-outline-primary m-2" (click)="selectCsp()" queryParamsHandling="preserve">{{ 'select_hosting_csp' | i18next }}</button>
  </div>
  <div *ngIf="isCsp" class="d-flex align-items-center justify-content-center text-uppercase font-weight-bold m-5">{{ 'select_hosting_or' | i18next }}</div>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div (click)="selectCloud()" class="d-flex justify-content-center align-items-center circle-red m-2"><i class="fa fa-cloud white icon-size-biggest"></i></div>
    <button class="btn btn-outline-primary m-2" (click)="selectCloud()" queryParamsHandling="preserve">{{ 'select_hosting_pascom_cloud' | i18next }}</button>
  </div>
  <div class="d-flex align-items-center justify-content-center text-uppercase font-weight-bold m-5">{{ 'select_hosting_or' | i18next }}</div>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div (click)="selectOnsite()" class="d-flex justify-content-center align-items-center circle-red m-2"><i class="fa fa-home white icon-size-biggest"></i></div>
    <button class="btn btn-outline-primary m-2" (click)="selectOnsite()" queryParamsHandling="preserve">{{ 'select_hosting_onsite' | i18next }}</button>
  </div>
</div>
