import {Component, Input, OnInit} from '@angular/core';
import {PhonenumberCheckoutSummaryType} from '../../../phonenumber/phonenumber.model';
import {SaleOrderLine} from "../../buy.model";
import {I18NextService} from "angular-i18next";
import {PhoneNumber} from "../../../phonenumber/phoneNumber";

@Component({
  selector: 'app-checkout-phonenumber-summary-products',
  templateUrl: './checkout-phonenumber-summary-products.component.html',
  styleUrls: []
})
export class CheckoutPhonenumberSummaryProductsComponent implements OnInit {
  @Input() summary: PhonenumberCheckoutSummaryType;
  @Input() totalLabel = 'checkout_summary_total';
  @Input() orderLines?: SaleOrderLine[];
  @Input() tenantCountryCode: string = 'DE';

  constructor(private i18next: I18NextService) {
  }

  ngOnInit() {
  }

  translateOrderLineName(oderLineName: string) {
    let orderLine = oderLineName
      .replace('NumberFrom', this.i18next.t('summary_number_from') as string)
      .replace('NumberTo', this.i18next.t('summary_number_to') as string)
      .replace('bill_company_name', this.i18next.t('summary_company_name') as string);

    return PhoneNumber.formatNumbersInText(orderLine, this.tenantCountryCode);
  }
}
