import {Component, Input, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {AuthService} from '../../auth/auth.service';
import {LanguageService} from '../../language.service';
import {I18NextService} from 'angular-i18next';
import {Router} from '@angular/router';
import {NavigationService} from "../../navigation.service";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: []
})
export class UserMenuComponent extends AutorunComponent implements OnInit {

  @Input()
  public name: string;

  public isUserInCompany: boolean;


  constructor(
    private m: Model,
    private router: Router,
    private authService: AuthService,
  ) { super(); }


  ngOnInit() {
    this.autorun(() => {
      this.isUserInCompany = this.m.account.isInCompany;
    });
  }

  public logout() {
    this.authService.logout();
  }

  public get accountManagerUrl() {
    return this.authService.getOidcAuthUrl() + '/account/';
  }

  public openSettings() {
    this.router.navigate(['company', 'contacts', this.m.account.currentUser.id]);
  }
}
