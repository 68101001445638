<div class="price-card card card-nb h-100 standard">
  <div class="d-flex flex-column">
    <div class="price-card-header phonenumber">
      <div class="d-flex align-items-center flex-column phonenumber-type-container">
        <div class="subscription-type h3">{{ headline | i18next }}</div>
      </div>
    </div>
    <div class="price-card-body mt-4">
      <div class="container">
        <div class="d-flex justify-content-center text-justify">
          <div class="d-flex flex-row">
            <div class="d-flex flex-grow-1 flex-column">
              <div class="p-2" style="height: 200px;" [innerHTML]="text | i18next"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price-card-footer">
      <ng-content></ng-content>
    </div>
  </div>
</div>
