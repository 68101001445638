import {Directive, OnDestroy} from '@angular/core';
import {autorun as mobxAutorun,
  IReactionPublic,
  IReactionDisposer
} from 'mobx';
import {IAutorunOptions} from 'mobx/lib/api/autorun';

/**
 * Base class to actually handle autorun lifecycle management and avoid leaking
 */
@Directive()
export class AutorunComponent implements OnDestroy {

  private _registeredAutoruns: IReactionDisposer[] = [];

  /**
   * Adds an autorun and registers it's IReactionDisposer for destroying. Same interfaces as mobx/autorun
   * @param {(r: IReactionPublic) => any} view
   * @param {IAutorunOptions} opts
   */
  protected autorun(view: (r: IReactionPublic) => any, opts?: IAutorunOptions): void {
    const disposer = mobxAutorun(view, opts);
    this._registeredAutoruns.push(disposer);
  }

  ngOnDestroy() {
    this._registeredAutoruns.forEach(disposer => disposer());
  }
}
