import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownItem} from "@myshared/pascom-dropdown-multiselect/pascom-dropdown";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: []
})
export class FilterComponent implements OnInit {

  @Output() readonly searchFilterChanged = new EventEmitter<string>();
  @Output() readonly checkboxFilterChanged = new EventEmitter<boolean>();
  @Output() readonly multiFilterChanged = new EventEmitter<any>();

  @Input() placeholder = '';
  @Input() multiPlaceholder?;
  @Input() onSearching = false;
  @Input() filterType: 'checkbox' | 'multi' | 'both' | '' = '';
  @Input() checkboxFilterText = '';
  @Input() multiFilterOptions: IDropdownItem[];
  @Input() multiSelectedDefault?: IDropdownItem[] = [];
  @Input() multiFilterDisplay: 'chip' | 'comma' = 'comma';
  @Input() multiFilterSearch = false;


  constructor() { }

  ngOnInit() {
  }
}
