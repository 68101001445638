<div *ngIf="subscription" class="card text-white bg-danger h-100 card-nb shadow">
  <div class="card-body pt-0 pb-0">
      <div class="d-flex flex-column flex-md-row p-3">
        <div class="d-flex flex-column">
          <h3 class="">{{ "one_click_cloud_migration_headline" | i18next }}</h3>
          <div class="mb-3" [innerHTML]="'one_click_cloud_migration_discontinue_text' | i18next:
          { type: subscriptionType, date: (subscription.migrationEndDate | localized_date)  }"></div>
          <div class="mt-auto">
            <button name="cloud-migration" class="btn btn-outline-light m-0" (click)="doMigration()">{{ "one_click_cloud_migration_button"  | i18next }}</button>
          </div>
        </div>
        <div class="mt-3 ml-0 ml-md-5 mt-md-0">
          <div class="d-flex">
            <div class="mr-1 align-items-start"><i class="fas fa-plus-circle benefits-icon"></i></div>
            <div [innerHTML]="'one_click_cloud_migration_benefit_user' | i18next"></div>
          </div>
          <div class="d-flex">
            <div class="mr-1 align-items-start"><i class="fas fa-plus-circle benefits-icon"></i></div>
            <div [innerHTML]="'one_click_cloud_migration_benefit_fair_migration' | i18next"></div>
          </div>
          <div class="d-flex">
            <div class="mr-1 align-items-start"><i class="fas fa-plus-circle benefits-icon"></i></div>
            <div [innerHTML]="'one_click_cloud_migration_benefit_monthly_billing' | i18next"></div>
          </div>
          <div class="d-flex">
            <div class="mr-1 align-items-start"><i class="fas fa-plus-circle benefits-icon"></i></div>
            <div [innerHTML]="'one_click_cloud_migration_benefit_trunk' | i18next"></div>
          </div>
        </div>
      </div>
  </div>
</div>


