import {Component, EventEmitter, OnInit, Output, AfterViewInit, Input} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {CustomerService} from '../../customer/customer.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {CountryService} from '../../country/country.service';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {CompanyService} from '../../company/company.service';
import {filterText} from '@myshared/utils';
import {BuyService} from '../buy.service';
import {action, observable} from '@myshared/mobx-connector';
import {Contact} from '../../company/company.model';
import {getContactFromForm} from '../../company/contact.form';
import {Customer} from '../../customer/customer.model';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html'
})
export class SelectContactComponent extends AutorunComponent implements OnInit, AfterViewInit {
  public contacts: Contact[];
  @Input() public customer: Customer;

  public formModel: DynamicFormControlModel[];
  public formGroup: FormGroup;
  public formLayout: DynamicFormLayout;

  @observable private filter$: string;

  @Output() public selected = new EventEmitter<string>();

  private builder: PCFormBuilder;

  constructor(private m: Model,
              private customerService: CustomerService,
              private pcFormService: PCFormsService,
              private countryService: CountryService,
              private companyService: CompanyService,
              private buyService: BuyService,
              private messageService: MessageService,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    this.doFilter('');
    this.builder = this.pcFormService.newBuilder('contact');
    this.builder.addTextInput({
      id: 'first_name',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'last_name',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'email',
      validators: {required: null}
    });

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.autorun(() => {
      const customer = this.m.customer.customers.find(r => r.id === this.customer?.id);
      this.contacts = filterText<Contact>(customer.contacts, this.filter$);
    });
  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  @action doFilter(value: string) {
    this.filter$ = value;
  }

  addContact() {
    const c = getContactFromForm(this.formGroup, '');
    c.company_id = this.customer.id;

    this.companyService.inviteContact(c).subscribe(() => {
      this.messageService.add({severity: 'success',
        summary: this.i18next.t('success') as string,
        detail: this.i18next.t('contact_invited') as string});
      this.formGroup.reset();
      this.refreshCompany();
    });
  }

  selectContact(c: Contact) {
    this.selected.emit(c.id);
  }

  public back() {
    window.history.back();
  }

  private refreshCompany() {
    this.countryService.refresh();
    this.companyService.refresh();
    this.customerService.refresh(true);
  }

}
