import {Component, OnInit, AfterViewInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {CustomerService} from '../customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {FormGroup} from '@angular/forms';
import {DynamicFormLayout, DynamicFormControlModel} from '@ng-dynamic-forms/core';
import {CountryService} from '../../country/country.service';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';
import {customerForm, getCustomerFromForm, setCustomerToForm} from '../customer.form';
import {Customer} from '../customer.model';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: []
})
export class CustomerDetailsComponent extends AutorunComponent implements OnInit, AfterViewInit {
  public customerId: string;
  public customer: Customer;

  customerSubscriptions: Subscription[];
  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;

  constructor(private route: ActivatedRoute,
              private m: Model,
              private customerService: CustomerService,
              private countryService: CountryService,
              private subscriptionService: SubscriptionService,
              private pcFormService: PCFormsService,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId');
    // Param 2 as 'CountrySelectionDisabled' will deactivate the selectable countries.
    this.builder = customerForm(this.pcFormService, 'CountrySelectionDisabled');

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.customerService.refresh();
    this.countryService.refresh();
    this.subscriptionService.refresh();

    this.autorun(() => {
      this.customer = this.m.customer.customers.find((x) => x.id == this.customerId);
      if (this.customer) {
        this.customerSubscriptions = this.m.subscription.subscriptions.filter(s => this.customer.appliance_ids.indexOf(s.appliance_id as any) !== -1);
        setCustomerToForm(this.formGroup, this.customer);
      }
    });

  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  onSave() {
    if (this.formGroup.status === 'VALID') {
      this.customerService.update(getCustomerFromForm(this.formGroup, this.m.country.countries, this.customer))
        .subscribe(r => this.router.navigate(['/customers']));
    }
  }

  onAddSubscription() {
    this.router.navigate(['/subscriptions/select-product'], {
      queryParams: { customerid: this.customerId }
    });
  }

  public onBack() {
    this.router.navigate(['/customers']);
  }

}
