import {Component, OnInit} from '@angular/core';
import {CloudStatusType, SupportService} from '../support.service';

@Component({
  selector: 'app-cloud-status',
  templateUrl: './cloud-status.component.html'
})
export class CloudStatusComponent implements OnInit {

  status: CloudStatusType;

  constructor(private supportService: SupportService) { }

  ngOnInit(): void {
    this.supportService.getCloudStatus().subscribe(status => {
      this.status = status;
    });
  }

  get operational(): boolean {
    return this.status === 'operational';
  }


  get degraded(): boolean {
    return this.status === 'degraded';
  }

  get outage(): boolean {
    return this.status === 'outage';
  }

}
