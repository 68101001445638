import { Component, OnInit } from '@angular/core';
import {Model} from '../../app.model';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BuyService} from '../../buy/buy.service';
import {SubscriptionService} from '../../subscription/subscription.service';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {HttpClient} from '@angular/common/http';
import {ItemizedBillingTypes, Subscription} from '../../subscription/subscription.model';

@Component({
  selector: 'app-workflow-itemized-billing',
  templateUrl: './workflow-itemized-billing.component.html',
  styles: [
  ]
})
export class WorkflowItemizedBillingComponent implements OnInit {

  public applianceId: string;
  public subscription: Subscription;
  private backroute: string[] = null;

  constructor(
    private m: Model,
    private route: ActivatedRoute,
    private router: Router,
    private buyService: BuyService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService,
    private i18next: I18NextService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {

    if (!this.m.account.hasTrunkAccess && !this.m.account.currentUser.companyIsPartner) {
      this.resetWorkflowAndRedirect();
      return;
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }

      if (params.has('subroute')) {
        const appId = params.get('subroute');

        if (!appId || !Number.isInteger(parseInt(appId))) {
          this.resetWorkflowAndRedirect();
          return;
        }

        this.applianceId = appId;

        this.subscriptionService.getSubscriptionDetails(+this.applianceId)
          .subscribe((r) => {
            this.subscription = Subscription.fromJson(r);
            if (!this.subscription
                || !this.subscription.isOne || !this.subscription.isValid
                || !this.subscription.tariffPlanDetail?.currentPlan
                || this.subscription.tariffPlanDetail.currentPlan === 'PC-ONE-TARIFF-TRIAL') {
              this.resetWorkflowAndRedirect();
              return;
            }
          });

        return;
      }

      this.resetWorkflowAndRedirect();

      return;
    });
  }

  resetWorkflowAndRedirect(redirectToSubscriptionsList: boolean = false) {
    this.m.workflow.resetWorkflow();

    if (this.backroute) {
      this.router.navigate(this.backroute);
      return;
    }

    if (redirectToSubscriptionsList || !this.applianceId) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/subscriptions', this.applianceId]);
    }
  }

  public onItemizedBillingChanged(itemizedBilling: ItemizedBillingTypes) {
    this.http.post<any>(`/services/appliance/${this.applianceId}/itemized_billing_type`, {
      'itemized_billing_type': itemizedBilling
    }).subscribe(r => {
        this.resetWorkflowAndRedirect();
    });
  }
}
