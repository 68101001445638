<div class="card card-nb h-100 agreements" *ngIf="agreement">
  <div class="card-body d-flex flex-column">
      <div class="d-flex flex-column mb-3">
        <div class="font-weight-bold">{{agreement.subject}}</div>
      </div>
    <div *ngIf="!agreement.acknowledged && showNotSignedHint">
      <i class="fa fa-exclamation-triangle mb-3 red"></i><span class="ml-2 red">{{'agreement_not_signed' | i18next}}</span>
    </div>
    <div class="subinformations d-flex flex-column">
      <div>
        <i class="fa fa-info-circle red"></i><span class="ml-2">{{agreement.info}}</span>
      </div>
      <ng-content select="[content]"></ng-content>
    </div>
    <div *ngIf="inlinePdfLink">
      <ng-content select="[top-buttons]"></ng-content>
    </div>
    <!-- Attachment section BEGIN -->
    <div *ngIf="showAttachmentsList && agreement.attachments.length > 0" class="mt-3">
      <div class="font-weight-bold mb-3">{{'attachments' | i18next}}</div>
      <div *ngFor="let a of agreement.attachments">
        <div class="d-flex justify-content-between align-items-center align-self-center mb-1">
          <a class="btn btn-link m-0 p-0" [href]=createLinkForAttachment(a) [download]=a.downloadName target="_blank">
            {{a.downloadName}}
          </a>
          <i class="fas fa-print red cursor-pointer" (click)="printAttachment(a)"></i>
        </div>
      </div>
    </div>
    <!-- Attachment section END -->
    <pdf-viewer *ngIf="inlinePdfLink" [src]="inlinePdfLink" [original-size]="true" (error)="onError($event)" (after-load-complete)="callBackFn($event)"></pdf-viewer>
    <div>
      <ng-content select="[bottom-buttons]"></ng-content>
    </div>
  </div>
  <div *ngIf="agreement.acknowledged" class="row subinformations pb-1 mr-2">
    <div class="d-flex ml-auto align-items-end flex-column mt-auto">
      <div class="mt-auto" style="min-height: 22px;">
        <span class="mr-2 status-text active" [innerHTML]="'agreement_signed_on' | i18next: {date: (agreement.acknowledged | localized_date)}">
        </span>
      </div>
    </div>
  </div>
  <div class="m-0 pt-1 status-bg active"></div>
</div>



