<div *ngIf="article" class="mt-3">
    <div class="card" [ngClass]="{ 'is-customer': isCustomer }">
        <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted"><strong>{{ article.from }}</strong><span class="ml-3">{{ article.createdAt | localized_datetime }}</span></h6>
            <div class="mt-3" [innerHTML]="articleBody"></div>
        </div>
    </div>
</div>
<div *ngIf="hasAttachment" class="attachment mb-4">
    <app-article-attachments [ticketId]="ticketId" [articleId]="article.id" [attachments]="article.attachments"></app-article-attachments>
</div>
