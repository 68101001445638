<div class="d-flex flex-column mb-3">
  <div *ngIf="!hideBackButton">
    <button class="btn btn-outline-primary mb-2" (click)="back()"><i class="fas fa-angle-left mr-2"></i>{{ backLabel | i18next }}</button>
  </div>
  <div *ngIf="headline" class="h3">{{headline}}</div>
  <ng-content></ng-content>
</div>
<div *ngIf="!!hint" class="d-flex">
  <div class="mr-2">
    <i class="fa fa-info-circle mb-3 red"></i>
  </div>
  <div>{{hint}}</div>
</div>
