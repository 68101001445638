import {Routes} from "@angular/router";
import {WorkflowPartnerOneDowngradeCuComponent} from "./workflow-partner-one-downgrade-cu.component";

export const workflowPartnerOneDowngradeCuRouting: Routes = [
    { path: 'partner/downgrade-cu/:aid', component: WorkflowPartnerOneDowngradeCuComponent, children: [
        { path: 'info', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'info' } },
        { path: 'contact', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'contact' } },
        { path: 'address', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'address' } },
        { path: 'payment', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'payment' } },
        { path: 'checkout', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'checkout' } },
        { path: 'complete', component: WorkflowPartnerOneDowngradeCuComponent, data: { step: 'complete' } },
        { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
