import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Model} from "../app.model";

@Component({
  selector: 'app-workflow-handler',
  templateUrl: './workflow-handler.component.html',
  styleUrls: []
})
export class WorkflowHandlerComponent implements OnInit, OnDestroy {

  constructor(private m: Model) {
  }

  ngOnInit() {
    this.m.buy.resetCurrentOrder(); // be sure to reset the current order on every workflow
  }

  ngOnDestroy(): void {
  }
}
