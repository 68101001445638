import {
  format,
  isAfter,
  isBefore,
  sub,
  add,
  differenceInDays,
  getDay,
  toDate,
  set,
} from 'date-fns';
import type {Duration} from 'date-fns';

type PcDateInput = string | Date | number;

export enum Days {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export type DateSetValues = {
  year?: number
  month?: number
  date?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

/**
 * Static pcDate wrapper function
 *
 * Standard used library is date-fns for all formats and calculations.
 * You can easily chain methods for calculate, output etc.
 *
 * @param customDate
 */
export function pcDate(customDate?: PcDateInput) {
  const date = customDate ? getDate(customDate) : Date.now();

  function getDate(date: PcDateInput): Date | number {
    return (date instanceof Date || typeof date === 'number') ? date : new Date(date);
  }

  return {
    format(dateFormat: string = 'yyyy-MM-dd') {
      return format(date, dateFormat);
    },

    formatDate(): string {
      return format(date, 'P');
    },

    formatDateTime(): string {
      return format(date, 'Pp');
    },

    isAfter(dateToCompare: PcDateInput): boolean {
      return isAfter(date, getDate(dateToCompare));
    },

    isBefore(dateToCompare: PcDateInput) {
      return isBefore(date, getDate(dateToCompare));
    },

    sub(duration: Duration) {
      return pcDate(sub(date, duration));
    },

    add(duration: Duration) {
      return pcDate(add(date, duration));
    },

    diffInDays(diffDate: PcDateInput) {
      return differenceInDays(date, getDate(diffDate))
    },

    day(): Days {
      return getDay(date)
    },

    set(dateValues: DateSetValues) {
      return pcDate(set(date, dateValues));
    },

    toDate() {
      return toDate(date);
    }
  }
}
