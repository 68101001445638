<div class="card card-nb h-100" *ngIf="subscription">
  <div class="card-body pb-0">
    <div class="row">
      <div class="col">
        <h2>{{ 'details' | i18next }}</h2>
      </div>
    </div>
    <div class="row mb-2" *ngIf="subscription.appliance_type != 'csp'">
      <div class="col">{{subscription.users}} {{ (subscription.is_v3 && !subscription.isOnsitePerpetual ? 'subscription_concurrent_users' : 'subscription_users') | i18next }}</div>
    </div>
    <div class="row">
      <div class="col">{{ 'subscription_license' | i18next }}: {{subscription.licenseTypeFormatted}}</div>
    </div>
    <div class="row" *ngIf="subscription.has_support">
      <div class="col">{{ 'subscription_support' | i18next }}: {{subscription.support_level}}</div>
    </div>
    <div class="row" *ngIf="subscription.sla_product && subscription.support_level == 'premium'">
      <div class="col">{{ 'subscription_sla' | i18next }}: {{subscription.sla_product | i18next}}</div>
    </div>
    <div class="row mt-3" *ngIf="subscription.isOnsitePerpetual">
      <div class="col">
        <i class="fa fa-info-circle mb-3 red"></i><span class="ml-2 red">{{ 'subscription_onsite_perpetual' | i18next }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-3 red" *ngIf="subscription.isCancelled">{{'subscription_cancelled' | i18next}}&nbsp;{{subscription.end_date | localized_date}}</div>
        <div class="mt-3" *ngIf="!subscription.isCancelled && subscription.next_invoice">{{'subscription_next_invoice' | i18next}}&nbsp;{{subscription.next_invoice | localized_date}}</div>
      </div>
    </div>
    <div class="row mt-3 w-100 justify-content-end" *ngIf="subscription.is_v3">
      <button name="cancel-subscription" class="btn btn-link" *ngIf="isAdmin && !subscription.isCancelled && !subscription.isPerpetual" (click)="doCancelSubscription()">
        {{'cancel_subscription'|i18next}}
      </button>
      <button *ngIf="!subscription.isClassicFree && isUpgradeAvailable" class="btn btn-outline-primary" (click)="doUpgradeSubscription()">
        {{ 'subscription_upgrade_plan' | i18next }}
      </button>
    </div>
  </div>
</div>
