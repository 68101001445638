import {Directive} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WorkflowComponent} from "../workflow.component";
import {firstValueFrom} from "rxjs";
import {Subscription} from "../../subscription/subscription.model";
import {normalizeOneSLAProduct} from "@myshared/utils";
import {SlaProduct} from "../../buy/select-product/product.model";
import {observable, runInAction} from "mobx";
import {SubscriptionService} from "../../subscription/subscription.service";
import {BuyService} from "../../buy/buy.service";
import {Model} from "../../app.model";
import {HttpClient} from "@angular/common/http";
import {CompanyService} from "../../company/company.service";
import {WorkflowEndcustomerService} from "./workflow-endcustomer.service";
import {SaleOrder} from "../../buy/buy.model";

interface WorkflowModel {
    cu?: number,
    saleOrder?: SaleOrder,
    subscription: Subscription,
    slaName?: string,
    sla?: SlaProduct,
    selectedSla?: { product: string, short: string, index: number },
    subscriptionSla?: { product: string, short: string, index: number }
}

@Directive()
export abstract class WorkflowEndcustomerChangeSlaComponent extends WorkflowComponent {

    @observable
    public model: WorkflowModel;

    private workflowName: 'upgrade-sla' | 'downgrade-sla';

    protected constructor(protected route: ActivatedRoute,
                          protected router: Router,
                          protected subscriptionService: SubscriptionService,
                          protected buyService: BuyService,
                          protected m: Model,
                          protected http: HttpClient,
                          protected workflowEndcustomerService: WorkflowEndcustomerService,
                          protected companyService: CompanyService) {
        super(route);
    }

    protected async initWorkflow(workflowName: 'upgrade-sla' | 'downgrade-sla') {
        this.workflowName = workflowName;
        const so = this.route.snapshot.queryParamMap.get('so');
        const slaName = this.route.snapshot.queryParamMap.get('sla');
        const subscriptionJson = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
        const subscription = Subscription.fromJson(subscriptionJson);

        if (!subscription) {
            this.resetWorkflow();
            return;
        }

        if (subscription.isCancelled) {
            this.resetWorkflow();
            return;
        }

        if (!subscription.is_my) {
            this.resetWorkflow();
            return;
        }

        if (!!subscription.scheduledSLAOrder) {
            this.resetWorkflow();
            return;
        }

        const model: WorkflowModel = {
            subscription
        };


        try {
            model.selectedSla = normalizeOneSLAProduct(slaName);
            model.subscriptionSla = normalizeOneSLAProduct(subscription.sla_product);
        } catch (e) {
            this.resetWorkflow();
            return;
        }

        const cloudProducts = await firstValueFrom(
          this.http.get<{ sla }>('/services/products/cloud')
        );
        const sla = cloudProducts.sla
          .map(r => SlaProduct.fromJson(r))
          .find(r => r.name === model.selectedSla.product);

        if (!sla) {
            this.resetWorkflow();
            return;
        }

        model.sla = sla;

        // If we already have a sale order on our purchase model, we load it from the API.
        if (!this.m.buy.currentOrder?.name && so) {
            model.saleOrder = await firstValueFrom(this.buyService.getSaleOrder(so));
        }

        runInAction(() => {
            this.model = model
        });

        if (this.checkSelectedProduct(true)) {
            this.resetWorkflow();
            return;
        }
    }

    /**
     * Override this checkSelectedProduct function to handle the workflow.
     * This can be overridden by the class and can include the statement
     *
     * If TRUE, workflow will reset
     * IF FALSE, workflow will continue
     *
     * @param ifStatement
     * @protected
     */
    protected abstract checkSelectedProduct(ifStatement: boolean);

    protected async createOffer() {
        if (this.model.saleOrder?.name) {
            return;
        }

        const saleOrder = await firstValueFrom(this.workflowEndcustomerService.oneApplianceSlaChange(this.applianceId, this.model.selectedSla.product));
        runInAction(() => {
            this.model.saleOrder = saleOrder;
        })
    }

    protected navigate(url: any[]) {
        this.router.navigate(['/do', this.workflowName, this.applianceId, ...url], {
            queryParams: {
                so: this.model.saleOrder?.name ?? undefined,
                sla: this.model.selectedSla?.short ?? undefined,
            },
        });
    }
}
