import {Directive, OnInit} from '@angular/core';
import {AutorunComponent} from "@myshared/autorun.component";
import {ActivatedRoute} from "@angular/router";

@Directive()
export abstract class WorkflowComponent extends AutorunComponent implements OnInit {
    public step: string = '';
    public applianceId: string = '';

    public initialized = false;

    protected constructor(protected route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.applianceId = this.route.snapshot?.paramMap.get('aid');
        this.route.url.subscribe((r: any) => {
            // First, check if the child (if it exists) has a data step, then the parent.
            // If neither has step data, it will be empty.
            const step = this.route.snapshot?.firstChild?.data['step'] ?? this.route.snapshot?.data['step'] ?? '';
            this.handleRoute(step);
        });
    }

    ngOnDestroy() {
    }

    protected handleRoute(step: string) {
        this.step = step;
    }

    protected resetWorkflow() {
        if (!!this.applianceId) {
            window.location.replace('/subscriptions/'+this.applianceId);
        } else {
            window.location.replace('/');
        }
    }
}
