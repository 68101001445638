import {Subscription} from "../subscription/subscription.model";
import {pcDate} from "../date.service";
import {slaOneProductNames} from "../buy/select-product/product.model";

/**
 * Internal const accessors
 */
export const STORAGE_ODOO_SESSION_ID = 'odoo_session_id';
export const SESSION_ID = 'session_id';

/**
 * Internal interface to make the filterText signature more readable
 */
interface IMatchable {
  matchesFilter(predicate: (x: string) => boolean);
}

/**
 * Filter a list of T for a given search text (case insensitive)
 * @param {(T & IMatchable)[]} items - the collection to filter
 * @param {string} filter - the search criterion
 * @returns {T[]} - the filtered collection
 */
export function filterText<T>(items: (T & IMatchable)[], filter: string): T[] {
  const flt = filter.toUpperCase();
  return items.filter(i => i.matchesFilter((n) => n && n.toUpperCase().indexOf(flt) !== -1));
}

/**
 * Polyfill for missing "origin" property on anchor elements in IE
 * @param a
 * @returns string
 */
export function originFromAnchor(a: any): string {
  if (a.origin) {
    return a.origin as string;
  }
  return a.protocol + '//' + a.hostname + (a.port ? ':' + a.port : '') + '/';
}

export function generateBackUrlWithParameters(url: string, parameters: object): string {
  // Parse and construct a valid back URL
  const a: any = document.createElement('a');
  a.href = url;

  const newSearch = Object.keys(parameters).map(k => k + '=' + parameters[k]).join('&');

  return originFromAnchor(a) + a.pathname + a.search + (a.search ? '&' : '?') + newSearch;
}

export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

/**
 * Easier function for size calc
 *
 * Additional mode for iec binary calculation.
 * Means to calculate as standard empty or true with bytes of 1024,
 * or otherwise calc with 1000.
 * Sometimes it can be helpfully to use calculation with 1000 instead of 1024. Also, it is possible to
 * choose between iec symbols MiB (only if iec calc enabled) or MB.
 *
 * @param bytes
 * @param iecCalc true or empty for iec binary 1024 calculation, otherwise decimal 1000 calculation
 * @param iecOutput shows the output as MB or MiB (iecOutput = true works only with iecCalc enabled)
 */
export function formatByteSize(bytes: number, iecCalc: boolean = true, iecOutput: boolean = false): { value: number, symbol: string} {
  if (bytes === 0) {
      return {
        value: 0,
        symbol: 'B'
      };
  }
  const k = iecCalc ? 1024 : 1000;
  const symbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const iecSymbols = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const selectedSymbols = iecOutput ? iecSymbols : symbols;
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  // clamp number between minimum decimal 0 and maximum array length of selectable symbols
  i = clamp(i, 0, selectedSymbols.length - 1);

  return {
    value: (bytes / Math.pow(k, i)),
    symbol: selectedSymbols[i]
  };
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function tariffNameTranslationKey(tariff: string) {
    return 'tariff_' + tariff
        .replace('PC-ONE-TARIFF-', '')
        .replace('-', '_')
        .toLowerCase();
}

export function ibanMask(iban: string) {
  if (!iban) return;
  return (iban.substring(0,7) + iban.substring(7).replace(/.(?=.{4,}$)/g, '*'))
    .replace(/\s/g, '');
}

export function trimInput(input: string) {

  if (input && typeof input === 'string') {
    return input.trim();
  }

  return input;
}

export function classicMigrationEndDate(subscription: Subscription) {
  if (subscription.isClassicFree || !subscription.nextPossibleEndDate) {
    return pcDate('01/01/2025');
  }

  return pcDate(subscription.nextPossibleEndDate).set({ year: 2024 });
}

export function normalizeOneSLAProduct(slaName: string): { product: string, short: string, index: number } {
  if (!slaName) {
    return;
  }

  if (!slaName.includes('PC-ONE')) {
    slaName = `PC-ONE-${slaName}`;
  }

  if (!slaOneProductNames.includes(slaName)) {
    throw 'Cannot find sla.';
  }

  const matchedIndex = slaName.match(new RegExp('\\d+$', 'g'));
  if (!matchedIndex) {
    throw 'Cannot execute sla name index matching.';
  }

  const matchedShort = slaName.match(new RegExp('SLA\\d+$', 'g'));
  if (!matchedShort) {
    throw 'Cannot execute sla name short matching.';
  }

  return {
    product: slaName,
    short: matchedShort[0],
    index: +matchedIndex[0]
  }
}

export function getEnvByUrl() {
  const hostname = window.location.hostname;

  return {
    test: hostname.includes('my.pascom-test.net'),
    prod: hostname.includes('my.pascom.net'),
    dev: !hostname.includes('my.pascom.net') && !hostname.includes('my.pascom-test.net'),
  }
}
