<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div *ngIf="disabled">
        <img src="assets/images/erdmaennchen.svg" alt="pascom welcome picture">
      </div>
      <div *ngIf="!disabled">
        <img src="assets/images/flamingo.svg" alt="pascom welcome picture">
      </div>
      <h1 *ngIf="disabled">{{ 'thank_you_cloud_headline' | i18next}}</h1>
      <h1 *ngIf="!disabled">{{ 'thank_you_cloud_headline2' | i18next}}</h1>
      <p class="gray">{{ 'thank_you_cloud_content' | i18next}}</p>
      <p class="mt-5"><a [name]="disabled ? 'waiting' : 'ready'" class="btn btn-outline-primary" href="{{redirectUrl}}" target="_blank" [ngClass]="activeUrl">{{ 'thank_you_cloud_goto' | i18next }}</a></p>
      <div *ngIf="disabled" class="spinner m-4">
        <img src="assets/images/loading.gif" alt="Loading content">
      </div>
      <div class="mb-3">
        <span class="btn btn-link no-border" (click)="openBackroute()">{{ 'thank_you_cloud_back' | i18next }}</span>
      </div>
    </div>
  </div>
</div>
