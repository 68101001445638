<div class="d-flex align-items-center mb-3">
  <div class="h3">{{ 'customer_headline' | i18next }}</div>
  <div class="ml-auto bd-highlight" *ngIf="isAdmin">
    <button class="btn btn-outline-primary" [routerLink]="['/customers', 'add']">{{'customer_add' | i18next }}</button>
  </div>
</div>

<app-filter (searchFilterChanged)="doFilter($event)" [placeholder]="'customer_search_placeholder' | i18next"></app-filter>

<div class="row align-items-stretch mt-2">
    <app-customer-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let c of customers" [customer]="c" [detaillist]="true">
      <button class="btn btn-outline-primary" [routerLink]="['/customers', c.id, 'contacts']">{{'app_details' | i18next }}</button>
    </app-customer-card>
</div>
