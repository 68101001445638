import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-phonenumber-unavailable',
  templateUrl: './phonenumber-unavailable.component.html',
})
export class PhonenumberUnavailableComponent {
  @Output() selected: EventEmitter<void> = new EventEmitter<void>();
  public onBack(): void {
    this.selected.emit();
  }
}
