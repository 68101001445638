import { Component, OnInit } from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {AffiliateService} from './affiliate/affiliate.service';
import {Model} from '../app.model';
import {Affiliate} from './affiliate/affiliate.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html'
})
export class PartnerComponent extends AutorunComponent implements OnInit {

  public affiliate: Affiliate;

  constructor(private affiliateService: AffiliateService,
              private m: Model,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.affiliate = this.m.affiliate.affiliate;
      this.redirectIfPartnerDead();
    });
    this.affiliateService.refresh();
  }

  // FIXME: Maybe a guard for this route
  redirectIfPartnerDead() {
    if (this.m.affiliate.isPartnerDead) {
      this.router.navigate(['/']);
      return;
    }
  }

}
