import {AbstractControl, ValidationErrors} from '@angular/forms';
import {PhoneNumber} from "../phonenumber/phoneNumber";

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// It will be also check if domain exists (for W3C spec is correct without top level domain)
export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === '') return null;

    const numberMatchPattern = /^\+[0-9]+$/.test(value);
    const libPhoneNumberCheck = PhoneNumber.isValid(value ?? '');

    return libPhoneNumberCheck && numberMatchPattern ? null : {phoneNumber: true};
}
