import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {AutorunComponent} from "@myshared/autorun.component";
import {Model} from "../../app.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {I18NextService} from "angular-i18next";
import {SubscriptionService} from "../subscription.service";

@Component({
  selector: 'app-subscription-one-cancel-button',
  templateUrl: './subscription-one-cancel-button.component.html',
})
export class SubscriptionOneCancelButtonComponent extends AutorunComponent implements OnInit {

  @Input() public subscription: Subscription;
  public isAdmin: boolean;

  constructor(private m: Model,
              private router: Router,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private i18next: I18NextService,
              private subscriptionService: SubscriptionService)
  { super(); }

  async ngOnInit() {
    this.autorun(async () => {
      this.isAdmin = this.m.account.isMypascomAdmin;
    });
  }

  public doCancelSubscription() {
    this.router.navigate(['/do', 'cancel-subscription', this.subscription.appliance_id], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public undoCancellationSubscription() {
    this.confirmationService.confirm({
      key: 'undoSubscription',
      message: this.i18next.t('undo_cancellation_subscription_confirm_message') as string,
      header: this.i18next.t('undo_cancellation_subscription_confirm_header') as string,
      accept: () => this.subscriptionService.undoCancellationSubscription(this.subscription)
        .subscribe(_ => window.location.reload())
    });
  }
}
