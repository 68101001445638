import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-company-add-existing',
  templateUrl: './company-add-existing.component.html'
})
export class CompanyAddExistingComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
