import { Component, OnInit, Input } from '@angular/core';
import { Attachment } from '../support.model';
import { SupportService } from '../support.service';

@Component({
    selector: 'app-article-attachments',
    templateUrl: './article-attachments.component.html',
    styleUrls: []
  })
  export class ArticleAttachmentsComponent implements OnInit {

    @Input()
    articleId: number;
    @Input()
    ticketId: number;
    @Input()
    attachments: Attachment[];

    constructor(private supportService: SupportService) {}
    ngOnInit() {}

    get downloadLink() {
      return this.supportService.downloadLink;
    }

  }
