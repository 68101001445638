<button class="dropdown-item p-2" *ngIf="isDE" (click)="switchEN()">
  <span class="d-flex justify-content-between">
    <i class="fas fa-globe icon-size-big mr-2"></i>
    <span>{{ 'language_english' | i18next }}</span>
  </span>
</button>
<button class="dropdown-item p-2" *ngIf="isEN" (click)="switchDE()">
  <span class="d-flex justify-content-between">
    <i class="fas fa-globe icon-size-big mr-2"></i>
    <span>{{ 'language_german' | i18next }}</span>
  </span>
</button>
