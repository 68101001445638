import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tariff} from '../../tariff.model';
import {tariffNameTranslationKey} from "@myshared/utils";

@Component({
    selector: 'app-tariff-info-card',
    templateUrl: './tariff-info-card.component.html'
})
export class TariffInfoCardComponent implements OnInit {

    @Input() public tariff: Tariff;
    @Input() public badgeTitle = '';
    @Input() public highlighted = false;
    @Input() public currentlyUsed = false;

    @Output() public selected = new EventEmitter<string>();

    ngOnInit(): void {
    }

    public onTariffSelected() {
        this.selected.emit(this.tariff.name);
    }

    public get displayName(): string {
        return tariffNameTranslationKey(this.tariff.name);
    }

    public getZoneTranslationKey(zoneName: string): string {
        return 'tariff_min_' + zoneName.replace('-', '_').toLowerCase();
    }
}

