import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {Subscription} from '../../subscription/subscription.model';

type WorkflowChangeHostStep =
  | 'change-host-warning'
  | 'select-csp-host'
  | 'confirm-change-host'
  | 'change-host-done' ;

@Component({
  selector: 'app-workflow-change-host',
  templateUrl: './workflow-change-host.component.html'
})
export class WorkflowChangeHostComponent extends AutorunComponent implements OnInit {

  public workflowStep: WorkflowChangeHostStep = 'change-host-warning';
  public applianceId: string;
  public subscription: Subscription;
  public oldCspHost: {aid: string, aname: string};
  public newCspHost: {aid: string, aname: string};
  private backroute: string[] = null;

  constructor(private m: Model,
              private route: ActivatedRoute,
              private router: Router,
              private subscriptionService: SubscriptionService) {
    super();
  }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }

      if (params.has('subroute')) {
        const appId = params.get('subroute');

        if (!appId || !Number.isInteger(parseInt(appId))) {
          this.resetWorkflowAndRedirect();
          return;
        }

        this.applianceId = appId;

        this.subscriptionService.refresh();
        this.autorun(() => {
          this.subscription = this.m.subscription.subscriptions.find((v) => v.appliance_id == this.applianceId && v.isValid);
          if (!this.subscription) {
            this.resetWorkflowAndRedirect();
          }
        });

        return;
      }

      this.resetWorkflowAndRedirect();

      return;
    });
  }

  onContinue(event: string) {
    this.workflowStep = 'select-csp-host';

  }

  onHostSelected(cspHost: {aid: string, aname: string}) {
    this.oldCspHost = {aid: this.subscription.parent_id, aname: this.subscription.parent_name};
    this.newCspHost = cspHost;
    this.workflowStep = 'confirm-change-host';
  }

  onChangeHostConfirmed() {
     this.subscriptionService.changeHost(this.subscription, this.newCspHost).subscribe(r => {
         this.workflowStep = 'change-host-done';
     });
  }

  onChangeHostDone() {
    this.resetWorkflowAndRedirect();
  }

  resetWorkflowAndRedirect(redirectToSubscriptionsList: boolean = false) {
    this.m.workflow.resetWorkflow();

    if (this.backroute) {
      this.router.navigate(this.backroute);
      return;
    }

    if (redirectToSubscriptionsList) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/subscriptions', this.applianceId]);
    }
  }
}

