import {workflowCspOneDowngradeCuRouting} from "./workflow-csp-one-downgrade-cu/workflow-csp-one-downgrade-cu.routing";
import {workflowCspOneUpgradeCuRouting} from "./workflow-csp-one-upgrade-cu/workflow-csp-one-upgrade-cu.routing";
import {workflowCspOneUpgradeSlaRouting} from "./workflow-csp-one-upgrade-sla/workflow-csp-one-upgrade-sla.routing";
import {
  workflowCspOneDowngradeSlaRouting
} from "./workflow-csp-one-downgrade-sla/workflow-csp-one-downgrade-sla.routing";
import {workflowCspOneTrialCreateRouting} from "./workflow-csp-one-trial-create/workflow-csp-one-trial-create.routing";

export const workflowCspRouting = [
  ...workflowCspOneUpgradeCuRouting,
  ...workflowCspOneUpgradeSlaRouting,
  ...workflowCspOneDowngradeCuRouting,
  ...workflowCspOneDowngradeSlaRouting,
  ...workflowCspOneTrialCreateRouting,
]