<ul class="nav link-navigation">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/partner/level">{{'nav_partnership_level' | i18next}}</a>
  </li>
  <li class="nav-item ml-2" routerLinkActive="active">
    <a class="nav-link" routerLink="/partner/tests">{{'nav_partnership_test' | i18next}}</a>
  </li>
  <li class="nav-item ml-2" routerLinkActive="active">
    <a class="nav-link" routerLink="/partner/profiles">{{'nav_partnership_profile' | i18next}}</a>
  </li>
  <!--<li class="nav-item" routerLinkActive="active">-->
    <!--<a class="nav-link" routerLink="/partner/contracts">{{'nav_partnership_contracts' | i18next}}</a>-->
  <!--</li>-->
</ul>
