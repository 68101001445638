import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-customer-navigation',
  templateUrl: './customer-navigation.component.html',
  styleUrls: []
})
export class CustomerNavigationComponent implements OnInit {

  @Input()
  id: string;

  constructor() { }

  ngOnInit() {
  }

}
