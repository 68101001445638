import {FormControl, ValidatorFn} from '@angular/forms';

/**
 * Form Validator function attached to one FormControl.
 *
 * ['otherControl']
 * Checks otherControl unequal to validator attached control.
 *
 * ['otherControl', '>']
 * Checks otherControl > than validator attached control.
 *
 * ['otherControl', '>', 'otherControl2']
 * Can be used to compare two different other controls. The error will be shown on the control
 * with this attached validator, but is not a part of the validation. Sometimes we need to spell
 * an error on a different control for better "design" view.
 *
 * @param {string} otherControlName
 * @returns {ValidatorFn}
 */
export function compareNumber (matcher: any[]): ValidatorFn {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function compareNumberValidate (control: FormControl) {
    if (!control.parent) {
      return null;
    }

    if (matcher.length > 2) {
      throw new Error('Too many arguments on matcher field. Use max of 3 Elements on Array.');
    }


    const otherControlName = matcher[0];
    const operator = matcher[1] ?? '!==';
    const compareController = matcher[2] ?? '';

    // Initializing the validator.
    if (!thisControl) {
      if (compareController !== '') {
        thisControl = control.root.get(compareController) as FormControl;
      } else {
        thisControl = control;
      }
      otherControl = control.root.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('compareNumberValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }
    if (!otherControl) {
      return null;
    }

    switch (operator) {
      case '>':
        if (+otherControl.value > +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
      case '<':
        if (+otherControl.value < +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
      case '>=':
        if (+otherControl.value >= +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
      case '<=':
        if (+otherControl.value <= +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
      case '===':
        if (+otherControl.value === +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
      case '!==':
      default:
        if (+otherControl.value !== +thisControl.value) {
          return {
            compareNumber: true
          };
        }
        return null;
    }
  };
}

export interface Matcher {
  otherControlName: string;
  operator?: string;
}
