import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
})
export class ThankYouComponent implements OnInit {

  @Input() public headline;
  @Input() public content;

  constructor() { }

  ngOnInit() {
  }

}
