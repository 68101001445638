import {runInAction} from 'mobx';
import {observable, computed} from '@myshared/mobx-connector';

export enum TicketState {
  'new' = 1,
  'open' = 2,
  'pendingReminder' = 3,
  'closed' = 4,
  'pendingClose' = 7
}

export class AttachedFile {
  public fileId: string;
  public file: any; // cannot be File because it will get additional params
  public event: any;
  public isDone: boolean;
}

export class AttachmentPresignedUrl {
  public fileId: string;
  public url: string;

  public static fromJson(json: any): AttachmentPresignedUrl {
    const a = new AttachmentPresignedUrl();
    a.fileId = json.file_id;
    a.url = json.presigned_url;
    return a;
  }
}
export class AttachmentRequest {
  public fileId: string;
  public name: string;

  public toJson() {
    return {
      name: this.name,
      file_id: this.fileId
    };
  }
}

export class ArticleRequest {
  public ticketId: number;
  public body: string;
  public attachments: AttachmentRequest[];

  public toJson() {
    return {
      ticket_id: this.ticketId,
      body: this.body,
      attachments: this.attachments ? this.attachments.map(a => a.toJson()) : []
    };
  }
}

export class TicketRequest {
  public title: string;
  public body: string;
  public applianceId: string;
  public attachments: AttachmentRequest[];

  public toJson() {
    return {
      title: this.title,
      body: this.body,
      appliance_id: this.applianceId,
      attachments: this.attachments ? this.attachments.map(a => a.toJson()) : []
    };
  }
}

export class Attachment {
  public filename: string;
  public id: number;
  public size: number;
  public preferences: { mimeType: string, contentPreview: boolean, resizable: boolean };

  public static fromJson(json: any): Attachment {
    const a = new Attachment();
    a.filename = json.filename || '';
    a.id = json.id || 0;
    a.size = json.size || 0;
    a.preferences = {
      mimeType: json.preferences['Mime-Type'] || '',
      contentPreview: json.preferences['content_preview'] || false,
      resizable: json.preferences['resizable'] || false
    };
    return a;
  }
}

export class Article {
  public body: string;
  public contentType: string;
  public createdBy: string;
  public createdById: number;
  public createdAt: string;
  public from: string;
  public id: number;
  public internal: boolean;
  public originBy: string;
  public originById: number;
  public sender: string;
  public senderId: number;
  public subject: string;
  public ticketId: number;
  public to: string;
  public type: string;
  public updatedAt: string;
  public updatedBy: string;
  public updatedById: number;
  public attachments: Attachment[];

  public static fromJson(json: any): Article {
    const a = new Article();
    a.body = json.body || '';
    a.contentType = json.content_type || '';
    a.createdBy = json.created_by || '';
    a.createdById = json.created_by_id || 0;
    a.createdAt = json.created_at || '';
    a.from = json.from || '';
    a.id = json.id || 0;
    a.internal = json.internal || false;
    a.originBy = json.origin_by || '';
    a.originById = json.origin_by_id || 0;
    a.sender = json.sender || '';
    a.senderId = json.senrder_id || 0;
    a.subject = json.subject || '';
    a.ticketId = json.ticket_id || 0;
    a.to = json.to || '';
    a.type = json.type || '';
    a.updatedAt = json.updated_at || '';
    a.updatedBy = json.updated_by || '';
    a.updatedById = json.updated_by_id || 0;
    a.attachments = (json.attachments || []).map(v => Attachment.fromJson(v));
    return a;
  }

}

export class CreatedUserData {
  public firstname;
  public lastname;

  public static fromJson(json) {
    const t = new CreatedUserData();
    t.firstname = json.created_user_data ? json.created_user_data.firstname : '';
    t.lastname = json.created_user_data ? json.created_user_data.lastname : '';
    return t;
  }
}

export class Ticket {
  public id: number;
  public groupId: number;
  public priorityId: number;
  public stateId: number;
  public organizationId: number;
  public number: string;
  public title: string;
  public ownerId: number;
  public customerId: number;
  public note: string;
  public articleCount: number;
  public updatedById: number;
  public createdById: number;
  public createdAt: string;
  public closedAt: string;
  public updatedAt: string;
  public appliance: string;
  public applianceId: string;
  public sla: string;
  public articleIds: [number];
  public state: string;
  public priority: string;
  public customer: string;
  public createdBy: string;
  public updatedBy: string;
  public createdUserData: CreatedUserData;
  public articles: Article[];

  public static fromJson(json: any): Ticket {
      const t = new Ticket();
      t.id = json.id || 0;
      t.groupId = json.group_id || 0;
      t.priorityId = json.priority_id || 0;
      t.stateId = json.state_id || 0;
      t.organizationId = json.organization_id || 0;
      t.number = json.number || '';
      t.title = json.title || '';
      t.ownerId = json.owner_id || 0;
      t.customerId = json.customer_id || 0;
      t.note = json.note || '';
      t.articleCount = json.article_count || 0;
      t.updatedById = json.updated_by_id || 0;
      t.createdById = json.created_by_id || 0;
      t.createdAt = json.created_at || '';
      t.updatedAt = json.updated_at || '';
      t.appliance = json.appliance || '';
      t.applianceId = json.applianceid || '';
      t.sla = json.sla || '';
      t.articleIds = json.article_ids || [];
      t.state = json.state || '';
      t.priority = json.priority || '';
      t.customer = json.customer || '';
      t.createdBy = json.created_by || '';
      t.closedAt = json.close_at || '';
      t.updatedBy = json.updated_by || '';
      t.createdUserData = CreatedUserData.fromJson(json);
      t.articles = (json.articles_data || []).map(v => Article.fromJson(v));
      return t;
    }

    @computed
    get createdByName(): string {
      return this.createdUserData.firstname + ' ' + this.createdUserData.lastname;
    }

    get stateTranslationString(): string {
      return 'support_state_' + this.state.replace(' ', '_').toLocaleLowerCase();
    }
}


export class SupportModel {
    @observable tickets: Ticket[];

    constructor() {
      runInAction(() => {
        this.tickets = [];
      });
    }
  }
