import {Routes} from "@angular/router";
import {WorkflowCspOneDowngradeSlaComponent} from "./workflow-csp-one-downgrade-sla.component";

export const workflowCspOneDowngradeSlaRouting: Routes = [
    { path: 'csp/downgrade-sla/:aid', component: WorkflowCspOneDowngradeSlaComponent, children: [
            { path: 'info', component: WorkflowCspOneDowngradeSlaComponent, data: { step: 'info' } },
            { path: 'address', component: WorkflowCspOneDowngradeSlaComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowCspOneDowngradeSlaComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowCspOneDowngradeSlaComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowCspOneDowngradeSlaComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'info', pathMatch: 'full' },
    ]},
]
