<app-content-header
  (backEvent)="back.emit()"
  [historyBack]="historyBack"
  [headline]="'select_invoice_contact_headline' | i18next"
  [hint]="!isPC20CSP ? ('select_invoice_contact_hint' | i18next) : ''">
</app-content-header>

<div class="row mt-5">
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_invoice_choose_contact" *ngIf="!isCreating">
    <div class="d-flex flex-column w-100">
      <p-dropdown class="d-flex flex-column w-100"
                 optionLabel="label"
                 optionValue="value"
                 [options]="multiselectableContacts"
                 [(ngModel)]="selectedContact"
                 [emptyMessage]="'app_empty_multiselect' | i18next"
                 [filter]="true"
                 filterBy="label">
      </p-dropdown>
    </div>
    <div class="d-flex justify-content-end mt-3 form-container-footer">
      <button class="btn btn-outline-primary" (click)="onAddContact()">
        {{ 'select_invoice_add_contact_button' | i18next }}
      </button>
      <button class="btn btn-primary" (click)="selectContact()">{{ 'select_invoice_contact_select_button' | i18next }}</button>
    </div>
  </app-icon-card>
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_invoice_add_contact" *ngIf="isCreating">
    <div class="d-flex flex-column w-100">
      <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      <div class="d-flex justify-content-end align-items-center mt-3" *ngIf="!isPC20CSP">
        <app-pascom-checkbox class="align-self-end" [text]="'select_invoice_contact_send_invite' | i18next"
                             (acceptChanged)="accept($event)"></app-pascom-checkbox>
      </div>
      <div class="d-flex justify-content-end align-items-center mb-3">
        <button class="btn btn-link" *ngIf="multiselectableContacts.length > 0" (click)="isCreating = false">
          {{ 'select_invoice_contact_select_existing_button' | i18next }}
        </button>
        <button [disabled]="formGroup.status !== 'VALID'" class="btn btn-primary" (click)="addContact()">
          {{ 'select_invoice_contact_create_next_button' | i18next }}
        </button>
      </div>
    </div>
  </app-icon-card>
</div>


