import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {CustomerService} from '../customer.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {Customer} from '../customer.model';
import {filterText} from '@myshared/utils';
import {action, observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: []
})
export class CustomerListComponent extends AutorunComponent implements OnInit {

  customers: Customer[];

  @observable private filter$: string;
  public isAdmin: boolean;

  constructor(private m: Model,
              private customerService: CustomerService) {
    super();
  }

  ngOnInit() {
    this.doFilter('');
    this.customerService.refresh(true);
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.customers = filterText(this.m.customer.customers, this.filter$)
    });
  }

  @action
  doFilter(value: string) {
    this.filter$ = value;
  }

}
