<app-content-header (backEvent)="onBack()" [historyBack]="historyBack" [backLabel]="backLabel"
                    [headline]="'downgrade_cu_headline' | i18next"></app-content-header>

<app-icon-card>
    <div class="mt-2" [innerHTML]="'downgrade_cu_text' | i18next: {newCU: selectedCus, oldCU: this.subscription.users}">
    </div>
    <div>
        <div class="d-flex mt-3">
            <div>
                <i class="fa fa-info-circle red mr-2"></i>
                <span class="subinformations">{{ 'downgrade_cu_note' | i18next:
                  {date: (subscription.nextPossibleDowngradeDate | localized_date)} }}</span>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-end mt-5 mb-3">
        <div class="row justify-content-end">
            <app-pascom-checkbox class="align-self-end col-12 col-lg-10 col-xl-8"
                                 [text]="'downgrade_cu_info_accept_term' | i18next:
                                 {date: (subscription.nextPossibleDowngradeDate | localized_date)}"
                                 (acceptChanged)="doCheck($event)"></app-pascom-checkbox>
        </div>
        <button class="btn btn-outline-primary align-self-end" type="submit" (click)="onNext()"
                [disabled]="!checked">
            {{ 'app_next' | i18next }}
        </button>
    </div>
</app-icon-card>