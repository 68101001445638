import { Component, OnInit } from '@angular/core';
import {Subscription} from "../../subscription/subscription.model";
import {BuyService} from "../buy.service";
import {Model} from "../../app.model";
import {WorkflowRequest} from "../../workflow/workflow.model";
import {WorkflowService} from "../../workflow/workflow.service";
import {ActivatedRoute} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {SaleOrder} from "../buy.model";
import {SubscriptionService} from "../../subscription/subscription.service";
import {PhonenumberService} from "../../phonenumber/phonenumber.service";

type OfferCompleteSteps =
  | 'phone-numbers-checkout-summary'
  | 'phone-numbers-thank-you'
  | 'upgrade-checkout-summary'
  | 'upgrade-thank-you'
  | 'flatrate-checkout-summary'
  | 'flatrate-thank-you'
  | 'cancel-subscription'
  | 'cancel-done'
  | 'offer-cancelled'
  | 'offer-not-existing'
  | 'subscription-cancelled';

@Component({
  selector: 'app-offer-complete',
  templateUrl: './offer-complete.component.html'
})
export class OfferCompleteComponent implements OnInit {
  public orderName: string;
  public step: OfferCompleteSteps;
  public subscription: Subscription;
  public saleOrder: SaleOrder;
  public migrationCredit: number;

  private token: string;
  private offer: string;
  private phoneNumbers: any[];

  constructor(
    private buyService: BuyService,
    private m: Model,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private phoneNumberService: PhonenumberService
  ) {

  }

  async ngOnInit() {
    // Generate a 'fake' workflow to hide the menu
    const request = new WorkflowRequest();
    request.w = '__offer-complete__';
    this.workflowService.setWorkflowRequest(request)

    // Wait for params map and get the ?token=xxx and order name
    try {
      const { token } = await firstValueFrom(this.route.queryParams);
      const params = await firstValueFrom(this.route.paramMap)
      const offer = params.get('offer');
      if (!token) {
        // if no token is given, we will redirect to the main page
        window.location.replace('/');
        return;
      }
      if (!offer) {
        this.step = 'offer-not-existing';
        return;
      }
      this.token = token;
      this.offer = offer;
    } catch (e) {
      this.step = 'offer-not-existing';
      return;
    }

    // wait for offer details to show the next pages
    try {
      this.saleOrder = await firstValueFrom(this.buyService.getPartnerOffer(this.token, this.offer));

      // Partner is able to send multiple "cancel subscription" offers to a customer
      // therefore, checking whether the customer already approved cancellation
      if (this.saleOrder.order_state === 'sent') {
        this.nextStep();
        if (this.step === 'cancel-subscription' && this.saleOrder.subscription_cancelled) {
          this.step = 'subscription-cancelled';
          return;
        }
      } else if (this.saleOrder.order_state === 'cancelled') {
        this.step = 'offer-cancelled';
        return;
      } else if (this.saleOrder.order_state === 'sale') {
        this.nextStep();
        if (this.step === 'upgrade-checkout-summary') {
          this.step = 'upgrade-thank-you';
        } else if (this.step === 'cancel-subscription') {
          this.step = 'subscription-cancelled';
          return;
        } else if (this.step === 'phone-numbers-checkout-summary') {
          this.step = 'phone-numbers-thank-you';
        } else {
          this.step = 'flatrate-thank-you';
        }
        return;
      }
    } catch (e) {
      this.step = 'offer-not-existing';
      return;
    }

    try {
      const { 'PC-CREDIT-MOVE': migrationCredit } = await firstValueFrom(this.buyService.getSharedCalculatedCredits(this.token, this.offer));
      this.migrationCredit = migrationCredit
    } catch (e) {}

    try {
      this.phoneNumbers = await firstValueFrom(this.phoneNumberService.getPhoneNumbersForPartnerOffer(this.token, this.offer));
      this.nextStep();
    } catch (e) {}
  }

  nextStep() {
    for (const line of this.saleOrder.lines) {
      if (line.name.toUpperCase().includes('[PC-ONE]') || line.name.toUpperCase().includes('[PC-ONE-SLA')) {
        this.step = 'upgrade-checkout-summary';
        break;
      }
      if (line.name.toUpperCase().includes('[PC-ONE-PORT-NUMBER]') || line.name.toUpperCase().includes('[PC-ONE-NEW-NUMBER]')) {
        this.step = 'phone-numbers-checkout-summary';
        break;
      }
      if (line.name.toUpperCase().includes('[PC-ONE-TARIFF-FLAT')) {
        this.step = 'flatrate-checkout-summary';
        break;
      }
      if (line.name.toUpperCase().includes('[PC-CANCEL]')) {
        this.step = 'cancel-subscription';
        break;
      }
    }
  }

  public onBuyPhoneNumbers(): void {
    if (!this.token) return;
    this.buyService.confirmPartnerOffer(this.token, this.offer).subscribe(() => {
      this.step = 'phone-numbers-thank-you';
    });
  }

  public onBuyUpgrade(): void {
    if (!this.token) return;
    this.buyService.confirmPartnerOffer(this.token, this.offer).subscribe(() => {
      this.step = 'upgrade-thank-you';
    });
  }

  public onBuyFlatrate(): void {
    if (!this.token) return;
    this.buyService.confirmPartnerOffer(this.token, this.offer).subscribe(() => {
      this.step = 'flatrate-thank-you';
    });
  }

  public onSubscriptionCancelConfirm() {
    if (!this.token) return;
    this.buyService.confirmPartnerOffer(this.token, this.offer).subscribe(() => {
      this.step = 'cancel-done';
    });
  }
}
