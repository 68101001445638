import { Component, OnInit } from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '../autorun.component';

@Component({
  selector: 'app-content-loading',
  templateUrl: './content-loading.component.html',
  styleUrls: []
})
export class ContentLoadingComponent extends AutorunComponent implements OnInit {

  isLoading = false;
  blurFilter = '';

  private isLoadingIntermediate = false;

  constructor(private m: Model) {
    super();
  }

  ngOnInit() {
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    let filterClass = 'blur-filter';
    if (navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1) {
      filterClass = 'blur-filter-ie';
    }

    // NOTE: The simplest approach would be to use { delay: 1000 } for autorun, BUT
    // It's better to have an "asynchronous" delay here: Start to show the loading overlay after 2s, but show it
    // for at least 1s if it's visible. Therefore the approach using setTimeout.
    this.autorun(() => {
      this.isLoadingIntermediate = this.m.network.isLoading;
      setTimeout(() => {
        this.isLoading = this.isLoadingIntermediate;
        this.blurFilter = this.isLoading ? filterClass : null;
      }, this.isLoading ? 555 : 1111); // Use slightly longer timeouts to avoid ugly flapping effects
    });
  }

}
