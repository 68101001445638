import {Routes} from "@angular/router";
import {WorkflowCspOneUpgradeCuComponent} from "./workflow-csp-one-upgrade-cu.component";

export const workflowCspOneUpgradeCuRouting: Routes = [
    { path: 'csp/upgrade-cu/:aid', component: WorkflowCspOneUpgradeCuComponent, children: [
            { path: 'address', component: WorkflowCspOneUpgradeCuComponent, data: { step: 'address' } },
            { path: 'payment', component: WorkflowCspOneUpgradeCuComponent, data: { step: 'payment' } },
            { path: 'checkout', component: WorkflowCspOneUpgradeCuComponent, data: { step: 'checkout' } },
            { path: 'complete', component: WorkflowCspOneUpgradeCuComponent, data: { step: 'complete' } },
            { path: '', redirectTo: 'address', pathMatch: 'full' },
    ]},
]
