import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from "../../subscription/subscription.model";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-cloud-migration',
  templateUrl: './one-click-cloud-migration.component.html'
})
export class OneClickCloudMigrationComponent implements OnInit {

  @Input() subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  public doMigration() {
    this.router.navigate(['/do', 'cloud-migration', this.subscription.appliance_id]);
  }

  public get subscriptionType() {
    return this.subscription.licenseTypeFormatted;
  }

}
