import {runInAction} from 'mobx';
import {computed, observable} from '@myshared/mobx-connector';

export class WorkflowRequest {
  r: string; // Redirect-Url
  v: string; // Version
  w: string; // Workflow-Name
  u: any; // Additional User data (Workflow-Specific)

  get redirectUrl(): string   { return this.r; }
  get version(): string       { return this.v; }
  get workflowName(): string  { return this.w; }
  get userData(): any         { return this.u; }
}

export class WorkflowRequestModel {

  @observable request: WorkflowRequest;

  constructor() {
    runInAction(() => this.request = new WorkflowRequest());
  }

  public resetWorkflow() {
    runInAction(() => this.request = new WorkflowRequest());
  }

  @computed get userData(): any {
    return this.request.userData || {};
  }

  @computed get isWelcome(): boolean {
    return this.request.workflowName === 'welcome';
  }

  @computed get isCsui(): boolean {
    return this.request.workflowName === 'csui';
  }

  @computed get isActivateUser(): boolean {
    return this.request.workflowName === 'activate-user';
  }

  @computed get isResetPassword(): boolean {
    return this.request.workflowName === 'reset-password';
  }

  @computed get isInvite(): boolean {
    return this.request.workflowName === 'invite-user';
  }

  @computed get isOnsiteSetup(): boolean {
    return this.request.workflowName === 'onsite-setup';
  }

  @computed get isUpgradeSubscription(): boolean {
    return this.request.workflowName === 'upgrade-subscription';
  }

  @computed get isCancelSubscription(): boolean {
    return this.request.workflowName === 'cancel-subscription';
  }

  @computed get isManagementPasswordReset(): boolean {
    return this.request.workflowName === 'password-reset';
  }

  @computed get isOneTrial(): boolean {
    return this.request.workflowName === 'trial';
  }
  @computed get isOneUpgrade(): boolean {
    return this.request.workflowName === 'upgrade';
  }

  @computed get isPhonenumbers(): boolean {
    return this.request.workflowName === 'phonenumbers';
  }

  @computed get isTariff(): boolean {
    return this.request.workflowName === 'tariff';
  }

  @computed get isTariffCancel(): boolean {
    return this.request.workflowName === 'tariff-cancel';
  }
  @computed get isTariffMigration(): boolean {
    return this.request.workflowName === 'tariff-migration';
  }
  @computed get isCloudMigration(): boolean {
    return this.request.workflowName === 'cloud-migration';
  }

  @computed get isItemizedBilling(): boolean {
    return this.request.workflowName === 'itemized-billing';
  }

  @computed get isNfrOne(): boolean {
    return this.request.workflowName === 'nfr-one';
  }
  @computed get isOneTrialPartner(): boolean {
    return this.request.workflowName === 'trial-partner';
  }

  @computed get inWorkflow(): boolean {
    // little js trick to check for undefined, null, empty string, etc.
    return !!this.request.workflowName;
  }

  @computed get macFromUserData(): string|undefined {
    if (this.request.userData && this.request.userData.mac) {
      return this.request.userData.mac;
    }
    return undefined;
  }

  @computed get isChangeHost(): boolean {
    return this.request.workflowName === 'change-host';
  }
}
