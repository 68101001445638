<div class="badge rounded-pill bg-info recommended-product" *ngIf="product.recommended">{{ 'recommended_product' | i18next }}</div>
<div class="price-card product card card-nb h-100"
     [ngClass]="{ 'normal': !product.highlighted, 'highlight': product.highlighted }">
  <div class="d-flex flex-column">
    <div class="price-card-header product">
      <div class="d-flex align-items-center flex-column">
        <div class="subscription-type product h3">{{ product.name |  i18next }}</div>
        <div class="subscription-description">{{ product.description |  i18next }}</div>
        <div class="subscription-price mt-auto">
          <div class="price">{{ product.startingPrice | localized_currency:{vatMarker: true} }}</div>
          <div class="time-billing product">{{ product.billingPeriod |  i18next }}</div>
        </div>
      </div>
    </div>

    <div class="price-card-body product one mt-5">
      <div class="container" *ngIf="product.features.length">
        <div class="d-flex justify-content-center features">
          <div class="d-flex flex-column align-items-start">
            <div *ngFor="let f of product.features">
              <div class="d-flex align-items-center">
                <i class="fas fa-check product-features-icon" [ngClass]="{'red': f.highlighted}"></i>
                <span>{{ f.name | i18next }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price-card-footer">
      <div>{{product.buttonHre}}</div>
      <a [name]="'select-' + product.name" class="btn btn-primary" [class]="{ 'btn-primary': product.highlighted, 'btn-outline-primary' : !product.highlighted }"
         [routerLink]="product.buttonHref">{{ product.buttonText | i18next }}</a>
    </div>
  </div>

</div>
