<app-content-header backLabel="app_back"
                    (backEvent)="back()"
                    [historyBack]="false"
                    [headline]="'phonenumber_to_port_headline' | i18next"></app-content-header>
<form>
  <div class="row mt-2">
    <div class="col-12 col-md-6">
      <app-icon-card [stretch]="false">
        <div class="h3 red">{{ 'port_number_add_single_number' | i18next}}</div>
        <dynamic-primeng-form [group]="formGroup"
                                   [layout]="formLayout"
                                   [model]="formModel">
        </dynamic-primeng-form>
        <div class="row d-flex justify-content-end mt-2">
          <button class="btn btn-outline-primary"
                  (click)="addNumber()"
                  type="submit"
                  [disabled]="!formGroup.valid">{{ 'port_number_add_single_number_button' | i18next}}</button>
        </div>
      </app-icon-card>

    </div>
    <div class="col-12 col-md-6">
      <app-icon-card [stretch]="false" header="port_single_number_example_a_headline"
                     headlineText="port_single_number_example_a_text"></app-icon-card>
      <app-icon-card [stretch]="false" header="" headline="" headlineText="">
        <div class="h3 red">{{ 'port_number_add_single_number_list' | i18next}} ({{ singlePhoneNumbers.length }}/{{ maxSelectableSingleNumbers }})</div>
        <div class="d-flex justify-content-between" *ngFor="let number of singlePhoneNumbers | keyvalue">
          <div>{{ number.value.previewNumber }}</div>
          <div>
            <i class="fas fa-trash-alt red clickable-icon" (click)="removeSingleNumber(number.key)"></i>
          </div>
        </div>
      </app-icon-card>
    </div>
  </div>
  <div class="row d-flex justify-content-end pb-3 pt-3 form-container-footer">
    <button class="btn btn-primary" (click)="next()"
            type="button"
            [disabled]="formDisabled">{{ 'app_next' | i18next}}</button>
  </div>
</form>
