import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  styles: [
  ]
})
export class FaIconComponent {
  @Input() icon: string;
  @Input() isPcColor = true;
  @Input() size: 'normal' | 'lg' | 'xl' | '2xl' | '3xl' | 'sm' | 'xs' = 'normal';
}
