<app-content-header [hint]="'csphost_select_host_hint' | i18next" [headline]="'csphost_select_host_header' | i18next" [stepsBack]="2" (backEvent)="back()"></app-content-header>

<div class="row mb-5">
  <div class="col">
    <app-searchbox (filterChanged)="filterLicenses($event)" [placeholder]="'host_search_placeholder' | i18next"></app-searchbox>
  </div>
</div>
<div class="row align-items-stretch">
  <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let s of subscriptions" [subscription]="s">
    <button class="btn btn-outline-primary" (click)="selectHost(s)">{{'csphost_select_host_btn' | i18next }}</button>
  </app-subscription-card>
</div>
