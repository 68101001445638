<div class="d-flex flex-column h-100">
  <app-content-header
    [hideBackButton]="hideBackButton"
    [historyBack]="false"
    (backEvent)="back()"
    [headline]="'product_select_headline' | i18next"></app-content-header>
  <div class="row d-flex justify-content-center mt-2" *ngIf="trial">
    <app-product-card class="col-sm-12 col-lg-6 mb-3 mb-lg-0" [product]="trial"></app-product-card>
  </div>
  <div class="ml-auto mt-auto p-3" style="width: 16rem;">
    <div class="small"> {{ 'vat_not_included' | i18next }} </div>
    <div class="small"> {{ 'pascom_one_deleting_hint' | i18next }} </div>
  </div>
</div>
