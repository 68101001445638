<app-content-header backLabel="app_back" [historyBack]="false" (backEvent)="onBack()" [headline]="'tariff_headline_select_order' | i18next"></app-content-header>

<div class="row d-flex justify-content-center mt-2">
  <div class="col-sm-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
    <div class="card card-nb">
      <div class="d-flex card-header header-red card-nb shadow-sm">
        <div class="h3 mx-auto">{{ 'tariff_headline_select_order' | i18next }}</div>
      </div>
      <div class="card-body pb-0 pt-0">
        <div class="p-3 d-flex flex-column h-100">
          <div class="h3 red" >{{ 'tariff_summary' | i18next }}</div>
          <div class="mb-3">{{ 'tariff_summary_info' | i18next: {currentTariff: currentTariffTranslationKey, selectedTariff: selectedTariffTranslationKey} }}</div>

          <div class="h3 red" >{{ 'tariff_select_start_date' | i18next }}</div>
          <div class="mb-3">{{ 'tariff_select_start_date_info' | i18next}}</div>


          <div class="d-flex">
            <p-dropdown [options]="dates" autoDisplayFirst="true" [(ngModel)]="selectedDate" optionLabel="date"></p-dropdown>
          </div>

          <div class="d-flex justify-content-end mt-2">
            <button class="btn btn-outline-primary" (click)="onOrder()">
              {{'app_next' | i18next}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

