import {AutorunComponent} from '@myshared/autorun.component';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {slaOneProductNames, SlaProduct, slaProductFeatures, slaProductNames} from '../select-product/product.model';
import {Subscription} from '../../subscription/subscription.model';

@Component({
  selector: 'app-select-one-sla',
  templateUrl: './select-one-sla.component.html'
})
export class SelectOneSlaComponent extends AutorunComponent implements OnInit {

  @Input() readonly slaProducts: SlaProduct[];
  @Output() public readonly selected = new EventEmitter<string>();
  @Output() public readonly backEmitter = new EventEmitter<void>();
  @Input() public subscription: Subscription;

  public slaViewProducts: SlaProduct[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    let wasSelected = false;
    let selectedSlaSubscriptionProduct = this.subscription.sla_product;

    // Upgrade from a legacy annual cloud to one cloud
    if ((this.subscription.isAnnualCloud && this.subscription.isOneAppliance) || this.subscription.isOnsite) {
      // E.g. MDSL2 is the selected SLA product on annual cloud subscription:
      // For this upgrade, one cloud SLA2 will be selected as the corresponding product
      selectedSlaSubscriptionProduct = this.getCorrespondingMDSL(this.subscription.sla_product);
    }

    for (const product of this.slaProducts) {
      if (slaOneProductNames.includes(product.name)) {
        const selected = !wasSelected
          && (selectedSlaSubscriptionProduct === '' || product.name === selectedSlaSubscriptionProduct);
        product.monthlyPayment = true;

        if (this.subscription.isOne) {
          if (!selected && wasSelected) {
            product.type = 'upgrade';
          } else if (selected) {
            product.type = 'selected';
          } else {
            product.type = 'downgrade';
          }
        } else {
          product.type = 'select';
        }


        product.features = slaProductFeatures.find(f => f.name === product.name).features;

        if (selected) {
          wasSelected = true;
        }

        this.slaViewProducts.push(product);
      }
    }
  }

  onSelected(e) {
    this.selected.emit(e);
  }

  back() {
    this.backEmitter.emit();
  }

  /**
   * Search function to get the legacy annual cloud MDSL<number> product
   * and search for corresponding pascom one cloud SLA<number>
   * e.g. MDSL2 -> PC-ONE-SLA2
   * @param slaProductName
   * @private
   */
  private getCorrespondingMDSL(slaProductName: string): string {
    const slaNumber = slaProductName.slice(-1);
    return slaOneProductNames.find(s => s.match(slaNumber));
  }

}
