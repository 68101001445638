import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

type AlertMessageTypes = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: []
})
export class AlertMessageComponent implements OnInit {

  @Input() alertType: AlertMessageTypes = 'primary';

  constructor() { }

  ngOnInit() {
  }
}
