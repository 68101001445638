<div class="app header-fixed sidebar-fixed" [ngClass]="{ 'sidebar-hidden': true}">
  <div class="app-header">
    <app-top-navigation></app-top-navigation>
  </div>
  <div class="app-body">
    <main class="main">
      <div id="router" class="p-2 p-md-5">
        <div class="content-card-container-bg">
          <div class="content-card-container">
            <div class="content-card text-align-center">
              <div>
                <img src="assets/images/erdmaennchen.svg" alt="We are checking the connection">
              </div>
              <app-maintenance-api *ngIf="isChecking"></app-maintenance-api>
              <app-maintenance-scheduled *ngIf="isScheduled"></app-maintenance-scheduled>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
