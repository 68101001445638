import {Routes} from "@angular/router";
import {WorkflowHandlerComponent} from "./workflow-handler.component";
import {CompanyGuard} from "../company/company.guard";
import {AuthGuard} from "../auth/auth.guard";
import {WorkflowManagerComponent} from "./workflow-manager/workflow-manager.component";
import {workflowChangeSlaRouting} from "./workflow-change-sla/workflow-change-sla-routing";
import {workflowChangeCuRouting} from "./workflow-change-cu/workflow-change-cu-routing";
import {workflowEndcustomerRouting} from "./endcustomer/workflow-endcustomer.routing";
import {workflowCspRouting} from "./csp/workflow-csp.routing";
import {workflowPartnerRouting} from "./partner/workflow-partner.routing";
import {WorkflowFallbackNumberComponent} from "./workflow-fallback-number/workflow-fallback-number.component";

/**
 * Every workflow will get an own routing file to load all steps inside a specified workflow
 */
export const workflowRouting: Routes = [
    { path: 'do', component: WorkflowHandlerComponent, canActivate: [AuthGuard, CompanyGuard], children: [
        ...workflowChangeCuRouting,
        ...workflowChangeSlaRouting,
        ...workflowEndcustomerRouting,
        ...workflowCspRouting,
        ...workflowPartnerRouting,
        // one time workflows, no need for an own file
        { path: 'change-fallback-number/:aid', component: WorkflowFallbackNumberComponent},
        // All non rewritten (legacy) workflow components
        {path: ':b64',   component: WorkflowManagerComponent, canActivate: [AuthGuard, CompanyGuard]},
        {path: ':b64/:subroute', component: WorkflowManagerComponent, canActivate: [AuthGuard, CompanyGuard]},
        {path: ':b64/:subroute/:id', component: WorkflowManagerComponent, canActivate: [AuthGuard, CompanyGuard]},
    ]},
]
