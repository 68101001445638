<app-content-header backLabel="app_cancel" [stepsBack]="1" [headline]="'phonenumber_bill_check_company_name' | i18next"></app-content-header>

<div class="d-flex justify-content-center mt-5">
  <div class="card card-nb card-mw-300">
    <div class="card-body">
      <div class="alert alert-warning" role="alert">
        {{ 'phonenumber_bill_check_company_name_warning' | i18next }}
      </div>
      <div class="mt-3 mb-3 row">
        <label for="companyName" class="form-label">{{ 'company_name_label' | i18next }}</label>
        <input type="input" class="form-control" [(ngModel)]="selectedCompanyName"
               id="companyName" [placeholder]="companyName">
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end mt-2">
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="!selectedCompanyName">{{ 'app_next' | i18next}}</button>
</div>
