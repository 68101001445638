import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Model} from '../app.model';
import {runInAction} from 'mobx';
import { map, mergeMap } from 'rxjs/operators';
import { Ticket, ArticleRequest, TicketRequest, AttachmentPresignedUrl, AttachedFile } from './support.model';
import { Observable, throwError, of, combineLatest } from 'rxjs';
import { Router } from '@angular/router';

export type CloudStatusType = ('operational' | 'degraded' | 'outage' | '');

@Injectable({providedIn: 'root'})
export class SupportService {

    constructor(private httpClient: HttpClient, private m: Model, private router: Router) {
    }

    get downloadLink(): string {
        return '/services/v1/support/attachment/download';
    }

    public refresh(page: number = 1, searchQuery = '', isOnlyMyTickets = false, stateFilter: any[] = []) {
        let params = new HttpParams();
        params = params.append('my', '' + isOnlyMyTickets);
        params = params.append('page', '' + page);
        if (searchQuery) {
            params = params.append('query', searchQuery);
        }
        if (stateFilter.length > 0) {
            params = params.append('status', stateFilter.toString());
            this.httpClient.get<any[]>('/services/v1/support/tickets', {params: params})
                .pipe(map((r: any[]) => r.map((item) => Ticket.fromJson(item))))
                .subscribe((r: any[]) => runInAction(() => {
                    if (page > 1) {
                        this.m.support.tickets = [...this.m.support.tickets, ...r];
                    } else {
                        this.m.support.tickets = r;
                    }
                }));
        } else {
            runInAction(() => this.m.support.tickets = []);
        }
    }

  /**
   * Get the actual defined cloud status
   *
   * We need to "check" the overall status of all cloud services from the status page.
   *
   * Because of the checking mechanism we need to go through all components, if one is "outage" we can return immediately,
   * otherwise we need to check all the other components, because can be happened, that one or more components
   * on status "degraded". At the end, if no component is outage or degraded, it should be everything fine,
   * and we can return "operational".
   *
   */
  public getCloudStatus(): Observable<CloudStatusType> {
      return this.httpClient.get('/services/v1/support/cloud-status').pipe(
        map((components: [{ component: string, status: string }]) => {
          let status: CloudStatusType = '';
          // Iterate over all cloud service components to check there status
          for (const c of components) {
            const componentStatus = c.status.toLowerCase();
            // If status is empty, we set status. We do not want to overwrite other status like degraded
            if (!status && componentStatus === 'operational') {
              status = 'operational';
            }
            // If one (or more) components are degraded, we need to overwrite the status (maybe was operational before)
            if (componentStatus === 'degraded') {
              status = 'degraded';
            }
            // We can immediately return outage because this is the not working status
            // for all the cloud services (maybe down)
            if (componentStatus === 'outage') {
              return 'outage';
            }
          }
          return status;
        })
      );
    }

    public getTicketDetails(id: number): Observable<Ticket> {
        return this.httpClient.get<any>('/services/v1/support/ticket/'+id).pipe(map((r) => Ticket.fromJson(r)));
    }

    public addArticle(a: ArticleRequest): Observable<any> {
        return this.httpClient.post<any>('/services/v1/support/ticket/add_article', a.toJson());
    }

    public createTicket(t: TicketRequest): Observable<any> {
        return this.httpClient.post<any>('/services/v1/support/ticket/create', t.toJson());
    }

    public closeTicket(ticketId: number): Observable<any> {
        return this.httpClient.put<any>('/services/v1/support/ticket/close/' + ticketId, {});
    }

    public upload(file: File, applianceId: string): Observable<any> {
        if (!file || !applianceId) {
            return throwError('file_upload_error');
        }

        return this.httpClient.get<any>('/services/v1/support/attachment/prepare/' + applianceId).pipe(
            mergeMap(r => {
                const attachmentResponse = AttachmentPresignedUrl.fromJson(r);
                const attachmentFiles = new AttachedFile();
                attachmentFiles.fileId = attachmentResponse.fileId;
                // attachmentFiles.fileName = file.name;
                const upload$ = this.httpClient.put(attachmentResponse.url, file, { reportProgress: true, observe: 'events' });
                return combineLatest(of(attachmentFiles), upload$);
            })
        );
    }
}
