<p-fileUpload name="file-upload[]"
              (onUpload)="onUpload($event)"
              (onSelect)="onSelect($event)"
              (uploadHandler)="onUpload($event)"
              [multiple]="multipleFiles"
              [showCancelButton]="false"
              [auto]="false"
              [accept]="allowedFiles"
              [chooseLabel]="chooseLabel | i18next: {count: maxFileLength}"
              [uploadLabel]="uploadLabel | i18next"
              [chooseIcon]="chooseIcon"
              [styleClass]="styleClass"
              [url]="uploadUrl"
              [showUploadButton]="showUploadButton"
              [customUpload]="customUpload">
  <ng-template pTemplate="toolbar">
    <div class="mt-2">{{ "file_uploader_file_upload_hint" | i18next: { count: maxFileLength, maxSize: (maxFileSize | formatByteSize) } }}</div>
    <div>{{ "file_uploader_file_upload_allowed_type_hint" | i18next: { allowedFileTypes: displayAllowedFileTypesTranslation } }}</div>
  </ng-template>
  <ng-template pTemplate="file"></ng-template>
  <ng-template pTemplate="content">
    <div *ngIf="attachedFiles.length <= 0" class="red font-weight-bold">{{ 'file_uploader_drag_select' | i18next }}</div>
    <div *ngIf="attachedFiles.length > 0">
      <div class="mt-3 font-weight-bold">
        <span class="pi pi-upload p-button-icon p-button-icon-left"></span>
        {{ "file_uploader_attached_files_headline" | i18next }}
      </div>
      <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center" *ngFor="let f of attachedFiles; let i = index;">
        <div *ngIf="f.file && f.file.objectURL && isImage(f.file)"><img [src]="f.file.objectURL" width="50px" /></div>
        <div *ngIf="f.file && !isImage(f.file)" class="text-right">
          <i class="fas fa-paperclip"></i>
        </div>
        <div *ngIf="f.file">{{f.file.name}}</div>
        <div *ngIf="f.file">{{ f.file.size | formatByteSize:{iecCalc: false} }}</div>
        <div *ngIf="f.file">
          <span *ngIf="!isUploadDone(i) && getFileProgress(i) > 0">{{ getFileProgress(i) }}%</span>
          <button *ngIf="!isUploadDone(i)" type="button" icon="pi pi-times" pButton (click)="removeFile(i)"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-fileUpload>
