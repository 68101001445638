import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {Model} from '../../app.model';
import {CompanyService} from '../company.service';
import {runInAction} from 'mobx';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {contactForm, getContactFromForm, setContactToForm} from '../contact.form';
import {I18NextService} from 'angular-i18next';
import {HttpClient} from '@angular/common/http';
import {normalizeLanguageCode} from '@myshared/i18nutils';
import {AutorunComponent} from '@myshared/autorun.component';
import {AuthService} from '../../auth/auth.service';
import {Contact} from '../company.model';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: []
})
export class ContactDetailsComponent extends AutorunComponent implements OnInit, AfterViewInit {

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  public isAdmin: boolean;
  public userIsActive: boolean;
  public loggedInUser: boolean;
  public isMypascomDisabled: boolean;
  public hasPhoneNumber: boolean;
  public contact: Contact;
  public contactId: string;

  private builder: PCFormBuilder;

  constructor(
    private m: Model,
    private pcFormService: PCFormsService,
    private i18next: I18NextService,
    private httpClient: HttpClient,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
    super();
  }

  ngOnInit() {
    let initForm = false;
    this.route.paramMap.subscribe(r => {
      const contactId = r.get('contactId');
      if (contactId && contactId !== this.contactId) {
        runInAction(() => {
          this.contactId = contactId;
          this.companyService.refresh(true);

          if (!initForm && this.m.account.currentUser.id && this.contactId) {
            this.builder = contactForm(this.pcFormService, this.i18next, ['disabled', 'enabled', 'admin'], +this.m.account.currentUser.id === +this.contactId);
            this.formModel = this.builder.model;
            this.formLayout = this.builder.layout;
            this.formGroup = this.builder.createFormGroup();
            initForm = true;
          }
        });
      }
    });

    this.autorun(() => {
      if (!this.contactId) {
        return;
      }
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.hasPhoneNumber = this.m.account.keycloakUser.hasPhoneNumber;
      this.loggedInUser = this.m.account.currentUser.id == this.contactId;

      if (!this.isAdmin && !this.loggedInUser) {
        this.router.navigate(['/']);
        return;
      }

      const contacts = this.m.company.company.contacts;
      if (this.contactId && contacts.length > 0) {
        this.contact = contacts.find((x) => x.id == this.contactId);

        if (this.contact) {
          this.userIsActive = this.contact.active;
          this.isMypascomDisabled = this.contact.mypascom_account.toLowerCase() === 'disabled';
          setContactToForm(this.formGroup, this.contact);
          if (!this.isAdmin) {
            // If user has no admin permissions, we need to disable all fields and hide the save button on HTML
            runInAction(() => this.formGroup.disable());
          }
        } else {
          this.router.navigate(['/']);
          return;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.builder.autoFocus();
  }

  onResendInvitationEmail() {
    if (this.isMypascomDisabled) {
      return;
    }
    const c = getContactFromForm(this.formGroup, this.contactId);
    this.httpClient.post<any>('/services/auth/resend_invitation_email', {
      email: c.email,
    }).subscribe(() => {
      this.messageService.add({severity: 'info', summary: this.i18next.t('info') as string,
        detail: this.i18next.t('invitation_sent') as string});
    });
  }

  onSave() {
    const c = getContactFromForm(this.formGroup, this.contactId);
    if (this.loggedInUser && c.email !== this.m.account.currentUser.email) {
      this.confirmationService.confirm({
        message: this.i18next.t('update_user_confirm_message') as string,
        header: this.i18next.t('update_user_confirm_title') as string,
        key: 'change_userdata_confirm',
        accept: () => this.saveUser(c)
      });
    } else {
      this.saveUser(c);
    }
  }

  private saveUser(c: Contact) {
    this.companyService.updateContact(c).subscribe(
      () => {
        this.companyService.refresh(true);
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('contact_saved') as string});
        // If the logged-in user changes their own email address, they will need to log in again.
        if (this.loggedInUser && c.email !== this.m.account.currentUser.email) {
          this.authService.logout();
        } else {
          this.router.navigate(['/company/contacts/']);
        }
    });
  }

  public back() {
    window.history.back();
  }

  public get accountManagerUrl() {
    return this.authService.getOidcAuthUrl() + '/account/';
  }
}
