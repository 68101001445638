import {PCFormBuilder, PCFormContainer, PCFormRow, PCFormsService} from '@myshared/pcform.service';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {Customer} from './customer.model';
import {Country} from '../country/country.model';
import { trimInput } from '@myshared/utils';

/**
 * Create the customer form
 *
 * countries = 'CountrySelectionDisabled will deactivate the possibility to edit the country. This is important, because we
 * dont want to change the country after customer was created
 * @param pcFormService
 * @param countries
 */
export function customerForm(pcFormService: PCFormsService,
                             countries: BehaviorSubject<{ label, value }[]> | 'CountrySelectionDisabled', selectedCountryCode: string = 'DE')
  : PCFormBuilder {
  const builder = pcFormService.newBuilder('company');

  const row1 = builder.addContainer(PCFormRow, {
    id: 'row1',
    legend: null,
    elementClass: {
      control: 'row'
    }
  });
  row1.addTextInput({
    id: 'name',
    autoFocus: true,
    tabIndex: 1,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });
  row1.addTextInput({
    id: 'street',
    tabIndex: 2,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });

  const row2 = builder.addContainer(PCFormRow, {
    id: 'row2',
    legend: null,
    elementClass: {
      control: 'row'
    }
  });
  row2.addTextInput({
    id: 'street2',
    tabIndex: 3,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
  });
  row2.addTextInput({
    id: 'zip',
    tabIndex: 4,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });

  const row3 = builder.addContainer(PCFormRow, {
    id: 'row3',
    legend: null,
    elementClass: {
      control: 'row'
    }
  });
  row3.addTextInput({
    id: 'city',
    tabIndex: 5,
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  });
  
  // If countries given, show a selectable country field, otherwise a readonly input
  // We need to do it, because ng-dynamic-forms cannot set select on readOnly
  // also its not possible anymore to change country https://pascom.atlassian.net/browse/IT-1815
  (row3 as PCFormContainer).addSelect<string>({
    id: 'country',
    tabIndex: 6,
    readOnly: countries === 'CountrySelectionDisabled',
    elementClass: {
      container: 'form-group-container',
      host: 'col-md-6 col-12'
    },
    validators: {required: null}
  }, countries, selectedCountryCode);
  return builder;
}

export function setCustomerToForm(formGroup: FormGroup, customer: Customer) {
  formGroup.patchValue({
    'company': {
      'row1': {
        'name': customer.name,
        'street': customer.street
      },
      'row2': {
        'street2': customer.street2,
        'zip': customer.zip
      },
      // it is not possible to change the country, in this case we will fill up a input with the name
      'row3': {
        'city': customer.city,
        'country': customer.country.name
      }
    }
  });
}

/**
 * Parse form data for API
 *
 * If customer is null, it will create a new customer instead of overwriting one
 *
 * @param formGroup
 * @param countries
 * @param customer
 */
export function getCustomerFromForm(formGroup: FormGroup, countries: Country[], customer: Customer): Customer {
  const data = formGroup.value['company'];
  return new Customer(
    customer ? customer.id : '',
    trimInput(data['row1'].name),
    trimInput(data['row1'].street),
    trimInput(data['row2'].street2),
    trimInput(data['row2'].zip),
    trimInput(data['row3'].city),
    customer ? customer.country : countries.find(c => data['row3'].country == c.code),
    []
  );
}
