<h1>{{ 'maintenance_api_unavailable_headline' | i18next}}</h1>
<p>
  {{ 'maintenance_api_unavailable_description' | i18next }}
</p>
<div class="spinner" *ngIf="!isRefreshButtonShown">
  <img src="assets/images/loading.gif" alt="Loading content">
</div>
<div *ngIf="isRefreshButtonShown">
  <p>
    {{ 'maintenance_api_unavailable_takes_longer_description' | i18next }}
  </p>

  <button class="btn btn-outline-primary mt-3" (click)="doRefresh()">
    {{ 'maintenance_api_unavailable_refresh_button' | i18next }}
  </button>
</div>
