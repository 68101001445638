import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-warning-confirm-box',
  templateUrl: './warning-confirm-box.component.html',
  providers: [LocalizedDatePipe]
})
export class WarningConfirmBoxComponent implements OnInit {
  @Input() confirmHeadline: string;
  @Input() confirmDetailsHeadline: string;
  @Input() confirmDetailsText: string;
  @Input() cancelBtn: string;
  @Input() confirmBtn: string;
  @Input() hideCancelBtn = false;

  @Output() confirm = new EventEmitter<boolean>();
  @Output() back = new EventEmitter<any>();

  constructor(private i18next: I18NextService) {
  }

  ngOnInit() {
    this.cancelBtn = this.cancelBtn || this.i18next.t('app_back') as string;
    this.confirmBtn = this.confirmBtn || this.i18next.t('app_cancel') as string;
  }


  onBack() {
    this.back.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }

}
