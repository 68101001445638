import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PCFormsService} from '@myshared/pcform.service';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {PortNumberBlockDetails, PortNumbersPortingFormDetails} from "../../port-phonenumber.model";

export enum PortSteps {
  'select_provider',
  'select_size',
  'select_phone_numbers',
}

@Component({
  selector: 'app-phonenumber-port-de',
  templateUrl: './phonenumber-port-de.component.html'
})
export class PhonenumberPortDeComponent implements OnInit {

  public step: PortSteps;
  public formDetails: PortNumbersPortingFormDetails;
  public selectedNumberSize: 'single' | 'block';

  @Input()
  public countryCode: string;

  @Output()
  public selected = new EventEmitter<PortNumbersPortingFormDetails>();

  constructor(public pcFormService: PCFormsService,
              public messageService: MessageService,
              public i18next: I18NextService) {
  }

  ngOnInit(): void {
    this.formDetails = new PortNumbersPortingFormDetails();
    this.step = 0;
  }

  next() {
    ++this.step;
  }

  back() {
    --this.step;
  }

  onProviderSelected(providerName: string) {
    this.formDetails.provider = providerName;
    this.next();
  }

  onPortNumberSizeSelected(size: 'single' | 'block') {
    this.selectedNumberSize = size;
    this.next();
  }

  onSelectNumbersToPortComplete(numbers: PortNumberBlockDetails[]) {
    this.formDetails.numbers = numbers;
    this.selected.emit(this.formDetails);
  }

  public get portSteps() {
    return PortSteps;
  }

}
