import {Injectable} from '@angular/core';
import {runInAction} from 'mobx';
import {map} from 'rxjs/operators';
import {Model} from '../app.model';
import {HttpClient} from '@angular/common/http';
import {Block} from './phonenumber.model';

@Injectable()
export class PhonenumberService {
  private needsRefresh = true;

  constructor(private httpClient: HttpClient, private m: Model) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.httpClient.get<any[]>('/services/trunk/block/all')
        .pipe(map((r) => r.map((item) => Block.fromJson(item))))
        .subscribe((r) => runInAction(() => {
          this.m.phonenumber.blocks = r;
          this.needsRefresh = false;
        }));
    }
  }

  public getPhonenumberUsedInSubscriptionSize(applianceId: string, blocks: Block[], saleOrderName?: string): number {
    let usedInSubscription = 0;
    blocks.map(r => {
      if (r.appliance.appliance_id === applianceId) {
        if (!saleOrderName || r.saleOrderName !== saleOrderName) {
          usedInSubscription += r.numbers.length;
        }
      }
    });
    return usedInSubscription;
  }

  public removePhoneNumber(blockId: number) {
    this.httpClient.delete('/services/trunk/block/' + blockId).subscribe(_ => {
      this.refresh(true);
    });
  }

  public getPhoneNumbersForPartnerOffer(token: string, offer: string) {
    return this.httpClient.get<any>(`/services/subscriptions/offer/shared/${offer}/trunk/blocks`, {params: { token }})
      .pipe(map((r) => r.map((item) => Block.fromJson(item))));
  }
}
