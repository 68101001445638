import {Component, EventEmitter, OnInit, Output, AfterViewInit, Input} from '@angular/core';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {CompanyService} from '../../company/company.service';
import {CountryService} from '../../country/country.service';
import {Model} from '../../app.model';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {companyForm, getCompanyFromForm, setCompanytoForm, setVatRequirement} from '../../company/company.form';
import {AutorunComponent} from '@myshared/autorun.component';
import {Company} from '../../company/company.model';

@Component({
  selector: 'app-invoice-address',
  templateUrl: './invoice-address.component.html',
  styleUrls: []
})
export class InvoiceAddressComponent extends AutorunComponent implements OnInit, AfterViewInit {

  @Input() public validateViaApi = false;
  @Input() public backLabel = 'app_back';
  @Input() public historyBack = true;
  @Input() public company?: Company;
  @Input() public onlyEmit = false;

  @Output() public backEvent = new EventEmitter();
  @Output() public readonly next = new EventEmitter<Company>();

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private builder: PCFormBuilder;


  constructor(private m: Model,
              private pcFormService: PCFormsService,
              private countryService: CountryService,
              private companyService: CompanyService) {
    super();
  }

  ngAfterViewInit(): void {
    this.builder.autoFocus();
  }

  ngOnInit() {
    // Second value 'CountrySelectionDisabled' will disable the country selection field
    this.builder = companyForm(this.pcFormService, 'CountrySelectionDisabled', true);

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    this.autorun(() => {
      setCompanytoForm(this.formGroup, this.company ?? this.m.company.company);
      this.builder.autoFocus();
      setVatRequirement(this.formGroup, this.m.country.countries);
    });

    this.countryService.refresh();
    if (!this.company) {
      this.companyService.refresh();
    }
  }

  onNext() {
    if (this.formGroup.valid) {
      const company = getCompanyFromForm(this.formGroup, this.company ?? this.m.company.company);
      // If this flag is set, we will check the address server side via the colt api
      if (this.validateViaApi) {
        company.validate_address = true;
      }

      // When we want to only get back the company from form without API call
      if (this.onlyEmit) {
        this.next.emit(Company.fromJson(company));
        return;
      }

      this.companyService.update(company).subscribe(ok => {
        this.next.emit(Company.fromJson(ok));
      });
    }
  }

  public onBack() {
    this.backEvent.emit();
  }

}
