import { Component, OnInit } from '@angular/core';
import {Model} from '../../../app.model';
import {AffiliateService} from '../affiliate.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {Affiliate} from '../affiliate.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-partner-view',
  templateUrl: './partner-view.component.html'
})
export class PartnerViewComponent extends AutorunComponent implements OnInit {

  public affiliate: Affiliate;
  public isPartner: boolean;
  public isPartnerPending: boolean;
  public isPartnershipAllowed: boolean;
  public isInitialized: boolean;

  constructor(private affiliateService: AffiliateService,
              private m: Model,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.router.navigate(['/partner', 'level']);

    this.autorun(() => {
      this.affiliate = this.m.affiliate.affiliate;
      this.isPartner = this.m.affiliate.isPartner;
      this.isPartnerPending = this.m.affiliate.isPartnerPending;
      this.isPartnershipAllowed = !this.m.affiliate.isPartnerDead;
      this.isInitialized = this.m.account.isInitialized;

      if (this.isInitialized && !this.isPartnershipAllowed) {
        this.router.navigate(['/subscriptions', 'my']);
        return;
      }
    });
  }

}
