import {Injectable} from '@angular/core';
import {SubscriptionModel} from './subscription/subscription.model';
import {AccountModel} from './auth/account.model';
import {WorkflowRequestModel} from './workflow/workflow.model';
import {CustomerModel} from './customer/customer.model';
import {CountryModel} from './country/country.model';
import {CompanyModel} from './company/company.model';
import {AppNetworkModel} from './app.network.model';
import {BuyModel} from './buy/buy.model';
import {PartnerModel} from './partner/partner.model';
import {AgreementModel} from './agreement-management/agreement.model';
import {AffiliateModel} from './partner/affiliate/affiliate.model';
import { SupportModel } from './support/support.model';
import {OrderModel} from './order/order.model';
import {MaintenanceModel} from '@myshared/maintenance/maintenance.model';
import {PhonenumberModel} from './phonenumber/phonenumber.model';
import {TariffModel} from "./tariff/tariff.model";

@Injectable()
export class Model {
  public readonly subscription = new SubscriptionModel();
  public readonly order = new OrderModel();
  public readonly account = new AccountModel();
  public readonly workflow = new WorkflowRequestModel();
  public readonly customer = new CustomerModel();
  public readonly country = new CountryModel();
  public readonly agreement = new AgreementModel();
  public readonly company = new CompanyModel();
  public readonly network = new AppNetworkModel();
  public readonly buy = new BuyModel();
  public readonly partner = new PartnerModel();
  public readonly affiliate = new AffiliateModel();
  public readonly support = new SupportModel();
  public readonly maintenance = new MaintenanceModel();
  public readonly phonenumber = new PhonenumberModel();
  public readonly tariff = new TariffModel();
}
