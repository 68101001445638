<div *ngIf="step === 'creating-offer'">{{'create_offer_please_wait' |  i18next}}</div>
<app-select-upgrade-product (next)="onProductSelected()" *ngIf="step === 'select-upgrade'"></app-select-upgrade-product>
<app-invoice-address (next)="onInvoiceAddressFilled()" *ngIf="step === 'invoice-address'"></app-invoice-address>
<app-payment-method [paymentMethod]="paymentMethod" (next)="onPaymentMethodSelected()" *ngIf="step === 'payment-method'"></app-payment-method>
<app-checkout-summary (buy)="onBuy()" (back)="onBack()" [historyBack]="false" *ngIf="step === 'checkout-summary'"></app-checkout-summary>
<app-buy-thankyou  *ngIf="step === 'thank-you'" [orderName]="orderName"></app-buy-thankyou>
<app-alert-card *ngIf="step === 'already-pcone-subscription'"
  [pageHeadline]="'cloud_migration_already_pcone_page_headline' | i18next"
  [cardHeadline]="'cloud_migration_already_pcone_card_headline' | i18next"
  [alertText]="'cloud_migration_already_pcone_card_text' | i18next"
  [showBackToMypascomPage]="true"
></app-alert-card>
