<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'pc_credit' | i18next }}</h2>
        <div class="mt-2" [innerHTML]="'subscription_credit_details' | i18next: {credit: (credit | localized_currency ) }">
        </div>
        <div>
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations">{{ 'subscription_credit_note' | i18next }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
