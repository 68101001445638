<app-content-header backLabel="app_cancel" [stepsBack]="1" [headline]="'download_port_form_headline' | i18next"></app-content-header>

<div class="row d-flex justify-content-center">
  <div class="col-12 col-md-6">
    <app-icon-card [stretch]="false" icon="far fa-file-alt" headlineText="download_port_form_text">
      <button class="btn btn-primary" (click)="createLinkForAttachment()">{{ 'app_download' | i18next }}</button>
    </app-icon-card>
  </div>
</div>

<div class="d-flex justify-content-end mt-5">
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="isNextDisabled">{{ 'app_next' | i18next}}</button>
</div>
