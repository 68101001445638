import {BankAccount} from '../company/company.model';
import {PaymentMethodCode} from '../buy/buy.model';
import {observable, runInAction} from "mobx";

export const tariffFlatNames = ['PC-ONE-TARIFF-FLAT-S', 'PC-ONE-TARIFF-FLAT-M', 'PC-ONE-TARIFF-FLAT-L', 'PC-ONE-TARIFF-FLAT-XL'];

export class TariffFlatOffer {
  appliance_id: number;
  tariff_plan: string;
  delivery_date: string;
  payment_details: TariffBankAccount;
  partner_id?: number;
}

export class TariffZones {
  name: string;
  freeMinutes: number;

  public static fromJson(json: any): TariffZones {
    const z = new TariffZones();
    z.name = json?.name ?? '';
    z.freeMinutes = json?.free_minutes ?? '';
    return z;
  }
}

export class Tariff {
  name: string;
  listPrice: string;
  zones: TariffZones[];

  public static fromJson(json: any): Tariff {
    const t = new Tariff();
    t.name = json?.name ?? '';
    t.listPrice = json?.list_price ?? '';
    t.zones = json?.zones?.map(z => TariffZones.fromJson(z));
    return t;
  }
}

export class TariffBankAccount extends BankAccount {}

export class TariffModel {
  @observable flatrates: Tariff[];

  constructor() {
    runInAction(() => { this.flatrates = []; });
  }


}
