<h1>{{ 'maintenance_scheduled_headline' | i18next}}</h1>
<p>
  {{ 'maintenance_scheduled_description' | i18next }}
</p>

<button class="btn btn-outline-primary mt-3" (click)="doRefresh()">
  {{ 'maintenance_api_unavailable_refresh_button' | i18next }}
</button>

<div class="mt-3" *ngIf="!!statusUrl">
  <button class="btn btn-link" (click)="openStatusPage()">
    {{ 'maintenance_scheduled_status_page_link_text' | i18next }}
  </button>
</div>
