import { Component, OnInit } from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Agreement} from './agreement.model';
import {Model} from '../app.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-agreement-management',
  templateUrl: './agreement-management.component.html',
  styleUrls: []
})
export class AgreementManagementComponent extends AutorunComponent implements OnInit {

  public agreement: Agreement;
  public agreementType: string;

  constructor(
    private m: Model,
    private router: Router
  ) { super(); }

  ngOnInit() {
    this.autorun(() => {

      const isAgreementNeeded = this.m.agreement.agreementsToAck;

      if (!isAgreementNeeded) {
        return;
      }

      this.agreement = this.m.agreement.activeAgreement;
      if (this.agreement) {
        switch (this.agreement.type) {
          case 'free-idle-warning':
            this.agreementType = 'free-idle-warning';
            break;
          case 'info-all':
          case 'ack-all':
          case 'affiliate-contract':
            this.agreementType = 'ack';
            break;
        }
      }
    });
  }

}
