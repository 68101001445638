import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Model} from '../app.model';
import {SubscriptionBootstate, Subscription} from './subscription.model';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SubscriptionService {

  private needsRefresh = true;
  private boostateForParentNameWhitelist = ['pascom.cloud', 'pascom-dev.cloud', 'pascom-test.cloud'];

  constructor(private httpClient: HttpClient, private m: Model) {
  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.getSubscriptions().subscribe((r: Subscription[]) => {
          this.m.subscription.setSubscriptions(r);
          this.needsRefresh = false;
        });
    }
  }

  public getSubscriptions() {
    return this.httpClient.get<any[]>('/services/licenses')
      .pipe(map((r: any[]) => r.map((item: any) => Subscription.fromJson(item))));
  }

  public getSubscriptionDetails(appliance_id: number) {
    return this.httpClient.get<any[]>(`/services/license/${appliance_id}`);
  }

  public generateSsoToken(aid: string): Observable<{token, instance, url}> {
    return this.httpClient.post<{token, instance, url}>('/services/license/' + aid + '/generate_token', {});
  }

  public startPairing(l: Subscription): Observable<{token}> {
    return this.httpClient.post<{token}>(
      `/services/v1/license/${l.license_id}/startpairing`,
      {}
    );
  }

  public cancelSubscription(s: Subscription, cancelNow: boolean): Observable<boolean> {
    const data = {
      appliance_id: s.appliance_id,
      now: cancelNow
    };
    return this.httpClient.post<any>('/services/subscriptions/cancel', data).pipe(map(() => {
      this.refresh(true);
      return true;
    }));
  }

  public undoCancellationSubscription(s: Subscription): Observable<boolean> {
    if (!s.isOne || !this.m.account.isMypascomAdmin || !s.cancelled) {
      return;
    }
    return this.httpClient.post<any>('/services/subscriptions/cancelundo',
      {appliance_id: s.appliance_id}).pipe(map(() => {
      this.refresh(true);
      return true;
    }));
  }

  public unpair(l: Subscription) {
    return this.httpClient.post(`/services/v1/license/${l.license_id}/unpair`, {})
  }

  public changeHost(s: Subscription, newCspHost: {aid: string, aname: string}): Observable<boolean> {
    const data = {
      appliance_id: s.appliance_id,
      new_host_id: newCspHost.aid
    };
    return this.httpClient.post<any>('/services/subscriptions/host/change', data).pipe(map(() => {
      this.refresh(true);
      return true;
    }));
  }

  public generatePasswordResetToken(s: Subscription, rt: string) {
    const data = {
      request_token: rt
    };
    return this.httpClient.post<{token}>('/services/appliance/' + s.appliance_id + '/generate_reset_password_token', data);
  }

  /**
   * Load specified subscriptions
   * or one, if [subscription] array with single item was given to this method
   * (will be used by the subscription details view)
   *
   * @param subscriptions
   */
  public getSubscriptionsBootstate(subscriptions: Subscription[]) {
    return this.httpClient.post<SubscriptionBootstate[]>('/services/appliances/state', this.getBootstateRequestObject(subscriptions));
  }

  /**
   * load bootstate for all subscriptions called via refresh function
   *
   * Soft caching method included (see first return if statement)
   * The states will only be reloaded when a server refresh was done
   */
  public refreshSubscriptionsBootstate(): Observable<SubscriptionBootstate[]> {
    if (this.m.subscription.subscriptionBoostates.size > 0) return;
    // add 'api_loading' for loading boostate and null, if it isn't on the whitelist
    this.m.subscription.setSubscriptions(this.m.subscription.subscriptions.map(s => {
      const isWhitelisted = this.isBoostateForParentNameWhitelisted(s.parent_name);
      s.bootstate = isWhitelisted
        ? (s.isValid ? 'api_loading' : 'down')
        : null;
        return s;
      }
    ));

    this.getSubscriptionsBootstate(this.m.subscription.subscriptions).subscribe({
      next: (r: any) => {
        this.m.subscription.setSubscriptionBoostates(r); // set bootstate model
        this.m.subscription.setAllSubscriptionsBootstate(); // set subscriptions bootstate
      },
      error: (error: any) => {
        this.m.subscription.setAllSubscriptionsBootstateToUnknown();
      }
    });
  }

  /**
   * Check if subscription parent name is on whitelist
   * @param parentName
   */
  public isBoostateForParentNameWhitelisted(parentName: string) {
    return this.boostateForParentNameWhitelist.includes(parentName);
  }

  public get partnerCanCreateNfrOne() {
    if (!this.m.account.currentUser.companyIsPartner) {
      return false;
    }

    if (this.m.account.currentUser.companyIsPC20Csp) {
      return false;
    }

    return !this.m.subscription.hasNfrOne;
  }

  public setFallbackNumber(aid: string, fallbackNumber: string) {
    return this.httpClient.post(`/services/appliance/${aid}/fallback_number`,{
      one_trunk_fallback_phonenumber: fallbackNumber,
    });
  }

  /**
   * Creates an object for server request
   *
   * { 'pascom.cloud': ['pbx1', 'pbx2'], 'pascom-dev.cloud': ['pbxdev1', 'pbxdev2'] }
   * See whitelist for more info
   * @param subscriptions
   * @private
   */
  private getBootstateRequestObject(subscriptions: Subscription[]): any {
    const applianceState = {};
    for (const s of subscriptions) {
      if (this.isBoostateForParentNameWhitelisted(s.parent_name) && s.isValid) {
        if (!applianceState[s.parent_name]) applianceState[s.parent_name] = [];
        applianceState[s.parent_name].push(s.instance_name);
      }
    }
    return applianceState;
  }
}
