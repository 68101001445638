<div class="card card-nb h-100" *ngIf="hasTariffDetail">
    <div class="card-body pb-0">
        <div class="row">
            <div class="col-12 h2">
                <app-subscription-title></app-subscription-title>
                <h2>{{ 'telephone_tariff' | i18next }}</h2>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 highlighted">
                <span *ngIf="isTariffOrdered">{{ 'ordered_tariff_text' | i18next: {
                    currentTariff: currentTariffName,
                    orderedTariff: orderedTariffName,
                    date: orderedTariffDate,
                    interpolation: { escapeValue: false }
                } }}</span>
                <span *ngIf="!isTariffOrdered">{{ 'current_tariff_text' | i18next: {currentTariff: currentTariffName} }}</span>
            </div>
        </div>
        <div class="row subinformations" *ngIf="isAdmin">
            <div class="row mt-3 w-100 justify-content-end">
                <button *ngIf="isTariffOrdered" class="btn btn-link small" [routerLink]="['/do', 'tariff-cancel', this.subscription.appliance_id]">{{ 'tariff_cancel_order' | i18next }}</button>
                <button [disabled]="isTariffOrdered" class="btn btn-outline-primary"
                        [routerLink]="['/do', 'tariff', subscription.appliance_id, '']">{{ 'choose_tariff' | i18next }}</button>
            </div>
        </div>
    </div>
</div>
