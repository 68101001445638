import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from '../../../subscription/subscription.model';
import { SupportService } from '../../support.service';
import { TicketRequest, Ticket, AttachmentRequest, AttachedFile } from '../../support.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-ticket-form',
    templateUrl: './create-ticket-form.component.html',
    styleUrls: []
})
export class CreateTicketFormComponent implements OnInit {

    @Input()
    subscription: Subscription;

    @Output()
    onBack = new EventEmitter<void>();

    public articleMessage: string;
    public subject: string;
    public isRequesting = false;
    public isUploading = false;
    public attachments: AttachmentRequest[] = [];

    constructor(private supportService: SupportService,
                private router: Router) {}

    ngOnInit() {
        if (!this.subscription.hasSupport) {
            this.router.navigate(['/subscriptions', this.subscription.appliance_id]);
        }
    }

    get subscriptionName(): string {
        return '[' + this.subscription.appliance_id + '] ' + this.subscription.name;
    }

    back() {
        window.history.back();
    }

    submitTicket() {
        if (!this.articleMessage || !this.subject) {
            return;
        }
        this.isRequesting = true;
        const ticket = new TicketRequest();
        ticket.applianceId = this.subscription.appliance_id;
        ticket.body = this.articleMessage;
        ticket.title = this.subject;
        if (this.attachments.length > 0) {
            ticket.attachments = this.attachments;
        }
        this.supportService.createTicket(ticket).subscribe(r => {
            const ticketId = Ticket.fromJson(r).id;
            this.isRequesting = false;
            this.router.navigate(['/support/ticket', ticketId]);
        });
    }

    onUpload(event: boolean) {
        this.isUploading = event;
      }

    onFilesChanged(attachedFiles: AttachedFile[]) {
        this.attachments = [];
        // Get attachment for create ticket request
        for (const attachedFile of attachedFiles) {
            const attachmentRequest = new AttachmentRequest();
            attachmentRequest.fileId = attachedFile.fileId;
            attachmentRequest.name = attachedFile.file.name;
            this.attachments.push(attachmentRequest);
        }
    }

    get isArticleBlank() {
        return !this.articleMessage || this.articleMessage.trim().length === 0;
    }

    get isSubjectBlank() {
        return !this.subject || this.subject.trim().length === 0;
    }
}
