import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {FormGroup} from '@angular/forms';
import {BankAccount} from './company.model';
import { trimInput } from '@myshared/utils';

export function sepaForm(pcFormService: PCFormsService): PCFormBuilder {
  const builder = pcFormService.newBuilder('payment');
  builder.addTextInput({
    id: 'sepa_account',
    validators: {required: null},
    elementClass: {
      container: 'form-group-container'
    },
  });
  builder.addTextInput({
    id: 'sepa_iban',
    validators: {required: null},
    elementClass: {
      container: 'form-group-container'
    },
  });
  return builder;
}


export function setSepaDetailsToForm(formGroup: FormGroup, bankAccount: BankAccount) {
  formGroup.setValue({
    'payment': {
      'sepa_account': bankAccount.account_holder,
      'sepa_iban': bankAccount.acc_number
    }
  });
}


export function getSepaDetailsFromForm(formGroup: FormGroup, id: string): BankAccount {
  const data = formGroup.value['payment'];
  const b = BankAccount.fromJson({});
  b.id = id;
  b.acc_number = trimInput(data.sepa_iban);
  b.account_holder = trimInput(data.sepa_account);
  return b;
}
