<div *ngIf="subscription">
<app-subscription-change-host-warning
    *ngIf="workflowStep === 'change-host-warning'"
    [subscription]="subscription"
    (continueChangeHostEvent)="onContinue($event)"
    (backEvent)="resetWorkflowAndRedirect()"
  ></app-subscription-change-host-warning>
  <app-select-csphost *ngIf="workflowStep === 'select-csp-host'" [currentSubscription]="subscription"
                      (selected)="onHostSelected($event)"></app-select-csphost>
  <app-subscription-change-host-confirm
    *ngIf="workflowStep === 'confirm-change-host'"
    [subscription]="subscription"
    [newCspHost]="newCspHost"
    (changeHostConfirmedEvent)="onChangeHostConfirmed()"
    (backEvent)="resetWorkflowAndRedirect()"
  ></app-subscription-change-host-confirm>

  <app-subscription-change-host-done
    *ngIf="workflowStep === 'change-host-done'"
    [subscription]="subscription"
    [oldCspHost]="oldCspHost"
    [newCspHost]="newCspHost"
    (changeHostDoneEvent)="onChangeHostDone()"
  ></app-subscription-change-host-done>

</div>
