<div class="badge rounded-pill bg-info recommended-product"
     *ngIf="slaProduct.type === 'selected'">{{ 'sla_product_selected' | i18next }}</div>
<div class="price-card card card-nb h-100"
     [ngClass]="cssClasses">
  <div class="d-flex flex-column">

    <div class="price-card-header">
      <div class="d-flex align-items-center flex-column">
        <div class="subscription-type h3">{{ slaProduct.name | i18next }}</div>
        <div class="subscription-description">{{ slaProduct.name+'_description' | i18next}}</div>
        <div class="subscription-price mt-auto">
          <span class="price">+ {{ slaProduct.list_price | localized_currency:{vatMarker: slaProduct.list_price > 0} }}</span>
          <span class="time-billing">{{billingPeriod |  i18next }}</span>
        </div>
      </div>
    </div>

    <div class="price-card-body sla-product mt-4">
      <div class="container" *ngIf="slaProduct && slaProduct.features && slaProduct.features.length > 0">
        <div class="row features justify-content-center">
          <div class="features-item">
            <div *ngFor="let f of slaProduct.features">
              <div class="d-flex">
                <i class="fas fa-plus-circle features-icon pt-1" [ngClass]="{'red': f.highlighted}"></i>
                <span class="wrap-text-translation">{{ f.name  | i18next }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="price-card-footer">
      <button *ngIf="!disabled"
              [name]="'select-' + slaProduct.name" class="btn btn-primary"
              (click)="send(slaProduct.name)">{{ selectBtnTranslation | i18next }}</button>
    </div>
  </div>
</div>
