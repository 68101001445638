import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { SupportService } from '../support.service';
import { Model } from '../../app.model';

@Component({
  selector: 'app-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: []
})
export class ArticleEditorComponent implements OnInit {

    private editorMessage: string;

    @Output()
    messageChange = new EventEmitter<string>();

    @Input()
    get message() {
        return this.editorMessage;
    }

    set message(value) {
        this.editorMessage = value;
        this.messageChange.emit(this.editorMessage);
    }

    constructor(private supportService: SupportService,
                private m: Model) {
    }

    ngOnInit(): void {
    }
}
