import {Injectable} from "@angular/core";
import {filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";

type GtagDataLayer = {[key: string]: any}

@Injectable()
export class AnalyticsService {
  constructor(
    private router: Router,
    private titleService: Title
  ) {}

  initTagManagerNavListener() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.addTagManager('virtualPageView', {
        pagePath: event.urlAfterRedirects,
        pageTitle: this.titleService.getTitle(),
        pageLocation: window.location.href
      });
    });
  }

  /**
   * Push an event and additional information to the google tag dataLayer array
   *
   * @param {string} eventName main dataLayer event name
   * @param {GtagDataLayer} params optional, further event parameters
   */
  addTagManager(eventName: string, params?: GtagDataLayer) {
    const dataLayer = (window as any).dataLayer;
    if (dataLayer && typeof dataLayer.push === 'function') {
      // See: https://support.google.com/tagmanager/answer/7679219?hl=en&authuser=1&ref_topic=7679108
      // Cast to any to avoid type warnings, assign needs either available natively or through a polyfill
      // This needs to be enabled in the consuming angular application
      dataLayer.push(
        (Object as any).assign({}, params ?? {}, {event: eventName})
      );
    } else {
      if (!environment.production) {
        console.warn('Google Analytics is not initialized properly.');
      }
    }
  }
}