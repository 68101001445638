<div>
  <div *ngIf="step === 'creating-offer'">{{'create_offer_please_wait' | i18next}}</div>
  <app-select-invoice-contact
    [customer]="selectedCustomer"
    [subscription]="subscription"
    (back)="onSelectContactBack()"
    (selected)="onContactSelected($event)"
    *ngIf="step === 'select-contact'">
  </app-select-invoice-contact>
  <app-upgrade-one
    [productPrice]="pcOnePrice"
    [productDiscount]="pcOneDiscount"
    [subscription]="subscription"
    [customer]="selectedCustomer"
    (back)="onBackUpgradeOne()"
    (next)="onProductSelected($event)"
    *ngIf="step === 'select-upgrade'">
  </app-upgrade-one>
  <app-select-one-sla
    [slaProducts]="slaProducts"
    [subscription]="subscription"
    (selected)="onSLAProductSelected($event)"
    (backEmitter)="onSLAProductBack()"
    *ngIf="step === 'select-sla'">
  </app-select-one-sla>
<!--  If partner-->
  <app-invoice-address
    [company]="company"
    [onlyEmit]="true"
    (next)="onCustomerInvoiceAddressFilled($event)"
    *ngIf="(isPC20CSP || company) && step === 'customer-invoice-address'">
  </app-invoice-address>
  <app-payment-method
    [company]="company"
    [onlyEmit]="true"
    (next)="onCustomerPaymentMethodSelected($event)"
    [paymentMethod]="paymentMethod"
    *ngIf="(isPC20CSP || company) && step === 'customer-payment-method'">
  </app-payment-method>
  <!--  if my-->
  <app-invoice-address
    (next)="onInvoiceAddressFilled($event)"
    *ngIf="step === 'invoice-address'">
  </app-invoice-address>
  <app-payment-method
    (next)="onPaymentMethodSelected()"
    [paymentMethod]="paymentMethod"
    *ngIf="step === 'payment-method'">
  </app-payment-method>
  <app-checkout-one-summary
    [migrationCredit]="migrationCredit"
    [subscription]="subscription"
    [historyBack]="false"
    (buy)="onBuy()"
    (back)="onSummaryBack()"
    *ngIf="step === 'checkout-summary'">
  </app-checkout-one-summary>
  <app-buy-thankyou
    *ngIf="step === 'thank-you'"
    [orderName]="orderName">
  </app-buy-thankyou>
  <app-buy-thankyou-partner
    *ngIf="step === 'thank-you-partner'"
    [orderName]="orderName">
  </app-buy-thankyou-partner>
  <!-- if cloud migration-->
    <app-tenant-migration
        (back)="onTenantMigrationBack()"
        (continue)="onTenantMigrationNext()"
        [subscription]="subscription"
        *ngIf="step === 'tenant-migration'"
    ></app-tenant-migration>
  <app-cloud-migration-information
    (back)="onBackMigrateCloud()"
    (continue)="onReadInformation()"
    [subscription]="subscription"
    *ngIf="step === 'migration-info'">
  </app-cloud-migration-information>
  <app-migration-credit
    (continue)="onReadCredit()"
    (back)="onBackMigrateCloud()"
    [subscription]="subscription"
    [activeProduct]="activeProduct"
    *ngIf="step === 'migration-credit'">
  </app-migration-credit>
  <app-select-instance-name
    (selected)="onInstanceNameSelected($event)"
    [selectedDomain]="selectedDomain"
    [applianceName]="subscription?.name ?? undefined"
    (backEmitter)="onBackMigrateCloud()"
    *ngIf="subscription && step === 'select-instance-name'">
  </app-select-instance-name>
  <app-select-domain (selected)="onDomainNameSelected($event)"
                     [domains]="domains"
                     *ngIf="domains && domains.length > 1 && step === 'select-domain-name'"></app-select-domain>
  </div>
