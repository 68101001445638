import {Injectable} from '@angular/core';
import {I18NextService} from 'angular-i18next';
import {MessageService} from "primeng/api";

@Injectable()
export class ToastService {
  constructor(private i18next: I18NextService,
              private ngMessageService: MessageService) {
  }

  public info(detail: string, summary: string = 'info') {
    this.sendMessage('info', summary, detail);
  }
  public success(detail: string, summary: string = 'info') {
    this.sendMessage('success', summary, detail);
  }
  public error(detail: string, summary: string = 'error') {
    this.sendMessage('error', summary, detail);
  }
  public warn(detail: string, summary: string = 'info') {
    this.sendMessage('warn', summary, detail);
  }

  private sendMessage(type: string, summary: string, detail: string) {
    this.ngMessageService.add({severity: type,
      summary: this.i18next.t(summary) as string,
      detail: this.i18next.t(detail) as string});
  }


}