import {Component, OnInit} from '@angular/core';
import {SubscriptionService} from '../../subscription/subscription.service';
import {runInAction} from 'mobx';

import {Model} from '../../app.model';
import {Subscription} from '../../subscription/subscription.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {filterText} from '@myshared/utils';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {action, observable} from '@myshared/mobx-connector';

@Component({
  selector: 'app-workflow-select-license',
  templateUrl: './workflow-select-license.component.html',
  styleUrls: []
})
export class WorkflowSelectLicenseComponent extends AutorunComponent implements OnInit {

  public subscriptions: Subscription[];

  @observable private showPaired$: boolean;
  @observable private searchText$: string;

  private applianceTypeFilter: string[] = [];
  private currentWorkflowString: string;

  public isAdmin: boolean;
  public isCsp: boolean;

  constructor(private m: Model,
              private subscriptionService: SubscriptionService,
              private router: Router,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => this.currentWorkflowString = params.get('b64'));
    runInAction(() => {
      this.showPaired$ = false;
      this.searchText$ = '';
    });
    this.autorun(() => {
      if (this.m.workflow.request.workflowName === 'onsite-setup') {
        this.applianceTypeFilter = ['onsite', 'csp'];
      }
      const mac = this.m.workflow.macFromUserData;
      if (mac) {
        const filtered = this.m.subscription.subscriptions.filter(lic => lic.mac === mac);
        if (filtered.length > 0) {
          this.subscriptions = filtered;
          return;
        }
      }
      this.subscriptions = filterText(this.m.subscription.subscriptions, this.searchText$)
        .filter(lic => !this.applianceTypeFilter || this.applianceTypeFilter.indexOf(lic.appliance_type) !== -1)
        .filter(lic => this.showPaired$ ? true : lic.pairing_state === 'unpaired')
        .filter(lic => lic.isValid);
    });
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.isCsp = this.m.account.currentUser.companyIsCsp;
    });
    this.subscriptionService.refresh(true);
  }

  @action public showPaired(value: boolean) {
    this.showPaired$ = value;
  }

  @action public filterLicenses(value: string) {
    this.searchText$ = value;
  }

  onAddSubscription() {
    this.router.navigate(['/do', 'onsite'], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  onAddCsp() {
    this.router.navigate(['/do', 'csphost'], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }
}
