<app-select-one-subscription (back)="cancelWorkflow()"
                             (selected)="onSubscriptionSelected($event)"
                             [historyBackSteps]="2"
                             *ngIf="step === 'select-subscription'"></app-select-one-subscription>
<app-select-invoice-contact
  [customer]="selectedCustomer"
  [subscription]="subscription"
  (selected)="onContactSelected($event)"
  *ngIf="step === 'select-contact'"></app-select-invoice-contact>

<app-select-phonenumber-type (selected)="onPhoneTypeSelected($event)"
                             [subscription]="subscription"
                             [trunkNumbers]="trunkNumbers"
                             *ngIf="step === 'select-type' && subscription"></app-select-phonenumber-type>
<!-- AT customer cannot order tenant automatically. We will display info text -->
<app-phonenumber-at-contact-sales *ngIf="step === 'contact-sales'" (selected)="cancelWorkflow()"></app-phonenumber-at-contact-sales>
<!-- PHONE NUMBER PORT -->
<app-phonenumber-port-prepare *ngIf="step === 'port-prepare'"
                              (selected)="onPortPrepare()"></app-phonenumber-port-prepare>
<app-phonenumber-port *ngIf="step === 'port-number'"
                      [countryCode]="subscription.trunkEnvironment.tenantCountryCode"
                      (selected)="onPortNumbersSelected($event)"></app-phonenumber-port>
<app-upload-phonebill-form *ngIf="step === 'upload-phonebill-form'"
                           (selected)="onPhoneBillUploaded($event)"></app-upload-phonebill-form>
<app-phonenumber-bill-company-name *ngIf="step === 'phonebill-company-name-check'"
                                   [companyName]="company.name"
                                   (selected)="onCompanyName($event)"></app-phonenumber-bill-company-name>
<app-cancel-provider-connection
  *ngIf="step === 'cancel-provider-connection'"
  [portingFormDetails]="portingFormDetails"
  (selected)="onPortFormOptionsSelected($event)"></app-cancel-provider-connection>
<app-download-port-form
  *ngIf="step === 'download-port-form'"
  [portingFormDetails]="portingFormDetails"
  (selected)="onPortFormDownloaded($event)"></app-download-port-form>
<app-upload-port-form *ngIf="step === 'upload-port-form'"
                      (selected)="onPortFormUploaded($event)"></app-upload-port-form>
<app-phonenumber-port-date *ngIf="step === 'select-port-date'"
                      (selected)="onPortDateSelected($event)"></app-phonenumber-port-date>
<!-- NEW PHONE NUMBER -->
<app-upload-company-license  (selected)="onCompanyFilesUploaded($event)"
                             *ngIf="step === 'upload-company-files'"></app-upload-company-license>
<app-select-phonenumber-blocksize (selected)="onPhoneBlocksizeSelected($event)"
                                  *ngIf="step === 'select-blocksize'"></app-select-phonenumber-blocksize>
<app-phonenumber-unavailable
  (selected)="onPhonenumberUnavailableBack()"
  *ngIf="step === 'numbers-unavailable'"></app-phonenumber-unavailable>
<app-select-phonenumber (selected)="onPhonennumberSelected($event)"
                         [blocks]="blocks"
                         *ngIf="step === 'select-phonenumber' && blocks"></app-select-phonenumber>
<app-select-loc-ind-phonenumber (selected)="onLocIndPhonennumberSelected($event)"
                         [blocks]="blocks"
                         *ngIf="step === 'select-loc-ind-phonenumber' && blocks"></app-select-loc-ind-phonenumber>
<!-- BOTH -->
<!--Partner-->
<app-invoice-address
  [company]="company"
  [onlyEmit]="true"
  [backLabel]="backLabel" [validateViaApi]="true"
  (next)="onCustomerInvoiceAddressFilled($event)" *ngIf="step === 'customer-invoice-address'"></app-invoice-address>
<app-payment-method
  [company]="company"
  [onlyEmit]="true"
  [backLabel]="backLabel" (next)="onCustomerPaymentMethodSelected($event)"
  [paymentMethod]="paymentMethod"
  *ngIf="step === 'customer-payment-method'"></app-payment-method>
<!--is my-->
<app-invoice-address [backLabel]="backLabel" [validateViaApi]="true" (next)="onInvoiceAddressFilled($event)" *ngIf="step === 'invoice-address'"></app-invoice-address>
<app-payment-method [paymentMethod]="paymentMethod" [backLabel]="backLabel" (next)="onPaymentMethodSelected($event)" *ngIf="step === 'payment-method'"></app-payment-method>
<app-checkout-phonenumber-summary [subscription]="subscription"
                                  (buy)="onBuy()"
                                  (cancelBuy)="cancelWorkflow()"
                                  *ngIf="step === 'checkout-summary' && subscription"></app-checkout-phonenumber-summary>
<app-buy-phonenumber-thankyou  *ngIf="step === 'thank-you'" [orderName]="orderName"></app-buy-phonenumber-thankyou>
<app-buy-thankyou-partner  *ngIf="step === 'thank-you-partner'" [orderName]="orderName"></app-buy-thankyou-partner>
