import { Component, OnInit, AfterViewInit } from '@angular/core';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {matchOther} from '@myshared/matchOtherValidator';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {Model} from '../../app.model';
import {AuthService} from '../../auth/auth.service';
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  private passBuilder: PCFormBuilder;

  constructor(
    private pcFormService: PCFormsService,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private i18next: I18NextService,
    private m: Model,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.passBuilder = this.pcFormService.newBuilder('change_password');
    this.passBuilder.addPassword({
      id: 'current_password',
      tabIndex: 1,
      elementClass: {
        container: 'form-group-container'
      },
      validators: {required: null}
    });
    this.passBuilder.addPassword({
      id: 'password',
      tabIndex: 2,
      elementClass: {
        container: 'form-group-container'
      },
      validators: {required: null, minLength: 8}
    });
    this.passBuilder.addPassword({
      tabIndex: 3,
      id: 'confirmPassword',
      elementClass: {
        container: 'form-group-container'
      },
      validators: {
        matchOther: {
          name: matchOther.name,
          args: 'change_password.password'
        }
      }
    });
    this.formModel = this.passBuilder.model;
    this.formLayout = this.passBuilder.layout;
    this.formGroup = this.passBuilder.createFormGroup();
  }

  ngAfterViewInit() {
    this.passBuilder.autoFocus();
  }

  public changePassword() {
    const passValues = this.formGroup.value['change_password'];
    const password = passValues.password;
    const confirm_password = passValues.confirmPassword;
    const current_password = passValues.current_password;
    const email = trimInput(this.m.account.currentUser.email);

    this.httpClient.post<any>('/services/auth/change_password', {
      current_password: current_password,
      password: password,
      confirm_password: confirm_password,
      email: email,
    }).subscribe(() => {
      this.messageService.add({severity: 'success',
        summary: this.i18next.t('success') as string,
        detail: this.i18next.t('change_password_saved') as string});
      this.authService.logout();
    });
  }

}
