<app-content-header (backEvent)="onBack()" [stepsBack]="stepsBack"
    [headline]="'tenant_migration_headline' | i18next"></app-content-header>


<app-icon-card>
  <div class="h3 mt-4 red">{{ 'tenant_migration_headline' | i18next}}</div>

  <span class="">{{ 'tenant_migration_text' | i18next }}</span>
  <div class="d-flex justify-content-end mt-2 form-container-footer">
      <button name="continue" class="btn btn-outline-primary"
          (click)="onContinue()">{{ 'app_continue' | i18next }}</button>
  </div>
</app-icon-card>
