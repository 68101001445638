<app-warning-confirm-box
  *ngIf="step === 'tariff-cancel-confirm'"
  [hideCancelBtn]="true"
  [confirmHeadline]="'tariff_cancel_order_confirm_headline' | i18next"
  [confirmDetailsHeadline]="'tariff_cancel_order_confirm_headline' | i18next"
  [confirmDetailsText]="'tariff_cancel_order_confirm_text' | i18next"
  [confirmBtn]="'tariff_cancel_order_confirm_btn' | i18next"
  (back)="onBack()"
  (confirm)="onConfirm()"
></app-warning-confirm-box>
