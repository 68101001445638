import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ProductCard} from '../product.model';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: []
})
export class PriceCardComponent implements OnInit, OnChanges {

  @Input() public product: ProductCard;
  @Output() public readonly onBtnPressed = new EventEmitter<string>();

  selectedProduct = '';
  currentPrice = 0;

  public concurrentUserLink: string;

  constructor(
    private i18next: I18NextService
  ) { }

  ngOnInit() {
    this.concurrentUserLink = this.i18next.t('concurrent_user_link') as string;
  }

  ngOnChanges() {
    if (this.product.products.length > 0) {
      this.selectedProduct = this.product.products[0].name;
      this.onProductChange();
    }
  }

  send() {
    this.onBtnPressed.emit(this.selectedProduct);
  }

  onProductChange() {
    this.currentPrice = this.product.products.find(p => p.name === this.selectedProduct).list_price;
  }

}
