import {Directive} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WorkflowComponent} from "../workflow.component";
import {firstValueFrom} from "rxjs";
import {Subscription} from "../../subscription/subscription.model";
import {observable, runInAction} from "mobx";
import {SubscriptionService} from "../../subscription/subscription.service";
import {BuyService} from "../../buy/buy.service";
import {Model} from "../../app.model";
import {CustomerService} from "../../customer/customer.service";
import {CompanyService} from "../../company/company.service";
import {Customer} from "../../customer/customer.model";
import {Company, Contact} from "../../company/company.model";
import {WorkflowCspService} from "./workflow-csp.service";
import {SaleOrder} from "../../buy/buy.model";

export interface WorkflowModel {
    cu?: number,
    saleOrder?: SaleOrder,
    subscription: Subscription,
    customer?: Customer,
    contact?: Contact,
    company?: Company,
}

@Directive()
export abstract class WorkflowCspChangeCuComponent extends WorkflowComponent {

    @observable
    public model: WorkflowModel;

    private workflowName: 'upgrade-cu' | 'downgrade-cu';

    protected constructor(protected route: ActivatedRoute,
                          protected router: Router,
                          protected subscriptionService: SubscriptionService,
                          protected buyService: BuyService,
                          protected m: Model,
                          protected customerService: CustomerService,
                          protected workflowCspService: WorkflowCspService,
                          protected companyService:CompanyService) {
        super(route);
    }

    protected async initWorkflow(workflowName: 'upgrade-cu' | 'downgrade-cu') {
        this.workflowName = workflowName;
        const cu = +this.route.snapshot.queryParamMap.get('cu');
        const so = this.route.snapshot.queryParamMap.get('so');
        const contactId = this.route.snapshot.queryParamMap.get('c_id');
        const subscriptionJson = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
        const subscription = Subscription.fromJson(subscriptionJson);

        if (!subscription) {
            this.resetWorkflow();
            return;
        }

        if (subscription.isCancelled) {
            this.resetWorkflow();
            return;
        }

        if (cu === undefined || cu < 4) {
            this.resetWorkflow();
            return;
        }

        if (subscription.is_my || !this.m.account.currentUser.companyIsPC20Csp) {
            this.resetWorkflow();
            return;
        }

        if (!!subscription.scheduledCUOrder) {
            this.resetWorkflow();
            return;
        }

        const model: WorkflowModel = {
            subscription,
            cu: cu > 0 ? cu : undefined
        }

        const customers = await firstValueFrom(this.customerService.getCustomers());
        model.customer = customers.find(r => r.id === model.subscription?.customer_id);
        model.contact = model.customer.contacts.find(r => +r.id === +contactId);

        // If we already have a sale order on our purchase model, we load it from the API.
        if (!this.m.buy.currentOrder?.name && so) {
            model.saleOrder = await firstValueFrom(this.buyService.getSaleOrder(so));
        }

        runInAction(() => {
            this.model = model;
        });

        // We NEED to override this check function checkSelectedProduct inside our class
        // Otherwise the workflow will be retested
        if (this.checkSelectedProduct(true)) {
            this.resetWorkflow();
            return;
        }
    }

    /**
     * Override this checkSelectedProduct function to handle the workflow.
     * This can be overridden by the class and can include the statement
     *
     * If TRUE, workflow will reset
     * IF FALSE, workflow will continue
     *
     * @param ifStatement
     * @protected
     */
    protected abstract checkSelectedProduct(ifStatement: boolean);

    protected async createOffer() {
        if (this.model.saleOrder?.name) {
            return;
        }

        const saleOrder = await firstValueFrom(this.workflowCspService.oneApplianceCuChange(this.applianceId, this.model.cu));
        runInAction(() => {
            this.model.saleOrder = saleOrder;
        })
    }

    protected navigate(url: any[]) {
        this.router.navigate(['/do', 'csp', this.workflowName, this.applianceId, ...url], {
            queryParams: {
                so: this.model.saleOrder?.name ?? undefined,
                cu: this.model.cu ?? undefined,
                c_id: this.model.contact?.id ?? undefined
            },
        });
    }
}
