<div class="d-flex flex-column h-100">
  <app-content-header [stepsBack]="1" [headline]="'phonenumber_type_headline' | i18next" class="pl-3"></app-content-header>
    <app-phonenumbers-type-summary class="col-lg-12 col-sm-12 mt-2"
                              [monthlyPrice]="monthlyPrice"
                              [setupPrice]="setupPrice"
                              [includedInSubscription]="includedInSubscription"
                              [currentlyInUse]="usedInSubscription">
    </app-phonenumbers-type-summary>
  <div class="row mt-2 align-items-stretch">
    <app-phonenumber-type-card class="col-sm-12 col-lg-4 mb-3 mb-lg-0"
                              typeText="phonenumbers_type_loc_independent">
                              <button class="btn btn-primary" style="width: 250px;"
                              (click)="selectLocInd()">{{ 'phonenumbers_type_loc_independent_button' | i18next }}</button>
    </app-phonenumber-type-card>
    <app-phonenumber-type-card class="col-sm-12 col-lg-4 mb-3 mb-lg-0"
                               typeText="phonenumbers_type_new"
                               [monthlyPrice]="monthlyPrice"
                               [setupPrice]="setupPrice"
                               [included]="includedInSubscription"
                               [currentlyInUse]="usedInSubscription">
      <button class="btn btn-primary" style="width: 250px;"
              (click)="selectNew()">{{ 'phonenumbers_type_new_button' | i18next }}</button>
    </app-phonenumber-type-card>
    <app-phonenumber-type-card class="col-sm-12 col-lg-4"
                               typeText="phonenumbers_type_port"
                               [monthlyPrice]="monthlyPrice"
                               [setupPrice]="setupPrice"
                               [included]="includedInSubscription"
                               [currentlyInUse]="usedInSubscription">
      <button class="btn btn-primary" style="width: 250px;"
              (click)="selectPort()">{{ 'phonenumbers_type_port_button' | i18next }}</button>
    </app-phonenumber-type-card>
  </div>
  <div class="ml-auto mt-auto p-3 small">{{ 'vat_not_included' | i18next }}</div>
</div>
