import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styles: [
  ]
})
export class AlertCardComponent implements OnInit {

  @Input() pageHeadline?: string;
  @Input() cardHeadline?: string;
  @Input() alertText?: string;
  @Input() showBackToMypascomPage = false;


  constructor() { }

  ngOnInit(): void {
  }

  public goToMypascom() {
    window.location.replace('/');
  }
}
