<app-content-header [hint]="'contact_select_or_create_hint' | i18next" [headline]="'select_contact_select_or_create' | i18next:{companyName: customer?.name }" ></app-content-header>

<div class="d-flex flex-column flex-md-row justify-content-between">
  <div class="col-md-5 flex-fill">
    <div class="row mb-3">
      <div class="col m-0 p-0">
        <app-searchbox (filterChanged)="doFilter($event)" [placeholder]="'company_contacts_search' | i18next"></app-searchbox>
      </div>
    </div>

    <div class="row mb-3" *ngFor="let c of contacts">
      <app-contact-card class="col m-0 p-0"  [contact]="c" [showMypascomLevel]="false">
        <button class="btn btn-outline-primary mr-0" (click)="selectContact(c)">{{'app_select' | i18next }}</button>
      </app-contact-card>
    </div>


  </div>
  <p class="h1 text-uppercase text-align-center align-self-top col-md-2">{{ 'app_or' | i18next }}</p>
  <div class="flex-fill col-md-5">
    <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
    <div class="d-flex justify-content-end align-items-center mb-3 mt-3">
      <button class="btn btn-outline-primary" (click)="addContact()">{{ 'app_create_button' | i18next }}</button>
    </div>
  </div>
</div>
