import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {ActivatedRoute, Router} from '@angular/router';
import {BuyService} from '../../buy/buy.service';
import {Model} from '../../app.model';
import {SubscriptionService} from '../subscription.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {ConfirmationService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'app-subscription-one-details-card',
  templateUrl: './subscription-one-details-card.component.html',
})
export class SubscriptionOneDetailsCardComponent extends AutorunComponent implements OnInit {
  @Input() subscription: Subscription;
  public isAdmin: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private m: Model,
              private confirmationService: ConfirmationService,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
    });
  }

  public undoCancellationSubscription() {
    this.confirmationService.confirm({
      key: 'undoSubscription',
      message: this.i18next.t('undo_cancellation_subscription_confirm_message') as string,
      header: this.i18next.t('undo_cancellation_subscription_confirm_header') as string,
      accept: () => this.subscriptionService.undoCancellationSubscription(this.subscription).subscribe(_ =>
      window.location.reload())
    });
  }

  public goToCloudInstance() {
    this.subscriptionService.generateSsoToken(this.subscription.appliance_id).subscribe(r => {
      const url = r.url + '/?t=' + r.token;
      window.open(url, '_blank');
    });
  }
}
