import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';

@Component({
  selector: 'app-subscription-type-badge',
  templateUrl: './subscription-type-badge.component.html',
})
export class SubscriptionTypeBadgeComponent implements OnInit {

  @Input() public subscription: Subscription;

  constructor() { }

  ngOnInit() {
  }

  public get isOneTrial(): boolean {
    return this.subscription?.isOneTrial;
  }

  public get isOne(): boolean {
    return this.subscription?.isOne;
  }

}
