import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {PrimeNGConfig} from 'primeng/api';
import {AutorunComponent} from "@myshared/autorun.component";
import {Model} from "../../app.model";
import {normalizedShortLang} from "@myshared/i18nutils";
import {Days, pcDate} from "../../date.service";

@Component({
  selector: 'app-phonenumber-port-date',
  templateUrl: './phonenumber-port-date.component.html'
})
export class PhonenumberPortSelectDateComponent extends AutorunComponent implements OnInit {

  constructor(private config: PrimeNGConfig, private m: Model) { super(); }

  public minDate: Date;
  public selectedDate: string;

  @Output() selected: EventEmitter<any> = new EventEmitter<string>();

  ngOnInit(): void {
    this.autorun(() => {
      // FIXME: WE NEED TO USE ALSO i18n HERE
      // SEE: https://www.primefaces.org/primeng/i18n
      let calTranslation = {
        "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "dayNamesMin": ["Su","Mo","Tu","We","Th","Fr","Sa"],
        "monthNames": ["January","February","March","April","May","June","July","August","September","October","November","December"],
        "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      }
      if (normalizedShortLang(this.m.account.currentUser.lang).toLowerCase() === 'de') {
        calTranslation = {
          "dayNames": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          "dayNamesShort": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          "dayNamesMin": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          "monthNames": ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
          "monthNamesShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
        }
      }
      this.config.setTranslation(calTranslation)
    })
    const minDate = this.setMinDate();
    this.minDate = minDate.toDate();
    this.selectedDate = minDate.format();
  }

  onNext() {
    this.selected.emit(this.selectedDate);
  }


  setMinDate() {
    const minDate = pcDate().add({ days: 30 });
    const day = minDate.day();
    if (day === Days.SUNDAY) {
      return minDate.add({ days: 1 });
    }
    if (day === Days.SATURDAY) {
      return minDate.add({ days: 2 });
    }

    return minDate;
  }
}
