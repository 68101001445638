import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Model} from '../app.model';
import {I18NextService} from 'angular-i18next';
import {Profile} from './partner.model';
import {map, tap} from 'rxjs/operators';
import {runInAction} from 'mobx';
import {Observable} from 'rxjs';

@Injectable()
export class ProfileService {

  private needsRefresh = true;

  constructor(private m: Model,
              private http: HttpClient,
              private i18next: I18NextService) {

  }

  public refresh(force: boolean = false) {
    if (this.needsRefresh || force) {
      this.http.get<any>('/services/partner/profiles', {
        params: {
          lang: this.i18next.t('language_code') as string,
        }
      }).pipe(map(r => r.map(i => Profile.fromJson(i)))).subscribe(r => {
        runInAction(() => this.m.partner.profiles = r);
      });
    }
  }

  public save(p: Profile): Observable<boolean> {
    const data = {
      lang: this.i18next.t('language_code') as string,
      country_code: p.country.code,
      zip: p.zip,
      city: p.city,
      website: p.website,
      logo: p.logo
    };

    if (p.id) {
      return this.http.post<any>('/services/partner/profile/' + p.id, data).pipe(tap(() => this.refresh(true)));
    } else {
      return this.http.post<any>('/services/partner/profile/', data).pipe(tap(() => this.refresh(true)));
    }
  }

  public deleteProfile(profileId: string): Observable<boolean> {
    return this.http.delete<any>('/services/partner/profile/' + profileId, {}).pipe(tap(() => this.refresh(true)));
  }
}
