import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {PCFormBuilder, PCFormsService} from '@myshared/pcform.service';
import {Model} from '../../app.model';
import {CompanyService} from '../../company/company.service';

@Component({
  selector: 'app-company-address',
  templateUrl: './company-address.component.html',
  styleUrls: []
})
export class CompanyAddressComponent extends AutorunComponent implements OnInit {

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;

  accepted: boolean;

  private builder: PCFormBuilder;

  @Output() public selected = new EventEmitter<string>();

  constructor(private m: Model,
              private pcFormService: PCFormsService,
              private companyService: CompanyService) {
    super();
  }

  ngOnInit() {

    this.builder = this.pcFormService.newBuilder('company_address');
    this.builder.addTextInput({
      id: 'street',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'zip',
      validators: {required: null}
    });
    this.builder.addTextInput({
      id: 'city',
      validators: {required: null}
    });

    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();
  }

  onNext() {
    this.selected.emit('');
  }

  accept(v: boolean) {
    this.accepted = v;
  }
}
