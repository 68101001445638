<app-content-header [hint]="'company_address_hint' | i18next" [headline]="'company_address_headline' | i18next"></app-content-header>

<div class="d-flex justify-content-center">
  <div class="card card-nb">
    <div class="card-body">
      <h3>FIXME: Companyname</h3>
      <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      <app-pascom-checkbox [text]="'company_address_accept_terms' | i18next" (acceptChanged)="accept($event)"></app-pascom-checkbox>
    </div>
</div>
</div>

<div class="d-flex justify-content-end mt-2 form-container-footer">
  <button class="btn btn-outline-primary" (click)="onNext()" [disabled]="!formGroup.valid && accepted">{{ 'app_next' | i18next}}</button>
</div>
