import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownItem} from './pascom-dropdown';
import {I18NextService} from "angular-i18next";

@Component({
    selector: 'app-pascom-dropdown',
    templateUrl: './pascom-dropdown.component.html',
})
export class PascomDropdownComponent implements OnInit {

    constructor(private i18next: I18NextService) {}

    @Input() dropdownOptions: IDropdownItem[];
    @Output() public selectedOptionsEventEmitter = new EventEmitter<IDropdownItem[]>();
    @Input() selectedDropdownOptions: IDropdownItem[] = [];
    @Input() public placeholder?: string;
    @Input() public display: 'chip' | 'comma' = 'comma';
    @Input() public filter: boolean = false;

    ngOnInit(): void {
        this.onChangeSelection();
        if (!this.placeholder) {
          this.placeholder = this.i18next.t('filter') as string;
        }
    }

    onChangeSelection() {
        this.selectedOptionsEventEmitter.emit(this.selectedDropdownOptions);
    }
}
