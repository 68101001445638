import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Model } from '../../../app.model';
import { AutorunComponent } from '@myshared/autorun.component';
import { filterText } from '@myshared/utils';
import { runInAction } from 'mobx';
import { SubscriptionService } from '../../../subscription/subscription.service';
import { Subscription } from '../../../subscription/subscription.model';
import { observable, action } from '@myshared/mobx-connector';

@Component({
  selector: 'app-support-select-subscription',
  templateUrl: './support-select-subscription.component.html',
  styleUrls: []
})
export class SupportSelectSubscriptionComponent extends AutorunComponent implements OnInit {

  @Output()
  onSubscriptionSelected = new EventEmitter<Subscription>();

  public subscriptions: Subscription[];
  private selectedSubscription = 0;

  @observable private searchText$;

  constructor(private m: Model,
              private subscriptionService: SubscriptionService) {
        super();
  }

  ngOnInit(): void {
    runInAction(() => this.filterLicenses(''));
    this.autorun(() => {
      this.subscriptions = filterText(this.m.subscription.subscriptions, this.searchText$)
        .filter(s => s.isValid);
    });
    this.subscriptionService.refresh(true);
  }

  get isSubscriptionSelected() {
    return this.selectedSubscription > 0;
  }

  selectSubscription(subscription: Subscription) {
    this.onSubscriptionSelected.emit(subscription);
  }

  @action filterLicenses(value: string) {
    this.searchText$ = value;
  }
}
