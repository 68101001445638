<app-content-header backLabel="app_cancel" [stepsBack]="1" [headline]="'cancel_provider_connection_headline' | i18next"></app-content-header>

<div class="row d-flex justify-content-center">
  <div class="col-12 col-md-6">
    <app-icon-card [stretch]="false">
      <h3 class="h3 red mb-5 mt-3" [innerHtml]="'cancel_provider_connection_check' | i18next"></h3>
      <div class="d-flex">
        <app-pascom-checkbox [accepted]="cancelConnection" [text]="'cancel_provider_connection_check' | i18next" (acceptChanged)="onCancelConnectionCheckbox($event)"></app-pascom-checkbox>
        <i class="fas fa-exclamation-triangle red mt-1 mr-1 mb-5"></i>
      </div>
      <div class="d-flex align-items-start mb-5 mt-3">
        <i class="fas fa-exclamation-triangle red mt-1 mr-1"></i>
        <span class="subinformations" [innerHtml]="'cancel_provider_connection_hint' | i18next"></span>
      </div>
    </app-icon-card>
  </div>
</div>

<div class="d-flex justify-content-end mt-5">
  <button class="btn btn-outline-primary" (click)="onNext()">{{ 'app_next' | i18next}}</button>
</div>
