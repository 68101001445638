import {runInAction} from 'mobx';
import {Country} from '../country/country.model';
import {observable} from '@myshared/mobx-connector';
import {Contact} from '../company/company.model';
import {TrunkEnvironment} from "../auth/account.model";

export class Customer {

  constructor(
    public id: string,
    public name: string,
    public street: string,
    public street2: string,
    public zip: string,
    public city: string,
    public country: Country,
    public appliance_ids: string[],
    public contacts?: Contact[],
    public trunkEnvironment?: TrunkEnvironment) {
  }

  public matchesFilter(predicate: (filter: string) => boolean): boolean {
    return predicate(this.name)
      || predicate(this.street)
      || predicate(this.street2)
      || predicate(this.zip)
      || predicate(this.city)
      || predicate(this.country.name);

  }

  public static fromJson(json: any): Customer {
    return new Customer(
      json.id || '',
      json.name || '',
      json.street || '',
      json.street2 || '',
      json.zip || '',
      json.city || '',
      Country.fromJson(json.country),
      json.appliance_ids || [],
      json.contacts.map(c => Contact.fromJson(c)),
      TrunkEnvironment.fromJson(json.trunk_environment || {})
    );
  }

  get hasTrunkAccess(): boolean {
    return this.trunkEnvironment.hasTrunkAccess;
  }
}

export class CustomerModel {

  @observable customers: Customer[];

  constructor() {
    runInAction(() => this.customers = []);
  }
}
