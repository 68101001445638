<div *ngIf="initialized">
    <app-content-header (backEvent)="onBack()" [historyBack]="historyBack" [backLabel]="backLabel" [headline]="'payment_method_label' | i18next"></app-content-header>

    <app-icon-card *ngIf="!!company">
        <h3 class="h3 red mb-3">{{ 'payment_method_sepa' | i18next }}</h3>

      <div>
        <dynamic-primeng-form *ngIf="!!formGroup"  [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      </div>

        <div *ngIf="formGroup" class="d-flex justify-content-end mt-2 form-container-footer">
          <button [disabled]="!formGroup.valid" class="btn btn-outline-primary" (click)="onNextSepa()">
            {{ 'app_next' | i18next }}
          </button>
        </div>

    </app-icon-card>
</div>
