import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {I18NextService} from 'angular-i18next';
import {Subscription} from '../subscription.model';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';

@Component({
  selector: 'app-subscription-cancel-customer-confirm',
  templateUrl: './subscription-cancel-customer-confirm.component.html',
  providers: [LocalizedDatePipe]
})
export class SubscriptionCancelCustomerConfirmComponent implements OnInit {

  @Input() applianceName: string;
  @Input() applianceId: string;
  @Input() nextPossibleEndDate: string;
  @Input() isSubscriptionCancellingNow: boolean;
  @Output() confirm = new EventEmitter();
  @Output() backEvent = new EventEmitter<any>();

  public accept = false;

  constructor(private i18next: I18NextService,
              private datePipe: LocalizedDatePipe) {
  }

  ngOnInit() {
  }

  onConfirm() {
    this.confirm.emit();
  }

  get pascomOneEndDate() {
    return this.datePipe.transform(this.nextPossibleEndDate);
  }

  back() {
    this.backEvent.emit();
  }

  public onAccept(accept: boolean) {
    this.accept = accept;
  }

  get warningPointsList() {
    return [
      this.i18next.t('cancel_warning_deleted_after',
        { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
      this.i18next.t('cancel_warning_loose_data_after',
        { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
      this.i18next.t('cancel_warning_no_calls_after',
        { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
      this.i18next.t('cancel_one_warning_phonenumbers',
        { date: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
      this.i18next.t('cancel_warning_noconnect_pascomclient_after',
        { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }),
      this.i18next.t('cancel_warning_subscription_cannot_open_again') as string
    ];
  }

}
