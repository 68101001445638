<div *ngIf="!confirmFinish">
  <div class="row">
    <div class="col-10">
      <h3>{{currentTest.name}}</h3>
    </div>
    <div class="col-2">
      <button class="btn btn-outline-primary float-right" (click)="goBack()">{{'partner_test_cancel' | i18next}}
      </button>
    </div>
  </div>
  <div class="card card-nb mt-1">
    <div class="card-body">
      <div class="row" *ngIf="currentQuestion">
        <div class="col">
          <p>{{currentQuestion.question_text}}</p>
          <ul class="list-group">
            <li class="list-group-item no-border" *ngFor="let a of currentQuestion.answers">
              <app-pascom-checkbox [inputId]="a.id" [text]="a.text" [accepted]="a.checked" (acceptChanged)="a.checked = !a.checked"></app-pascom-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p *ngIf="currentQuestion">{{'partner_test_possible_answers' | i18next: { num: currentQuestion.max_answers}
            }}</p>
          <div class="col">
            <div class="float-right">
              <button *ngIf="canPrevious" class="btn btn-link" (click)="previous()">{{'partner_test_previous_question' |
                i18next}}
              </button>
              <button *ngIf="canNext" class="btn btn-outline-primary" (click)="next()">{{'partner_test_next_question' |
                i18next }}
              </button>
              <button *ngIf="!canNext && !confirmFinish" class="btn btn-outline-primary" (click)="confirmFinish = true">
                {{'partner_test_next_question' | i18next }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-5" *ngIf="!confirmFinish">
  <div class="offset-3 col-6 text-center">
    <div class="progress" style="height: 1.5rem;">
      <div class="progress-bar pt-1" role="progressbar" style="" [ngStyle]="{ width: progressPercent }">
      </div>
    </div>
    <p class="mt-2 text-center w-100">
      {{'partner_test_question_position' | i18next: { current:questionIdx + 1, max:currentTest.questions.length} }}
    </p>
  </div>
</div>
<div *ngIf="confirmFinish && !result">
  <div class="content-card-container-bg">
    <div class="content-card-container">
      <div class="content-card text-align-center">
        <img src="/assets/images/animals/wal.svg"/>
        <h1>{{'partner_test_confirm_submit_text' | i18next }}</h1>
        <div class="d-flex flex-column align-items-center">
          <button class="btn btn-outline-primary mt-5" (click)="doubleCheck()">{{'partner_test_doublecheck_btn' |
            i18next}}
          </button>
          <button class="btn btn-outline-primary mt-2" (click)="submitTest()">{{'partner_test_submit_btn' | i18next }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="result">
  <div class="content-card-container-bg">
    <div class="content-card-container">
      <div class="content-card text-align-center">
        <img src="/assets/images/animals/nasenbaer.svg"/>
        <h1 *ngIf="result.passed && !result.failed">{{'partner_test_successful' | i18next }}</h1>
        <h1 *ngIf="!result.passed && !result.failed">{{'partner_test_unsuccessful' | i18next }}</h1>
        <h1 *ngIf="result.failed">{{'partner_test_failed' | i18next }}</h1>
        <div class="d-flex flex-column align-items-center">
          <button class="btn btn-outline-primary" (click)="goBack()">{{'partner_test_back_to_overview_btn' |
            i18next}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
