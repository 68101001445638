import {Component, Input, OnInit} from '@angular/core';
import { I18NextService } from 'angular-i18next';
import {Subscription, TariffPlanDetail} from '../../../subscription/subscription.model';
import {tariffNameTranslationKey} from '@myshared/utils';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {TariffService} from '../../tariff.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-tariff-card',
    templateUrl: './tariff-card.component.html',
    providers: [LocalizedDatePipe]
})
export class TariffCardComponent implements OnInit {

    @Input() subscription: Subscription;
    @Input() isAdmin: boolean;

    constructor(
      private router: Router,
        private tariffService: TariffService,
        private i18next: I18NextService,
        private date: LocalizedDatePipe) {
    }

    ngOnInit() {
    }

    get hasTariffDetail(): boolean {
      return !!this.subscription?.tariffPlanDetail?.currentPlan
        && this.subscription?.tariffPlanDetail?.currentPlan !== 'PC-ONE-TARIFF-TRIAL';
    }

    get tariffDetail(): TariffPlanDetail {
      return this.subscription?.tariffPlanDetail;
    }

    get currentTariffName() {
      return this.getTariffName(this.tariffDetail.currentPlan);
    }

    get orderedTariffName() {
      return this.getTariffName(this.tariffDetail.orderedPlan);
    }

    get orderedTariffDate() {
      return this.date.transform(this.tariffDetail.orderedPlanActivationDate);
    }

    get isTariffOrdered() {
      return this.subscription.tariffPlanDetail.orderedPlan !== ''
        && this.subscription.tariffPlanDetail.orderedPlanActivationDate !== '';
    }

    private getTariffName(tariff: string): string {
        return this.i18next.t(tariffNameTranslationKey(tariff)) as string;
    }
}
