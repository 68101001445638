<app-content-header
  [headline]="'subscription_change_host_done_headline' | i18next:{ applianceId: subscription.appliance_id, applianceName: subscription.name }"></app-content-header>

<div class="col text-align-center mb-5">
  <div class="h3 red">{{ 'subscription_change_host_done_warning_header' | i18next:{currentHostName: subscription.parent_name, newHostName: newCspHost.aname} }}</div>
</div>

<div class="row align-items-stretch">
  <div class="mb-3 col-md-8 offset-md-2">
    <div class="card h-100 card-nb">
      <div class="card-body pb-0">
        <div class="">
          <h2 style="min-height:70px;">{{ 'subscription_change_host_done_label' | i18next }}</h2>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <p style="min-height:130px;">
          <span class="wrap-text-translation">{{ 'subscription_change_host_done_text' | i18next }}</span>
          <span *ngIf="oldCspHost.aid" innerHtml="{{ 'subscription_change_host_done_host' | i18next:{ hostName: oldCspHost.aname} }}"></span>
          <span *ngIf="newCspHost.aid" innerHtml="{{ 'subscription_change_host_done_host' | i18next:{ hostName: newCspHost.aname} }}"></span>
        </p>
        <div class="row justify-content-end">
          <button class="btn btn-primary" name="change-host-done" (click)="onOk()">{{ "subscription_change_host_done_button"  | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

