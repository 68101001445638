import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "../../subscription/subscription.model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-one-tariff-plan',
  templateUrl: './one-click-change-tariff-plan.component.html'
})
export class OneClickChangeTariffPlanComponent implements OnInit {

  @Input() subscription: Subscription;
  @Input() canSeeTrunk: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  public doChange() {
    this.router.navigate(['/do', 'tariff-migration', this.subscription.appliance_id, ''], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public get subscriptionType() {
    return this.subscription.licenseTypeFormatted;
  }

}
