<div class="pc2FAAuthPage">
  <div class="pcHeader login-page-header">
    <div class="pcHeader-wrapper">
      <div class="pcLogo"></div>
    </div>
  </div>
  <div class="card">
    <header class="login-header">
      <h3 class="h3 pcPage-title">
        {{ "2fa_sms_headline" | i18next }}
      </h3>
    </header>
    <div class="pcContent">
      <div class="pcContent-wrapper">
        <div class="pcForm">
          <div class="pcForm-wrapper">
            <div class="form-group-row">
              <div class="col-12">
                <div class="pcInfoBoxSuccess alert alert-success">
                  <div class="pcInfoBoxSuccess-wrapper" [innerHtml]="'2fa_sms_step_info' | i18next: { number: '+49 0000 0000000' }"></div>
                </div>
              </div>
              <div class="col-12">
                <label class="w-100" for="code">{{ "2fa_sms_code_label" | i18next }}</label>
                <input class="pcInput" type="text" id="code" [placeholder]="'2fa_sms_code_label' | i18next" [(ngModel)]="authCode">
              </div>
              <div class="col-12">
                <div class="pcFormButtons">
                  <button class="btn btn-primary" (click)="verifyCode()">{{ "2fa_sms_send_btn" | i18next }}</button>
                </div>
              </div>
              <div class="col-12">
                <div class="pcFormButtons">
                  <button class="btn btn-link red" (click)="resendCode()">{{ "2fa_sms_resend_btn" | i18next }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
