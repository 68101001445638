export class CookieHelper {
  static set(cname: string, cvalue: string, exdays: number, path: string = '/'): void {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=' + path;
  }

  static get(cname: string): string {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  static exists(cname: string): boolean {
    return this.get(cname) !== null;
  }

  static delete(cname: string, cvalue: string = '', path: string = '/') {
    CookieHelper.set(cname, cvalue, -1, path);
  }
}
