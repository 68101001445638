import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockOffer} from '../../phonenumber/phonenumber.model';

@Component({
  selector: 'app-select-loc-ind-phonenumber',
  templateUrl: './select-loc-ind-phonenumber.component.html',
})
export class SelectLocIndPhonenumberComponent implements OnInit {

  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Input() blocks: BlockOffer[];

  selectedPhoneNumber: string = '';

  constructor() {
  }

  ngOnInit() {
  }

  onSelectionChange(selectedId: string) {
    this.selectedPhoneNumber = selectedId;
  }

  public select() {
    this.selected.emit(this.selectedPhoneNumber);
  }

}
