<ng-container *ngIf="customer">
  <app-content-header [historyBack]="false" (backEvent)="onBack()" [headline]="customer.name"></app-content-header>

  <div class="d-flex align-items-center mb-3">
    <div class="h3">{{ 'customer_subscriptions_headline' | i18next }}</div>
    <button class="btn btn-outline-primary ml-auto" (click)="onAddSubscription()">
      {{ 'customer_view_add_subscription' | i18next }}
    </button>
  </div>

  <app-customer-navigation [id]="customerId"></app-customer-navigation>

  <app-filter (searchFilterChanged)="doFilter($event)" [placeholder]="'customer_subscription_search' | i18next"></app-filter>

  <div *ngIf="!customerSubscriptions || customerSubscriptions.length === 0"><i class="fas fa-info-circle red"></i>&nbsp;{{'customer_has_no_subscriptions' | i18next}}
  </div>

  <div class="row align-items-stretch mt-2">
    <app-subscription-card class="col-xs-12 col-md-6 col-lg-6 mb-3" *ngFor="let lic of customerSubscriptions" [subscription]="lic" [detaillist]="true">
      <button *ngIf="lic.isValid" class="btn btn-outline-primary" [routerLink]="['/subscriptions', lic.appliance_id]">{{'app_details' | i18next}}</button>
        <div *ngIf="!lic.isValid" upgrade>
            <button *ngIf="lic.isOneTrial" class="btn btn-outline-primary" (click)="doUpgradeExpiredOneTrial(lic.appliance_id)">{{'subscription_upgrade_plan' | i18next}}</button>
            <button *ngIf="lic.isAnnualCloud && lic.isOneAppliance" class="btn btn-outline-primary" (click)="doUpgradeExpiredCloud(lic.appliance_id)">{{'subscription_upgrade_plan' | i18next}}</button>
        </div>
    </app-subscription-card>
  </div>
</ng-container>
