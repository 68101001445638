import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../company.model';
import {I18NextService} from 'angular-i18next';
import { AutorunComponent } from '@myshared/autorun.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: []
})
export class ContactCardComponent implements OnInit {

  @Input() contact: Contact;
  @Input() showMypascomLevel = true;
  @Input() detaillist = false;

  constructor(
    private i18next: I18NextService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  get mypascomLevel() {
    if (this.contact) {
      return this.i18next.t('mypascom_' + this.contact.mypascom_account) as string;
    }
    return '';
  }

  get mypascomLevelIcon(): string {
    if (this.contact.mypascom_account === 'admin') {
      return 'fa-user-tie';
    } else if (this.contact.mypascom_account === 'enabled') {
      return 'fa-user-circle';
    }
    return 'fa-times-circle';
  }

  ngOnInit() {
  }

  public editDetails() {
    if (this.detaillist) {
      this.router.navigate(['/company/contacts/', this.contact.id])
    }
  }

}
