/**
 * Local browser language for both IE and regular browsers
 * @returns {string}
 */
export function browserLanguage() {
    return navigator.language || (navigator as any).userLanguage;
}

/**
 * Turn a long language code ("de_DE") into a short value like "de"
 * @param {string} language
 * @returns {string} 2-character string or "en"
 */
export function extractShortLang(language: string): string {
    if (!language || language.length < 2) {
        return 'en';
    }
    return language.substr(0, 2);
}

export function extractShortCountry(language: string): string {
    if (!language || language.length < 2) {
        return 'us';
    }
    return language.slice(-2);
}

/**
 * "Normalize" any given language code to the values this application actually supports.
 * @param {string} code
 * @returns {string} Either de_DE or en_US
 */
export function normalizeLanguageCode(code: string): string {
    const curLang = code.replace('-', '_').toLowerCase();
    // Lets "normalize" the language to the list of languages we *really* support
    switch (curLang) {
        case 'de':
        case 'de_de':
        case 'de_ch':
        case 'de_at':
            // This is just german for us.
            return 'de_DE';
        default:
            // Either an unsupported language or some fishy value. Lets stick to the default
            return 'en_US';
    }
}

/**
 * Returns a short language code, normalized to values supported by the translation system
 * @param {string} code a langauge code like "de-DE" or "en_US"
 * @returns {string} "de" or "en"
 */
export function normalizedShortLang(code: string): string {
    return extractShortLang(normalizeLanguageCode(code));
}
