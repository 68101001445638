import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockOffer} from '../../../phonenumber/phonenumber.model';

@Component({
  selector: 'app-select-loc-ind-phonenumber-card',
  templateUrl: './select-loc-ind-phonenumber-card.component.html',
})
export class SelectLocIndPhonenumberCardComponent implements OnInit {

  @Input() blockOffer: BlockOffer;
  @Input() isSelected: boolean;
  @Output() toggle: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  select() {
    this.toggle.emit(this.blockOffer.blockId);
  }
}
