import {Component, OnInit} from '@angular/core';
import { Ticket, ArticleRequest, TicketState, AttachmentRequest, AttachedFile } from '../support.model';
import { SupportService } from '../support.service';
import { ActivatedRoute, Router } from '@angular/router';
import {firstValueFrom} from "rxjs";
import {SubscriptionService} from "../../subscription/subscription.service";
import {Subscription} from "../../subscription/subscription.model";

@Component({
  selector: 'app-ticket-details-card',
  templateUrl: './ticket-details.component.html',
  styleUrls: []
})
export class TicketDetailsComponent implements OnInit {

  public ticket: Ticket;
  public articleMessage: string;
  public isRequesting = false;
  public isUploading = false;
  public attachments: AttachmentRequest[] = [];
  public isTicketView = true;
  public subscription: Subscription;

  constructor(private supportService: SupportService,
              private route: ActivatedRoute,
              private subscriptionService: SubscriptionService,
              private router: Router) {}

  get isTicketClosed(): boolean {
    return this.ticket.stateId === TicketState.closed;
  }

  async ngOnInit() {
    const ticketId = +this.route.snapshot.paramMap.get('id');
    await this.getTicketDetails(ticketId);

    try {
      this.subscription = Subscription.fromJson(
        await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.ticket.applianceId))
      );
    } catch (e) {
      this.subscription = new Subscription();
    }
  }

 async getTicketDetails(ticketId: number) {
    try {
      this.ticket = await firstValueFrom(this.supportService.getTicketDetails(ticketId));
    } catch (e) {
      this.router.navigate(['/support']);
    }
  }

  onUpload(event) {
    this.isUploading = event;
  }

  onFilesChanged(attachedFiles: AttachedFile[]) {
    this.attachments = [];
    // Get attachment for article request
    for (const attachedFile of attachedFiles) {
        const attachmentRequest = new AttachmentRequest();
        attachmentRequest.fileId = attachedFile.fileId;
        attachmentRequest.name = attachedFile.file.name;
        this.attachments.push(attachmentRequest);
    }
  }

  sendArticle() {
    if (!this.articleMessage || this.isRequesting || this.isUploading) {
      return;
    }
    const article = new ArticleRequest();
    const ticketId = this.ticket.id;
    article.body = this.articleMessage;
    article.ticketId = ticketId;
    if (this.attachments.length > 0) {
      article.attachments = this.attachments;
    }
    this.isRequesting = true;
    this.supportService.addArticle(article).subscribe({
      next: () => {
        this.articleMessage = '';
        this.getTicketDetails(ticketId);
        this.isRequesting = false;
        this.attachments = [];
      },
      error: (e) => {
        this.isRequesting = false;
        console.error(e);
      }
    });
  }

  closeTicket() {
    this.isTicketView = false;
  }

  onCloseCancel() {
    this.isTicketView = true;
  }

  onCloseConfirm() {
    if (this.isRequesting) {
      return;
    }
    this.isTicketView = true;
    this.isRequesting = true;
    this.supportService.closeTicket(this.ticket.id).subscribe(r => {
      this.getTicketDetails(this.ticket.id);
      this.isRequesting = false;
    });
  }
}
