<div class="content-card-container-bg">
  <div class="content-card-container">
    <div class="content-card text-align-center">
      <div>
        <img src="assets/images/flamingo.svg" alt="pascom welcome picture">
      </div>
      <div>
        <h1>{{ 'thank_you_tariff_headline' | i18next}}</h1>
        <p class="gray">{{ 'thank_you_tariff_content' | i18next }} </p>
        <div class="mt-3 mb-3">
          <button class="btn btn-outline-primary" (click)="openBackroute()">{{ 'thank_you_tariff_back_button' | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

