import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {filterText} from '@myshared/utils';
import {runInAction} from 'mobx';
import {ActivatedRoute, Router} from '@angular/router';
import {AutorunComponent} from '@myshared/autorun.component';
import {action, observable} from '@myshared/mobx-connector';
import {Contact} from '../../company/company.model';
import {Customer} from '../customer.model';
import {CustomerService} from '../customer.service';

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: []
})
export class CustomerContactsComponent extends AutorunComponent implements OnInit {
  public customerId: string;

  contacts: Contact[];
  customer: Customer;
  is20CSP: boolean;

  @observable filter$: string;
  @observable private showDisabled$: boolean;

  constructor(
    private m: Model,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId');
    runInAction(() => {
      this.showDisabled$ = false;
      this.filter$ = '';
    });
    this.autorun(() => {
      this.customer = this.m.customer.customers.find((x) => x.id == this.customerId);
      this.is20CSP = this.m.account.currentUser.companyIsPC20Csp;
      if (this.customer?.contacts) {
        this.contacts = filterText<Contact>(this.customer?.contacts, this.filter$)
          .filter(c => this.showDisabled$ ? true : c.mypascom_account !== 'disabled');
      }
    });
    this.customerService.refresh(true);
  }

  @action public showDisabled(value: boolean) {
    this.showDisabled$ = value;
  }

  @action
  doFilter(value: string) {
    this.filter$ = value;
  }

  public onBack() {
    this.router.navigate(['/customers']);
  }
}
