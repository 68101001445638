<app-content-header backLabel="app_cancel" [historyBack]="false" (backEvent)="onCancel()" [headline]="'change_fallback_number_headline' | i18next"></app-content-header>

<div class="row d-flex justify-content-center mt-2" *ngIf="subscription">
    <div class="col-sm-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
        <app-icon-card>
            <h3 class="h3 red mb-3">{{ 'fallback_number_headline' | i18next }}</h3>

            <div class="mt-2" *ngIf="subscription.fallbackNumber"
                 [innerHTML]="'fallback_number_details' | i18next: {fallbackNumber: subscription.formattedFallbackNumber}"></div>
            <div class="mt-2" *ngIf="!subscription.fallbackNumber"
                 [innerHTML]="'fallback_number_not_configured' | i18next"></div>
            <div class="d-flex mt-3 mb-5">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations">{{ 'fallback_number_note' | i18next }}</span>
                </div>
            </div>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{ numberCountryPrefixPreview }}</span>
                <input type="text" pInputText
                       [ngClass]="{ 'ng-invalid': validationError }"
                       [placeholder]="'fallback_number_placeholder' | i18next"
                       (ngModelChange)="onFallbackNumberChange($event)"
                       (blur)="onFallbackNumberChange(fallbackNumber)"
                       [(ngModel)]="fallbackNumber">
            </div>
            <div class="position-relative">
                <small *ngIf="validationError" id="instancename-help" class="p-error block position-absolute">{{ validationError }}</small>
            </div>

            <div class="row d-flex justify-content-center mt-5">
                <button class="btn btn-primary"
                        (click)="onChangeFallbackNumber()"
                        [disabled]="!inputDirty || validationError !== ''">
                    {{ 'fallback_number_button' | i18next}}
                </button>
            </div>
        </app-icon-card>
    </div>
</div>