import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-subscription-itemized-billing',
  templateUrl: './subscription-itemized-billing.component.html'
})
export class SubscriptionItemizedBillingComponent implements OnInit {

  @Input() public subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  public doUpdateItemizedBilling() {
    this.router.navigate(['/do', 'itemized-billing', this.subscription.appliance_id], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }
}
