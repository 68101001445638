import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {HttpClient} from '@angular/common/http';
import {BuyService} from '../buy.service';
import {takeUntil} from 'rxjs/operators';
import {interval, Subject} from 'rxjs';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cloud-thankyou',
  templateUrl: './cloud-thankyou.component.html',
  styleUrls: []
})
export class CloudThankyouComponent extends AutorunComponent implements OnInit, OnDestroy {

  @Input() orderName: string;

  public token: string;
  public applianceName: string;
  public redirectUrl: string;
  public cloudUrl: string;
  public disabled = true;
  public activeUrl: string;
  private doPoll = true;

  private stop$: Subject<boolean>;

  public backroute: string[] = ['/'];

  constructor(private m: Model,
              private buyService: BuyService,
              private router: Router,
              private subscriptionService: SubscriptionService,
              private http: HttpClient) { super(); }

  ngOnInit() {
    this.activeUrl = 'disabledLink';
    this.stop$ = new Subject<boolean>();
    this.autorun(() => {
      if (this.m.buy.currentOrder.aid) {
        this.backroute = ['/subscriptions', this.m.buy.currentOrder.aid];
        this.stop$.next(true);
        this.subscriptionService.generateSsoToken(this.m.buy.currentOrder.aid).subscribe(r => {
          this.token = r.token;
          this.applianceName = r.instance;
          this.cloudUrl = r.url;
          this.redirectUrl = r.url + '?t=' + r.token;
          this.startPolling();
        });
      }
    });
    interval(15000).pipe(takeUntil(this.stop$)).subscribe(() => {
      if (!this.m.buy.currentOrder.aid) {
        this.fetchOrder();
      }
    });
    this.subscriptionService.refresh(true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stop$.next(true);
    this.doPoll = false;
  }

  fetchOrder() {
    this.buyService.refresh(this.orderName);
  }

  private poll() {
    this.http.get<any>(this.cloudUrl + '/status').subscribe(
      (r) => {
          if (r.bootstate === 'running') {
            this.activeUrl = '';
            this.disabled = false;
            this.doPoll = false;
        }
    });
  }

  async startPolling() {
    while (this.doPoll) {
      this.poll();
      await this.sleep(2000);
    }
  }

  private sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public openBackroute() {
    this.m.workflow.resetWorkflow();
    window.location.replace(this.backroute.join('/'));
  }

}
