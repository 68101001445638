import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {SubscriptionService} from '../subscription.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';

@Component({
  selector: 'app-subscription-details-card',
  templateUrl: './subscription-details-card.component.html',
})
export class SubscriptionDetailsCardComponent extends AutorunComponent implements OnInit {

  @Input() public subscription: Subscription;
  public isCsp: boolean;

  constructor(private m: Model,
              private router: Router,
              private route: ActivatedRoute,
              private subscriptionService: SubscriptionService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isCsp = this.m.account.currentUser.companyIsCsp;
    });
  }

  public goToCloudInstance() {
    this.subscriptionService.generateSsoToken(this.subscription.appliance_id).subscribe(r => {
      const url = r.url + '/?t=' + r.token;
      window.open(url, '_blank');
    });
  }

  public doChangeHost() {
    this.router.navigate(['/do', 'change-host', this.subscription.appliance_id], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }

  public get isNotPascomOne(): boolean {
    return !this.subscription.isOneAppliance && !this.subscription.isOne && !this.subscription.isOneTrial;
  }
}
