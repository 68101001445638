<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card h-100 card-nb">
      <div class="card-body pb-0">
        <h2 class="text-uppercase text-align-center">{{ "support_ticket_close_headline" | i18next: {ticketNumber: ticketNumber} }}</h2>
      </div>
      <div class="card-body pt-0 pb-0">
        <div>{{ "support_ticket_close_description" | i18next }}</div>
        <div class="row mt-3 justify-content-end">
          <button name="cancel-ticket-abort" class="btn btn-link" (click)="onBack()">{{ "app_cancel"  | i18next }}</button>
          <button name="close-ticket-confirm" class="btn btn-outline-primary" (click)="onConfirm()">{{ "support_ticket_close_confirm"  | i18next }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
