/**
 * Model to show the price card
 */

export const slaProductNames = ['MDSL1', 'MDSL2', 'MDSL3'];
export const slaOneProductNames = ['PC-ONE-SLA1', 'PC-ONE-SLA2', 'PC-ONE-SLA3'];
export const slaProductFeatures = [
  {
    'name': 'MDSL1',
    'features': [
      {
        'name': 'sla_product_feature_reaction_next_day',
        'highlighted': false
      },
      {
        'name': 'sla_product_feature_support_sla1',
        'highlighted': false
      }
    ]
  },
  {
    'name': 'MDSL2',
    'features': [
      {
        'name': 'sla_product_feature_reaction_4_hours',
        'highlighted': true
      },
      {
        'name': 'sla_product_feature_support_sla2',
        'highlighted': true
      }
    ]
  },
  {
    'name': 'MDSL3',
    'features': [
      {
        'name': 'sla_product_feature_reaction_2_hours',
        'highlighted': true
      },
      {
        'name': 'sla_product_feature_support_sla3',
        'highlighted': true
      }
    ]
  },
  {
    'name': 'PC-ONE-SLA1',
    'features': [
      {
        'name': 'pc_one_sla_feature_reaction_next_day',
        'highlighted': true
      },
      {
        'name': 'pc_one_sla_feature_support_sla1',
        'highlighted': true
      }
    ]
  },
  {
    'name': 'PC-ONE-SLA2',
    'features': [
      {
        'name': 'pc_one_sla_feature_reaction_4_hours',
        'highlighted': true
      },
      {
        'name': 'sla_product_feature_support_sla2',
        'highlighted': true
      }
    ]
  },
  {
    'name': 'PC-ONE-SLA3',
    'features': [
      {
        'name': 'pc_one_sla_feature_reaction_2_hours',
        'highlighted': true
      },
      {
        'name': 'sla_product_feature_support_sla3',
        'highlighted': true
      }
    ]
  }
];
export const PC_ONE_TRUNK_LINES: number = 5;
export const PC_ONE_ENDPOINTS: number = 5;
export const PC_ONE_PHONE_NUMBERS: number = 5;

export interface ProductCard {
  name: string;
  description: string;
  products: Product[];
  slaProducts: SlaProduct[];
  features: ProductFeature[];
  btnText: string;
}

export interface Product {
  name: string;
  list_price: number; // list price is the standard price without any calculation
  users: string;
  priceDiscount?: ProductPriceDiscount[]; // for calculation
}

export interface ProductFeature {
  name: string;
  highlighted: boolean;
}

export class SlaProduct {
  name: string;
  list_price: number;
  displayName?: string;
  monthlyPayment?: boolean;
  features?: ProductFeature[];
  type?: 'upgrade' | 'downgrade' | 'selected' | 'select';

  static fromJson(json): SlaProduct {
    const s = new SlaProduct();
    s.name = json.name ?? '';
    s.list_price = json.list_price ?? 0;
    return s;
  }
}

export class ProductPriceDiscount {
  public discount: number;
  public quantity: number;

  static fromJson(json): ProductPriceDiscount {
    const p = new ProductPriceDiscount();
    p.discount = json.discount ?? null; // check undefined, null is okay
    p.quantity = json.quantity ?? null;
    return p;
  }
}
