import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {BuyService} from '../buy.service';
import {SaleOrder} from '../buy.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';

@Component({
  selector: 'app-buy-thankyou',
  templateUrl: './buy-thankyou.component.html'
})
export class BuyThankyouComponent extends AutorunComponent implements OnInit {

  @Input() orderName: string;

  public order: SaleOrder;
  public isUpgrade: boolean;
  public fromInstance = false;

  public backroute: string[];
  public isCloud: boolean;

  constructor(private m: Model,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router) {  super(); }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('toinstance')) {
      this.fromInstance = true;
    }
    this.backroute = ['/'];
    this.autorun(() => {
      this.isUpgrade = this.m.workflow.isUpgradeSubscription
        || this.m.workflow.isOneUpgrade
        || this.m.workflow.isTariffMigration
        || this.m.workflow.isCloudMigration;
      if (this.isUpgrade && !this.route.snapshot.queryParamMap.has('fromwf')) {
        this.backroute = ['/subscriptions', this.m.buy.currentOrder.aid];
      }
      // pascom ONE is a cloud product
      this.isCloud = this.m.buy.currentOrder.product.indexOf('CLOUD') !== -1 ||
        this.m.buy.currentOrder.product.indexOf('ONE') !== -1;
    });

    this.route.queryParamMap.subscribe(params => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }
    });

    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
    });
    this.buyService.refresh(this.orderName);
    this.subscriptionService.refresh(true);
  }

  public openBackroute() {
    if (this.fromInstance) {
      const aid = this.m.buy.currentOrder.aid;
      // When we come from instance (commander) -> Upgrade plan and not directly from mypascom
      // We need to read the subscription detail to get the cloud url (only exists in detail)
      this.subscriptionService.getSubscriptionDetails(+aid).subscribe(r => {
        const subscription = Subscription.fromJson(r);
        if (subscription?.cloud_url) {
          window.location.replace(subscription.cloud_url);
          return;
        }
        this.navigateToBackroute();
      });
    } else {
      this.navigateToBackroute();
    }
  }

  private navigateToBackroute() {
    window.history.replaceState(null, null, '/subscriptions');
    this.m.workflow.resetWorkflow();
    this.router.navigate(this.backroute);
  }

  public openDocumentation() {
    window.open('http://doc.pascom.net/');
  }

  public get thankyouContentData() {
    const data = {
      'headline': 'thank_you_onsite_headline',
      'content': 'thank_you_onsite_content',
      'documentation': 'thank_you_onsite_documentation',
      'backrouteButton': 'thank_you_onsite_pascom',
      'showBackrouteButton': false
    };
    if (this.isUpgrade) {
      data['headline'] = 'thank_you_upgrade_headline';
      data['content'] = 'thank_you_upgrade_content_onsite';
      data['documentation'] = null;
      if (this.isCloud || !this.fromInstance) {
        data['showBackrouteButton'] = true;
      }
      if (this.isCloud) {
        data['content'] = 'thank_you_upgrade_content_cloud';
      }
    } else {
      data['showBackrouteButton'] = true;
    }
    return data;
  }

}
