<app-content-header (backEvent)="backEmitted()"
                    [hideBackButton]="hideBackButton"
                    [hint]="testPhoneNumberHint | i18next"
                    [headline]="'test_phonenumber_headline' | i18next">
</app-content-header>

<div class="d-flex justify-content-center mt-5 mb-3">
  <div class="d-flex flex-column">
    <div class="alert" [ngClass]="{ 'alert-success': !!phonenumber, 'alert-warning': phonenumber === '' }" role="alert">
      <h1 class="m-3" *ngIf="!!phonenumber">{{ 'your_phonenumber_text' | i18next }} <span class="text-nowrap">
        {{ phonenumber | phonenumber }}
      </span></h1>
      <h1 class="m-3" *ngIf="phonenumber === ''">{{ 'manage_phonenumber_later' | i18next }}</h1>
    </div>
    <app-pascom-checkbox [text]="acceptTermsText | i18next" (acceptChanged)="accept($event)"></app-pascom-checkbox>
  </div>
</div>


<div class="d-flex justify-content-end align-items-center mb-3">

  <button class="btn btn-outline-primary" type="submit" (click)="onBuy()"
          [disabled]="!accepted">{{ 'app_next' | i18next}}
  </button>
</div>
