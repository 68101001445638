import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: []
})
export class SearchboxComponent implements OnInit {

  @Output() readonly filterChanged = new EventEmitter<string>();

  @Input() placeholder = '';
  @Input() onSearching = false;

  constructor() { }

  ngOnInit() {
  }
}
