import {Component, EventEmitter, OnInit, Output, AfterViewInit, Input} from '@angular/core';
import {DynamicFormControlModel, DynamicFormLayout} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {PCFormsService, PCFormBuilder} from '@myshared/pcform.service';
import {CompanyService} from '../../company/company.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {BankAccount, Company} from '../../company/company.model';
import {Model} from '../../app.model';
import {BuyService} from '../buy.service';
import {getSepaDetailsFromForm, sepaForm, setSepaDetailsToForm} from '../../company/sepa.form';
import {PaymentMethodCode} from '../buy.model';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: []
})
export class PaymentMethodComponent extends AutorunComponent implements OnInit, AfterViewInit {

  @Input() public backLabel = 'app_back';
  @Input() public historyBack = true;
  @Input() public company?: Company;
  @Input() public onlyEmit = false;
  @Input() paymentMethod: PaymentMethodCode = 'sepa';

  @Output() public backEvent = new EventEmitter();
  @Output() public readonly next: EventEmitter<{bank?: BankAccount, method?: PaymentMethodCode}>
    = new EventEmitter<{bank?: BankAccount, method?: PaymentMethodCode}>();

  formModel: DynamicFormControlModel[];
  formGroup: FormGroup;
  formLayout: DynamicFormLayout;
  bankAccount: BankAccount;

  initialized = false;

  private builder: PCFormBuilder;

  constructor(private pcFormService: PCFormsService,
              private companyService: CompanyService,
              private buyService: BuyService,
              private m: Model) {
    super();
  }

  async ngOnInit() {
    if (!this.company) {
      this.company = await firstValueFrom(this.companyService.getMyCompany());
    }

    // If the company can do wire transfer because no SEPA exists
    if (this.paymentMethod === 'wire_transfer') {
      this.onNextWireTransfer();
      return;
    }

    // Create SEPA Form
    this.builder = sepaForm(this.pcFormService);
    this.formModel = this.builder.model;
    this.formLayout = this.builder.layout;
    this.formGroup = this.builder.createFormGroup();

    if (this.company.bankAccounts.length > 0) {
      this.bankAccount = this.company.bankAccounts[0];
    } else {
      this.bankAccount = BankAccount.fromJson({});
    }

    setSepaDetailsToForm(this.formGroup, this.bankAccount);

    this.initialized = true;
  }

  ngAfterViewInit(): void {
    if (this.builder) {
      this.builder.autoFocus();
    }
  }

  onNextWireTransfer() {
    this.next.emit({ method: 'wire_transfer' });
  }

  onNextSepa() {
    if (this.formGroup.status !== 'VALID') {
      return;
    }

    const bankAccount = getSepaDetailsFromForm(this.formGroup, this.bankAccount.id);

    if (this.onlyEmit) {
      this.next.emit({bank: bankAccount, method: 'sepa' });
      return;
    }

    this.companyService.updateBankAccount(bankAccount).subscribe(ok => {
      this.next.emit({bank: bankAccount, method: 'sepa' });
    });
  }

  public onBack() {
    this.backEvent.emit();
  }
}
