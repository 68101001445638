import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockOffer} from '../../phonenumber/phonenumber.model';

@Component({
  selector: 'app-select-phonenumber',
  templateUrl: './select-phonenumber.component.html',
})
export class SelectPhonenumberComponent implements OnInit {

  @Output() selected: EventEmitter<Map<string, boolean>> = new EventEmitter<Map<string, boolean>>();
  @Input() blocks: BlockOffer[];

  selectedPhonenumbers: Map<string, boolean> = new Map<string, boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  public onPhonenumberBlockToggled(event: {id: string; selected: boolean}) {
    if (event.selected) {
      this.selectedPhonenumbers.set(event.id, event.selected);
    } else {
      this.selectedPhonenumbers.delete(event.id);
    }
  }

  public select() {
    this.selected.emit(this.selectedPhonenumbers);
  }

}
